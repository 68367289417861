let accounts = null;

function getAccounts() {
    if(accounts === null) {
        accounts = [
            {
                Id: 1,
                Email: "admin@fcim.org",
                FirstName: "Fcim",
                LastName: "Test",
                Password: "123",
                isadmin: true,
                Role: "Administrator",
                RoleId: 0,
                DistrictId: 0,
                DistrictName: "Admin (No district)",
                IsActive: true,
                ResetPasswordGuid: "admin",
                ResetPasswordExpiration: false
            },
            {
                Id: 2,
                Email: "proctor@fcim.org",
                FirstName: "Proctor",
                LastName: "Test",
                Password: "123",
                isadmin: false,
                Role: "Proctor",
                RoleId: 1,
                DistrictId: 37,
                DistrictName: "Leon",
                IsActive: true,
                ResetPasswordGuid: "proctor",
                ResetPasswordExpiration: true
            },
            {
                Id: 3,
                Email: "adminInactive@fcim.org",
                FirstName: "FcimA",
                LastName: "TestA",
                Password: "123",
                isadmin: true,
                Role: "Admin",
                RoleId: 0,
                DistrictId: 0,
                DistrictName: "Admin (No district)",
                IsActive: false,
                ResetPasswordGuid: "adminInactive",
                ResetPasswordExpiration: false
            },
            {
                Id: 4,
                Email: "proctorInactive@fcim.org",
                FirstName: "ProctorA",
                LastName: "TestA",
                Password: "123",
                isadmin: false,
                Role: "Proctor",
                RoleId: 1,
                DistrictId: 37,
                DistrictName: "Leon",
                IsActive: false,
                ResetPasswordGuid: "proctorInactive",
                ResetPasswordExpiration: false
            },
            {
                Id: 5,
                Email: "author@fcim.org",
                FirstName: "Author",
                LastName: "FCRR",
                Password: "123",
                isadmin: false,
                Role: "Author",
                RoleId: 3,
                DistrictId: 37,
                DistrictName: "Leon",
                IsActive: true,
                ResetPasswordGuid: "proctorInactive",
                ResetPasswordExpiration: false
            },
            {
                Id: 6,
                Email: "siteCoordinator@fcim.org",
                FirstName: "siteCoordinator",
                LastName: "FCRR",
                Password: "123",
                isadmin: false,
                Role: "SiteCoordinator",
                RoleId: 4,
                DistrictId: 37,
                DistrictName: "Leon",
                IsActive: true,
                ResetPasswordGuid: "proctorInactive",
                ResetPasswordExpiration: false
            }
        ];
    }

    return accounts;
}

export {getAccounts};

function saveAccounts(newAccountList) {
    accounts = newAccountList;
}

export {saveAccounts};