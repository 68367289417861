import React from 'react';
import PropTypes from "prop-types";
import { FormValidation, ValidatorsProvider } from "calidation";
import { generateCatModelOptions } from "../../constants/catModelConstants";
import TextField from "../../components/inputs/textField";
import {hasErrors} from "../../components/form/createErrorList";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import ButtonBar from "../../components/buttons/buttonBar";
import SelectField from "../../components/inputs/selectField";
import ItemParameterList from "./ItemParameterList";
import ImportErrorNotification from "../../components/notification/importErrorNotification";

const ItemPoolConfigurationInfoForm = ({config}) => {
    const extraValidators = {
        isSelectRequired: config => value => (value === null || value === undefined || value === 0) ? config.message : null,
    };

    let formConfig = {
        Name: {
            isRequired: 'Item Pool Name is required!'
        },
        CatModelType: {
            isSelectRequired: 'CAT Model Type is required!'
        },
        Id: {}
    };

    const initValues = Object.assign({}, { Name: config.itemPoolInfo.Name, CatModelType: config.itemPoolInfo.CatModelType});

    function isDisabled(isLoading, isValidated, importResults)
    {
        const hasMessage = !importResults ? false : !!importResults.Message;
        return (isLoading ||  isValidated || hasMessage);
    }

    return (
        <ValidatorsProvider validators={extraValidators}>
            <FormValidation onSubmit={config.onItemPoolSubmit} config={formConfig} initialValues={initValues} id={"itemPool-form"} >
                {({ fields, errors, submitted }) => (
                    <div className={"grid-x"}>
                        <div className={"cell center-element"}>
                            <input type={"hidden"} name={"Id"} value={fields.Id} />
                            {submitted && hasErrors(errors) && <p className="text-danger">Please correct the issues as specified.</p>}
                            {config.isImportError(submitted, config.itemPoolInfo) && <div className="text-danger">Item parameters have not been uploaded.</div>}
                            <div className={"grid-x"}>
                                <div className={"cell "}>
                                    <TextField config={{
                                        name: "Name",
                                        label: "Name",
                                        maxLength: 50,
                                        showLabel: true,
                                        value: fields.Name,
                                        error: (submitted && errors.Name) ? errors.Name : ""
                                    }} />
                                </div>
                            </div>
                            <div className={"grid-x"}>
                                <div className={"cell medium-5 large-5"}>
                                    <SelectField config={{
                                        name: "CatModelType",
                                        label: "CAT Model",
                                        showLabel: true,
                                        options: generateCatModelOptions(),
                                        value: fields.CatModelType.toString(),
                                        defaultOption: "Select a CAT Model",
                                    }} />
                                    {submitted && errors.CatModelType && <div className="text-danger">{errors.CatModelType}</div>}
                                </div>
                            </div>
                            <hr/>
                            <ItemParameterList key={config.itemPoolInfo.id} config={{
                                itemPoolInfo: config.itemPoolInfo,
                                onImportItemParameters: config.onImportItemParameters,
                                onExportItemParameters: config.onExportItemParameters
                            }}/>
                            <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_RIGHT} wrapperClass={"margin-top-2"}>
                                <Button config={{
                                    name: "btnCancel",
                                    label: "Cancel",
                                    onClick: config.onReturnItemPoolList,
                                    btnClass: "secondary",
                                    btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                }} />
                                <Button config={{
                                    name: "btnSave",
                                    label: "Save",
                                    disabled: isDisabled(config.isLoading, hasErrors(errors), config.importResults)
                                }}/>
                            </ButtonBar>
                        </div>
                    </div>
                )}
            </FormValidation>
            <ImportErrorNotification importResults={config.importResults} />
        </ValidatorsProvider>
    );
};

ItemPoolConfigurationInfoForm.propTypes = {
    config: PropTypes.shape({
        onItemPoolSubmit: PropTypes.func.isRequired,
        onExportItemParameters: PropTypes.func.isRequired,
        onImportItemParameters: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        itemPoolInfo: PropTypes.object,
        itemPoolSearchCriteria: PropTypes.object,
        onReturnItemPoolList: PropTypes.func.isRequired,
        message: PropTypes.string,
        isImportError: PropTypes.func.isRequired,
        importResults: PropTypes.object
    })
};

export default ItemPoolConfigurationInfoForm;