import React from "react";
import PropTypes from "prop-types";

const Loader = ({ isLoading }) => {
    const className = isLoading ? "" : "is-hidden";
    return (
        <div className={"loader " + className}>
            <span className="show-for-sr">Loading</span>
        </div>
    );
};

Loader.propTypes = {
    isLoading: PropTypes.bool.isRequired
};

Loader.defaultProps = {
    isLoading: false
};

export default Loader;