import { beginAjaxCall, ajaxCallError } from "../AjaxStatus/AjaxStatusActions";
import * as actionTypes from "./LoginConstants";
import storage from "../../components/localStorage/access";
import loginApi from "./LoginApi";

export function loginSuccess(isLoggedIn, isAdmin, role) {
    const loginData = { isLoggedIn: isLoggedIn, isAdmin: isAdmin, role: role};
    return { type: actionTypes.LOGIN_AJAX_SUCCESS, loginData };
}

export function attemptLogin(loginViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return loginApi.attemptLogin(loginViewModel)
            .then((loginInfo) => {
                storage.persistLogin(loginInfo.AccessToken, loginInfo.ExpiresIn, loginInfo.Identity.RefreshToken, loginInfo.Identity.RefreshTokenExpiresIn, loginInfo.IsAdmin, loginInfo.Role);
                dispatch(loginSuccess(true, loginInfo.IsAdmin, loginInfo.Role));
            })
            .catch(error => {
                storage.logout();
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function setRedirectUrl(redirectUrl) {
    return {type: actionTypes.LOGIN_SET_RETURNURL, redirectUrl};
}

export function refreshLogin() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return loginApi.refreshToken(storage.getRefreshToken())
            .then((loginInfo) => {
                storage.persistLogin(loginInfo.AccessToken, loginInfo.ExpiresIn, loginInfo.Identity.RefreshToken, loginInfo.Identity.RefreshTokenExpiresIn, loginInfo.IsAdmin, loginInfo.Role);
                dispatch(loginSuccess(true, loginInfo.IsAdmin, loginInfo.Role));
            })
            .catch(error => {
                storage.logout();
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}