import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as itemBankActions from "./ItemBankActions";
import {itemBankInitialState} from "./ItemBankReducer";
import * as routes from "../../components/routes/routesConstants";
import ItemBankListSearchForm from "./ItemBankListSearchForm";
import ItemBankListSearchResults from "./ItemBankListSearchResults";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import {Search} from "../../components/table/Search";
import localStorage from "../../components/localStorage/access";
import * as loginActions from "../Login/LoginAction";

export class ItemBankListPage extends Search {
    constructor(props, context) {
        super(props, context, itemBankInitialState.itemBankSearchCriteria);

        this.state = {
            isLoading: false
        };

        this.onAddItemBank = this.onAddItemBank.bind(this);
        this.onEditItemBank = this.onEditItemBank.bind(this);
        this.onDeleteItemBank = this.onDeleteItemBank.bind(this);
    }

    componentDidMount(){
        PushFocusToElement("NameContains");
        this.props.actions.updatePageTitle_BreadCrumb(routes.ITEM_BANK_LIST);
        if(this.props.itemBankSearchCriteria !== undefined &&
            this.props.itemBankSearchCriteria.itemBankSearchPerformed) {
            this.props.actions.loadItemBanks(this.props.itemBankSearchCriteria);
        }

        this.setLoadSearchResults(this.loadItemBanks);
        this.setProps(this.props.itemBankSearchCriteria, this.props.itemBankTotalRecords);
    }

    componentDidUpdate() {
        this.setProps(this.props.itemBankSearchCriteria, this.props.itemBankTotalRecords);
    }

    loadItemBanks(itemBankSearchCriteria) {
        itemBankSearchCriteria.itemBankSearchPerformed = true;
        this.props.actions.saveItemBankSearchCriteria(itemBankSearchCriteria);

        this.props.actions.loadItemBanks(itemBankSearchCriteria);
    }

    onAddItemBank(event) {
        event.preventDefault();

        this.props.history.push(routes.ITEM_BANK_INFO.path.replace(routes.ITEM_BANK_INFO.params.itemBankId, 0));
    }

    onEditItemBank(event, itemBankId) {
        event.preventDefault();

        this.props.history.push(routes.ITEM_BANK_INFO.path.replace(routes.ITEM_BANK_INFO.params.itemBankId, itemBankId));
    }

    onDeleteItemBank(event, deleteLinkInfo) {
        event.preventDefault();

        if(confirm("Are you sure you want to delete this item bank?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page."))
        {
            this.props.actions.deleteItemBank(deleteLinkInfo)
                .then(() => this.reloadAfterDelete())
        }
    }

    reloadAfterDelete() {
        let criteria = Object.assign({}, this.props.itemBankSearchCriteria);
        criteria.Page = 1;

        this.loadItemBanks(criteria);
    }

    render() {
        let {itemBankTotalRecords, itemBankSearchCriteria, itemBankSearchResults } = this.props;

        return (
            <div>
                <ItemBankListSearchForm config={{
                    onSearchSubmit: this.onSearchSubmit,
                    clearCriteria: this.clearCriteria,
                    isLoading: this.state.isLoading,
                    itemBankSearchCriteria: itemBankSearchCriteria,
                    onAddItemBank: this.onAddItemBank
                }} />
                {itemBankSearchCriteria !== undefined &&
                itemBankSearchCriteria.itemBankSearchPerformed &&
                <div id={"searchResults"}>
                    <hr/>
                    <ItemBankListSearchResults config={{
                        itemBankSearchResults: itemBankSearchResults,
                        order: this.order,
                        first: this.first,
                        last: this.last,
                        next: this.next,
                        previous: this.previous,
                        itemBankTotalRecords: itemBankTotalRecords,
                        currentPage: itemBankSearchCriteria.Page,
                        itemBankRecordsPerPage: itemBankSearchCriteria.RecordsPerPage,
                        onEditItemBank: this.onEditItemBank,
                        onDeleteItemBank: this.onDeleteItemBank
                    }}/>
                </div>
                }
            </div>
        );
    }
}

ItemBankListPage.propTypes = {
    itemBankSearchResults: PropTypes.arrayOf(PropTypes.object),
    itemBankSearchCriteria: PropTypes.object,
    itemBankTotalRecords: PropTypes.number,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        itemBankSearchResults: state.itemBank.itemBankSearchResults,
        itemBankSearchCriteria: state.itemBank.itemBankSearchCriteria,
        itemBankTotalRecords: state.itemBank.itemBankTotalRecords
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        itemBankActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (ItemBankListPage));
