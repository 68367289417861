import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as routes from "../../components/routes/routesConstants";
import SlabSearchForm from "./SlabSearchForm";
import * as slabActions from "./SlabActions";
import {Notify} from "../../components/notification/notify";
import SlabSearchResults from "./SlabSearchResults";
import moment from "moment";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import $ from "jquery";

export class SlabPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            hasSubmitted: false,
            errors: {}
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onExportScores = this.onExportScores.bind(this);
        this.onProcessAudioFiles = this.onProcessAudioFiles.bind(this);
    }

    componentDidMount(){
        this.props.actions.updatePageTitle_BreadCrumb(routes.SLAB);

        if (this.props.slabSearchCriteria !== undefined &&
            this.props.slabSearchCriteria.slabSearchPerformed) {
            this.setState({hasSubmitted: true});
            this.props.actions.loadSlabInfo(this.props.slabSearchCriteria);
        }
        else {
            this.setState({hasSubmitted: false});
            $(document).ready(() => {
                window.setTimeout(function() {
                    PushFocusToElement("StartDate");
                }, 200);
            });
        }
    }

    onDateChange(selectedDates, dateStr, instance) {
        const slabSearchCriteria = Object.assign({}, this.props.slabSearchCriteria);
        const searchFieldName = instance.config.class;

        dateStr = (dateStr === "") ? null : dateStr;

        slabSearchCriteria[searchFieldName] = dateStr;

        slabSearchCriteria.slabSearchPerformed = false;
        this.props.actions.saveSlabSearchCriteria(slabSearchCriteria);
    }

    searchFormIsValid(searchCriteria, errors) {
        let formIsValid = true;

        const { StartDate, EndDate } = searchCriteria;

        if( StartDate === "" ) {
            errors.StartDate = "Start date is required.";
            formIsValid = false;
        }

        if( EndDate === "" ) {
            errors.EndDate = "End date is required.";
            formIsValid = false;
        }

        if(StartDate !== "" && EndDate !== "" && moment(StartDate).diff(moment(EndDate)) > 0) {
            errors.StartDate = "Start date needs to come before or be equal to the end date.";
            formIsValid = false;
        }

        return formIsValid;
    }

    onSearchSubmit(missing) {
        Notify.Clear();

        let errors = {};
        let formIsValid = this.searchFormIsValid(this.props.slabSearchCriteria, errors);
        this.setState({errors: errors, hasSubmitted: true});

        if(!formIsValid)
            return;

        let reprocess = missing.fields.Reprocess;
        if (!reprocess)
            reprocess = false;
        let detachedFields = Object.assign({}, { Reprocess: reprocess });
        let slabSearchCriteria = Object.assign({}, this.props.slabSearchCriteria, detachedFields);
        slabSearchCriteria.slabSearchPerformed = true;
        this.props.actions.saveSlabSearchCriteria(slabSearchCriteria);

        this.props.actions.loadSlabInfo(slabSearchCriteria);
    }

    onExportScores(event) {
        event.preventDefault();
        this.getExportFiles("Scores");
    }

    onProcessAudioFiles(event) {
        event.preventDefault();
        this.processAudioFiles("AudioFiles");
    }

    processAudioFiles()
    {
        let slabSearchCriteria = Object.assign({}, this.props.slabSearchCriteria);

        this.props.actions.processAudioFiles(slabSearchCriteria);
    }

    getExportFiles(whichExport) {
        let slabSearchCriteria = Object.assign({}, this.props.slabSearchCriteria);
        slabSearchCriteria.Export = whichExport;

        this.props.actions.exportSlabData(slabSearchCriteria);
    }

    render() {
        let {slabSearchCriteria, processedAudioFiles, remainingAudioFiles, isAjaxLoading} = this.props;
        return (
            <div>
                <SlabSearchForm config={{
                    onSearchSubmit: this.onSearchSubmit,
                    isLoading: this.state.isLoading,
                    slabSearchCriteria: slabSearchCriteria,
                    onDateChange: this.onDateChange,
                    hasSubmitted: this.state.hasSubmitted,
                    errors: this.state.errors
                }} />
                {slabSearchCriteria !== undefined &&
                slabSearchCriteria.slabSearchPerformed &&
                <div id={"searchResults"}>
                    <hr/>
                    <SlabSearchResults processedAudioFiles={processedAudioFiles}
                                       remainingAudioFiles={remainingAudioFiles}
                                       reprocessFiles={slabSearchCriteria.Reprocess}
                                       onExportScores={this.onExportScores}
                                       onProcessAudioFiles={this.onProcessAudioFiles}
                                       isAjaxLoading = {isAjaxLoading}
                    />
                </div>
                }

            </div>
        );
    }
}

SlabPage.propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    slabSearchCriteria: PropTypes.object,
    processedAudioFiles: PropTypes.number,
    remainingAudioFiles: PropTypes.number,
    isAjaxLoading: PropTypes.bool
};


function mapStateToProps(state) {
    return {
        slabSearchCriteria: state.slab.slabSearchCriteria,
        processedAudioFiles: state.slab.processedAudioFiles,
        remainingAudioFiles: state.slab.remainingAudioFiles,
        isAjaxLoading : state.ajaxStatus.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        slabActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (SlabPage));
