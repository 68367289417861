import {beginAjaxCall, ajaxCallError} from "../AjaxStatus/AjaxStatusActions";
import studentMgmtApi from "./StudentMgmtApi";
import * as actionTypes from "./StudentMgmtConstants";

export function loadStudentsSuccess(data) {
    return {type: actionTypes.LOAD_STUDENTS_AJAX_SUCCESS, data};
}

export function loadStudents(studentFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return studentMgmtApi.getStudents(studentFilterCriteria)
            .then(data => {
                dispatch(loadStudentsSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function saveStudentSearchCriteria(criteria) {
    return {type: actionTypes.SAVE_STUDENT_SEARCH_CRITERIA, criteria};
}

export function editStudent(studentId) {
    return {type: actionTypes.EDIT_STUDENT, data: studentId};
}

export function cancelEditStudent() {
    return {type: actionTypes.CANCEL_EDIT_STUDENT};
}

export function updateStudentsSuccess(data) {
    return {type: actionTypes.SAVE_STUDENT_AJAX_SUCCESS, data};
}

export function updateStudent(student) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return studentMgmtApi.updateStudent(student)
            .then(() => {
                dispatch(updateStudentsSuccess(student));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            );
    };
}

export function exportStudentData(studentFilterCriteria) {
    return () => {
        studentMgmtApi.exportStudentData(studentFilterCriteria);
    };
}

export function importStudentsSuccess(importResults) {
    return {type: actionTypes.IMPORT_STUDENTS_AJAX_SUCCESS, importResults};
}

export function importStudents(fileData) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return studentMgmtApi.importStudents(fileData)
            .then((importResults) => {
                dispatch(importStudentsSuccess(importResults));
            })
            .catch(error => {
                if (error.Message)
                    dispatch(importStudentsSuccess({Message: error.Message}));
                else {
                    dispatch(ajaxCallError(error));
                    throw(error);
                }
            });
    };
}

export function clearOutImportMessage() {
    var importResults = {Message: ""};
    return {type: actionTypes.CLEAR_OUT_IMPORT_MESSAGE, importResults};
}
