import $ from 'jquery';
import * as config from "../../constants/config";
import LocalStorage from "../../components/localStorage/access";
import {getStudents, saveStudents} from "../../mockData/studentData";
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";
import {
    downloadBlob,
    mockRejectApiCall,
    mockResolveApiCall,
    sendRequest
} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerStudentMgmtApi {
    static getStudents(studentSearchCriteria) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}students?${$.param(studentSearchCriteria)}`,
                RequestTypes.GET,
            ));
    }

    static updateStudent(student) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}students`,
                RequestTypes.PUT,
                JSON.stringify(student),
                ApiSuccessMessages.UPDATE_STUDENT
            )
        );
    }

    static exportStudentData(studentSearchCriteria) {
        return downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}students/export?${$.param(studentSearchCriteria)}`,
                RequestTypes.GET,
                null,
                ApiSuccessMessages.EXPORT_SUCCESS
            ));
    }

    static importStudents(fileData) {

        let request = AjaxRequestOptions(
            `${config.apiUrl}students/import`,
            RequestTypes.POST,
            fileData);

        request.processData = false;
        request.enctype = `multipart/form-data`;
        request.contentType = false;

        return sendRequest(request);
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockStudentMgmtApi {
    static getStudents(studentSearchCriteria) {
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            let students = getStudents();

            if (studentSearchCriteria.NameContains !== "")
                students = students.filter(student => (student.FirstName + " " + student.LastName).includes(studentSearchCriteria.NameContains));

            if (studentSearchCriteria.StudentTrackingIdContains !== "")
                students = students.filter(student => (student.StudentTrackingId).includes(studentSearchCriteria.StudentTrackingIdContains));

            if (studentSearchCriteria.IsActive !== null && studentSearchCriteria.IsActive !== "")
                students = students.filter(student => (student.IsActive === (studentSearchCriteria.IsActive.toLowerCase() === "true")));

            let sortProp = studentSearchCriteria.OrderByColumn;
            let sortDir = (studentSearchCriteria.OrderDescending) ? -1 : 1;

            students.sort(function (a, b) {
                return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);
            });

            const skip = (studentSearchCriteria.Page - 1) * studentSearchCriteria.RecordsPerPage;
            const take = studentSearchCriteria.RecordsPerPage + skip;

            return mockResolveApiCall(Object.assign({}, {
                Data: students.slice(skip, take),
                TotalRecords: students.length
            }));
        }
    }

    static updateStudent(student) {
        let students = getStudents();
        const otherStudents = students.filter(s => s.Id !== student.Id);
        const studentRecord = students.filter(s => s.Id === student.Id);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (studentRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the student record.");
        else if (otherStudents.filter(f => f.StudentTrackingId === student.StudentTrackingId).length > 0)
            return mockRejectApiCall("The student tracking id entered is already associated with another student.");
        else {
            const editStudent = {
                Id: student.Id,
                StudentTrackingId: student.StudentTrackingId,
                FirstName: student.FirstName,
                LastName: student.LastName,
                IsActive: (student.IsActive.toString().toLowerCase() === "true"),
                Enrollments: []
            };
            saveStudents([...otherStudents, editStudent]);
            return mockResolveApiCall(ApiSuccessMessages.UPDATE_STUDENT);
        }

    }

    static exportStudentData() {
        // no mock available as no way to create a mock Excel file
    }

    static importStudents() {
        // no mock available as no way to create a mock Excel file
        return mockResolveApiCall("StudentImported", "1 student imported");
    }
}

const StudentMgmtApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerStudentMgmtApi : MockStudentMgmtApi;
export default StudentMgmtApi;