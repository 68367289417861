import {ajaxCallError, beginAjaxCall} from "../AjaxStatus/AjaxStatusActions";
import * as actionTypes from "./AssessmentConstants";
import itemPoolApi from "../ItemPoolConfiguration/ItemPoolConfigurationApi";
import mCatConfigApi from "./McatConfigApi";

export function loadItemPoolSelectListSuccess(data) {
    return {type: actionTypes.LOAD_ITEM_POOL_SELECT_LIST_AJAX_SUCCESS, data};
}

export function loadItemPoolSelectList(taskIds) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return itemPoolApi.getItemPoolsForTasks(taskIds)
            .then(data => {
                dispatch(loadItemPoolSelectListSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    }
}

export function saveMcatTaskGroupSuccess() {
    return {type: actionTypes.SAVE_MCAT_AJAX_SUCCESS};
}

export function saveMcatTaskGroups(mCatTaskGroups) {
    return dispatch => {
        dispatch(beginAjaxCall());
        const useAdd = !mCatTaskGroups.find(s => s.McatTaskGroupId > 0);
        if (useAdd) {
            return mCatConfigApi.addMcatTaskGroups(mCatTaskGroups)
                .then(() => {
                    dispatch(saveMcatTaskGroupSuccess());
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                });
        } else {
            return mCatConfigApi.updateMcatTaskGroups(mCatTaskGroups)
                .then(() => {
                    dispatch(saveMcatTaskGroupSuccess());
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                });
        }
    };
}

export function deleteMcatTaskGroupsSuccess() {
    return {type: actionTypes.DELETE_MCAT_AJAX_SUCCESS};
}

export function deleteMcatTaskGroups(assessmentId) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return mCatConfigApi.deleteMcatTaskGroups(assessmentId)
            .then(() => {
                dispatch(deleteMcatTaskGroupsSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}
