import $ from 'jquery';
import * as config from "../../constants/config";
import LocalStorage from "../../components/localStorage/access";
import {
    getItemPoolData,
    saveItemPoolData
} from "../../mockData/itemPoolConfigurationData";
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";
import convertObjWithSpecificFields from "../../mockData/convertObjWithSpecificFields";
import {
    downloadBlob,
    mockRejectApiCall,
    mockResolveApiCall,
    sendRequest
} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerItemPoolApi {
    static getItemPools(itemPoolSearchCriteria) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}itemPools?${$.param(itemPoolSearchCriteria)}`,
                RequestTypes.GET,
            ));
    }

    static getItemPoolsForTask(taskId) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}itemPools/tasks/${taskId}`,
                RequestTypes.GET,
            ));
    }

    static getItemPoolsForTasks(taskIds) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}itemPools/tasks/`,
                RequestTypes.GET,
                {taskIds: taskIds}
            ));
    }

    static getItemPool(itemPoolId) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}itemPools/${itemPoolId}`,
                RequestTypes.GET,
            ));
    }

    static deleteItemPool(linkInfo) {
        return sendRequest(
            AjaxRequestOptions(
                linkInfo.Href,
                RequestTypes.DELETE,
                ApiSuccessMessages.ITEM_POOL_DELETED
            ));
    }

    static addItemPool(itemPool) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}itemPools`,
                RequestTypes.POST,
                JSON.stringify(itemPool),
                ApiSuccessMessages.ITEM_POOL_ADDED
            )
        );
    }

    static updateItemPool(itemPool) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}itemPools`,
                RequestTypes.PUT,
                JSON.stringify(itemPool),
                ApiSuccessMessages.ITEM_POOL_UPDATED
            )
        );
    }

    static importItemPool(fileData) {
        let request = AjaxRequestOptions(
            `${config.apiUrl}itemPools/import`,
            RequestTypes.POST,
            fileData);

        request.processData = false;
        request.enctype = `multipart/form-data`;
        request.contentType = false;

        return sendRequest(request);
    }

    static exportItemParameterData(id) {
        return downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}itemPools/export?itemPoolId=${id}`,
                RequestTypes.GET,
                null,
                ApiSuccessMessages.EXPORT_SUCCESS
            ));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockItemPoolApi {
    static getItemPools(itemPoolSearchCriteria) {
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            let itemPools = getItemPoolData();

            if (itemPoolSearchCriteria.NameContains !== "")
                itemPools = itemPools.filter(itemPool => itemPool.Name.includes(itemPoolSearchCriteria.NameContains));

            let catModelType = parseInt(itemPoolSearchCriteria.CatModelType);
            if (catModelType > 0)
                itemPools = itemPools.filter(itemPool => itemPool.CatModelType === catModelType);

            let sortProp = itemPoolSearchCriteria.OrderByColumn;
            let sortDir = (itemPoolSearchCriteria.OrderDescending) ? -1 : 1;

            itemPools.sort(function (a, b) {
                if (typeof a[sortProp] === 'object')
                    return (a[sortProp].value > b[sortProp].value) ? sortDir : ((b[sortProp].value > a[sortProp].value) ? -1 * sortDir : 0);

                return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);
            });

            const skip = (itemPoolSearchCriteria.Page - 1) * itemPoolSearchCriteria.RecordsPerPage;
            const take = itemPoolSearchCriteria.RecordsPerPage + skip;

            let itemPoolsReturned = (itemPoolSearchCriteria.Fields !== "")
                ? convertObjWithSpecificFields(itemPoolSearchCriteria.Fields, itemPools)
                : itemPools.slice(skip, take);

            return mockResolveApiCall(Object.assign({}, {
                Data: itemPoolsReturned,
                TotalRecords: itemPools.length
            }));
        }
    }

    static getItemPoolsForTask(taskId) {
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            let itemPools = getItemPoolData();

            itemPools = itemPools.filter(itemPool => itemPool.AssociatedWithTaskId === taskId);

            return mockResolveApiCall(Object.assign({}, {
                Data: itemPools,
            }));
        }
    }

    static getItemPoolsForTasks(taskIds) {
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            let itemPools = getItemPoolData();

            itemPools = itemPools.filter(search =>
                !taskIds.find(s => (search.AssociatedWithTaskId) && s.toString() === search.AssociatedWithTaskId.toString())
            );

            return mockResolveApiCall(Object.assign({}, {
                Data: itemPools,
            }));
        }
    }

    static getItemPool(itemPoolId) {
        let itemPools = getItemPoolData();
        const itemPoolRecord = itemPools.filter(i => i.Id === itemPoolId);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (itemPoolRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the itemPool record.");
        else {
            return mockResolveApiCall(itemPoolRecord[0]);
        }
    }

    static deleteItemPool(linkInfo) {
        let itemPools = getItemPoolData();
        const itemPoolRecord = itemPools.filter(i => i.Id === parseInt(linkInfo.Href));
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (itemPoolRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the itemPool record.");
        else {
            const otherItemPools = itemPools.filter(i => i.Id !== parseInt(linkInfo.Href));
            saveItemPoolData([...otherItemPools]);
            return mockResolveApiCall(ApiSuccessMessages.ITEM_POOL_DELETED);
        }
    }

    static addItemPool(itemPool) {
        let itemPools = getItemPoolData();
        const otherItemPools = itemPools.filter(i => i.Id !== itemPool.Id);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (otherItemPools.filter(f => f.Name === itemPool.Name).length > 0)
            return mockRejectApiCall("The item pool name entered is already associated with another itemPool.");
        else {
            const allIds = itemPools.map((i) => {
                return i.Id;
            });
            let newId = Math.max(...allIds) + 1;
            const addItemPool = {
                Id: newId,
                Name: itemPool.Name,
                CatModelType: itemPool.CatModelType,
                CatModelTypeDescription: itemPool.CatModelTypeDescription,
                LastImportFileName: itemPool.LastImportFileName,
                LastImportDate: itemPool.LastImportDate,
                ItemParameters: itemPool.ItemParameters,
                Links: [{
                    Href: newId.toString(),
                    Rel: "delete_itemPool",
                    Method: "DELETE"
                }]
            };
            saveItemPoolData([...otherItemPools, addItemPool]);
            return mockResolveApiCall(ApiSuccessMessages.ITEM_POOL_ADDED);
        }
    }

    static updateItemPool(itemPool) {
        let itemPools = getItemPoolData();
        const otherItemPools = itemPools.filter(i => i.Id !== itemPool.Id);
        const itemPoolRecord = itemPools.filter(i => i.Id === itemPool.Id);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (itemPoolRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the itemPool record.");
        else if (otherItemPools.filter(f => f.Name === itemPool.Name).length > 0)
            return mockRejectApiCall("The item pool name entered is already associated with another itemPool.");
        else {
            const editItemPool = {
                Id: itemPool.Id,
                Name: itemPool.Name,
                CatModelType: itemPool.CatModelType,
                CatModelTypeDescription: itemPool.CatModelTypeDescription,
                FileData: itemPool.FileData,
                LastImportFileName: itemPool.LastName,
                LastImportDate: itemPool.LastImportDate,
                ItemParameters: itemPool.ItemParameters,
                Links: itemPoolRecord[0].Links
            };
            saveItemPoolData([...otherItemPools, editItemPool]);
            return mockResolveApiCall(ApiSuccessMessages.ITEM_POOL_UPDATED);
        }
    }

    static importItemPool() {
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            const newItemParametersInfo = {
                LastImportFileName: "Import File BE DONE",
                LastImportDate: "3/25/2040",
                ItemParameters: [
                    {
                        Name: "Parameter " + Math.random().toString(),
                        ItemParameterA: Math.random(),
                        ItemParameterB: Math.random()
                    },
                    {
                        Name: "Parameter " + Math.random().toString(),
                        ItemParameterA: Math.random(),
                        ItemParameterB: Math.random()
                    }
                ],
            };
            return mockResolveApiCall(newItemParametersInfo);
        }
    }

    static exportItemParameterData() {
        // no mock available as no way to create a mock Excel file
    }

}

const ItemPoolApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerItemPoolApi : MockItemPoolApi;
export default ItemPoolApi;