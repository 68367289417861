import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";
import {generateIsActiveOptions} from "./StudentMgmtConstants";

import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import TextField from "../../components/inputs/textField";
import SelectField from "../../components/inputs/selectField";
import ButtonBar from "../../components/buttons/buttonBar";
import FileUploadButton from "../../components/inputs/fileUploadButton";
import ImportErrorNotification from "../../components/notification/importErrorNotification";

const StudentMgmtSearchForm = ({config}) => {
    let formConfig = {
        NameContains: {},
        StudentTrackingIdContains: {},
        IsActive: {}
    };
    return (
        <div>
            <FormValidation onSubmit={config.onSearchSubmit} config={formConfig} initialValues={config.studentSearchCriteria} >
                {({ fields, setField }) => (
                    <div className={"input-bottom-alignment"}>
                        <div className={"grid-x grid-padding-x"}>
                            <div className={"cell medium-6 large-6"}>
                                <TextField config={{
                                    name: "NameContains",
                                    label: "Name:",
                                    maxLength: 100,
                                    showLabel: true,
                                    value: fields.NameContains
                                }} />
                            </div>
                            <div className={"cell medium-6 large-6"}>
                                <TextField config={{
                                    name: "StudentTrackingIdContains",
                                    label: "Student Tracking Id:",
                                    maxLength: 50,
                                    showLabel: true,
                                    value: fields.StudentTrackingIdContains
                                }} />
                            </div>
                        </div>
                        <div className={"grid-x grid-padding-x align-bottom"}>
                            <div className={"cell medium-3 large-3"}>
                                <SelectField config={{
                                    name: "IsActive",
                                    label: "Status",
                                    showLabel: true,
                                    options: generateIsActiveOptions(),
                                    value: fields.IsActive,
                                    defaultOption: "Any Status",
                                    inputClass: "margin-bottom-0"
                                }} />
                            </div>
                            <div className={"cell medium-5 large-5"}>
                                <div className={"button-group"}>
                                    <Button config={{
                                        name: "btnClearStudentSearch",
                                        label: "Clear",
                                        onClick: (event) => config.clearCriteria(event, setField),
                                        btnClass: "light",
                                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                    }} />
                                    <Button config={{
                                        name: "btnSearchStudents",
                                        label: "Search",
                                        disabled: config.isLoading
                                    }} />
                                </div>
                            </div>
                            <div className={"cell medium-4 large-4"}>
                                <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_RIGHT}>
                                    <Button config={{
                                        name: "btnExport",
                                        label: "Export",
                                        onClick: config.onExportStudents,
                                        btnClass: "light",
                                        btnType: buttonConstants.BUTTON_TYPE_BUTTON,
                                        disabled: !config.studentSearchCriteria.studentSearchPerformed
                                    }}/>
                                    <FileUploadButton name={"btnImport"} label={"Import"} onChange={config.onImportStudents} disabled={config.isEditing} />
                                </ButtonBar>
                            </div>
                        </div>
                    </div>
                )}
            </FormValidation>
            <ImportErrorNotification importResults={config.importResults} />
        </div>
    );
};

StudentMgmtSearchForm.propTypes = {
    config: PropTypes.shape({
        onSearchSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        clearCriteria: PropTypes.func.isRequired,
        studentSearchCriteria: PropTypes.object,
        onExportStudents: PropTypes.func.isRequired,
        onImportStudents: PropTypes.func.isRequired,
        FileData: PropTypes.object,
        importResults: PropTypes.object,
        isEditing: PropTypes.bool.isRequired
    })
};

export default StudentMgmtSearchForm;