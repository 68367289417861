import {beginAjaxCall, ajaxCallError} from "../AjaxStatus/AjaxStatusActions";
import assessmentApi from "./AssessmentApi";
import * as actionTypes from "./AssessmentConstants";

export function loadAssessmentsSuccess(data) {
    return {type: actionTypes.LOAD_ASSESSMENTS_AJAX_SUCCESS, data};
}

export function loadAssessments(assessmentFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return assessmentApi.getAssessments(assessmentFilterCriteria)
            .then(data => {
                dispatch(loadAssessmentsSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function saveAssessmentSearchCriteria(criteria) {
    return {type: actionTypes.SAVE_ASSESSMENT_SEARCH_CRITERIA, criteria};
}

export function deleteAssessmentSuccess() {
    return {type: actionTypes.DELETE_ASSESSMENT_AJAX_SUCCESS};
}

export function deleteAssessment(deleteLinkInfo) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return assessmentApi.deleteAssessment(deleteLinkInfo)
            .then(() => {
                dispatch(deleteAssessmentSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function addAssessment() {
    return {type: actionTypes.ADD_ASSESSMENT};
}

export function editAssessmentSuccess(assessment) {
    return {type: actionTypes.EDIT_ASSESSMENT_AJAX_SUCCESS, data: assessment};
}

export function editAssessment(assessmentId) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return assessmentApi.getAssessment(assessmentId)
            .then((assessment) => {
                dispatch(editAssessmentSuccess(assessment));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function updateAssessment(assessment) {
    return {type: actionTypes.UPDATE_ASSESSMENT, data: assessment};
}

export function saveAssessmentSuccess() {
    return {type: actionTypes.SAVE_ASSESSMENT_AJAX_SUCCESS};
}

export function saveAssessment(assessment) {
    return dispatch => {
        dispatch(beginAjaxCall());

        if (assessment.Id === 0) {
            return assessmentApi.addAssessment(assessment)
                .then((assessment) => {
                    dispatch(saveAssessmentSuccess());
                    return assessment;
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                });
        } else {
            return assessmentApi.updateAssessment(assessment)
                .then(() => {
                    dispatch(saveAssessmentSuccess());
                    return assessment;
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                });
        }
    };
}

export function associateTask(taskId) {
    return {type: actionTypes.ASSOCIATE_TASK_TO_ASSESSMENT, data: taskId};
}

export function unassociateTask(index) {
    return {type: actionTypes.UNASSOCIATE_TASK_TO_ASSESSMENT, data: index};
}

export function publishAssessmentSuccess(assessment) {
    return {type: actionTypes.PUBLISH_ASSESSMENT_AJAX_SUCCESS, data: assessment};
}

export function publishAssessment(assessmentId) {
    return dispatch => {
        dispatch(beginAjaxCall());

        if (assessmentId !== 0) {
            return assessmentApi.publishAssessment(assessmentId)
                .then((assessment) => {
                    dispatch(publishAssessmentSuccess(assessment));
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                });
        }
    };
}

export function deletePublishedAssessment(assessmentId) {
    return dispatch => {
        dispatch(beginAjaxCall());

        if (assessmentId !== 0) {
            return assessmentApi.deletePublishedAssessment(assessmentId)
                .then((assessment) => {
                    dispatch(publishAssessmentSuccess(assessment));
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                });
        }
    };
}
