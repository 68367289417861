import React from "react";
import PropTypes from "prop-types";

const CheckBoxField = ({config}) => {
    config = Object.assign({}, checkBoxFieldDefaults(), config);

    let labelClass = "show-for-sr";
    if (config.showLabel) {
        labelClass = "";
    }

    const isChecked = (config.checked.toString().toLowerCase() === "true");

    return (
        <div className={"grid-x " + config.wrapperClass}>
            <label htmlFor={config.name} className={"cell small-12"}>
                <input type="checkbox"
                       name={config.name}
                       id={config.name}
                       className={config.inputClass}
                       checked={isChecked}
                       onChange={f => f} />
                <span className={labelClass}>{config.label}</span></label>
                {config.error && <div className="text-danger">{config.error}</div>}
        </div>
    );
};

CheckBoxField.propTypes = {
    config: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
        showLabel: PropTypes.bool.isRequired,
        inputClass: PropTypes.string,
        error: PropTypes.string,
        wrapperClass: PropTypes.string
    })
};

function checkBoxFieldDefaults() {
    return {
        showLabel: true,
        checked: false,
        wrapperClass: ""
    };
}

export default CheckBoxField;