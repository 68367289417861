import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as assessmentActions from "./AssessmentActions";
import {assessmentInitialState} from "./AssessmentReducer";
import * as routes from "../../components/routes/routesConstants";
import AssessmentListSearchForm from "./AssessmentListSearchForm";
import AssessmentListSearchResults from "./AssessmentListSearchResults";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import {Search} from "../../components/table/Search";
import * as loginActions from "../Login/LoginAction";
import localStorage from "../../components/localStorage/access";

export class AssessmentListPage extends Search {
    constructor(props, context) {
        super(props, context, assessmentInitialState.assessmentSearchCriteria);

        this.state = {
            isLoading: false
        };

        this.onAddAssessment = this.onAddAssessment.bind(this);
        this.onEditAssessment = this.onEditAssessment.bind(this);
        this.onDeleteAssessment = this.onDeleteAssessment.bind(this);
        this.onMcatGroupEdit = this.onMcatGroupEdit.bind(this);
    }

    componentDidMount(){
        PushFocusToElement("NameContains");
        this.props.actions.updatePageTitle_BreadCrumb(routes.ASSESSMENT_MGMT);
        if(this.props.assessmentSearchCriteria !== undefined &&
            this.props.assessmentSearchCriteria.assessmentSearchPerformed) {
            this.props.actions.loadAssessments(this.props.assessmentSearchCriteria);
        }

        this.setLoadSearchResults(this.loadAssessments);
        this.setProps(this.props.assessmentSearchCriteria, this.props.assessmentTotalRecords);
    }

    componentDidUpdate() {
        this.setProps(this.props.assessmentSearchCriteria, this.props.assessmentTotalRecords);
    }

    loadAssessments(assessmentSearchCriteria) {
        assessmentSearchCriteria.assessmentSearchPerformed = true;
        this.props.actions.saveAssessmentSearchCriteria(assessmentSearchCriteria);

        this.props.actions.loadAssessments(assessmentSearchCriteria);
    }

    onAddAssessment(event) {
        event.preventDefault();

        this.props.history.push(routes.ASSESSMENT_INFO.path.replace(routes.ASSESSMENT_INFO.params.assessmentId, 0));
    }

    onEditAssessment(event, assessmentId) {
        event.preventDefault();

        this.props.history.push(routes.ASSESSMENT_INFO.path.replace(routes.ASSESSMENT_INFO.params.assessmentId, assessmentId));
    }

    onMcatGroupEdit(event, assessmentId){
        event.preventDefault();
        this.props.history.push(routes.ASSESSMENT_MCAT_CONFIG_INFO.path.replace(routes.ASSESSMENT_MCAT_CONFIG_INFO.params.assessmentId, assessmentId));
    }

    onDeleteAssessment(event, deleteLinkInfo) {
        event.preventDefault();

        if(confirm("Are you sure you want to delete this assessment?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page."))
        {
            this.props.actions.deleteAssessment(deleteLinkInfo)
                .then(() => this.reloadAfterDelete())
        }
    }

    reloadAfterDelete() {
        let criteria = Object.assign({}, this.props.assessmentSearchCriteria);
        criteria.Page = 1;

        this.loadAssessments(criteria);
    }

    render() {
        let {assessmentTotalRecords, assessmentSearchCriteria, assessmentSearchResults } = this.props;

        return (
            <div>
                <AssessmentListSearchForm config={{
                    onSearchSubmit: this.onSearchSubmit,
                    clearCriteria: this.clearCriteria,
                    isLoading: this.state.isLoading,
                    assessmentSearchCriteria: assessmentSearchCriteria,
                    onAddAssessment: this.onAddAssessment
                }} />
                {assessmentSearchCriteria !== undefined &&
                assessmentSearchCriteria.assessmentSearchPerformed &&
                <div id={"searchResults"}>
                    <hr/>
                    <AssessmentListSearchResults config={{
                        assessmentSearchResults: assessmentSearchResults,
                        order: this.order,
                        first: this.first,
                        last: this.last,
                        next: this.next,
                        previous: this.previous,
                        assessmentTotalRecords: assessmentTotalRecords,
                        currentPage: assessmentSearchCriteria.Page,
                        assessmentRecordsPerPage: assessmentSearchCriteria.RecordsPerPage,
                        onEditAssessment: this.onEditAssessment,
                        onMcatGroupEdit: this.onMcatGroupEdit,
                        onDeleteAssessment: this.onDeleteAssessment
                    }}/>
                </div>
                }
            </div>
        );
    }
}

AssessmentListPage.propTypes = {
    assessmentSearchResults: PropTypes.arrayOf(PropTypes.object),
    assessmentSearchCriteria: PropTypes.object,
    assessmentTotalRecords: PropTypes.number,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        assessmentSearchResults: state.assessment.assessmentSearchResults,
        assessmentSearchCriteria: state.assessment.assessmentSearchCriteria,
        assessmentTotalRecords: state.assessment.assessmentTotalRecords
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        assessmentActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (AssessmentListPage));
