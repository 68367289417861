import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ButtonIcon from "../buttons/buttonIcon";
import * as buttonConstants from "../buttons/buttonConstants";

const grid = 8;
class SortOptions extends React.Component {
    getOptionStyle(isDragging, draggableStyle) {
        return {
            // some basic styles to make the items look a bit nicer
            userSelect: 'none',
            padding: grid * 2,
            margin: `0 0 ${grid}px 0`,

            // change background colour if dragging
            background: isDragging ? 'lightgreen' : 'grey',

            // styles we need to apply on draggables
            ...draggableStyle
        };
    }

    getListStyle(isDraggingOver, config) {
        return {
            background: isDraggingOver ? 'lightblue' : 'lightgrey',
            padding: grid,
            width: config.width,
            maxHeight: 410,
            overflowY: "scroll"
        };
    }

    render()
    {
        let config = Object.assign({}, getSortOptionsDefaults(), this.props.config);
        return (
            <div className={"grid-x " + config.wrapperClass}>
                {
                    config.label !== "" &&
                    <div className={"cell " + config.labelClass}>{config.label}</div>
                }
                <div className={"cell"}>
                    <DragDropContext onDragEnd={config.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} style={this.getListStyle(snapshot.isDraggingOver, config)}>
                                    {config.options.map((option, index) => (
                                        <Draggable key={index} draggableId={index.toString()} index={index}>
                                            {(provided, snapshot) => (
                                                <div ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={this.getOptionStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div className={"grid-x"}>
                                                        <div className={"cell shrink padding-right-1"}>{index + 1}.</div>
                                                        <div className={"cell auto"}>{option.name}</div>
                                                        {
                                                            config.showDelete &&
                                                            <div className={"cell shrink text-right padding-left-1"}>
                                                                <ButtonIcon config={{
                                                                    name: "btnDeleteOption",
                                                                    label: "Delete Option",
                                                                    onClick: (event) => config.onDeleteOption(event, index, config.setField),
                                                                    iconType: buttonConstants.ICON_DELETE
                                                                }}/>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        );
    }
}

SortOptions.propTypes = {
    config: PropTypes.shape({
        onDragEnd: PropTypes.func.isRequired,
        options: PropTypes.array.isRequired,
        wrapperClass: PropTypes.string,
        labelClass: PropTypes.string,
        label: PropTypes.string,
        showDelete: PropTypes.bool,
        onDeleteOption: PropTypes.func,
        setField: PropTypes.func,
        width: PropTypes.number
    })
};

function getSortOptionsDefaults() {
    return {
        wrapperClass: "",
        label: "",
        labelClass: "font-bold",
        showDelete: false,
        width: 300
    };
}

export default SortOptions;