import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";
import {generateApOptions, generateYearOptions} from "./EnrollmentMgmtConstants";

import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import SelectField from "../../components/inputs/selectField";
import ButtonBar from "../../components/buttons/buttonBar";
import FileUploadButton from "../../components/inputs/fileUploadButton";
import ImportErrorNotification from "../../components/notification/importErrorNotification";

const EnrollmentMgmtSearchForm = ({config}) => {
    let formConfig = {
        Year: {},
        AssessmentPeriod: {},
        DistrictId: {},
        SchoolName: {},
        TeacherName: {}
    };
    return (
        <div>
            <FormValidation onSubmit={config.onSearchSubmit} config={formConfig} initialValues={config.enrollmentSearchCriteria} >
                {({ fields, setField }) => (
                    <div className={"input-bottom-alignment"}>
                        <div className={"grid-x grid-padding-x"}>
                            <div className={"cell medium-4 large-4"}>
                                <SelectField config={{
                                    name: "Year",
                                    label: "Year",
                                    showLabel: true,
                                    options: generateYearOptions(),
                                    value: fields.Year,
                                    includeDefaultOption: false,
                                    onChange: (event) => config.onFiltersChange(event, setField)
                                }} />
                            </div>
                            <div className={"cell medium-4 large-4"}>
                                <SelectField config={{
                                    name: "AssessmentPeriod",
                                    label: "AP",
                                    showLabel: true,
                                    options: generateApOptions(),
                                    value: fields.AssessmentPeriod,
                                    defaultOption: "Any AP",
                                    onChange: (event) => config.onFiltersChange(event, setField)
                                }} />
                            </div>
                            <div className={"cell medium-4 large-4"}>
                                <SelectField config={{
                                    name: "DistrictId",
                                    label: "District",
                                    showLabel: true,
                                    options: config.districts,
                                    value: fields.DistrictId.toString(),
                                    defaultOption: "Any District",
                                    onChange: (event) => config.onFiltersChange(event, setField)
                                }} />
                            </div>
                        </div>
                        <div className={"grid-x grid-padding-x align-bottom"}>
                            <div className={"cell medium-3 large-3"}>
                                <SelectField config={{
                                    name: "SchoolName",
                                    label: "School",
                                    showLabel: true,
                                    options: config.schools,
                                    value: fields.SchoolName,
                                    defaultOption: "Any School",
                                    inputClass: "margin-bottom-0",
                                    onChange: (event) => config.onFiltersChange(event, setField)
                                }} />
                            </div>
                            <div className={"cell medium-3 large-3"}>
                                <SelectField config={{
                                    name: "TeacherName",
                                    label: "Teacher",
                                    showLabel: true,
                                    options: config.teachers,
                                    value: fields.TeacherName,
                                    defaultOption: "Any Teacher",
                                    inputClass: "margin-bottom-0"
                                }} />
                            </div>
                            <div className={"cell medium-3 large-3"}>
                                <div className={"button-group"}>
                                    <Button config={{
                                        name: "btnClearEnrollmentSearch",
                                        label: "Clear",
                                        onClick: (event) => config.clearCriteria(event, setField),
                                        btnClass: "light",
                                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                    }} />
                                    <Button config={{
                                        name: "btnSearchEnrollments",
                                        label: "Search",
                                        disabled: config.isLoading
                                    }} />
                                </div>
                            </div>
                            <div className={"cell medium-3 large-3"}>
                                <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_RIGHT}>
                                    <Button config={{
                                        name: "btnExport",
                                        label: "Export",
                                        onClick: config.onExportEnrollments,
                                        btnClass: "light",
                                        btnType: buttonConstants.BUTTON_TYPE_BUTTON,
                                        disabled: !config.enrollmentSearchCriteria.enrollmentSearchPerformed
                                    }}/>
                                    <FileUploadButton name={"btnImport"} label={"Import"} onChange={config.onImportEnrollments} />
                                </ButtonBar>
                            </div>
                        </div>
                    </div>
                )}
            </FormValidation>
            <ImportErrorNotification importResults={config.importResults} />
        </div>
    );
};

EnrollmentMgmtSearchForm.propTypes = {
    config: PropTypes.shape({
        onSearchSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        clearCriteria: PropTypes.func.isRequired,
        enrollmentSearchCriteria: PropTypes.object,
        onExportEnrollments: PropTypes.func.isRequired,
        onImportEnrollments: PropTypes.func.isRequired,
        FileData: PropTypes.object,
        importResults: PropTypes.object,
        districts: PropTypes.array,
        schools: PropTypes.array,
        teachers: PropTypes.array,
        onFiltersChange: PropTypes.func.isRequired
    })
};

export default EnrollmentMgmtSearchForm;