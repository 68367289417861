import * as actionTypes from "./TaskConstants";
import * as config from "../../constants/config";
import {convertDataToOptions} from "../../components/generalData/convertGeneralDataToOptions";

export function taskDefaultState(taskId=0) {
    return {
        taskSearchCriteria: {
            NameContains: "",
            ItemBankNameContains: "",
            TaskTypeId: 0,
            CatModelType: undefined,
            ExcludeGamePlay: false,
            Page: 1,
            RecordsPerPage: config.defaultRecordsPerPage,
            OrderByColumn: "Name",
            OrderDescending: false,
            taskSearchPerformed: false,
            Fields: ""
        },
        taskTotalRecords: 0,
        taskSearchResults: [],
        taskSelectList: [],
        taskInfo: {
            Id: 0,
            Name: "",
            TaskTypeId: 0,
            TimeLimit: -1,
            ItemsPerPage: 1,
            GridRowTotal: 0,
            GridColumnTotal: 0,
            GridSpacing: 0,
            CatModelType: 0,
            CatModelTypeDescription: "None",
            CatConfig: {
                TaskCatConfigId: 0,
                TaskId: taskId,
                CatModelType: 0,
                ItemPoolConfigs: {},
                ItemSelectionAlgorithm: 0,
                FixedLength: "",
                Randomesque: "",
                StopCriteria: [],
                ItemIds: []
            },
            Forms: [],
            AssociatedItemIds: []
        },
        isEditing: false,
        editingRowIndex: 0,
        addRowIndex: -1,
        editingStopCriteriaRowIndex: 0,
        addStopCriteriaRowIndex: -1,
        isSubmitted: false
    };
}

export const taskInitialState = taskDefaultState();

export function formInitialState() {
    return {
        Id: 0,
        Name: "",
        ItemBankId: 0
    };
}

export function stopCriteriaInitialState() {
    return {
        TaskCatStopCriterionId: 0,
        StopCriteriaType: 0,
        StopCriteriaValue: ""
    };
}

export function taskReducer(state = taskInitialState, action) {
    switch (action.type) {
        case actionTypes.LOAD_TASKS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    taskSearchResults: [...action.data.Data],
                    taskTotalRecords: action.data.TotalRecords
                });
        case actionTypes.LOAD_TASK_SELECT_LIST_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    taskSelectList: convertDataToOptions([...action.data.Data], "Id", "%s;Name")
                });
        case actionTypes.SAVE_TASK_SEARCH_CRITERIA:
            return Object.assign({}, state, {taskSearchCriteria: action.criteria});
        case actionTypes.LOAD_TASK_AJAX_SUCCESS:
        case actionTypes.EDIT_TASK_AJAX_SUCCESS: {
            return Object.assign(
                {},
                state,
                {
                    isEditing: false,
                    editingRowIndex: 0,
                    addRowIndex: -1,
                    taskInfo: Object.assign({}, action.data, {CatConfig: Object.assign({}, !action.data.CatConfig ? taskDefaultState(action.data.Id).taskInfo.CatConfig : action.data.CatConfig )})
                });
        }
        case actionTypes.ADD_TASK:
        case actionTypes.SAVE_TASK_AJAX_SUCCESS:
        case actionTypes.SAVE_TASK_CAT_CONFIG_AJAX_SUCCESS:
            return Object.assign({}, state, {taskInfo: taskDefaultState().taskInfo});
        case actionTypes.EDIT_FORM:
            return Object.assign({}, state, {isEditing: true, editingRowIndex: action.data, addRowIndex: -1});
        case actionTypes.CANCEL_EDIT_FORM:
            return Object.assign({}, state, {isEditing: false, editingRowIndex: 0, addRowIndex: -1});
        case actionTypes.UPDATE_FORM:
            return Object.assign({}, state, {
                isEditing: action.data.isEditing, editingRowIndex: action.data.editingRowIndex, addRowIndex: -1,
                taskInfo: Object.assign({}, state.taskInfo, {
                    Forms: [...state.taskInfo.Forms].map((form, i) => {
                        let detachedForm = Object.assign({}, form);

                        if (i !== state.editingRowIndex) return detachedForm;

                        return action.data.form;
                    })
                })
            });
        case actionTypes.ADD_FORM:
            return Object.assign({}, state, {
                isEditing: true, editingRowIndex: state.taskInfo.Forms.length, addRowIndex: state.taskInfo.Forms.length,
                taskInfo: Object.assign({}, state.taskInfo, {Forms: [...state.taskInfo.Forms, formInitialState()]})
            });
        case actionTypes.DELETE_FORM: {
            let allForms = [...state.taskInfo.Forms];
            allForms.splice(action.data, 1);
            return Object.assign({}, state, {
                isEditing: false, editingRowIndex: 0, addRowIndex: -1,
                taskInfo: Object.assign({}, state.taskInfo, {Forms: allForms})
            });
        }
        case actionTypes.UPDATE_CAT_MODEL_TYPE: {
            return Object.assign(
                {},
                state,
                {
                    taskInfo: Object.assign({},
                        state.taskInfo,
                        {
                            CatConfig: Object.assign({}, state.taskInfo.CatConfig, {CatModelType: action.data})
                        })
                });
        }
        case actionTypes.LOAD_ITEM_POOL_SELECT_LIST_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    itemPoolSelectList: convertDataToOptions([...action.data.Data], "Id", "%s;Name")
                });

        case actionTypes.EDIT_STOP_CRITERIA:
            return Object.assign({}, state, {
                isEditing: true,
                editingStopCriteriaRowIndex: action.data,
                addStopCriteriaRowIndex: -1
            });
        case actionTypes.CANCEL_EDIT_STOP_CRITERIA:
            return Object.assign({}, state, {
                isEditing: false,
                editingStopCriteriaRowIndex: 0,
                addStopCriteriaRowIndex: -1
            });

        case actionTypes.UPDATE_STOP_CRITERIA: {
            return Object.assign({}, state, {
                isEditing: action.data.isEditing,
                editingStopCriteriaRowIndex: action.data.editingStopCriteriaRowIndex,
                addStopCriteriaRowIndex: -1,
                taskInfo: Object.assign({}, state.taskInfo, {
                    CatConfig: Object.assign({}, state.taskInfo.CatConfig, {
                        StopCriteria: [...state.taskInfo.CatConfig.StopCriteria].map((criterion, i) => {
                            let detachedCriterion = Object.assign({}, criterion);

                            if (i !== state.editingStopCriteriaRowIndex) return detachedCriterion;

                            return action.data.stopCriterion;
                        })
                    })
                })
            });
        }
        case actionTypes.ADD_STOP_CRITERIA:
            return Object.assign({}, state, {
                isEditing: true,
                editingStopCriteriaRowIndex: state.taskInfo.CatConfig.StopCriteria.length,
                addStopCriteriaRowIndex: state.taskInfo.CatConfig.StopCriteria.length,
                taskInfo: Object.assign({}, state.taskInfo,
                    {
                        CatConfig: Object.assign({}, state.taskInfo.CatConfig,
                            {StopCriteria: [...state.taskInfo.CatConfig.StopCriteria, stopCriteriaInitialState()]})
                    })
            });
        case actionTypes.DELETE_STOP_CRITERIA: {
            let allStopCriteria = [...state.taskInfo.CatConfig.StopCriteria];
            allStopCriteria.splice(action.data, 1);
            return Object.assign({}, state, {
                isEditing: false, editingStopCriteriaRowIndex: 0, addStopCriteriaRowIndex: -1,
                taskInfo: Object.assign({}, state.taskInfo,
                    {
                        CatConfig: Object.assign({}, state.taskInfo.CatConfig,
                            {StopCriteria: allStopCriteria})
                    })
            });
        }
        case actionTypes.ASSOCIATE_ITEM_TO_CAT_CONFIG: {
            return Object.assign({}, state, {
                taskInfo: Object.assign({}, state.taskInfo,
                    {
                        CatConfig: Object.assign({}, state.taskInfo.CatConfig,
                            {ItemIds: [...state.taskInfo.CatConfig.ItemIds, action.data]}
                        )
                    }
                )
            });
        }
        case actionTypes.UNASSOCIATE_ITEM_TO_CAT_CONFIG: {
            let allItems = [...state.taskInfo.CatConfig.ItemIds];
            allItems.splice(action.data, 1);
            let unAssociated = Object.assign({}, state, {
                taskInfo: Object.assign({}, state.taskInfo, { CatConfig:
                    Object.assign({}, state.taskInfo.CatConfig,
                        {ItemIds: allItems}
                    )})
            });
            return unAssociated;
        }
        case actionTypes.UPDATE_CAT_CONFIG_ITEM_ORDER: {
            let temp = Object.assign({}, state, {
                taskInfo: Object.assign({}, state.taskInfo,
                    {
                        CatConfig: Object.assign({}, state.taskInfo.CatConfig, action.data
                        )
                    }
                )
            });
            return temp;
        }
        case actionTypes.UPDATE_CAT_CONFIG_SUBMITTED: {
            return Object.assign({}, state, {
                isSubmitted: action.data,
            });
        }
        case actionTypes.REMOVE_CAT_CONFIG_MASTERY_STOP_CRITERIA: {
            const stopCriteria = state.taskInfo.CatConfig.StopCriteria.filter(sc=>sc.StopCriteriaType !== action.data);
            return Object.assign({}, state, {
                taskInfo: Object.assign({}, state.taskInfo,
                    {
                        CatConfig: Object.assign({}, state.taskInfo.CatConfig,
                            {StopCriteria: stopCriteria})
                    })
            });
        }
        default:
            return state;
    }
}