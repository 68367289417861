import React from "react";
import PropTypes from "prop-types";
import {FormValidation} from "calidation";
import OptionEditForm from "./OptionEditForm";
import {createErrorList, hasErrors} from "../../components/form/createErrorList";
import OptionRow from "./OptionRow";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import Button from "../../components/buttons/button";

const OptionsList = ({ config }) => {
    const numberOfColumns = "6";

    let optionConfig = {
        Name: {
            isRequired: 'Option name is required!'
        },
        ValueText: {
            isRequired: 'Option text is required!'
        },
        IsCorrect: {},
        Id: {}
    };

    return (
        <FormValidation onSubmit={config.onEditOptionSubmit} config={optionConfig} id={"optionsForm"}>
            {({ fields, errors, submitted, setField }) => (
            <div>
                <div className={"grid-x"}>
                    <div className={"cell auto"}>
                        <h2>Options</h2>
                    </div>
                    <div className={"cell text-right medium-2 large-2"}>
                        <Button config={{
                            disabled: config.isEditing,
                            name: "btnAddOption",
                            label: "Add Option",
                            onClick: (event) => config.onAddOption(event, setField),
                            btnClass: "secondary",
                            btnType: buttonConstants.BUTTON_TYPE_BUTTON
                        }}/>
                    </div>
                </div>
                {submitted && hasErrors(errors) &&
                <div className="text-danger">
                    <p>Please correct the issues as specified.</p>
                    {createErrorList(errors)}
                </div>
                }
                <table className="table-grid hover stack">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th className="text-left">Text</th>
                        <th>Image</th>
                        <th>Audio</th>
                        <th>Is Correct?</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {config.options.map(
                        (option, index) => {
                            if(config.isEditing && index === config.editingRowIndex) {
                                return (<OptionEditForm key={index} config={{
                                    option: option,
                                    fields: fields,
                                    submitted: submitted,
                                    errors: errors,
                                    onFileUploadChange: config.onFileUploadChange,
                                    onCancelUpload: config.onCancelUpload,
                                    onRemoveFileClick: config.onRemoveFileClick,
                                    onEditOptionCancel: (config.addRowIndex >= 0) ? (event) => config.onDeleteOption(event, index) : config.onEditOptionCancel
                                }}/>);
                            }
                            else {
                                return (<OptionRow
                                    key={index}
                                    option={option}
                                    isEditing={config.isEditing}
                                    onEditOption={(event) => config.onEditOption(event, option, setField, index)}
                                    onDeleteOption={(event) => config.onDeleteOption(event, index)}/>);
                            }
                        }
                    )}
                    {config.options.length === 0 &&
                    <tr>
                        <td colSpan={numberOfColumns}>
                            Click &quot;Add Option&quot; to add a new option.
                        </td>
                    </tr>
                    }
                    </tbody>
                </table>
            </div>
            )}
        </FormValidation>
    );
};

OptionsList.propTypes = {
    config: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.object),
        isEditing: PropTypes.bool.isRequired,
        editingRowIndex: PropTypes.number,
        addRowIndex: PropTypes.number,
        onEditOption: PropTypes.func.isRequired,
        onEditOptionSubmit: PropTypes.func.isRequired,
        onEditOptionCancel: PropTypes.func.isRequired,

        onFileUploadChange: PropTypes.func.isRequired,
        onCancelUpload: PropTypes.func.isRequired,
        onRemoveFileClick: PropTypes.func.isRequired,
        onDeleteOption: PropTypes.func.isRequired,
        onAddOption: PropTypes.func.isRequired
    })
};

export default OptionsList;