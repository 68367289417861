import React from "react";
import PropTypes from "prop-types";
import ButtonIcon from "../../components/buttons/buttonIcon";
import * as buttonConstants from "../../components/buttons/buttonConstants";

const StopCriteriaRow = ({criterion, criterionName, isEditing, onEditStopCriteria, onDeleteStopCriteria}) => {
    return (
        <tr>
            <td>
                {criterionName}
            </td>
            <td>
                {criterion.StopCriteriaValue}
            </td>
            <td className={"text-center whitespace-nowrap"}>
                <ButtonIcon config={{
                    disabled: isEditing,
                    name: "btnEdit",
                    label: "Edit",
                    onClick: onEditStopCriteria,
                    iconType: buttonConstants.ICON_EDIT
                }}/>
                <ButtonIcon config={{
                    disabled: isEditing,
                    name: "btnDelete",
                    label: "Delete",
                    iconType: buttonConstants.ICON_DELETE,
                    onClick: onDeleteStopCriteria
                }}/>
            </td>
        </tr>
    );
};

StopCriteriaRow.propTypes = {
    criterion: PropTypes.object.isRequired,
    criterionName: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onEditStopCriteria: PropTypes.func.isRequired,
    onDeleteStopCriteria: PropTypes.func.isRequired
};

export default StopCriteriaRow;