export const UPDATE_STUDENT = "Student updated!";
export const FORGOT_PASSWORD_SUCCESS = "An email has been sent to :email.";
export const FORGOT_PASSWORD_EMAIL = ":email";
export const RESET_PASSWORD_SUCCESS = "Your password has been successfully reset.";
export const ITEM_DELETED = "Item deleted successfully!";
export const ITEM_UPDATED = "Item updated!";
export const ITEM_ADDED = "Item added!";
export const ITEM_BANK_DELETED = "Item bank deleted successfully!";
export const ITEM_BANK_ADDED = "Item bank added!";
export const ITEM_BANK_UPDATED = "Item bank updated!";
export const TASK_DELETED = "Task deleted successfully!";
export const TASK_UPDATED = "Task updated!";
export const TASK_ADDED = "Task added!";
export const ASSESSMENT_DELETED = "Assessment deleted successfully!";
export const ASSESSMENT_ADDED = "Assessment added!";
export const ASSESSMENT_UPDATED = "Assessment updated!";
export const ASSESSMENT_PUBLISHED = "Assessment published!";
export const ASSESSMENT_UNPUBLISHED = "Published assessment deleted!";
export const UPDATE_USER = "User updated!";
export const ITEM_POOL_DELETED = "Item pool deleted successfully!";
export const ITEM_POOL_ADDED = "Item pool added!";
export const ITEM_POOL_UPDATED = "Item pool updated!";

export const TASK_CAT_CONFIG_UPDATED = "Task CAT Configuration updated!";
export const TASK_CAT_CONFIG_DELETED = "Task CAT Configuration removed!";
export const TASK_CAT_CONFIG_ADDED = "Task CAT Configuration added!";

export const MCAT_TASKS_UPDATED = "MCAT Task Groups updated!";
export const MCAT_TASKS_ADDED = "MCAT Task Groups added!";
export const MCAT_TASKS_DELETED = "MCAT Task Groups deleted!";

export const EXPORT_SUCCESS = "Export Successful";