import React from "react";
import PropTypes from "prop-types";

import ButtonIcon from "../../components/buttons/buttonIcon";
import CheckBox from "../../components/inputs/checkBoxField";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import TextArea from "../../components/inputs/textArea";
import FileUploadButtonContainer from "../../components/inputs/fileUploadButtonContainer";
import ItemInfoMedia from "./ItemInfoMedia";
import MediaPlayer from "../../components/audio/MediaPlayer";
import TextField from "../../components/inputs/textField";

const OptionEditForm = ({ config }) => {
    return (
        <tr>
            <td className={"align-top"}><TextField config={{
                name: "Name",
                label: "Option Name",
                maxLength: 50,
                showLabel: false,
                value: config.fields.Name,
                error: (config.submitted && config.errors.Name) ? "*" : "",
                isRequired: true
            }} /></td>
            <td>
                <TextArea config={{
                    name: "ValueText",
                    label: "Text",
                    value: config.fields.ValueText,
                    showLabel: false,
                    error:  (config.submitted && config.errors.ValueText) ? "*" : "",
                    isRequired: true
                }}/>
            </td>
            <td className={"text-center"}>
                {config.option.ValueImage === "" &&
                <FileUploadButtonContainer config={{
                    name: "ValueImage",
                    label: "Upload",
                    onChange: (event) => config.onFileUploadChange(event, config.option),
                    fileBeingUploaded: config.option.FileData.get("ValueImage"),
                    onCancelUpload: (event) => { config.onCancelUpload(event, "ValueImage", config.option) }
                }}/>
                }
                {config.option.ValueImage !== "" &&
                <ItemInfoMedia config={{
                    media: <img src={config.option.ValueImage} alt={"Value Image"} width={"50"} height={"50"} />,
                    onRemoveClick: (event) => { config.onRemoveFileClick(event, "ValueImage", config.option) },
                    id: "ValueImage",
                    label: ""
                }}/>
                }
            </td>
            <td className={"text-center"}>
                {config.option.ValueAudio === "" &&
                <FileUploadButtonContainer config={{
                    name: "ValueAudio",
                    label: "Upload",
                    onChange: (event) => config.onFileUploadChange(event, config.option),
                    fileBeingUploaded: config.option.FileData.get("ValueAudio"),
                    onCancelUpload: (event) => { config.onCancelUpload(event, "ValueAudio", config.option) }
                }}/>
                }
                {config.option.ValueAudio !== "" &&
                <ItemInfoMedia config={{
                    media: <MediaPlayer config={{url: config.option.ValueAudio}} />,
                    onRemoveClick: (event) => { config.onRemoveFileClick(event, "ValueAudio", config.option) },
                    id: "ValueAudio",
                    label: ""
                }}/>
                }
            </td>
            <td className={"text-center"}>
                <CheckBox config={{
                    name: "IsCorrect",
                    label: "Is Correct?",
                    checked: (config.fields.IsCorrect),
                    showLabel: false,
                    error: (config.submitted && config.errors.IsCorrect) ? "*" : ""
                }} />
            </td>
            <td className={"text-center"}>
                <ButtonIcon config={{
                    name: "btnSave",
                    label: "Save",
                    iconType: buttonConstants.ICON_SAVE,
                    disabled: config.isLoading,
                    isSubmit: true
                }} />
                <ButtonIcon config={{
                    name: "btnCancel",
                    label: "Cancel",
                    onClick: config.onEditOptionCancel,
                    iconType: buttonConstants.ICON_CANCEL,
                    disabled: config.isLoading
                }} />
                <input type={"hidden"} name={"Id"} value={config.fields.Id} />
            </td>
        </tr>
    );
};

OptionEditForm.propTypes = {
    config: PropTypes.shape({
        option: PropTypes.object.isRequired,
        fields: PropTypes.object.isRequired,
        submitted: PropTypes.bool.isRequired,
        errors: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        onFileUploadChange: PropTypes.func.isRequired,
        onCancelUpload: PropTypes.func.isRequired,
        onRemoveFileClick: PropTypes.func.isRequired,
        onEditOptionCancel: PropTypes.func.isRequired
    })
};

export default OptionEditForm;