import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";
import TextField from "../../components/inputs/textField";
import TextArea from "../../components/inputs/textArea";
import {hasErrors} from "../../components/form/createErrorList";
import FileUploadButtonContainer from "../../components/inputs/fileUploadButtonContainer";
import ItemInfoMedia from "./ItemInfoMedia";
import MediaPlayer from "../../components/audio/MediaPlayer";
import Button from "../../components/buttons/button";

const ItemInfoForm = ({config}) => {
    let formConfig = {
        Name: {
            isRequired: 'Item name is required!'
        },
        PromptText: {
            isRequired: "Prompt text is required!"
        },
        Verification : {},
        Id: {}
    };
    return (
        <FormValidation onSubmit={config.onItemSubmit} config={formConfig} initialValues={config.itemInfo} id={"item-form"} >
            {({ fields, errors, submitted }) => (
                <div>
                    <input type={"hidden"} name={"Id"} value={fields.Id} />
                    <Button config={{
                        name: "btnSave",
                        label: "Save",
                        btnClass: "hide",
                        disabled: (config.isLoading || config.shouldDisableSave)
                    }}/>
                    {submitted && hasErrors(errors) && <p className="text-danger">Please correct the issues as specified.</p>}
                    <div className={"grid-x"}>
                        <div className={"cell medium-6 large-6"}>
                            <TextField config={{
                                name: "Name",
                                label: "Item Name",
                                maxLength: 50,
                                showLabel: true,
                                value: fields.Name,
                                error: (submitted && errors.Name) ? errors.Name : ""
                            }} />
                        </div>
                    </div>
                    <div className={"grid-x"}>
                        <div className={"cell medium-6 large-6"}>
                            <TextArea config={{
                                name: "PromptText",
                                label: "Prompt text",
                                showLabel: true,
                                value: fields.PromptText,
                                error: (submitted && errors.PromptText) ? errors.PromptText : ""
                            }} />
                        </div>
                        <div className={"cell medium-1 large-1"}></div>
                        <div className={"cell medium-2 large-2 text-center"}>
                            {config.itemInfo.PromptImage === "" &&
                            <FileUploadButtonContainer config={{
                                name: "PromptImage",
                                uploadDescription: "Prompt Image",
                                label: "Upload",
                                onChange: config.onFileUploadChange,
                                fileBeingUploaded: config.itemInfo.FileData.get("PromptImage"),
                                onCancelUpload: (event) => { config.onCancelUpload(event, "PromptImage") }
                            }}/>
                            }
                            {config.itemInfo.PromptImage !== "" &&
                            <ItemInfoMedia config={{
                                media: <img src={config.itemInfo.PromptImage} alt={"Prompt Image"} style={{maxHeight: "65px", maxWidth: "100px"}} />,
                                onRemoveClick: (event) => { config.onRemoveFileClick(event, "PromptImage") },
                                id: "PromptImage",
                                label: "Prompt Image"
                            }}/>
                            }
                        </div>
                        <div className={"cell medium-1 large-1"}></div>
                        <div className={"cell medium-2 large-2 text-center"}>
                            {config.itemInfo.PromptAudio === "" &&
                            <FileUploadButtonContainer config={{
                                name: "PromptAudio",
                                uploadDescription: "Prompt Audio",
                                label: "Upload",
                                onChange: config.onFileUploadChange,
                                fileBeingUploaded: config.itemInfo.FileData.get("PromptAudio"),
                                onCancelUpload: (event) => { config.onCancelUpload(event, "PromptAudio") }
                            }}/>
                            }
                            {config.itemInfo.PromptAudio !== "" &&
                            <ItemInfoMedia config={{
                                media: <MediaPlayer config={{url: config.itemInfo.PromptAudio}} />,
                                onRemoveClick: (event) => { config.onRemoveFileClick(event, "PromptAudio") },
                                id: "PromptAudio",
                                label: "Prompt Audio"
                            }}/>
                            }
                        </div>
                    </div>
                    <div className={"grid-x"}>
                        <div className={"cell medium-6 large-6"}>
                            <TextField config={{
                                name: "Verification",
                                label: "Verification",
                                maxLength: 250,
                                showLabel: true,
                                value: fields.Verification
                            }} />
                        </div>
                    </div>
                </div>
            )}
        </FormValidation>
    );
};

ItemInfoForm.propTypes = {
    config: PropTypes.shape({
        onItemSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        itemInfo: PropTypes.object,
        onFileUploadChange: PropTypes.func.isRequired,
        onRemoveFileClick: PropTypes.func.isRequired,
        onCancelUpload: PropTypes.func.isRequired,
        shouldDisableSave: PropTypes.bool.isRequired
    })
};

export default ItemInfoForm;