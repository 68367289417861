import React from "react";
import PropTypes from "prop-types";
import ButtonIcon from "../../components/buttons/buttonIcon";
import * as buttonConstants from "../../components/buttons/buttonConstants";

const StudentMgmtSearchRow = ({ student, isEditing, onEditStudent, setField }) => {
    return (
        <tr>
            <td className={"text-center"}>
                {(student.IsActive) ? "YES" : "NO"}
            </td>
            <td className={"text-center"}>
                {student.StudentTrackingId}
            </td>
            <td>
                {student.LastName}
            </td>
            <td>
                {student.FirstName}
            </td>
            <td className={"text-center"}>
                <ButtonIcon config={{
                    disabled: isEditing,
                    name: "btnEdit",
                    label: "Edit",
                    onClick: (event) => onEditStudent(event, student, setField),
                    iconType: buttonConstants.ICON_EDIT
                }} />
            </td>
        </tr>
    );
};

StudentMgmtSearchRow.propTypes = {
    student: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onEditStudent: PropTypes.func.isRequired,
    setField: PropTypes.func.isRequired
};

export default StudentMgmtSearchRow;