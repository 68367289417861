import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";

import { generateCatModelOptions } from "../../constants/catModelConstants";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import TextField from "../../components/inputs/textField";
import SelectField from "../../components/inputs/selectField";

const ItemPoolListSearchForm = ({config}) => {
    let formConfig = {
        NameContains: {},
        CatModelType: {}
    };
    return (
        <FormValidation onSubmit={config.onSearchSubmit} config={formConfig} initialValues={config.itemPoolSearchCriteria} >
            {({ fields, setField }) => (
                <div className={"input-bottom-alignment"}>
                    <div className={"grid-x grid-padding-x align-bottom"}>
                        <div className={"cell medium-3 large-3"}>
                            <TextField config={{
                                name: "NameContains",
                                label: "Item Pool Name:",
                                maxLength: 50,
                                showLabel: true,
                                value: fields.NameContains,
                                inputClass: "margin-bottom-0"
                            }} />
                        </div>
                        <div className={"cell medium-3 large-3 margin-bottom-0"}>
                            <SelectField config={{
                                name: "CatModelType",
                                label: "CAT Model",
                                showLabel: true,
                                options: generateCatModelOptions(),
                                value: fields.CatModelType.toString(),
                                defaultOption: "Select a CAT Model",
                            }} />
                        </div>
                        <div className={"cell medium-3 large-3"}>
                            <div className={"button-group"}>
                                <Button config={{
                                    name: "btnClearItemPoolSearch",
                                    label: "Clear",
                                    onClick: (event) => config.clearCriteria(event, setField),
                                    btnClass: "light",
                                    btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                }} />
                                <Button config={{
                                    name: "btnSearchItemPools",
                                    label: "Search",
                                    disabled: config.isLoading
                                }} />

                            </div>
                        </div>
                        <div className={"cell medium-1 large-1 align-right"}>
                            <div className={"button-group"}>
                                <Button config={{
                                    name: "btnCreateItemPool",
                                    label: "Create Config",
                                    onClick: config.onAddItemPool,
                                    btnClass: "light",
                                    btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </FormValidation>
    );
};

ItemPoolListSearchForm.propTypes = {
    config: PropTypes.shape({
        onSearchSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        clearCriteria: PropTypes.func.isRequired,
        itemPoolSearchCriteria: PropTypes.object,
        onAddItemPool: PropTypes.func.isRequired
    })
};

export default ItemPoolListSearchForm;