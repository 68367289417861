import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as taskActions from "./TaskAction";
import {taskInitialState} from "./TaskReducer";
import * as routes from "../../components/routes/routesConstants";
import TaskListSearchForm from "./TaskListSearchForm";
import TaskListSearchResults from "./TaskListSearchResults";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import {Search} from "../../components/table/Search";
import {convertGeneralDataToOptions} from "../../components/generalData/convertGeneralDataToOptions";
import * as generalData from "../../components/generalData/generalDataConstants";
import localStorage from "../../components/localStorage/access";
import * as loginActions from "../Login/LoginAction";

export class TaskListPage extends Search {
    constructor(props, context) {
        super(props, context, taskInitialState.taskSearchCriteria);

        this.state = {
            isLoading: false
        };

        this.onAddTask = this.onAddTask.bind(this);
        this.onCatConfigEdit = this.onCatConfigEdit.bind(this);
        this.onEditTask = this.onEditTask.bind(this);
        this.onDeleteTask = this.onDeleteTask.bind(this);
    }

    componentDidMount(){
        PushFocusToElement("NameContains");
        this.props.actions.updatePageTitle_BreadCrumb(routes.TASK_LIST);
        if(this.props.taskSearchCriteria !== undefined &&
            this.props.taskSearchCriteria.taskSearchPerformed) {
            this.props.actions.loadTasks(this.props.taskSearchCriteria);
        }

        this.setLoadSearchResults(this.loadTasks);
        this.setProps(this.props.taskSearchCriteria, this.props.taskTotalRecords);
    }

    componentDidUpdate() {
        this.setProps(this.props.taskSearchCriteria, this.props.taskTotalRecords);
    }

    convertNumericSearchCriteriaToInt(taskSearchCriteria) {
        let searchCriteria = Object.assign({}, taskSearchCriteria);
        searchCriteria.TaskTypeId = (searchCriteria.TaskTypeId === "") ? 0 : parseInt(searchCriteria.TaskTypeId);
        searchCriteria.CatModelType = (isNaN(searchCriteria.CatModelType) || searchCriteria.CatModelType === "") ? undefined : parseInt(searchCriteria.CatModelType);

        return searchCriteria;
    }

    loadTasks(taskSearchCriteria) {
        taskSearchCriteria.taskSearchPerformed = true;
        taskSearchCriteria.ExcludeGamePlay = true;
        const updatedTaskSearchCriteria = this.convertNumericSearchCriteriaToInt(taskSearchCriteria);
        this.props.actions.saveTaskSearchCriteria(updatedTaskSearchCriteria);

        this.props.actions.loadTasks(updatedTaskSearchCriteria);
    }

    onAddTask(event) {
        event.preventDefault();

        this.props.history.push(routes.TASK_INFO.path.replace(routes.TASK_INFO.params.taskId, 0));
    }

    onCatConfigEdit(event, taskId) {
        event.preventDefault();

        this.props.history.push(routes.TASK_CAT_CONFIG_INFO.path.replace(routes.TASK_CAT_CONFIG_INFO.params.taskId, taskId));
    }

    onEditTask(event, taskId) {
        event.preventDefault();

        this.props.history.push(routes.TASK_INFO.path.replace(routes.TASK_INFO.params.taskId, taskId));
    }

    onDeleteTask(event, deleteLinkInfo) {
        event.preventDefault();

        if(confirm("Are you sure you want to delete this task?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page."))
        {
            this.props.actions.deleteTask(deleteLinkInfo)
                .then(() => this.reloadAfterDelete())
        }
    }

    reloadAfterDelete() {
        let criteria = Object.assign({}, this.props.taskSearchCriteria);
        criteria.Page = 1;

        this.loadTasks(criteria);
    }

    render() {
        let {taskTotalRecords, taskSearchCriteria, taskSearchResults, taskTypes } = this.props;

        if(taskTypes === undefined)
            return null;

        return (
            <div>
                <TaskListSearchForm config={{
                    onSearchSubmit: this.onSearchSubmit,
                    clearCriteria: this.clearCriteria,
                    isLoading: this.state.isLoading,
                    taskSearchCriteria: taskSearchCriteria,
                    onAddTask: this.onAddTask,
                    taskTypes: taskTypes
                }} />
                {taskSearchCriteria !== undefined &&
                taskSearchCriteria.taskSearchPerformed &&
                <div id={"searchResults"}>
                    <hr/>
                    <TaskListSearchResults config={{
                        taskSearchResults: taskSearchResults,
                        order: this.order,
                        first: this.first,
                        last: this.last,
                        next: this.next,
                        previous: this.previous,
                        taskTotalRecords: taskTotalRecords,
                        currentPage: taskSearchCriteria.Page,
                        taskRecordsPerPage: taskSearchCriteria.RecordsPerPage,
                        onCatConfigEdit: this.onCatConfigEdit,
                        onEditTask: this.onEditTask,
                        onDeleteTask: this.onDeleteTask
                    }}/>
                </div>
                }
            </div>
        );
    }
}

TaskListPage.propTypes = {
    taskSearchResults: PropTypes.arrayOf(PropTypes.object),
    taskSearchCriteria: PropTypes.object,
    taskTotalRecords: PropTypes.number,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    taskTypes: PropTypes.array
};

function mapStateToProps(state) {
    return {
        taskSearchResults: state.task.taskSearchResults,
        taskSearchCriteria: state.task.taskSearchCriteria,
        taskTotalRecords: state.task.taskTotalRecords,
        taskTypes: convertGeneralDataToOptions(state.taskType.taskTypes, generalData.TASK_TYPE_DATA.name)
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        taskActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (TaskListPage));
