import { beginAjaxCall, ajaxCallError } from "../AjaxStatus/AjaxStatusActions";
import * as actionTypes from "./TaskTypeConstants";
import taskTypeApi from "./TaskTypeApi";

export function loadTaskTypesSuccess(allTaskTypes) {
    return { type: actionTypes.GET_TASK_TYPES_AJAX_SUCCESS, allTaskTypes };
}

export function loadTaskTypes() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return taskTypeApi.getTaskTypes()
            .then((allTaskTypes) => {
                dispatch(loadTaskTypesSuccess(allTaskTypes));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}