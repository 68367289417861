import React from "react";
import PropTypes from "prop-types";
import MediaPlayer from "../../components/audio/MediaPlayer";
import ButtonIcon from "../../components/buttons/buttonIcon";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import ButtonBar from "../../components/buttons/buttonBar";
import * as config from "../../constants/config";
import {toDangerouslySetHtml} from "../../components/layout/toDangerouslySetHtml";

const ItemListSearchRow = ({ item, onEditItem, onDeleteItem }) => {
    let image = <span>N/A</span>;
    if(item.PromptImage !== "")
        image = <img src={item.PromptImage} alt={item.PromptText} style={{maxHeight: "50px", maxWidth: "50px"}} />;

    let audio = <span>N/A</span>;
     if(item.PromptAudio !== "")
         audio = <MediaPlayer config={{url: item.PromptAudio}} />;

    const itemLinkList = item.Links.filter(f => f.Method === config.DELETE_API_METHOD);
    let itemLinkInfo = {};
    if(itemLinkList.length > 0)
        itemLinkInfo = itemLinkList[0];

    return (
        <tr>
            <td>
                {item.Name}
            </td>
            <td dangerouslySetInnerHTML={toDangerouslySetHtml(item.PromptText)}>
            </td>
            <td className={"text-center"}>
                {image}
            </td>
            <td className={"text-center"}>
                {audio}
            </td>
            <td className={"text-center"}>
                <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_CENTER}>
                    <ButtonIcon config={{
                        name: "btnEdit",
                        label: "Edit",
                        onClick: (event) => onEditItem(event, item.Id),
                        iconType: buttonConstants.ICON_EDIT
                    }} />
                    <ButtonIcon config={{
                        name: "btnDelete",
                        label: (itemLinkList.length === 0) ? "Delete not enabled due to being associated with a score" : "Delete",
                        disabled: (itemLinkList.length === 0),
                        onClick: (event) => onDeleteItem(event, itemLinkInfo),
                        iconType: buttonConstants.ICON_DELETE
                    }} />
                </ButtonBar>
            </td>
        </tr>
    );
};

ItemListSearchRow.propTypes = {
    item: PropTypes.object.isRequired,
    onEditItem: PropTypes.func.isRequired,
    onDeleteItem: PropTypes.func.isRequired
};

export default ItemListSearchRow;