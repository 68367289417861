import React from 'react';
import PropTypes from "prop-types";
import * as buttonConstants from "./buttonConstants";

const Button = ({ config }) => {
    config = Object.assign({}, buttonDefaults(), config);

    const isButtonTypeIsValid = ((buttonConstants.buttonTypes.filter(f => f === config.btnType)).length > 0);

    if(!isButtonTypeIsValid)
        return null;

    return (
        <button
            id={config.name}
            type={config.btnType}
            onClick={config.onClick}
            className={"button " + config.btnClass}
            disabled={config.disabled}
            tabIndex={config.tabIndex}>
                <span />{config.label}
        </button>
    );
};

Button.propTypes = {
    config: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        btnClass: PropTypes.string,
        disabled: PropTypes.bool,
        btnType: PropTypes.string,
        tabIndex: PropTypes.number
    })
};

function buttonDefaults() {
    return {
        btnType: buttonConstants.BUTTON_TYPE_SUBMIT,
        disabled: false,
        btnClass: ""
    };
}

export default Button;
