import React from "react";
import PropTypes from "prop-types";
import ButtonBar from "../../components/buttons/buttonBar";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import Button from "../../components/buttons/button";

const ReportingSearchResults = ({ uniqueScores, uniqueAudioFiles, onExportScores, onExportAudioFiles }) => {
    const anyScoresForExport = (uniqueScores > 0);
    const anyAudioFilesForExport = (uniqueAudioFiles > 0);
    return (
        <div>
            <table className="table-grid shrink stack align-center margin-top-2 margin-bottom-3">
                <tbody>
                <tr>
                    <td className={"text-right"}>Unique scores in the database</td>
                    <td className={"text-center"}>{uniqueScores}</td>
                </tr>
                <tr>
                    <td className={"text-right"}>Audio responses in the database</td>
                    <td className={"text-center"}>{uniqueAudioFiles}</td>
                </tr>
                </tbody>
            </table>

            <ButtonBar name={"exportBtnContainers"} buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_CENTER}>
                <Button config={{
                    name: "btnExportScores",
                    label: "Export Scores",
                    onClick: onExportScores,
                    btnClass: "secondary",
                    btnType: buttonConstants.BUTTON_TYPE_BUTTON,
                    disabled: !anyScoresForExport
                }}/>
                <Button config={{
                    name: "btnExportAudioFiles",
                    label: "Export Audio Files",
                    onClick: onExportAudioFiles,
                    btnClass: "secondary",
                    btnType: buttonConstants.BUTTON_TYPE_BUTTON,
                    disabled: !anyAudioFilesForExport
                }}/>
            </ButtonBar>
        </div>
    );
};

ReportingSearchResults.propTypes = {
    uniqueScores: PropTypes.number,
    uniqueAudioFiles: PropTypes.number,
    onExportScores: PropTypes.func.isRequired,
    onExportAudioFiles: PropTypes.func.isRequired
};

export default ReportingSearchResults;