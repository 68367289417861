import React from "react";
import PropTypes from "prop-types";
import ButtonIcon from "../../components/buttons/buttonIcon";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import ButtonLink from "../../components/buttons/buttonLink";

const UserListSearchRow = ({ user, isEditing, onEditUser, onResetPassword, setField }) => {
    return (
        <tr>
            <td className={"text-center"}>
                {(user.IsActive) ? "YES" : "NO"}
            </td>
            <td>
                {user.Email}
            </td>
            <td>
                {user.FirstName}
            </td>
            <td>
                {user.LastName}
            </td>
            <td>
                {user.Role}
            </td>
            <td>
                {user.DistrictName}
            </td>
            <td className={"text-center"}>
                <ButtonLink config={{
                    name: "btnResetPassword",
                    label: "Reset Password",
                    onClick: (event) => onResetPassword(event, user),
                    disabled: isEditing
                }}/>
                <ButtonIcon config={{
                    disabled: isEditing,
                    name: "btnEdit",
                    label: "Edit",
                    onClick: (event) => onEditUser(event, user, setField),
                    iconType: buttonConstants.ICON_EDIT
                }} />
            </td>
        </tr>
    );
};

UserListSearchRow.propTypes = {
    user: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onEditUser: PropTypes.func.isRequired,
    setField: PropTypes.func.isRequired,
    onResetPassword: PropTypes.func.isRequired
};

export default UserListSearchRow;