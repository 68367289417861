import LocalStorage from "../localStorage/access";

export function setPageTitle(pageTitle) {
    let siteDetail = getSiteDetail();
    if (pageTitle && pageTitle != "")
        document.title = `${siteDetail.SiteName} | ${pageTitle}`;
    else
        document.title = `${siteDetail.SiteName}`;
}

export function getSiteDetail()
{
    let siteDetail = LocalStorage.getSiteDetails();
    if (!siteDetail)
        siteDetail = { SiteName: "", SupportEmail: "support+CZF_NGS@fcim.org"};

    return siteDetail;
}