import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";
import TextField from "../../components/inputs/textField";
import {hasErrors} from "../../components/form/createErrorList";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import ButtonBar from "../../components/buttons/buttonBar";
import SortOptions from "../../components/inputs/sortOptions";
import SearchableSelectField from "../../components/inputs/searchableSelectField";

const ItemBankInfoForm = ({config}) => {
    let formConfig = {
        Name: {
            isRequired: 'Item bank name is required!'
        },
        ItemIds: {
            isRequired: 'At least one item must be associated with this item bank!'
        },
        Id: {}
    };

    let associatedItems = [];
    for (let j = 0; j < config.itemBankInfo.ItemIds.length; j++) {
        associatedItems.push(config.itemSelectList.filter(f => f.value === config.itemBankInfo.ItemIds[j])[0]);
    }

    const initValues = Object.assign({}, {Name: config.itemBankInfo.Name, ItemIds : (config.itemBankInfo.ItemIds.length > 0) ? "Yes" : ""});

    return (
        <FormValidation onSubmit={config.onItemBankSubmit} config={formConfig} initialValues={initValues} id={"item-bank-form"} >
            {({ fields, errors, submitted, setField }) => (
                <div className={"grid-x"}>
                    <div className={"cell shrink center-element"}>
                        <input type={"hidden"} name={"Id"} value={fields.Id} />
                        <input type={"hidden"} name={"ItemIds"} value={fields.ItemIds} />
                        {submitted && hasErrors(errors) && <p className="text-danger">Please correct the issues as specified.</p>}
                        <div className={"grid-x"}>
                            <div className={"cell"}>
                                <TextField config={{
                                    name: "Name",
                                    label: "Item Bank Name",
                                    maxLength: 50,
                                    showLabel: true,
                                    value: fields.Name,
                                    error: (submitted && errors.Name) ? errors.Name : ""
                                }} />
                            </div>
                        </div>
                        <div className={"grid-x"}>
                            <div className={"cell"}>
                                <div className={"cell font-bold"}>Associated Items</div>
                                <SearchableSelectField config={{
                                    name: "itemSelected",
                                    label: "Item",
                                    defaultOption: "Select an item",
                                    showLabel: false,
                                    options: config.itemSelectList,
                                    includeDefaultOption: true,
                                    onChange: (event) => config.onItemChange(event, setField)
                                }} />
                                {
                                    associatedItems.length > 0 &&
                                    <SortOptions config={{
                                        onDragEnd: config.onDragEnd,
                                        options: associatedItems,
                                        showDelete: true,
                                        onDeleteOption: config.onDeleteItem,
                                        setField: setField,
                                        wrapperClass: "sort-container"
                                    }}/>
                                }
                                {submitted && errors.ItemIds && <div className="text-danger">{errors.ItemIds}</div>}
                            </div>
                        </div>
                        <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_RIGHT} wrapperClass={"margin-top-2"}>
                            <Button config={{
                                name: "btnCancel",
                                label: "Cancel",
                                onClick: config.onReturnItemBankList,
                                btnClass: "secondary",
                                btnType: buttonConstants.BUTTON_TYPE_BUTTON
                            }} />
                            <Button config={{
                                name: "btnSave",
                                label: "Save",
                                disabled: (config.isLoading || !config.isUpdateable)
                            }}/>
                        </ButtonBar>
                    </div>
                </div>
            )}
        </FormValidation>
    );
};

ItemBankInfoForm.propTypes = {
    config: PropTypes.shape({
        onItemBankSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        itemBankInfo: PropTypes.object,
        isUpdateable: PropTypes.bool.isRequired,
        onReturnItemBankList: PropTypes.func.isRequired,
        onDragEnd: PropTypes.func.isRequired,
        itemSelectList: PropTypes.array.isRequired,
        onDeleteItem: PropTypes.func.isRequired,
        onItemChange: PropTypes.func.isRequired
    })
};

export default ItemBankInfoForm;