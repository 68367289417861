export default function convertObjWithSpecificFields(SearchCriteriaFields, baseObject) {
    let fields = SearchCriteriaFields.split(",");
    return baseObject.map(obj => {
        let i = {};

        let objParams = Object.keys(obj);
        for(let param in objParams) {
            if(fields.includes(objParams[param]))
                i[objParams[param]] = obj[objParams[param]];
        }

        return i;
    });
}