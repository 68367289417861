import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";

import TextField from '../../components/inputs/textField';
import Button from '../../components/buttons/button';
import * as buttonConstants from "../../components/buttons/buttonConstants";

const ForgotPasswordForm = ({ config }) => {
    let formConfig = {
        Email: {
            isRequired: 'Email is required!'
        }
    };

    return (
        <FormValidation onSubmit={config.onForgotPasswordSubmit} config={formConfig}>
        {({ fields, errors, submitted }) => (
            <div>
                <p>Enter your email address into the box below and you will
                    receive an email with a link to update your password.</p>

                <TextField config={{
                    name: "Email",
                    label: "Email",
                    maxLength: 300,
                    showLabel: true,
                    value: fields.Email,
                    error: (submitted && errors.Email) ? errors.Email : ""
                }} />


                <div className={"button-group expanded margin-top-2"}>
                    <Button config={{
                        name: "btnCancel",
                        label: "Cancel",
                        onClick: config.onCancel,
                        btnClass: "secondary",
                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                    }} />
                    <Button config={{
                        name: "btnSubmit",
                        label: "Send Reminder",
                        disabled: config.isLoading
                    }} />
                </div>
            </div>
        )}
        </FormValidation>
    );
};

ForgotPasswordForm.propTypes = {
    config: PropTypes.shape({
        onForgotPasswordSubmit: PropTypes.func.isRequired,
        isValid: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    })
};

export default ForgotPasswordForm;
