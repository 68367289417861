import $ from 'jquery';
import * as config from "../../constants/config";
import LocalStorage from "../../components/localStorage/access";
import {
    getAssessmentsData,
    saveAssessmentsData,
    getTasksArrayForAssessment
} from "../../mockData/assessmentData";
import {
    sendRequest,
    mockRejectApiCall,
    mockResolveApiCall
} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from '../../components/handlers/requests/RequestTypes';
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";
import convertObjWithSpecificFields from "../../mockData/convertObjWithSpecificFields";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerAssessmentApi {
    static getAssessments(assessmentSearchCriteria) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}assessments?${$.param(assessmentSearchCriteria)}`,
                RequestTypes.GET,
            ));
    }

    static getAssessment(assessmentId) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}assessments/${assessmentId}`,
                RequestTypes.GET,
            ));
    }

    static deleteAssessment(linkInfo) {
        return sendRequest(
            AjaxRequestOptions(
                linkInfo.Href,
                RequestTypes.DELETE,
                ApiSuccessMessages.ASSESSMENT_DELETED
            ));
    }

    static addAssessment(assessment) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}assessments`,
                RequestTypes.POST,
                JSON.stringify(assessment),
                ApiSuccessMessages.ASSESSMENT_ADDED
            )
        );
    }

    static updateAssessment(assessment) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}assessments`,
                RequestTypes.PUT,
                JSON.stringify(assessment),
                ApiSuccessMessages.ASSESSMENT_UPDATED
            )
        );
    }

    static publishAssessment(assessmentId) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}publishAssessment/${assessmentId}`,
                RequestTypes.POST,
                ApiSuccessMessages.ASSESSMENT_PUBLISHED
            )
        );
    }

    static deletePublishedAssessment(assessmentId) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}publishAssessment/${assessmentId}`,
                RequestTypes.DELETE,
                ApiSuccessMessages.ASSESSMENT_UNPUBLISHED
            )
        );
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockAssessmentApi {
    static getNewId() {
        const assessments = getAssessmentsData();
        let assessmentId = 0;
        assessments.map(assessment => {
            if (assessment.Id > assessmentId)
                assessmentId = assessment.Id;
        });

        return (assessmentId + 1);
    }

    static getAssessments(assessmentSearchCriteria) {
        if (!LocalStorage.isLoggedIn()) {
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        } else {
            let assessments = getAssessmentsData();

            if (assessmentSearchCriteria.NameContains !== "")
                assessments = assessments.filter(assessment => assessment.Name.includes(assessmentSearchCriteria.NameContains));

            if (assessmentSearchCriteria.TaskNameContains !== "")
                assessments = assessments.filter(assessment => assessment.Tasks.filter(task => task.Name.includes(assessmentSearchCriteria.TaskNameContains)).length > 0);

            let sortProp = assessmentSearchCriteria.OrderByColumn;
            let sortDir = (assessmentSearchCriteria.OrderDescending) ? -1 : 1;

            assessments.sort(function (a, b) {
                return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);
            });

            const skip = (assessmentSearchCriteria.Page - 1) * assessmentSearchCriteria.RecordsPerPage;
            const take = assessmentSearchCriteria.RecordsPerPage + skip;

            let assessmentsReturned = (assessmentSearchCriteria.Fields !== "")
                ? convertObjWithSpecificFields(assessmentSearchCriteria.Fields, assessments)
                : assessments.slice(skip, take);

            return mockResolveApiCall(Object.assign({}, {
                Data: assessmentsReturned,
                TotalRecords: assessments.length
            }));
        }
    }

    static getAssessment(assessmentId) {
        let assessments = getAssessmentsData();
        const assessmentRecord = assessments.filter(i => i.Id === assessmentId);
        if (!LocalStorage.isLoggedIn()) {
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        } else if (assessmentRecord.length === 0) {
            return mockRejectApiCall("There was an error while attempting to retrieve the assessment record.");
        } else {
            return mockResolveApiCall(assessmentRecord[0]);
        }
    }

    static deleteAssessment(linkInfo) {
        let assessments = getAssessmentsData();
        const assessmentRecord = assessments.filter(i => i.Id === parseInt(linkInfo.Href));
        if (!LocalStorage.isLoggedIn()) {
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        } else if (assessmentRecord.length === 0) {
            return mockRejectApiCall("There was an error while attempting to retrieve the assessment record.");
        } else {
            const otherAssessments = assessments.filter(i => i.Id !== parseInt(linkInfo.Href));
            saveAssessmentsData([...otherAssessments]);
            return mockResolveApiCall(ApiSuccessMessages.ASSESSMENT_DELETED);
        }
    }

    static addAssessment(assessment) {
        let assessments = getAssessmentsData();
        const otherAssessments = assessments.filter(i => i.Id !== assessment.Id);
        if (!LocalStorage.isLoggedIn()) {
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        } else if (otherAssessments.filter(f => f.Name === assessment.Name).length > 0) {
            return mockRejectApiCall("The assessment name entered is already associated with another assessment.");
        } else {
            const newId = this.getNewId();
            const addAssessment = {
                Id: newId,
                Name: assessment.Name,
                AssessmentType: assessment.AssessmentType,
                TaskIds: assessment.TaskIds,
                Tasks: getTasksArrayForAssessment(assessment.TaskIds),
                TaskType: assessment.AssessmentType,
                NumberOfTasks: assessment.TaskIds.length,
                McatTaskGroupCount: assessment.McatTaskGroupCount,
                McatTaskGroups: assessment.McatTaskGroups,
                DateCreated: "02/14/2018",
                DateModified: "02/14/2018",
                Links: [{
                    Href: newId.toString(),
                    Rel: "delete_assessment",
                    Method: "DELETE"
                }]
            };
            saveAssessmentsData([...otherAssessments, addAssessment]);
            return mockResolveApiCall(ApiSuccessMessages.ASSESSMENT_ADDED);
        }
    }

    static updateAssessment(assessment) {
        let assessments = getAssessmentsData();
        const otherAssessments = assessments.filter(i => i.Id !== assessment.Id);
        const assessmentRecord = assessments.filter(i => i.Id === assessment.Id);
        if (!LocalStorage.isLoggedIn()) {
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        } else if (assessmentRecord.length === 0) {
            return mockRejectApiCall("There was an error while attempting to retrieve the assessment record.");
        } else if (otherAssessments.filter(f => f.Name === assessment.Name).length > 0) {
            return mockRejectApiCall("The assessment name entered is already associated with another assessment.");
        } else {
            const editAssessment = {
                Id: assessment.Id,
                Name: assessment.Name,
                AssessmentType: assessment.AssessmentType,
                TaskIds: assessment.TaskIds,
                Tasks: getTasksArrayForAssessment(assessment.TaskIds),
                TaskType: assessment.AssessmentType,
                NumberOfTasks: assessment.TaskIds.length,
                McatTaskGroupCount: assessment.McatTaskGroupCount,
                McatTaskGroups: assessment.McatTaskGroups,
                DateCreated: assessment.DateCreated,
                DateModified: assessment.DateModified,
                Links: assessmentRecord[0].Links
            };
            saveAssessmentsData([...otherAssessments, editAssessment]);
            return mockResolveApiCall(ApiSuccessMessages.ASSESSMENT_UPDATED);
        }
    }
    static publishAssessment(assessmentId) {
    }

    static deletePublishedAssessment(assessmentId) {
    }
}

const AssessmentApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerAssessmentApi : MockAssessmentApi;
export default AssessmentApi;