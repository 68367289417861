import * as actionTypes from "./TaskTypeConstants";

export const taskTypeInitialState = {
    taskTypes: []
};

export function taskTypeReducer(state = taskTypeInitialState, action) {
    switch(action.type){
        case actionTypes.GET_TASK_TYPES_AJAX_SUCCESS:
            return Object.assign({}, state, {taskTypes: action.allTaskTypes});
        default:
            return state;
    }
}