export const DISTRICT_DATA = {
    name: "Districts",
    propName: "districts",
    value: "Id",
    text: ["%s (%s);DistrictName;DistrictNumber", "%s;DistrictName"]
};

export const TASK_TYPE_DATA = {
    name: "TaskTypes",
    propName: "taskTypes",
    value: "Id",
    text: ["%s;Description"]
};

export const generalDataList = [DISTRICT_DATA, TASK_TYPE_DATA];