import * as config from "../../constants/config";
import {getAccounts, saveAccounts} from "../../mockData/accountData";
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";
import {mockRejectApiCall, mockResolveApiCall, sendRequest} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerResetPasswordApi {
    static resetPassword(resetPasswordViewModel) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}reset-password`,
                RequestTypes.PUT,
                JSON.stringify(resetPasswordViewModel),
                ApiSuccessMessages.RESET_PASSWORD_SUCCESS
            )
        );
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockResetPasswordApi {
    static resetPassword(resetPasswordViewModel) {
        let users = getAccounts();
        let userAcct = users.filter(account => account.ResetPasswordGuid === resetPasswordViewModel.ResetPasswordGuid);

        if (userAcct.length === 0)
            return mockRejectApiCall("The reset link is no longer valid.  Please send yourself a new link.");
        else if (!userAcct[0].IsActive)
            return mockRejectApiCall("This account is no longer active.  Please contact the site administrator for more information.");
        else if (userAcct[0].ResetPasswordExpiration) {
            return mockRejectApiCall("The reset link has expired.  Please send yourself a new link.");
        } else {
            const otherUsers = users.filter(s => s.ResetPasswordGuid !== resetPasswordViewModel.ResetPasswordGuid);
            const editUser = {
                Id: userAcct[0].Id,
                Email: userAcct[0].Email,
                FirstName: userAcct[0].FirstName,
                LastName: userAcct[0].LastName,
                IsActive: userAcct[0].IsActive,
                isadmin: userAcct[0].isadmin,
                Role: userAcct[0].Role,
                RoleId: userAcct[0].RoleId,
                DistrictId: userAcct[0].DistrictId,
                DistrictName: userAcct[0].DistrictName,
                Password: resetPasswordViewModel.Password
            };
            saveAccounts([...otherUsers, editUser]);
            return mockResolveApiCall(ApiSuccessMessages.RESET_PASSWORD_SUCCESS);
        }
    }
}

const ResetPasswordApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerResetPasswordApi : MockResetPasswordApi;
export default ResetPasswordApi;