import {ajaxCallError, beginAjaxCall} from "../AjaxStatus/AjaxStatusActions";
import taskCatConfigApi from "./TaskCatConfigApi";
import * as actionTypes from "./TaskConstants";
import itemPoolApi from "../ItemPoolConfiguration/ItemPoolConfigurationApi";

export function saveTaskCatConfig(taskCatConfig) {
    return dispatch => {
        dispatch(beginAjaxCall());

        if (taskCatConfig.TaskCatConfigId === 0) {
            return taskCatConfigApi.addTaskCatConfig(taskCatConfig)
                .then(() => {
                    dispatch(saveTaskCatConfigSuccess());
                })
                .catch(error => {
                        dispatch(ajaxCallError(error));
                        throw (error);
                    }
                );
        } else {
            return taskCatConfigApi.updateTaskCatConfig(taskCatConfig)
                .then(() => {
                    dispatch(saveTaskCatConfigSuccess());
                })
                .catch(error => {
                        dispatch(ajaxCallError(error));
                        throw (error);
                    }
                );
        }
    };
}

export function saveTaskCatConfigSuccess() {
    return {type: actionTypes.SAVE_TASK_CAT_CONFIG_AJAX_SUCCESS};
}

export function updateTaskCatModelType(catModelType) {
    return {type: actionTypes.UPDATE_CAT_MODEL_TYPE, data: catModelType};
}

export function removeTaskCatConfigSuccess() {
    return {type: actionTypes.DELETE_TASK_CAT_CONFIG_AJAX_SUCCESS};
}

export function removeTaskCatConfig(taskId) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return taskCatConfigApi.removeTaskCatConfig(taskId)
            .then(() => {
                dispatch(removeTaskCatConfigSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw(error);
            });
    };
}


export function loadItemPoolSelectListSuccess(data) {
    return {type: actionTypes.LOAD_ITEM_POOL_SELECT_LIST_AJAX_SUCCESS, data};
}

export function loadItemPoolSelectList(taskId) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return itemPoolApi.getItemPoolsForTask(taskId)
            .then(data => {
                dispatch(loadItemPoolSelectListSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    }
}

export function editStopCriteria(index) {
    return {type: actionTypes.EDIT_STOP_CRITERIA, data: index};
}

export function cancelEditStopCriteria() {
    return {type: actionTypes.CANCEL_EDIT_STOP_CRITERIA};
}

export function updateStopCriteria(stopCriterion, isEditing, editingStopCriteriaRowIndex) {
    return {type: actionTypes.UPDATE_STOP_CRITERIA,
        data: {
            stopCriterion: stopCriterion,
            isEditing: isEditing,
            editingStopCriteriaRowIndex: editingStopCriteriaRowIndex
        }
    };
}

export function addStopCriteria() {
    return {type: actionTypes.ADD_STOP_CRITERIA};
}

export function deleteStopCriteria(index) {
    return {type: actionTypes.DELETE_STOP_CRITERIA, data: index};
}

export function associateItem(itemId) {
    return {type: actionTypes.ASSOCIATE_ITEM_TO_CAT_CONFIG, data: itemId};
}

export function unassociateItem(index) {
    return {type: actionTypes.UNASSOCIATE_ITEM_TO_CAT_CONFIG, data: index};
}

export function updateCatConfigItemOrder(catConfig) {
    return {type: actionTypes.UPDATE_CAT_CONFIG_ITEM_ORDER, data: catConfig}
}

export function updateSubmitted(isSubmitted) {
    return {type: actionTypes.UPDATE_CAT_CONFIG_SUBMITTED, data: isSubmitted}
}

export function removeMasteryStopCriteria(masteryStopCriteriaOptionValue) {
    return {type: actionTypes.REMOVE_CAT_CONFIG_MASTERY_STOP_CRITERIA, data: masteryStopCriteriaOptionValue}
}