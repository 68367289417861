import React from 'react';
import PropTypes from "prop-types";
import { FormValidation, ValidatorsProvider  } from "calidation";
import { generateAssessmentTypeOptions } from "./AssessmentConstants";
import TextField from "../../components/inputs/textField";
import {hasErrors} from "../../components/form/createErrorList";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import ButtonBar from "../../components/buttons/buttonBar";
import SortOptions from "../../components/inputs/sortOptions";
import SelectField from "../../components/inputs/selectField";
import SearchableSelectField from "../../components/inputs/searchableSelectField";

const AssessmentInfoForm = ({config}) => {
    const extraValidators = {
        isArrayRequired: config => value => (value === null || value === undefined || value.length === 0) ? config.message : null
    };

    let formConfig = {
        Name: {
            isRequired: 'Assessment name is required!'
        },
        AssessmentType: {},
        TaskIds: {
            isArrayRequired: 'At least one task must be associated with this assessment!'
        },
        Id: {}
    };

    let associatedTasks = [];
    for (let j = 0; j < config.assessmentInfo.TaskIds.length; j++) {
        associatedTasks.push(config.taskSelectList.filter(f => f.value === config.assessmentInfo.TaskIds[j])[0]);
    }

    const initValues = Object.assign({}, {Name: config.assessmentInfo.Name, AssessmentType: config.assessmentInfo.AssessmentType, TaskIds : config.assessmentInfo.TaskIds});

    return (
        <ValidatorsProvider validators={extraValidators}>
            <FormValidation onSubmit={config.onAssessmentSubmit} config={formConfig} initialValues={initValues} id={"assessment-form"} >
                {({ fields, errors, submitted, setField }) => (
                    <div className={"grid-x"}>
                        <div className={"cell shrink center-element"}>
                            <input type={"hidden"} name={"Id"} value={fields.Id} />
                            <input type={"hidden"} name={"TaskIds"} value={fields.TaskIds} />
                            {submitted && hasErrors(errors) && <p className="text-danger">Please correct the issues as specified.</p>}
                            <div className={"grid-x"}>
                                <div className={"cell"}>
                                    <TextField config={{
                                        name: "Name",
                                        label: "Assessment Name",
                                        maxLength: 50,
                                        showLabel: true,
                                        value: fields.Name,
                                        error: (submitted && errors.Name) ? errors.Name : ""
                                    }} />
                                </div>
                            </div>
                            <div className={"grid-x"}>
                                <div className={"cell medium-5 large-5"}>
                                    <SelectField config={{
                                        name: "AssessmentType",
                                        label: "Assessment Type",
                                        showLabel: true,
                                        options: generateAssessmentTypeOptions(),
                                        value: fields.AssessmentType,
                                        includeDefaultOption: false,
                                    }} />
                                </div>
                            </div>
                            <div className={"grid-x"}>
                                <div className={"cell"}>
                                    <div className={"cell font-bold"}>Associated Tasks</div>
                                    <SearchableSelectField config={{
                                        name: "taskSelected",
                                        label: "Task",
                                        defaultOption: "Select a task",
                                        showLabel: false,
                                        options: config.taskSelectList,
                                        includeDefaultOption: true,
                                        onChange: (event) => config.onTaskChange(event, setField)
                                    }} />
                                    {
                                        associatedTasks.length > 0 &&
                                        <SortOptions config={{
                                            onDragEnd: (event) => config.onDragEnd(event, setField),
                                            options: associatedTasks,
                                            showDelete: true,
                                            onDeleteOption: config.onDeleteTask,
                                            setField: setField,
                                            wrapperClass: "sort-container"
                                        }}/>
                                    }
                                    {submitted && errors.TaskIds && <div className="text-danger">{errors.TaskIds}</div>}
                                </div>
                            </div>
                            <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_CENTER} wrapperClass={"margin-top-2"}>
                                <Button config={{
                                    name: "btnMCatConfig",
                                    label: "MCAT Config",
                                    onClick: config.onCalidationMcatConfigClick,
                                    disabled: (config.isLoading || !config.isUpdateable)
                                }}/>
                                <Button config={{
                                    name: "btnCancel",
                                    label: "Cancel",
                                    onClick: config.onReturnAssessmentList,
                                    btnClass: "secondary",
                                    btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                }} />
                                <Button config={{
                                    name: "btnSave",
                                    label: "Save",
                                    disabled: (config.isLoading || !config.isUpdateable)
                                }}/>
                            </ButtonBar>
                            {config.assessmentInfo.Id > 0 &&
                            <div className={"grid-x"}>
                                <div className={"cell"}>
                                    <div className={"font-bold"}>Publish Assessment</div>
                                    {config.assessmentInfo.IsPublished && <div>Date last updated: {config.assessmentInfo.DatePublished}</div>}
                                </div>
                            </div>}
                            {!config.assessmentInfo.IsPublished && config.assessmentInfo.Id > 0 &&
                            <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_LEFT}>
                                <Button config={{
                                    name: "btnPublish",
                                    label: "Publish",
                                    onClick: config.onPublishClick,
                                    disabled: (config.isLoading)
                                }}/>
                             </ButtonBar>}
                            {config.assessmentInfo.IsPublished && config.assessmentInfo.Id > 0 &&
                            <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_LEFT}>
                                <Button config={{
                                    name: "btnUpdatePublish",
                                    label: "Update",
                                    onClick: config.onPublishClick,
                                    btnType: buttonConstants.BUTTON_TYPE_BUTTON,
                                    disabled: (config.isLoading)
                                }} />
                                <Button config={{
                                    name: "btnDeletePublish",
                                    label: "Delete",
                                    onClick: config.onDeletePublishedClick,
                                    btnClass: "secondary",
                                    disabled: (config.isLoading)
                                }}/>
                            </ButtonBar>}
                        </div>
                    </div>
                )}
            </FormValidation>
        </ValidatorsProvider>
    );
};

AssessmentInfoForm.propTypes = {
    config: PropTypes.shape({
        onAssessmentSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        assessmentInfo: PropTypes.object,
        assessmentSearchCriteria: PropTypes.object,
        isUpdateable: PropTypes.bool.isRequired,
        onReturnAssessmentList: PropTypes.func.isRequired,
        onDragEnd: PropTypes.func.isRequired,
        taskSelectList: PropTypes.array.isRequired,
        onDeleteTask: PropTypes.func.isRequired,
        onTaskChange: PropTypes.func.isRequired,
        message: PropTypes.string,
        onCalidationMcatConfigClick: PropTypes.func.isRequired,
        onPublishClick: PropTypes.func.isRequired,
        onDeletePublishedClick: PropTypes.func.isRequired
    })
};

export default AssessmentInfoForm;