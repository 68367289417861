import React from "react";
import PropTypes from "prop-types";
import {FormValidation} from "calidation";
import UserListSearchRow from "./UserListSearchRow";
import UserListEditForm from "./UserListEditForm";
import ResultsPager from "../../components/table/ResultsPager";
import {createErrorList, hasErrors} from "../../components/form/createErrorList";

const UserListSearchResults = ({ config }) => {
    const numberOfColumns = "7";

    let formConfig = {
        Email: {
            isRequired: 'Email is required!'
        },
        LastName: {
            isRequired: 'Last name is required!'
        },
        FirstName: {
            isRequired: 'First name is required!'
        },
        RoleId: {
            isRequired: 'Role is required!'
        },
        DistrictId: {
            isRequired: 'District is required!'
        },
        IsActive: {},
        Id: {}
    };
    return (
        <FormValidation onSubmit={config.onEditUserSubmit} config={formConfig}>
            {({ fields, errors, submitted, setField }) => (
            <div>
                {submitted && hasErrors(errors) &&
                    <div className="text-danger">
                        <p>Please correct the issues as specified.</p>
                        {createErrorList(errors)}
                    </div>
                }
                <table className="table-grid hover stack">
                <thead>
                    <tr>
                        <th onClick={config.order} order-by-value="IsActive" className="is-clickable">Is Active</th>
                        <th onClick={config.order} order-by-value="Email" className="text-left is-clickable">Email</th>
                        <th onClick={config.order} order-by-value="FirstName" className="text-left is-clickable">First Name</th>
                        <th onClick={config.order} order-by-value="LastName" className="text-left is-clickable">Last Name</th>
                        <th onClick={config.order} order-by-value="Role" className="text-left is-clickable">Role</th>
                        <th onClick={config.order} order-by-value="DistrictName" className="text-left is-clickable">District</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {config.userSearchResults.map(
                    (user, index) => {
                        if(config.isEditing && user.Id === config.editingUserId) {
                            return (<UserListEditForm key={index} config={{
                                user: user,
                                onCancel: config.onEditUserCancel,
                                fields: fields,
                                submitted: submitted,
                                errors: errors,
                                isLoading: config.isLoading,
                                districts: config.districts
                            }} />);
                        }
                        else {
                            return (<UserListSearchRow
                                key={index}
                                user={user}
                                isEditing={config.isEditing}
                                onEditUser={config.onEditUser}
                                setField={setField}
                                onResetPassword={config.onResetPassword}
                            />);
                        }
                    }
                )}
                {config.userTotalRecords === 0 &&
                    <tr>
                        <td colSpan={numberOfColumns}>
                            No users match the search criteria.
                        </td>
                    </tr>
                }
                </tbody>
                {config.userTotalRecords > 0 &&
                    <tfoot>
                    <tr>
                        <td colSpan={numberOfColumns}>
                            <ResultsPager config={{
                                first: config.first,
                                previous: config.previous,
                                next: config.next,
                                last: config.last,
                                totalRecords: config.userTotalRecords,
                                recordsPerPage: config.userRecordsPerPage,
                                currentPage: config.currentPage,
                                isEditing: config.isEditing
                            }}  />
                        </td>
                    </tr>
                    </tfoot>
                }
                </table>
                </div>
            )}
        </FormValidation>
    );
};

UserListSearchResults.propTypes = {
    config: PropTypes.shape({
        userSearchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
        order: PropTypes.func,
        first: PropTypes.func.isRequired,
        last: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired,
        userTotalRecords: PropTypes.number.isRequired,
        currentPage: PropTypes.number,
        userRecordsPerPage: PropTypes.number,
        isEditing: PropTypes.bool.isRequired,
        editingUserId: PropTypes.number,
        onEditUser: PropTypes.func.isRequired,
        onEditUserSubmit: PropTypes.func.isRequired,
        onEditUserCancel: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        districts: PropTypes.array.isRequired,
        onResetPassword: PropTypes.func.isRequired
    })
};

export default UserListSearchResults;