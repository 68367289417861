import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as registerActions from "./RegisterAction";
import RegisterForm from "./RegisterForm";
import {Notify} from "../../components/notification/notify";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import * as routes from "../../components/routes/routesConstants";
import {convertGeneralDataToOptions} from "../../components/generalData/convertGeneralDataToOptions";
import * as generalData from "../../components/generalData/generalDataConstants";
import * as config from "../../constants/config";
import * as districtActions from "../District/DistrictAction";

let recaptchaId = null;
let addRecaptchaCount = 0;

export class RegisterPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isValid: false,
            isLoading: false
        };

        this.onRegisterSubmit = this.onRegisterSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(){
        this.addRecaptchaObject();

        PushFocusToElement("Email");
        this.props.actions.updatePageTitle_BreadCrumb(routes.REGISTER);
        this.props.actions.loadDistricts();
    }

    componentDidUpdate() {
        this.addRecaptchaObject();
    }

    addRecaptchaObject() {
        if(recaptchaId !== null)
            return;
        if( document.getElementById("recaptchav2") && window.grecaptcha.render) {
            recaptchaId = window.grecaptcha.render('recaptchav2', {
                'sitekey': config.environment.recaptchaKey
            });
        }
        else if(addRecaptchaCount > 50)
            alert("Issue loading Captcha.  Please contact support.");
        else {
            window.setTimeout(() => {
                addRecaptchaCount++;
                this.addRecaptchaObject();
            }, 100);
        }
    }

    getRecaptchaResponse() {
        return window.grecaptcha.getResponse(recaptchaId);
    }

    resetRecaptcha()
    {
        window.grecaptcha.reset(recaptchaId);
    }

    onRegisterSubmit({fields, isValid}) {
        if (isValid) {
            this.setState({isLoading: true, isValid: true});

            fields = Object.assign({}, fields, {recaptchaResponse: this.getRecaptchaResponse()});

            this.props.actions.createAccount(fields)
                .then(() => this.props.history.push(routes.REGISTRATION_COMPLETE.path))
                .catch(() => this.setState({isLoading: false}));
        }
        else {
            this.setState({isValid: false});
            PushFocusToElement("Email");
            this.resetRecaptcha();
        }
    }

    onCancel() {
        recaptchaId = null;
        Notify.Clear();
        this.props.history.push(routes.LOGIN.path);
    }

    render() {
        return (
            <div>
                <RegisterForm config={{
                    onRegisterSubmit: this.onRegisterSubmit,
                    isValid: this.state.isValid,
                    districts: this.props.districts,
                    onCancel: this.onCancel,
                    isLoading: this.state.isLoading
                }} />
            </div>
        );
    }
}

RegisterPage.propTypes = {
    actions: PropTypes.object.isRequired,
    districts: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        districts: convertGeneralDataToOptions(state.district.districts, generalData.DISTRICT_DATA.name)
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        registerActions,
        districtActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (RegisterPage));
