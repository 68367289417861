import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";

import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import TextField from "../../components/inputs/textField";
import SelectField from "../../components/inputs/selectField";
import {generateCatModelOptions} from "../../constants/catModelConstants";

const TaskListSearchForm = ({config}) => {
    let formConfig = {
        NameContains: {},
        ItemBankNameContains: {},
        TaskTypeId: {},
        CatModelType: {}
    };
    return (
        <FormValidation onSubmit={config.onSearchSubmit} config={formConfig} initialValues={config.taskSearchCriteria} >
            {({ fields, setField }) => (
                <div className={"input-bottom-alignment"}>
                    <div className={"grid-x grid-padding-x"}>
                        <div className={"cell medium-3 large-3"}>
                            <TextField config={{
                                name: "NameContains",
                                label: "Task Name:",
                                maxLength: 50,
                                showLabel: true,
                                value: fields.NameContains,
                                inputClass: "margin-bottom-0"
                            }} />
                        </div>
                        <div className={"cell medium-4 large-4"}>
                            <TextField config={{
                                name: "ItemBankNameContains",
                                label: "Associated with Item Bank Name:",
                                maxLength: 50,
                                showLabel: true,
                                value: fields.ItemBankNameContains,
                                inputClass: "margin-bottom-0"
                            }} />
                        </div>
                    </div>
                    <div className={"grid-x grid-padding-x align-bottom"}>
                        <div className={"cell medium-3 large-3"}>
                            <SelectField config={{
                                name: "TaskTypeId",
                                label: "Type:",
                                showLabel: true,
                                options: config.taskTypes,
                                value: fields.TaskTypeId.toString(),
                                defaultOption: "Any Type",
                                inputClass: "margin-bottom-0"
                            }} />
                        </div>
                        <div className={"cell medium-3 large-3"}>
                            <SelectField config={{
                                name: "CatModelType",
                                label: "CAT Model:",
                                showLabel: true,
                                options: generateCatModelOptions(true),
                                value: isNaN(fields.CatModelType) || fields.CatModelType === null ? "" : fields.CatModelType.toString(),
                                defaultOption: "Select a CAT Model",
                                inputClass: "margin-bottom-0"
                            }} />
                        </div>
                        <div className={"cell medium-3 large-3"}>
                            <div className={"button-group"}>
                                <Button config={{
                                    name: "btnClearTaskSearch",
                                    label: "Clear",
                                    onClick: (event) => config.clearCriteria(event, setField),
                                    btnClass: "light",
                                    btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                }} />
                                <Button config={{
                                    name: "btnSearchTasks",
                                    label: "Search",
                                    disabled: config.isLoading
                                }} />
                            </div>
                        </div>
                        <div className={"cell medium-3 large-3 text-right"}>
                            <Button config={{
                                name: "btnCreateTask",
                                label: "Create Task",
                                onClick: config.onAddTask,
                                btnClass: "light",
                                btnType: buttonConstants.BUTTON_TYPE_BUTTON
                            }}/>
                        </div>
                    </div>
                </div>
            )}
        </FormValidation>
    );
};

TaskListSearchForm.propTypes = {
    config: PropTypes.shape({
        onSearchSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        clearCriteria: PropTypes.func.isRequired,
        taskSearchCriteria: PropTypes.object,
        onAddTask: PropTypes.func.isRequired,
        taskTypes: PropTypes.array.isRequired
    })
};

export default TaskListSearchForm;