import * as actionTypes from "./UserMgmtConstants";
import * as config from "../../constants/config";

export function userMgmtDefaultState() {
    return {
        userSearchCriteria: {
            NameEmailContains: "",
            RoleId: -1,
            DistrictId: -1,
            IsActive: null,
            Page: 1,
            RecordsPerPage: config.defaultRecordsPerPage,
            OrderByColumn: "LastName",
            OrderDescending: false,
            userSearchPerformed: false
        },
        userTotalRecords: 0,
        userSearchResults: [],
        isEditing: false,
        editingUserId: 0
    };
}

export const userMgmtInitialState = userMgmtDefaultState();

export function userMgmtReducer(state = userMgmtInitialState, action) {
    switch(action.type){
        case actionTypes.LOAD_USERS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    userSearchResults: [...action.data.Data],
                    userTotalRecords: action.data.TotalRecords
                });
        case actionTypes.SAVE_USER_SEARCH_CRITERIA:
            return Object.assign({}, state, { userSearchCriteria: action.criteria});
        case actionTypes.EDIT_USER:
            return Object.assign({}, state, { isEditing: true, editingUserId: action.data });
        case actionTypes.CANCEL_EDIT_USER:
            return Object.assign({}, state, { isEditing: false, editingUserId: 0 });
        case actionTypes.SAVE_USER_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    isEditing: false,
                    editingUserId: 0,
                    userSearchResults: state.userSearchResults.map(user => {
                        let detachedUser = Object.assign({}, user);

                        if(detachedUser.Id !== action.data.Id) return detachedUser;

                        return action.data;
                    }),
                });
        default:
            return state;
    }
}