import { Notification } from "./Notification";

export class MultiErrorMessageNotification extends Notification {
    constructor(notificationAdapter, errorResponse) {
        super();
        this._notificationAdapter = notificationAdapter;
        this._notifications = [];

        errorResponse.forEach((errorGroup, index) => {
            let notificationGroup = {};
            
            let containsMultipleErrors = errorGroup.Errors.length > 1 || (errorGroup.Errors.length === 1 && errorGroup.Errors[0].Errors.length > 1);
            notificationGroup.Title = errorGroup.PropertyName
            ? `${errorGroup.PropertyName} ${ containsMultipleErrors ? 'Errors' : 'Error' }`
            : "";

            let message = '<ul>';
            let messageAsTitle = "";
            if (containsMultipleErrors) {
                if(errorGroup.Message !== "")
                    messageAsTitle = errorGroup.Message;

                errorGroup.Errors.forEach(item => {
                    if(item.Errors.length > 0)
                        item.Errors.forEach(subItem => message += `<li>${subItem.Message}</li>`);
                    else
                        message += `<li>${item.Message}</li>`;
                });
            }
            else 
                message += `<li>${errorGroup.Message}</li>`;
            
            message += '</ul>';

            if(notificationGroup.Title === "")
                notificationGroup.Title = messageAsTitle !== ""
                    ? messageAsTitle
                    : `Error ${index + 1}`;

            notificationGroup.Message = message;

            this._notifications.push(notificationGroup);
        });
    }
    

    notify() {
        this._notifications.forEach(notification => this._notificationAdapter.error(notification.Message, notification.Title));
    }
}