import React from "react";
import PropTypes from "prop-types";
import {FormValidation} from "calidation";
import StopCriteriaEdit from "./StopCriteriaEdit";
import {createErrorList, hasErrors} from "../../components/form/createErrorList";
import StopCriteriaRow from "./StopCriteriaRow";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import Button from "../../components/buttons/button";
import {isNullOrUndefined} from "../../components/common/commonUtilities";

const StopCriteriaList = ({ config }) => {

    const numberOfColumns = "3";

    let stopCriterionConfig = {

        StopCriteriaType: {
            isGreaterThan: {
                message: "Stop criterion is required!",
                value: 0,
            },
            isRequired : {
                message: "Stop criterion is required!",
                value: "",
            }
        },
        StopCriteriaValue: {
            isRequired: 'Stop criterion value is required!'
        },
        Id: {}
    };

    return (
        <FormValidation onSubmit={config.onEditStopCriteriaSubmit} config={stopCriterionConfig} id={"stopCriteriaForm"}>
            {({ fields, errors, submitted, setField }) => (
                <div>
                    <div className={"grid-x"}>
                        <div className={"cell auto"}>
                            <h3>CAT Stop Criteria</h3>
                        </div>
                        <div className={"cell text-right medium-4 large-4"}>
                            <Button config={{
                                disabled: config.isEditing || config.addStopDisabled,
                                name: "btnAddStopCriteria",
                                label: "Add Stop",
                                onClick: (event) => config.onAddStopCriteria(event, setField),
                                btnClass: "secondary",
                                btnType: buttonConstants.BUTTON_TYPE_BUTTON
                            }}/>
                        </div>
                    </div>
                    {submitted && hasErrors(errors) &&
                    <div className="text-danger">
                        <p>Please correct the issues as specified.</p>
                        {createErrorList(errors)}
                    </div>
                    }
                    <table className="table-grid hover stack">
                        <thead>
                        </thead>
                        <tbody>
                        {config.stopCriteria.map(
                            (criterion, index) => {
                                let criterionName = "";
                                let stopCriteriaInfo = config.stopCriteriaOptions.find(f => f.value === parseInt(criterion.StopCriteriaType));
                                if(!isNullOrUndefined(stopCriteriaInfo))
                                    criterionName = stopCriteriaInfo.name;

                                if(config.isEditing && index === config.editingStopCriteriaRowIndex) {
                                     return (<StopCriteriaEdit key={index} config={{
                                        criterion: criterion,
                                         criterionName: criterionName,
                                        fields: fields,
                                        submitted: submitted,
                                        errors: errors,
                                        onEditStopCriteriaCancel: (config.addStopCriteriaRowIndex >= 0) ? (event) => config.onDeleteStopCriteria(event, index) : config.onEditStopCriteriaCancel,
                                        stopCriteriaOptions: config.getStopCriteriaOptions(config.stopCriteria, parseInt(criterion.StopCriteriaType)),
                                        setField: setField,
                                        onStopCriteriaChange: config.onStopCriteriaChange
                                    }}/>);
                                }
                                else {
                                    return (<StopCriteriaRow
                                        key={index}
                                        criterion={criterion}
                                        criterionName = {criterionName}
                                        isEditing={config.isEditing}
                                        onEditStopCriteria={(event) => config.onEditStopCriteria(event, criterion, setField, index)}
                                        onDeleteStopCriteria={(event) => config.onDeleteStopCriteria(event, index)}/>);
                                }
                            }
                        )}
                        {config.stopCriteria.length === 0 &&
                        <tr>
                            <td colSpan={numberOfColumns}>
                                Click &quot;Add Stop&quot; to add new stop criteria.
                                {config.isSubmitted && <div className="text-danger">At least one stop criterion is required!</div>}
                            </td>
                        </tr>
                        }
                        </tbody>
                    </table>
                </div>
            )}
        </FormValidation>
    );
};

StopCriteriaList.propTypes = {
    config: PropTypes.shape({
        stopCriteria: PropTypes.arrayOf(PropTypes.object),
        isEditing: PropTypes.bool.isRequired,
        editingStopCriteriaRowIndex: PropTypes.number,
        addStopCriteriaRowIndex: PropTypes.number,
        onEditStopCriteria: PropTypes.func.isRequired,
        onEditStopCriteriaSubmit: PropTypes.func.isRequired,
        onEditStopCriteriaCancel: PropTypes.func.isRequired,
        onDeleteStopCriteria: PropTypes.func.isRequired,
        onAddStopCriteria: PropTypes.func.isRequired,
        stopCriteriaOptions: PropTypes.array.isRequired,
        onStopCriteriaChange: PropTypes.func.isRequired,
        addStopDisabled: PropTypes.bool.isRequired,
        getStopCriteriaOptions: PropTypes.func.isRequired,
        isSubmitted: PropTypes.bool,

    })
};

export default StopCriteriaList;