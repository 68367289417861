import React from 'react';
import PropTypes from "prop-types";

const TextArea = ({ config }) => {
    config = Object.assign({}, TextAreaDefaults(), config);

    if (!config.showLabel) {
        config.labelClass += " show-for-sr";
    }

    return (
        <div className={"grid-x " + config.wrapperClass}>
            <label className={"cell " + config.labelClass} htmlFor={config.name}>{ (config.isRequired) && <span className="text-danger">* </span>}{config.label}</label>
            <div className={"cell " + config.inputClass}>
                <textarea
                       name={config.name}
                       id={config.name}
                       placeholder={config.placeholder}
                       value={config.value}
                       onChange={f => f}
                       readOnly={config.disabled}
                />
            </div>

            {config.error && <div className="text-danger">{config.error}</div>}
        </div>
    );
};

TextArea.propTypes = {
    config: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        value: PropTypes.string.isRequired,
        showLabel: PropTypes.bool.isRequired,
        error: PropTypes.string,

        wrapperClass: PropTypes.string,
        labelClass: PropTypes.string,
        inputClass: PropTypes.string,
        disabled: PropTypes.bool,
        isRequired: PropTypes.bool
    })
};

function TextAreaDefaults() {
    return {
        wrapperClass: "",
        disabled: false,
        labelClass: "small-12",
        inputClass: "small-12",
        isRequired: false
    };
}

export default TextArea;