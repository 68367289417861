/* eslint-disable import/no-named-as-default */
import { Switch } from "react-router-dom";
import React from "react";
import { hot } from "react-hot-loader";

import LoginPage from "./Login/LoginPage";
import ForgotPasswordPage from "./ForgotPassword/ForgotPasswordPage";
import ResetPasswordPage from "./ResetPassword/ResetPasswordPage";
import RegisterPage from "./Register/RegisterPage";
import NotFoundPage from "./NotFoundPage";
import PublicLayoutPage from "./PublicLayout/PublicLayoutPage";
import EnsureLoggedIn from "./AuthenticatedLayout/EnsureLoggedIn";
import DashboardPage from "./Dashboard/DashboardPage";
import RegistrationComplete from "./Register/RegistrationComplete";
import StudentMgmtPage from "./StudentMgmt/StudentMgmtPage";
import AssessmentListPage from "./Assessments/AssessmentListPage";
import AssessmentInfoPage from "./Assessments/AssessmentInfoPage";
import McatConfigInfoPage from "./Assessments/McatConfigInfoPage";
import ItemListPage from "./Items/ItemListPage";
import ItemInfoPage  from "./Items/ItemInfoPage";
import ItemBankListPage from "./ItemBanks/ItemBankListPage";
import ItemBankInfoPage from "./ItemBanks/ItemBankInfoPage";
import TaskListPage from "./Tasks/TaskListPage";
import TaskInfoPage from "./Tasks/TaskInfoPage";
import TaskCatConfigInfoPage from "./Tasks/TaskCatConfigInfoPage";
import UserListPage from "./UserMgmt/UserListPage";
import EnrollmentMgmtPage from "./EnrollmentMgmt/EnrollmentMgmtPage";
import ReportingPage from "./Reporting/ReportingPage";
import SlabPage from "./Slab/SlabPage";

import ItemPoolConfigurationPage from "./ItemPoolConfiguration/ItemPoolConfigurationPage";
import ItemPoolConfigurationInfoPage from "./ItemPoolConfiguration/ItemPoolConfigurationInfoPage";

import * as routes from "../components/routes/routesConstants";

class App extends React.Component {
    render() {
        return (
            <div>
                <Switch>
                    <PublicLayoutPage exact path={routes.LOGIN.path} component={LoginPage} />
                    <PublicLayoutPage path={routes.FORGOT_PASSWORD.path} component={ForgotPasswordPage} />
                    <PublicLayoutPage path={routes.RESET_PASSWORD.path} component={ResetPasswordPage} />
                    <PublicLayoutPage path={routes.RESET_PASSWORD_WITHOUT_GUID.path} component={ResetPasswordPage} />
                    <PublicLayoutPage path={routes.REGISTER.path} component={RegisterPage} />
                    <PublicLayoutPage path={routes.REGISTRATION_COMPLETE.path} component={RegistrationComplete} />

                    <EnsureLoggedIn path={routes.DASHBOARD.path} component={DashboardPage}/>
                    <EnsureLoggedIn path={routes.STUDENT_MGMT.path} component={StudentMgmtPage}/>
                    <EnsureLoggedIn path={routes.USER_MGMT.path} component={UserListPage}/>
                    <EnsureLoggedIn path={routes.ENROLL_MGMT.path} component={EnrollmentMgmtPage}/>
                    <EnsureLoggedIn path={routes.REPORTING.path} component={ReportingPage}/>

                    <EnsureLoggedIn path={routes.ASSESSMENT_MGMT.path} component={AssessmentListPage}/>
                    <EnsureLoggedIn path={routes.ASSESSMENT_INFO.path} component={AssessmentInfoPage}/>
                    <EnsureLoggedIn path={routes.ASSESSMENT_MCAT_CONFIG_INFO.path} component={McatConfigInfoPage}/>
                    <EnsureLoggedIn path={routes.SLAB.path} component={SlabPage}/>
                    <EnsureLoggedIn path={routes.ITEM_POOL_MGMT.path} component={ItemPoolConfigurationPage} />
                    <EnsureLoggedIn path={routes.ITEM_POOL_INFO.path} component={ItemPoolConfigurationInfoPage} />
                    <EnsureLoggedIn path={routes.ITEM_LIST.path} component={ItemListPage}/>
                    <EnsureLoggedIn path={routes.ITEM_INFO.path} component={ItemInfoPage}/>
                    <EnsureLoggedIn path={routes.ITEM_BANK_LIST.path} component={ItemBankListPage}/>
                    <EnsureLoggedIn path={routes.ITEM_BANK_INFO.path} component={ItemBankInfoPage}/>
                    <EnsureLoggedIn path={routes.TASK_LIST.path} component={TaskListPage}/>
                    <EnsureLoggedIn path={routes.TASK_INFO.path} component={TaskInfoPage}/>
                    <EnsureLoggedIn path={routes.TASK_CAT_CONFIG_INFO.path} component={TaskCatConfigInfoPage}/>

                    <PublicLayoutPage component={NotFoundPage} />
                </Switch>
            </div>
        );
    }
}

export default hot(module)(App);
