import $ from 'jquery';
import * as config from "../../constants/config";
import LocalStorage from "../../components/localStorage/access";
import {getReportingData} from "../../mockData/reportingData";
import * as ApiUtility from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from '../../components/handlers/requests/RequestTypes';
import {mockRejectApiCall, mockResolveApiCall} from "../../components/handlers/requests/apiUtility";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerReportingApi {
    static getReportingInfo(reportingSearchCriteria) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'reports?' + $.param(reportingSearchCriteria),
            RequestTypes.GET));
    }

    static exportReportData(reportingSearchCriteria) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                config.apiUrl + 'reports/export?' + $.param(reportingSearchCriteria),
                RequestTypes.GET));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockReportingApi {
    static getReportingInfo() {
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            let reportingData = getReportingData();

            return mockResolveApiCall({...reportingData});
        }
    }

    static exportReportData() {
        // no mock available as no way to create a mock Excel file
    }
}

const ReportingApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerReportingApi : MockReportingApi;
export default ReportingApi;