import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as itemActions from "./ItemAction";
import {itemInitialState} from "./ItemReducer";
import * as routes from "../../components/routes/routesConstants";
import ItemListSearchForm from "./ItemListSearchForm";
import ItemListSearchResults from "./ItemListSearchResults";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import {Search} from "../../components/table/Search";
import localStorage from "../../components/localStorage/access";
import * as loginActions from "../Login/LoginAction";

export class ItemListPage extends Search {
    constructor(props, context) {
        super(props, context, itemInitialState.itemSearchCriteria);

        this.state = {
            isLoading: false
        };

        this.onAddItem = this.onAddItem.bind(this);
        this.onEditItem = this.onEditItem.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
    }

    componentDidMount(){
        PushFocusToElement("NameContains");
        this.props.actions.updatePageTitle_BreadCrumb(routes.ITEM_LIST);
        if(this.props.itemSearchCriteria !== undefined &&
            this.props.itemSearchCriteria.itemSearchPerformed) {
            this.props.actions.loadItems(this.props.itemSearchCriteria);
        }

        this.setLoadSearchResults(this.loadItems);
        this.setProps(this.props.itemSearchCriteria, this.props.itemTotalRecords);
    }

    componentDidUpdate() {
        this.setProps(this.props.itemSearchCriteria, this.props.itemTotalRecords);
    }

    loadItems(itemSearchCriteria) {
        itemSearchCriteria.itemSearchPerformed = true;
        this.props.actions.saveItemSearchCriteria(itemSearchCriteria);

        this.props.actions.loadItems(itemSearchCriteria);
    }

    onAddItem(event) {
        event.preventDefault();

        this.props.history.push(routes.ITEM_INFO.path.replace(routes.ITEM_INFO.params.itemId, 0));
    }

    onEditItem(event, itemId) {
        event.preventDefault();

        this.props.history.push(routes.ITEM_INFO.path.replace(routes.ITEM_INFO.params.itemId, itemId));
    }

    onDeleteItem(event, deleteLinkInfo) {
        event.preventDefault();

        if(confirm("Are you sure you want to delete this item?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page."))
        {
            this.props.actions.deleteItem(deleteLinkInfo)
                .then(() => this.reloadAfterDelete())
        }
    }

    reloadAfterDelete() {
        let criteria = Object.assign({}, this.props.itemSearchCriteria);
        criteria.Page = 1;

        this.loadItems(criteria);
    }

    render() {
        let {itemTotalRecords, itemSearchCriteria, itemSearchResults } = this.props;

        return (
            <div>
                <ItemListSearchForm config={{
                    onSearchSubmit: this.onSearchSubmit,
                    clearCriteria: this.clearCriteria,
                    isLoading: this.state.isLoading,
                    itemSearchCriteria: itemSearchCriteria,
                    onAddItem: this.onAddItem
                }} />
                {itemSearchCriteria !== undefined &&
                itemSearchCriteria.itemSearchPerformed &&
                <div id={"searchResults"}>
                    <hr/>
                    <ItemListSearchResults config={{
                        itemSearchResults: itemSearchResults,
                        order: this.order,
                        first: this.first,
                        last: this.last,
                        next: this.next,
                        previous: this.previous,
                        itemTotalRecords: itemTotalRecords,
                        currentPage: itemSearchCriteria.Page,
                        itemRecordsPerPage: itemSearchCriteria.RecordsPerPage,
                        onEditItem: this.onEditItem,
                        onDeleteItem: this.onDeleteItem
                    }}/>
                </div>
                }
            </div>
        );
    }
}

ItemListPage.propTypes = {
    itemSearchResults: PropTypes.arrayOf(PropTypes.object),
    itemSearchCriteria: PropTypes.object,
    itemTotalRecords: PropTypes.number,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        itemSearchResults: state.item.itemSearchResults,
        itemSearchCriteria: state.item.itemSearchCriteria,
        itemTotalRecords: state.item.itemTotalRecords
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        itemActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (ItemListPage));
