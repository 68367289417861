import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";

import { generateAssessmentTypeOptions } from "./AssessmentConstants";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import TextField from "../../components/inputs/textField";
import SelectField from "../../components/inputs/selectField";

const AssessmentListSearchForm = ({config}) => {
    let formConfig = {
        NameContains: {},
        TaskNameContains: {},
        AssessmentType: {}
    };
    return (
        <FormValidation onSubmit={config.onSearchSubmit} config={formConfig} initialValues={config.assessmentSearchCriteria} >
            {({ fields, setField }) => (
                <div className={"input-bottom-alignment"}>
                    <div className={"grid-x grid-padding-x align-bottom"}>
                        <div className={"cell medium-3 large-3"}>
                            <TextField config={{
                                name: "NameContains",
                                label: "Assessment Name:",
                                maxLength: 50,
                                showLabel: true,
                                value: fields.NameContains,
                                inputClass: "margin-bottom-0"
                            }} />
                        </div>
                        <div className={"cell medium-3 large-3"}>
                            <TextField config={{
                                name: "TaskNameContains",
                                label: "Associated with Task Name:",
                                maxLength: 50,
                                showLabel: true,
                                value: fields.TaskNameContains,
                                inputClass: "margin-bottom-0"
                            }} />
                        </div>
                        <div className={"cell medium-2 large-2 margin-bottom-0"}>
                            <SelectField config={{
                                name: "AssessmentType",
                                label: "Assessment Type",
                                showLabel: true,
                                options: generateAssessmentTypeOptions(),
                                value: fields.AssessmentType,
                                defaultOption: "Any Type",
                            }} />
                        </div>
                        <div className={"cell medium-2 large-2"}>
                            <div className={"button-group"}>
                                <Button config={{
                                    name: "btnClearAssessmentSearch",
                                    label: "Clear",
                                    onClick: (event) => config.clearCriteria(event, setField),
                                    btnClass: "light",
                                    btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                }} />
                                <Button config={{
                                    name: "btnSearchAssessments",
                                    label: "Search",
                                    disabled: config.isLoading
                                }} />
                                <Button config={{
                                    name: "btnCreateAssessment",
                                    label: "Create Assessment",
                                    onClick: config.onAddAssessment,
                                    btnClass: "light",
                                    btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </FormValidation>
    );
};

AssessmentListSearchForm.propTypes = {
    config: PropTypes.shape({
        onSearchSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        clearCriteria: PropTypes.func.isRequired,
        assessmentSearchCriteria: PropTypes.object,
        onAddAssessment: PropTypes.func.isRequired
    })
};

export default AssessmentListSearchForm;