import React from "react";
import PropTypes from "prop-types";
import * as routes from "../routes/routesConstants";
import ButtonBar from "../buttons/buttonBar";
import ButtonIcon from "../buttons/buttonIcon";
import ButtonLink from "../buttons/buttonLink";
import * as buttonConstants from "../buttons/buttonConstants";
import ButtonMock from "../buttons/buttonMock";
import {Notify} from "../notification/notify";

const BreadCrumbs = ({ sectionInfo, history }) => {
    return (
        <div className={"breadcrumb"}>
            <ButtonBar>
                <ButtonIcon config={{
                    name: "btnDashboard",
                    label: "Dashboard",
                    onClick: () => {Notify.Clear(); history.push(routes.DASHBOARD.path)},
                    iconType: buttonConstants.ICON_HOME
                }}/>
                <ButtonMock name={"separator"} label={"&gt;"}/>
                <ButtonLink config={{
                    name: "btnSection",
                    label: sectionInfo.section.display,
                    onClick: () => {Notify.Clear(); history.push(sectionInfo.path)},
                }} />
            </ButtonBar>
        </div>
    );
};

BreadCrumbs.propTypes = {
    sectionInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default BreadCrumbs;