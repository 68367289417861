import $ from "jquery";

function pushFocusToElement(whichElementOrClass, parentClassName) {
    let parentClass = (parentClassName !== undefined && parentClassName !== "") ? "." + parentClassName : "";
    let element = $(parentClass + " #"+ whichElementOrClass);

    if(element.length === 0) {
        element = $(parentClass + " ." + whichElementOrClass);
    }

    if(element.length === 0) return;

    window.setTimeout(function() {
        element.focus();
    }, 0);
}

export default pushFocusToElement;