export const supportEmail = "support+CZF_NGS@fcim.org";

export const defaultRecordsPerPage = 20;
export const apiUrl = (process.env.NODE_ENV === 'production') ? "/api/" : "http://localhost:33424/api/";
export const apiMaxRetryCount = 10;
export const apiRedirectToLoginPagePath = "/";
export const mockFilePath = "http://127.0.0.1/CZF_Media/";
export const mockAtExpiresInSeconds = 900;
export const mockRtExpiresInSeconds = 5400;
export let environment = {};

export const DELETE_API_METHOD = "DELETE";

export const SERVER_API_MODE = "SERVER_API_MODE";
export const MOCK_API_MODE = "MOCK_API_MODE";

if(process.env.NODE_ENV === 'production'){
    environment = {
        API_MODE: SERVER_API_MODE, // always should be server
        delay: 0,
        recaptchaKey: "6Lcw5CgUAAAAAKuRBNvK6GwsT5QBSjsN_ly0MtvP"
    }
}
else {
    environment = {
        API_MODE: (process.env.NODE_ENV === 'test') ? MOCK_API_MODE : SERVER_API_MODE, // mock or server
        delay: 0,
        recaptchaKey: "6LfICCkUAAAAABCByQ8NUb28cd-uXMqoN7BObX4K"
    }
}
