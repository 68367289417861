export const LOAD_USERS_AJAX_SUCCESS = "LOAD_USERS_AJAX_SUCCESS";
export const SAVE_USER_AJAX_SUCCESS = "SAVE_USER_AJAX_SUCCESS";
export const SAVE_USER_SEARCH_CRITERIA = "SAVE_USER_SEARCH_CRITERIA";
export const EDIT_USER = "EDIT_USER";
export const CANCEL_EDIT_USER = "CANCEL_EDIT_USER";

export function generateIsActiveOptions() {
    return [
        { value: "true", name: "Active" },
        { value: "false", name: "Inactive" }
    ];
}

export function generateRoleOptions() {
    return [
        { value: "0", name: "Administrator" },
        { value: "1", name: "Proctor" },
        { value: "3", name: "Author" },
        { value: "4", name: "SiteCoordinator" },
    ];
}