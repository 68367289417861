import * as actionTypes from "./ItemConstants";
import * as config from "../../constants/config";
import {convertDataToOptions} from "../../components/generalData/convertGeneralDataToOptions";

export function itemDefaultState(itemInfoId = 0) {
    return {
        itemSearchCriteria: {
            NameContains: "",
            Page: 1,
            RecordsPerPage: config.defaultRecordsPerPage,
            OrderByColumn: "Name",
            OrderDescending: false,
            itemSearchPerformed: false,
            Fields: ""
        },
        itemTotalRecords: 0,
        itemSearchResults: [],
        itemSelectList: [],
        itemInfo: {
            Id: itemInfoId,
            Name: "",
            PromptText: "",
            PromptImage: "",
            PromptAudio: "",
            Verification: "",
            FileData: new FormData(),
            Options: []
        },
        isEditing: false,
        editingRowIndex: 0,
        addRowIndex: -1
    };
}

export const itemInitialState = itemDefaultState();

export function optionInitialState(){
    return {
        Id: 0,
        Name: "",
        ValueText: "",
        ValueImage: "",
        ValueAudio: "",
        IsCorrect: false,
        FileData: new FormData()
    };
}

export function itemReducer(state = itemInitialState, action) {
    switch(action.type){
        case actionTypes.LOAD_ITEMS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    itemSearchResults: [...action.data.Data],
                    itemTotalRecords: action.data.TotalRecords
                });
        case actionTypes.LOAD_ITEM_SELECT_LIST_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    itemSelectList: convertDataToOptions([...action.data.Data], "Id", "%s;Name")
                });
        case actionTypes.SAVE_ITEM_SEARCH_CRITERIA:
            return Object.assign({}, state, { itemSearchCriteria: action.criteria});
        case actionTypes.EDIT_ITEM_AJAX_SUCCESS: {
            return Object.assign(
                {},
                state,
                {
                    isEditing: false,
                    editingRowIndex: 0,
                    addRowIndex: -1,
                    itemInfo: Object.assign(
                        {},
                        action.data,
                        {
                            FileData: new FormData(),
                            Options: [...action.data.Options].map(option => {
                                let detachedOption = Object.assign({}, option);
                                detachedOption.FileData = new FormData();
                                return detachedOption;
                            })
                        })
                });
        }
        case actionTypes.UPDATE_ITEM:
            return Object.assign({}, state, { itemInfo: Object.assign({}, state.itemInfo, action.data)});
        case actionTypes.ADD_ITEM:
            return Object.assign({}, state, { itemInfo:  itemDefaultState().itemInfo});
        case actionTypes.SAVE_ITEM_AJAX_SUCCESS:
        case actionTypes.CANCEL_ITEM:
            return Object.assign({}, state, { itemInfo:  itemDefaultState(-1).itemInfo});
        case actionTypes.EDIT_OPTION:
            return Object.assign({}, state, { isEditing: true, editingRowIndex: action.data, addRowIndex: -1 });
        case actionTypes.CANCEL_EDIT_OPTION:
            return Object.assign({}, state, { isEditing: false, editingRowIndex: 0, addRowIndex: -1 });
        case actionTypes.UPDATE_OPTION:
            return Object.assign({}, state, {isEditing: action.data.isEditing, editingRowIndex: action.data.editingRowIndex, addRowIndex: -1,
                itemInfo: Object.assign({}, state.itemInfo, {Options: [...state.itemInfo.Options].map((option, i) => {
                        let detachedOption = Object.assign({}, option);

                        if (i !== state.editingRowIndex) return detachedOption;

                        return action.data.option;
                    })
                })
            });
        case actionTypes.ADD_OPTION:
            return Object.assign({}, state, { isEditing: true, editingRowIndex: state.itemInfo.Options.length, addRowIndex: state.itemInfo.Options.length,
                itemInfo: Object.assign({}, state.itemInfo, {Options: [...state.itemInfo.Options, optionInitialState()]})
            });
        case actionTypes.DELETE_OPTION: {
            let allOptions = [...state.itemInfo.Options];
            allOptions.splice(action.data, 1);
            return Object.assign({}, state, { isEditing: false, editingRowIndex: 0, addRowIndex: -1,
                itemInfo: Object.assign({}, state.itemInfo, {Options: allOptions})
            });
        }
        default:
            return state;
    }
}