import { beginAjaxCall, ajaxCallError } from "../AjaxStatus/AjaxStatusActions";
import storage from "../../components/localStorage/access";
import * as actionTypes from "./HeaderConstants";
import { setPageTitle } from "../../components/layout/SetPageTitle";
import headerApi from "./HeaderApi";

export function logoffSuccess() {
    return { type: actionTypes.LOGOFF_SUCCESS };
}

export function logOff() {
    return dispatch => {
        storage.logout();
        dispatch(logoffSuccess());
    };
}

export function updatePageTitle_BreadCrumb(route) {
    let pageTitle = (route.browserTabTitle !== undefined) ? route.browserTabTitle : route.display;
    setPageTitle(pageTitle);

    return {type: actionTypes.UPDATE_ROUTE_INFO, route};
}

export function getUserIdForHeaderSuccess(userInfo) {
    return { type: actionTypes.GET_USERID_FOR_HEADER_AJAX_SUCCESS, userInfo };
}

export function getUserIdForHeader() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return headerApi.getUserIdForHeader()
            .then((userInfo) => {
                dispatch(getUserIdForHeaderSuccess(userInfo));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}