import * as actionTypes from "./ItemBankConstants";
import * as config from "../../constants/config";
import {convertDataToOptions} from "../../components/generalData/convertGeneralDataToOptions";

export function itemBankDefaultState() {
    return {
        itemBankSearchCriteria: {
            NameContains: "",
            ItemNameContains: "",
            Page: 1,
            RecordsPerPage: config.defaultRecordsPerPage,
            OrderByColumn: "Name",
            OrderDescending: false,
            itemSearchPerformed: false,
            Fields: ""
        },
        itemBankTotalRecords: 0,
        itemBankSearchResults: [],
        itemBankSelectList: [],
        itemBankInfo: {
            Id: 0,
            Name: "",
            ItemIds: []
        }
    };
}

export const itemBankInitialState = itemBankDefaultState();

export function itemBankReducer(state = itemBankInitialState, action) {
    switch(action.type){
        case actionTypes.LOAD_ITEM_BANKS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    itemBankSearchResults: [...action.data.Data],
                    itemBankTotalRecords: action.data.TotalRecords
                });
        case actionTypes.LOAD_ITEM_BANK_SELECT_LIST_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    itemBankSelectList: convertDataToOptions([...action.data.Data], "Id", "%s;Name")
                });
        case actionTypes.SAVE_ITEM_BANK_SEARCH_CRITERIA:
            return Object.assign({}, state, { itemBankSearchCriteria: action.criteria});
        case actionTypes.EDIT_ITEM_BANK_AJAX_SUCCESS:
        case actionTypes.UPDATE_ITEM_BANK:
            return Object.assign({}, state, { itemBankInfo: Object.assign({}, state.itemBankInfo, action.data)});
        case actionTypes.ADD_ITEM_BANK:
        case actionTypes.SAVE_ITEM_BANK_AJAX_SUCCESS:
            return Object.assign({}, state, { itemBankInfo:  itemBankDefaultState().itemBankInfo});
        case actionTypes.ASSOCIATE_ITEM_TO_ITEM_BANK:
            return Object.assign(
                {},
                state,
                {
                    itemBankInfo: Object.assign(
                        {},
                        state.itemBankInfo,
                        {ItemIds: [...state.itemBankInfo.ItemIds, action.data]})
                });
        case actionTypes.UNASSOCIATE_ITEM_TO_ITEM_BANK: {
            let allItems = [...state.itemBankInfo.ItemIds];
            allItems.splice(action.data, 1);
            return Object.assign(
                {},
                state,
                {itemBankInfo: Object.assign({}, state.itemBankInfo, {ItemIds: allItems})}
            );
        }
        default:
            return state;
    }
}