import React from "react";
import PropTypes from "prop-types";
import {Form, Validation, ValidatorsProvider} from "calidation";
import {createErrorList, hasErrors} from "../../components/form/createErrorList";
import TextField from "../../components/inputs/textField";
import SelectField from "../../components/inputs/selectField";
import {generateMcatModelOptions} from "../../constants/catModelConstants";
import Button from "../../components/buttons/button";
import AssociatedTasksList from "./AssociatedTasksList";
import McatConfigStopCriteriaList from "./McatConfigStopCriteriaList";
import ButtonIcon from "../../components/buttons/buttonIcon";
import * as buttonConstants from "../../components/buttons/buttonConstants";

const McatConfigGroupForm = ({config}) => {
    if (!config.mCatTaskGroups || !config.associatedTasks || config.associatedTasks.length === 0)
        return null;

    function validateStopCriterionType(value, config) {
        if (value.find(s=>s.StopCriteriaType === null || s.StopCriteriaType === 0))
            return config.message;

        return null;
    }

    function validateStopCriterionValue(value, config) {
        if (value.find(s=>s.StopCriteriaValue === null || s.StopCriteriaValue === ""))
            return config.message;

        return null;
    }

    const extraValidators = {
        isArrayRequired: config => value => (value === null || value === undefined || value.length === 0) ? config.message : null,
        isStopCriteriaTypeRequired: config => value => validateStopCriterionType(value, config),
        isStopCriteriaValueRequired: config => value => validateStopCriterionValue(value, config),
    };

    const formConfigFactory = (index) => {
        let formConfig = {};
        formConfig[`McatTaskGroupId_${index}`] = 0;
        formConfig[`McatModelType_${index}`] = {
            isGreaterThan: ({fields}) => ({
                message: "MCAT Model is required!",
                value: 0
            }),
            isRequired: ({fields}) => ({
                message: "MCAT Model is required!",
                value: ""
            }),
        };
        formConfig[`GroupName_${index}`] = {
            isRequired: ({fields}) => ({
                message: "Group Name is Required",
                value: ""
            }),
        };
        formConfig[`ItemPoolId_${index}`] = {
            isGreaterThan: ({fields}) => ({
                message: "Item Pool Configuration is required!",
                value: 0
            }),
            isRequired: ({fields}) => ({
                message: "Item Pool Configuration is required!",
                value: ""
            }),
        };
        formConfig[`Tasks_${index}`] = {
            isArrayRequired: ({fields}) => ({
                message: "At least one task is required!"
            })
        };
        formConfig[`McatStopCriteria_${index}`] = {
            isArrayRequired: ({fields}) => ({
                message: "At least one stop criterion is required!",
            }),
            isStopCriteriaTypeRequired: ({fields}) => ({
                message: "Stop criterion is required"
            }),
            isStopCriteriaValueRequired: ({fields}) => ({
                message: "Stop criterion value is required"
            })
        };
        formConfig[`StopCriteriaType_${index}`] = {
        };
        formConfig[`StopCriteriaValue_${index}`] = {
        };
        formConfig[`McatStopCriterionId_${index}`] = 0;
        formConfig[`isEditingTask_${index}`] = false;
        formConfig[`isEditingStopCriteria_${index}`] = false;
        return formConfig;
    }

    const initialValuesFactory = (mCatTaskGroupInfo, index) => {
        let initialValues = {};
        initialValues[`McatTaskGroupId_${index}`] = mCatTaskGroupInfo.McatTaskGroupId;
        initialValues[`McatModelType_${index}`] = mCatTaskGroupInfo.McatModelType;
        initialValues[`GroupName_${index}`] = mCatTaskGroupInfo.GroupName;
        initialValues[`ItemPoolId_${index}`] = mCatTaskGroupInfo.ItemPoolId;
        initialValues[`Tasks_${index}`] = buildGroupAssociatedTasks(mCatTaskGroupInfo.Tasks);
        initialValues[`McatStopCriteria_${index}`] = mCatTaskGroupInfo.McatStopCriteria
        initialValues[`isEditingTask_${index}`] = false;
        initialValues[`isEditingStopCriteria_${index}`] = false;
        return initialValues;
    }

    const buildGroupAssociatedTasks = (tasks) => {
        let temp = tasks.map((task) => {
            const associatedTask = config.associatedTasks.find(i => i.id === task);
            if (associatedTask) {
                let selectObject = {
                    value: associatedTask.id,
                    name: associatedTask.name
                };
                return selectObject;
            }
        });

        return temp;
    }

    const getGroupFields = (groupIndex) => {
        return {
            tasks: `Tasks_${groupIndex}`,
            isEditingTask: `isEditingTask_${groupIndex}`,
            groupName: `GroupName_${groupIndex}`,
            stopCriteria: `McatStopCriteria_${groupIndex}`,
            isEditingStopCriteria: `isEditingStopCriteria_${groupIndex}`,
            stopCriteriaType: `StopCriteriaType_${groupIndex}`,
            stopCriteriaValue: `StopCriteriaValue_${groupIndex}`,
            stopCriteriaId: `McatStopCriterionId_${groupIndex}`
        };
    }

    function onTaskChange(taskId, groupName) {
        const tasks = config.associatedTasks.map((task) => {
            if (task.id === taskId)
                task.mcatGroupName = groupName;
            return task;
        });

        config.setAssociatedTasks(tasks);
    }

    const onDeleteTask = (event, setField, taskId, fields, groupIndex) => {
        event.preventDefault();
        if (confirm("Are you sure you want to remove this task?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page.")) {
            deleteTask(setField, taskId, fields, groupIndex);
        }
    }

    const deleteTask = (setField, taskId, fields, groupIndex) => {
        const groupFields = getGroupFields(groupIndex);
        const newObject = {};
        newObject[groupFields.tasks] = fields[groupFields.tasks].filter(p => p.value !== taskId)
        setField(newObject);

        onTaskChange(taskId, config.notApplicable);

        config.setDisableAddTaskBtn(false);
        config.setIsEditing(false);
    }

    const onCancelTask = (event, setField, fields, groupIndex) => {
        const groupFields = getGroupFields(groupIndex);
        const newTaskObject = {};
        newTaskObject[groupFields.isEditingTask] = false;
        setField(newTaskObject);
        config.setDisableAddTaskBtn(false);
        config.setIsEditing(false);
        deleteTask(setField, 0, fields, groupIndex);
    }

    const onAddTask = (event, setField, fields, groupIndex) => {
        event.preventDefault();
        const groupFields = getGroupFields(groupIndex);
        const newTaskObject = {};
        newTaskObject[groupFields.tasks] = [...fields[groupFields.tasks], {value: 0, name: ""}];
        newTaskObject[groupFields.isEditingTask] = true;
        setField(newTaskObject);

        config.setDisableAddTaskBtn(true);
        config.setTaskRowIndex(fields[groupFields.tasks].length);
        config.setIsEditing(true);
    }

    const onTaskSave = (selectedTask, setField, fields, groupIndex) => {
        const taskId = parseInt(selectedTask.value);
        const groupFields = getGroupFields(groupIndex);
        const task = config.associatedTasks.find(i => i.id === taskId);
        if (!task)
            return;
        const tasks = fields[groupFields.tasks].filter(p => p.value !== 0)
        const newTaskObject = {};
        newTaskObject[groupFields.tasks] = [...tasks, {value: taskId, name: task.name}];
        newTaskObject[groupFields.isEditingTask] = false;
        setField(newTaskObject);

        onTaskChange(taskId, fields[groupFields.groupName]);
        config.setDisableAddTaskBtn(false);
        config.setIsEditing(false);
    }

    const availableTasksFactory = () => {
        const tasks = config.associatedTasks.filter((task) => task.mcatGroupName === config.notApplicable)
        if (!tasks)
            return [];
        return tasks.map((task) => {
            let selectObject = {
                value: task.id,
                name: task.name
            };
            return selectObject;
        });
    }

    const onAddStopCriteria = (event, setField, fields, groupIndex) => {
        event.preventDefault();
        const groupFields = getGroupFields(groupIndex);
        const newStopCriteriaObject = {};
        newStopCriteriaObject[groupFields.stopCriteria] = [...fields[groupFields.stopCriteria], {
            McatStopCriterionId: 0,
            StopCriteriaType: 0,
            StopCriteriaValue: ""
        }];
        newStopCriteriaObject[groupFields.stopCriteriaId] = 0;
        newStopCriteriaObject[groupFields.isEditingStopCriteria] = true;
        newStopCriteriaObject[groupFields.stopCriteriaType] = 0;
        newStopCriteriaObject[groupFields.stopCriteriaValue] = "";
        setField(newStopCriteriaObject);

        config.setDisableAddStopCriteriaBtn(true);
        config.setIsEditing(true);
        config.setStopCriteriaRowIndex(fields[groupFields.stopCriteria].length);
    }

    const onDeleteStopCriteria = (event, setField, mCatStopCriteriaId, fields, groupIndex) => {
        event.preventDefault();
        if (confirm("Are you sure you want to remove this stop criteria?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page.")) {
            const groupFields = getGroupFields(groupIndex);
            const newObject = {};
            newObject[groupFields.stopCriteria] = fields[groupFields.stopCriteria].filter(p => p.McatStopCriterionId !== mCatStopCriteriaId)
            setField(newObject);

            config.setDisableAddStopCriteriaBtn(false);
            config.setIsEditing(false);
        }
    }

    const onStopCriteriaChange = (selected, setField, groupIndex) => {
        const groupFields = getGroupFields(groupIndex);
        const newCriteriaObject = {};
        newCriteriaObject[groupFields.stopCriteriaType] = selected.value;
        setField(newCriteriaObject);
    }

    const onEditStopCriteriaSave = (event, setField, fields, groupIndex) => {
        const groupFields = getGroupFields(groupIndex);
        const mCatStopCriteria = fields[groupFields.stopCriteria].filter(criteria => criteria.McatStopCriterionId !== 0);
        const newStopCriteriaObject = {};
        let stopCriteriaId = fields[groupFields.stopCriteriaId];
        if (stopCriteriaId === 0) {
            stopCriteriaId = config.nextTemporaryId();
            newStopCriteriaObject[groupFields.stopCriteria] = [...mCatStopCriteria, {
                McatStopCriterionId: stopCriteriaId,
                StopCriteriaType: fields[groupFields.stopCriteriaType],
                StopCriteriaValue: fields[groupFields.stopCriteriaValue]
            }];
        } else {
            const othersCriterion = mCatStopCriteria.filter(criteria => criteria.McatStopCriterionId !== stopCriteriaId);
            newStopCriteriaObject[groupFields.stopCriteria] = [...othersCriterion, {
                McatStopCriterionId: stopCriteriaId,
                StopCriteriaType: fields[groupFields.stopCriteriaType],
                StopCriteriaValue: fields[groupFields.stopCriteriaValue]
            }];
        }

        newStopCriteriaObject[groupFields.isEditingStopCriteria] = false;

        setField(newStopCriteriaObject);

        config.setDisableAddStopCriteriaBtn(false);
        config.setIsEditing(false);
    }

    const onEditStopCriteriaCancel = (event, setField, fields, groupIndex) => {
        event.preventDefault();

        config.setDisableAddStopCriteriaBtn(false);
        config.setIsEditing(false);

        const groupFields = getGroupFields(groupIndex);
        const newStopCriteriaObject = {};
        newStopCriteriaObject[groupFields.stopCriteria] = fields[groupFields.stopCriteria].filter(p => p.McatStopCriterionId !== 0);
        newStopCriteriaObject[groupFields.isEditingStopCriteria] = false;
        setField(newStopCriteriaObject);

    }

    const onEditStopCriteria = (event, setField, mCatStopCriteriaId, fields, groupIndex, rowIndex) => {
        event.preventDefault();
        config.setStopCriteriaRowIndex(rowIndex);
        config.setDisableAddStopCriteriaBtn(true);
        config.setIsEditing(true);

        const groupFields = getGroupFields(groupIndex);
        const currentStopCriterion = fields[groupFields.stopCriteria].find(criteria => criteria.McatStopCriterionId === mCatStopCriteriaId);
        const newStopCriteriaObject = {};
        newStopCriteriaObject[groupFields.stopCriteriaId] = currentStopCriterion.McatStopCriterionId;
        newStopCriteriaObject[groupFields.stopCriteriaValue] = currentStopCriterion.StopCriteriaValue;
        newStopCriteriaObject[groupFields.stopCriteriaType] = currentStopCriterion.StopCriteriaType;
        newStopCriteriaObject[groupFields.isEditingStopCriteria] = true;
        setField(newStopCriteriaObject);
    }

    const hasGroupErrors = (errors, groupIndex) => {
        const groupErrors = hasErrors(getGroupErrors(errors, groupIndex));
        return groupErrors;
    }

    const getGroupErrors = (errors, groupIndex) => {
        const filteredErrorProperties = Object.keys(errors).filter((propValue) => {
            let temp = propValue.split(config.groupPropertySeparator);
            if (parseInt(temp[1]) === groupIndex)
                return propValue;
        });

        let filteredErrors = {};
        for(let i = 0; i < filteredErrorProperties.length; i++)
        {
            filteredErrors[filteredErrorProperties[i]] = errors[filteredErrorProperties[i]]
        }

        return filteredErrors;
    }

    const updateTasksOnDeleteGroup = (tasks) =>
    {
        if(!tasks)
            return;

        for(let i = 0; i < tasks.length; i++)
        {
            onTaskChange(tasks[i], config.notApplicable);

        }
    }

    const onDeleteGroup = (mcatTaskGroupId, mapIndex, fields, setFields) => {
        if (confirm("Are you sure you want to remove this MCAT task group?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page.")) {
            const currentMCatTaskGroups = config.mCatTaskGroupArrayBuilder(fields, 0);
            const mCatTaskGroupBeindRemoved = currentMCatTaskGroups.find(g => g.McatTaskGroupId === mcatTaskGroupId);
            if (mCatTaskGroupBeindRemoved)
                updateTasksOnDeleteGroup(mCatTaskGroupBeindRemoved.Tasks);

            const mCatTaskGroups = currentMCatTaskGroups.filter(g => g.McatTaskGroupId !== mcatTaskGroupId);
            config.setMcatTaskGroups(mCatTaskGroups);

            let fieldValues = {};
            for(let index = 0; index < mCatTaskGroups.length; index++)
            {
                fieldValues = {
                    ...fieldValues,
                    ...initialValuesFactory(mCatTaskGroups[index], index)
                };
            }
            setFields(fieldValues);

        }
    }

    return (
        <ValidatorsProvider validators={extraValidators}>
            <Form onSubmit={config.onMcatTaskGroupSubmit} id={"mcatGroup-form"}>
                {
                    config.mCatTaskGroups.map((mCatTaskGroupInfo, mapIndex) => {
                        let formConfig = formConfigFactory(mapIndex);
                        let initialValues = initialValuesFactory(mCatTaskGroupInfo, mapIndex);
                        return (
                            <Validation key={mapIndex} config={formConfig} initialValues={initialValues}>
                                {({fields, errors, submitted, setField}) => (
                                    <div className={"grid-x grid-padding-x align-bottom align-center"}>
                                        <div className={"cell medium-6 large-6 "}>
                                            <hr/>
                                            <div className={"grid-x"}>
                                                <div className={"cell medium-12 large-12 text-right"}>
                                                    <ButtonIcon config={{
                                                        name: "btnDeleteGroup",
                                                        label: "Delete Group",

                                                        iconType: buttonConstants.ICON_DELETE,
                                                        onClick: () => onDeleteGroup(fields[`McatTaskGroupId_${mapIndex}`], mapIndex, fields, setField)
                                                    }}/>
                                                </div>
                                            </div>
                                            {submitted && hasGroupErrors(errors, mapIndex) &&
                                            <div className="text-danger">
                                                <p>Please correct the issues as specified.</p>
                                                {createErrorList(getGroupErrors(errors, mapIndex))}
                                            </div>
                                            }
                                            <input type={"hidden"} name={`McatTaskGroupId_${mapIndex}`}
                                                   value={fields[`McatTaskGroupId_${mapIndex}`]}/>
                                            <Button config={{
                                                name: "btnSave",
                                                label: "Save",
                                                btnClass: "hide",
                                                disabled: (config.isLoading || config.shouldDisablePageActions)
                                            }}/>
                                            <div className={"grid-x"}>
                                                <div className={"cell medium-12 large-12"}>
                                                    <TextField config={{
                                                        name: `GroupName_${mapIndex}`,
                                                        label: "Group Name",
                                                        maxLength: 100,
                                                        showLabel: true,
                                                        value: fields[`GroupName_${mapIndex}`],
                                                    }}/>
                                                </div>
                                            </div>
                                            <div className={"grid-x"}>
                                                <div className={"cell medium-5 large-5"}>
                                                    <SelectField config={{
                                                        name: `McatModelType_${mapIndex}`,
                                                        label: "MCAT Model:",
                                                        showLabel: true,
                                                        options: generateMcatModelOptions(),
                                                        value: isNaN(fields[`McatModelType_${mapIndex}`]) || fields[`McatModelType_${mapIndex}`] === null ? "" : fields[`McatModelType_${mapIndex}`].toString(),
                                                        defaultOption: "Select MCAT Model",
                                                        inputClass: "margin-bottom-0",
                                                    }}/>
                                                </div>
                                            </div>
                                            <div className={"grid-x"}>
                                                <div className={"cell  medium-8 large-8 "}>
                                                    <SelectField config={{
                                                        name: `ItemPoolId_${mapIndex}`,
                                                        label: "MCAT Item Pool Configuration",
                                                        showLabel: true,
                                                        options: config.itemPoolSelectList,
                                                        value: isNaN(fields[`ItemPoolId_${mapIndex}`]) || fields[`ItemPoolId_${mapIndex}`] === null ? "" : fields[`ItemPoolId_${mapIndex}`].toString(),
                                                        inputClass: "margin-bottom-0",
                                                        defaultOption: "Select an Item Pool",
                                                    }}/>
                                                </div>
                                            </div>
                                            <AssociatedTasksList config={{
                                                tasks: fields[`Tasks_${mapIndex}`],
                                                availableTaskSelectList: availableTasksFactory(),
                                                isAddTaskDisabled: config.isAddTaskBtnDisabled,
                                                isLoading: config.isLoading,
                                                isEditing: fields[`isEditingTask_${mapIndex}`],
                                                onAddTask: (event) => onAddTask(event, setField, fields, mapIndex),
                                                onDeleteTask: onDeleteTask,
                                                onTaskSave: onTaskSave,
                                                onCancelTask: onCancelTask,
                                                setField: setField,
                                                fields: fields,
                                                groupIndex: mapIndex,
                                                submitted: submitted,
                                                editingTaskRowIndex: config.editingTaskRowIndex
                                            }}
                                            />
                                            <McatConfigStopCriteriaList
                                                config={{
                                                    stopCriteria: fields,
                                                    isAddStopCriteriaDisabled: config.isAddStopCriteriaBtnDisabled,
                                                    setField: setField,
                                                    fields: fields,
                                                    groupIndex: mapIndex,
                                                    submitted: submitted,
                                                    editingStopCriteriaRowIndex: config.editingStopCriteriaRowIndex,
                                                    isLoading: config.isLoading,
                                                    isEditing: fields[`isEditingStopCriteria_${mapIndex}`],
                                                    onAddStopCriteria: (event) => onAddStopCriteria(event, setField, fields, mapIndex),
                                                    onDeleteStopCriteria: onDeleteStopCriteria,
                                                    onEditStopCriteriaCancel: onEditStopCriteriaCancel,
                                                    onEditStopCriteriaSave: onEditStopCriteriaSave,
                                                    onStopCriteriaChange: onStopCriteriaChange,
                                                    onEditStopCriteria: onEditStopCriteria,
                                                    errors: getGroupErrors(errors, mapIndex),
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Validation>
                        );
                    })
                }
            </Form>
        </ValidatorsProvider>
    );
}

McatConfigGroupForm.propTypes = {
    config: PropTypes.object,
}

export default McatConfigGroupForm;