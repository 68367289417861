import React from "react";
import PropTypes from "prop-types";
import SelectSearch from "../selectSearch/dist/index";

const SearchableSelectField = ({ config }) => {

    config = Object.assign({}, searchableSelectFieldDefaults(), config);

    if (!config.showLabel) {
        config.labelClass += " show-for-sr";
    }

    let items = [];
    if(config.options && config.options.length > 0) {
        items = config.options.map((option) => {
            return {
                value: option.value.toString(),
                name: option.name
            };
        });
    }

    let selectListOptions = [
        {
            type: "group",
            name: config.label,
            items: items
        }
    ];

    if(config.value === null || config.value === undefined){
        config.value = "";
    }
    return (
        <div className={"grid-x " + config.wrapperClass}>
            <label htmlFor={config.name} className={"cell " + config.labelClass}>{ (config.isRequired) && <span className="text-danger">* </span>}{config.label}</label>
            <div className={"cell " + config.inputClass}>
                <SelectSearch
                    options={selectListOptions}
                    name={config.name}
                    onChange={config.onChange}
                    placeholder={(config.includeDefaultOption) ? config.defaultOption : ""}
                    value={config.value}
                />
            </div>
            {config.error && <div className="text-danger">{config.error}</div>}
        </div>
    );
};

SearchableSelectField.propTypes = {
    config: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        defaultOption: PropTypes.string,
        value: PropTypes.string,
        error: PropTypes.string,
        showLabel: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.object),
        includeDefaultOption: PropTypes.bool,

        wrapperClass: PropTypes.string,
        labelClass: PropTypes.string,
        inputClass: PropTypes.string,
        isRequired: PropTypes.bool
    })
};

function searchableSelectFieldDefaults() {
    return {
        includeDefaultOption: true,
        defaultOption: "Select an Option",
        wrapperClass: "",
        labelClass: "small-12",
        inputClass: "small-12",
        isRequired: false
    };
}

export default SearchableSelectField;