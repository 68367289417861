import {beginAjaxCall, ajaxCallError} from "../AjaxStatus/AjaxStatusActions";
import userMgmtApi from "./UserMgmtApi";
import * as actionTypes from "./UserMgmtConstants";

export function loadUsersSuccess(data) {
    return {type: actionTypes.LOAD_USERS_AJAX_SUCCESS, data};
}

export function loadUsers(userFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return userMgmtApi.getUsers(userFilterCriteria)
            .then(data => {
                dispatch(loadUsersSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function saveUserSearchCriteria(criteria) {
    return {type: actionTypes.SAVE_USER_SEARCH_CRITERIA, criteria};
}

export function editUser(userId) {
    return {type: actionTypes.EDIT_USER, data: userId};
}

export function cancelEditUser() {
    return {type: actionTypes.CANCEL_EDIT_USER};
}

export function updateUsersSuccess(data) {
    return {type: actionTypes.SAVE_USER_AJAX_SUCCESS, data};
}

export function updateUser(user) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return userMgmtApi.updateUser(user)
            .then((updatedUser) => {
                dispatch(updateUsersSuccess(updatedUser));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            );
    };
}

export function exportUserData(userFilterCriteria) {
    return () => {
        userMgmtApi.exportUserData(userFilterCriteria);
    };
}