import * as actionTypes from "./siteDetailConstants";

export const siteDetailInitialState = {
    SiteName: "",
    SupportEmail: ""
};

export function siteDetailReducer(state = siteDetailInitialState, action) {
    switch(action.type){
        case actionTypes.GET_SITE_DETAIL_AJAX_SUCCESS:
            return Object.assign({}, state, {SiteName: action.siteDetail.SiteName, SupportEmail: action.siteDetail.SupportEmail});
        default:
            return state;
    }
}