import React from "react";
import PropTypes from "prop-types";
import Button from "../buttons/button";
import * as buttonConstants from "../buttons/buttonConstants";
import FileUploadButton from "./fileUploadButton";

const FileUploadButtonContainer = ({ config }) => {
    config = Object.assign({}, fileUploadButtonContainerDefaults(), config);

    const fileInProcess = (config.fileBeingUploaded !== null && config.fileBeingUploaded !== "");

    return (
        <div className={"grid-x " + config.wrapperClass}>
            <div className={"cell fileUploadContainer "}>
                {config.uploadDescription &&
                <span><strong>{config.uploadDescription} { (config.isRequired) && <span className="text-danger">* </span>}</strong></span>}
                {fileInProcess &&
                    <div>
                        <p><em>{config.fileBeingUploaded.name} will be uploaded when saved</em></p>
                        <Button config={{
                            name: "btnCancel",
                            label: "Cancel",
                            btnClass: "light",
                            onClick: config.onCancelUpload,
                            btnType: buttonConstants.BUTTON_TYPE_BUTTON
                        }}/>
                    </div>
                }

                {!fileInProcess &&
                    <FileUploadButton name={config.name} label={config.label} onChange={config.onChange}/>
                }

            </div>

            {config.error && <div className="text-danger">{config.error}</div>}
        </div>
    );
};

FileUploadButtonContainer.propTypes = {
    config: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        fileBeingUploaded: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        isRequired: PropTypes.bool,
        error: PropTypes.string,
        uploadDescription: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onCancelUpload: PropTypes.func.isRequired,

        wrapperClass: PropTypes.string,
        labelClass: PropTypes.string,
    })
};

function fileUploadButtonContainerDefaults() {
    return {
        label: "Upload",
        isRequired: false,
        wrapperClass: ""
    };
}

export default FileUploadButtonContainer;