import * as config from "../../constants/config";
import LocalStorage from "../../components/localStorage/access";
import {
    getAssessmentsData,
    saveAssessmentsData,
    getTasksArrayForAssessment
} from "../../mockData/assessmentData";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from '../../components/handlers/requests/RequestTypes';
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";
import {mockRejectApiCall, mockResolveApiCall, sendRequest} from "../../components/handlers/requests/apiUtility";

class ServerMcatConfigApi {

    static addMcatTaskGroups(mCatTaskGroups) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}mcatTaskGroups`,
                RequestTypes.POST,
                JSON.stringify(mCatTaskGroups),
                ApiSuccessMessages.MCAT_TASKS_ADDED
            )
        );
    }

    static updateMcatTaskGroups(mCatTaskGroups) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}mcatTaskGroups`,
                RequestTypes.PUT,
                JSON.stringify(mCatTaskGroups),
                ApiSuccessMessages.MCAT_TASKS_UPDATED
            )
        );
    }

    static deleteMcatTaskGroups(assessmentId) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}mcatTaskGroups?assessmentId=${assessmentId}`,
                RequestTypes.DELETE,
                ApiSuccessMessages.MCAT_TASKS_DELETED,
            )
        );
    }
}
//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockMcatConfigApi {
    static getNewId() {
        return Math.floor(Math.random() * Math.floor(500));
    }

    static addMcatTaskGroups(mCatTaskGroups) {
        this.updateMcatTaskGroups(mCatTaskGroups);
    }

    static updateMcatTaskGroups(mCatTaskGroups) {
        if (mCatTaskGroups.length === 0) {
            return mockRejectApiCall("No MCAT Task Groups.");
        }

        const allAssessments = getAssessmentsData();
        const assessmentId = mCatTaskGroups[0].AssessmentId;
        const otherAssessments = allAssessments.filter(i => i.Id !== assessmentId);
        const assessment = allAssessments.filter(i => i.Id === assessmentId)[0];
        if (!LocalStorage.isLoggedIn()) {
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        } else if (assessment.length === 0) {
            return mockRejectApiCall("There was an error while attempting to retrieve the assessment record.");
        } else {
            mCatTaskGroups = mCatTaskGroups.map((mcatTaskGroup) => {
                if (mcatTaskGroup.McatTaskGroupId === 0)
                    mcatTaskGroup.McatTaskGroupId = this.getNewId();
                return mcatTaskGroup;
            });
            mCatTaskGroups = mCatTaskGroups.map((mcatTaskGroup) => {
                const mCatGroup = assessment.McatTaskGroups.find(m => m.McatTaskGroupId = mcatTaskGroup.McatTaskGroupId);
                mcatTaskGroup.Tasks = mCatGroup.Tasks;
                return mcatTaskGroup;
            });
            const editAssessment = {
                Id: assessment.Id,
                Name: assessment.Name,
                TaskIds: assessment.TaskIds,
                Tasks: getTasksArrayForAssessment(assessment.TaskIds),
                TaskType: assessment.AssessmentType,
                NumberOfTasks: assessment.TaskIds.length,
                McatTaskGroupCount: assessment.McatTaskGroupCount,
                McatTaskGroups: mCatTaskGroups,
                DateCreated: assessment.DateCreated,
                DateModified: assessment.DateModified,
                Links: assessment.Links
            };
            saveAssessmentsData([...otherAssessments, editAssessment]);
            return mockResolveApiCall(ApiSuccessMessages.MCAT_TASKS_ADDED);
        }
    }
}

const McatConfigApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerMcatConfigApi : MockMcatConfigApi;
export default McatConfigApi;