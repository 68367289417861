export const BUTTON_BAR_POSITION_LEFT = "align-left";
export const BUTTON_BAR_POSITION_RIGHT = "align-right";
export const BUTTON_BAR_POSITION_CENTER = "align-center";

export const buttonBarPositions = [BUTTON_BAR_POSITION_LEFT, BUTTON_BAR_POSITION_RIGHT, BUTTON_BAR_POSITION_CENTER];

export const BUTTON_TYPE_SUBMIT = "submit";
export const BUTTON_TYPE_BUTTON = "button";

export const buttonTypes = [BUTTON_TYPE_SUBMIT, BUTTON_TYPE_BUTTON];

export const ICON_FIRST_PAGE = "first";
export const ICON_PREVIOUS_PAGE = "previous";
export const ICON_NEXT_PAGE = "next";
export const ICON_LAST_PAGE = "last";
export const ICON_HOME = "home";
export const ICON_EDIT = "pencil";
export const ICON_CANCEL = "blocked";
export const ICON_SAVE = "checkmark";
export const ICON_PLAY = "play";
export const ICON_PAUSE = "pause";
export const ICON_DELETE = "bin";
export const ICON_READY_TO_BEGIN = "star-empty";
export const ICON_IN_PROGRESS = "star-half";
export const ICON_COMPLETE = "star-full";
export const ICON_CAT = "cat";

export const iconTypes = [ICON_FIRST_PAGE, ICON_PREVIOUS_PAGE, ICON_NEXT_PAGE, ICON_LAST_PAGE, ICON_CANCEL, ICON_HOME, ICON_EDIT, ICON_SAVE, ICON_PLAY, ICON_PAUSE, ICON_DELETE, ICON_READY_TO_BEGIN, ICON_IN_PROGRESS, ICON_COMPLETE, ICON_CAT];