import React from "react";
import PropTypes from "prop-types";
import * as buttonConstants from "./buttonConstants";

const ButtonBar = ({children, buttonBarPosition, name, isExpanded, wrapperClass }) => {

    const isButtonBarPositionValid = ((buttonConstants.buttonBarPositions.filter(f => f === buttonBarPosition)).length > 0);

    if (children === undefined || children === null || children.length === 0 || !isButtonBarPositionValid)
        buttonBarPosition=" is-hidden";

    if(isExpanded)
        wrapperClass += " expanded";

    return (
        <div className={"button-group margin-bottom-0 " + buttonBarPosition + " " + wrapperClass} name={name} id={name}>
            {children}
        </div>
    );
};

ButtonBar.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    buttonBarPosition: PropTypes.string,
    isExpanded: PropTypes.bool,
    name: PropTypes.string,
    wrapperClass: PropTypes.string
};
ButtonBar.defaultProps = {
    buttonBarPosition: buttonConstants.BUTTON_BAR_POSITION_LEFT,
    isExpanded: false,
    wrapperClass: ""
};

export default ButtonBar;