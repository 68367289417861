import React from 'react';
import {Route} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as districtActions from "../District/DistrictAction";
import * as taskTypeActions from "../TaskType/TaskTypeAction";
import * as siteDetailActions from "../SiteDetails/siteDetailAction";
import {bindActionCreators} from "redux";
import {loadGeneralData} from "../../components/generalData/loadGeneralData";
import Loader from "../../components/layout/Loader";
import Footer from "../../components/layout/Footer";

export class PublicLayoutPage extends React.Component {

    componentDidMount() {
        loadGeneralData(this.props);
        this.props.actions.getSiteDetails();
    }

    render() {
        const {component: Component, exact, path, isLoading, siteDetail} = this.props;

        return (
            <Route {...exact} {...path} render={matchProps => (
                <div className={"grid-y public"}>
                    <Loader isLoading={isLoading} />
                    <main className={"cell body"}>
                        <div className={"grid-x grid-container"}>
                            <div className={"cell medium-offset-3 medium-6 public__container"}>
                                    <h2>{siteDetail.SiteName}</h2>
                                <div className={"grid-x grid-container text-left"}>
                                    <div className={"cell medium-offset-1 medium-10"}>
                                        <Component {...matchProps} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer supportEmail={siteDetail.SupportEmail}/>
                </div>
            )}/>
        )
    }
}

PublicLayoutPage.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string,
    actions: PropTypes.object.isRequired,
    districts: PropTypes.array,
    taskTypes: PropTypes.array,
    siteDetail: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

PublicLayoutPage.defaultProps = {
    isLoading: false
};


function mapStateToProps(state) {
    return {
        districts: state.district.districts,
        taskTypes: state.taskType.taskTypes,
        siteDetail: state.siteDetail,
        isLoading: state.ajaxStatus.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        districtActions,
        taskTypeActions,
        siteDetailActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicLayoutPage);
