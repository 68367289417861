import * as routes from "../../components/routes/routesConstants";
import {ADMINISTRATOR, SOAPBOX, AUTHOR, SITECOORDINATOR} from "../Login/LoginConstants";

export const DashboardRoutes = {};
DashboardRoutes[ADMINISTRATOR] = [routes.STUDENT_MGMT, routes.ASSESSMENT_MGMT, routes.ENROLL_MGMT, routes.REPORTING, routes.USER_MGMT, routes.SLAB, routes.ITEM_POOL_MGMT ];
DashboardRoutes[SOAPBOX] = [routes.SLAB ];
DashboardRoutes[AUTHOR] = [routes.ASSESSMENT_MGMT, routes.REPORTING, routes.USER_MGMT, routes.SLAB, routes.ITEM_POOL_MGMT ];
DashboardRoutes[SITECOORDINATOR] = [routes.STUDENT_MGMT, routes.ENROLL_MGMT, routes.REPORTING, routes.USER_MGMT ];


export const DASHBOARD_MAX_TILES_PER_ROW = 3;