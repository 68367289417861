import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";

import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import TextField from "../../components/inputs/textField";
import SelectField from "../../components/inputs/selectField";

const RegisterForm = ({config}) => {
    let formConfig = {
        Email: {
            isRequired: 'Email is required!'
        },
        FirstName: {
            isRequired: 'First name is required!'
        },
        LastName: {
            isRequired: 'Last name is required!'
        },
        Password: {
            isRequired: 'Password is required!'
        },
        ConfirmPassword: {
            isRequired: 'Confirm password is required!',
            isEqual: ({fields}) => {
                return {
                    message: 'The two passwords must match',
                    value: fields.Password,
                    validateIf: fields.Password.length > 0
                };
            }
        },
        DistrictId: {
            isRequired: 'District is required!'
        }
    };
    return (
        <FormValidation onSubmit={config.onRegisterSubmit} config={formConfig}>
            {({ fields, errors, submitted }) => (
                <div>
                    {submitted && !config.isValid && <p className="text-danger">Please correct the issues as specified.</p>}
                    <TextField config={{
                        name: "Email",
                        label: "Email",
                        maxLength: 300,
                        showLabel: true,
                        value: fields.Email,
                        error: (submitted && errors.Email) ? errors.Email : ""
                    }} />
                    <TextField config={{
                        name: "FirstName",
                        label: "First Name",
                        maxLength: 50,
                        showLabel: true,
                        value: fields.FirstName,
                        error: (submitted && errors.FirstName) ? errors.FirstName : ""
                    }} />
                    <TextField config={{
                        name: "LastName",
                        label: "Last Name",
                        maxLength: 50,
                        showLabel: true,
                        value: fields.LastName,
                        error: (submitted && errors.LastName) ? errors.LastName : ""
                    }} />
                    <TextField config={{
                        name: "Password",
                        label: "Password",
                        maxLength: 64,
                        inputType: "password",
                        showLabel: true,
                        value: fields.Password,
                        error: (submitted && errors.Password) ? errors.Password : ""
                    }} />
                    <TextField config={{
                        name: "ConfirmPassword",
                        label: "Confirm Password",
                        inputType: "password",
                        maxLength: 64,
                        showLabel: true,
                        value: fields.ConfirmPassword,
                        error: (submitted && errors.ConfirmPassword) ? errors.ConfirmPassword : ""
                    }} />
                    <SelectField config={{
                        name: "DistrictId",
                        label: "School District",
                        showLabel: true,
                        options: config.districts,
                        value: fields.DistrictId,
                        error: (submitted && errors.DistrictId) ? errors.DistrictId : ""
                    }} />

                    <div id="recaptchav2" />
                    <div className={"button-group expanded margin-top-2"}>
                        <Button config={{
                            name: "btnCancel",
                            label: "Cancel",
                            onClick: config.onCancel,
                            btnClass: "secondary",
                            btnType: buttonConstants.BUTTON_TYPE_BUTTON
                        }} />
                        <Button config={{
                            name: "btnRegister",
                            label: "Register",
                            disabled: config.isLoading
                        }} />
                    </div>
                </div>
            )}
        </FormValidation>
    );
};


RegisterForm.propTypes = {
    config: PropTypes.shape({
        onRegisterSubmit: PropTypes.func.isRequired,
        isValid: PropTypes.bool.isRequired,
        districts: PropTypes.array.isRequired,
        onCancel: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    })
};



export default RegisterForm;