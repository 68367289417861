export const LOAD_TASKS_AJAX_SUCCESS = "LOAD_TASKS_AJAX_SUCCESS";
export const LOAD_TASK_SELECT_LIST_AJAX_SUCCESS = "LOAD_TASK_SELECT_LIST_AJAX_SUCCESS";
export const SAVE_TASK_AJAX_SUCCESS = "SAVE_TASK_AJAX_SUCCESS";
export const SAVE_TASK_SEARCH_CRITERIA = "SAVE_TASK_SEARCH_CRITERIA";
export const EDIT_TASK_AJAX_SUCCESS = "EDIT_TASK_AJAX_SUCCESS";
export const ADD_TASK = "ADD_TASK";
export const DELETE_TASK_AJAX_SUCCESS = "DELETE_TASK_AJAX_SUCCESS";
export const EDIT_FORM = "EDIT_FORM";
export const CANCEL_EDIT_FORM = "CANCEL_EDIT_FORM";
export const UPDATE_FORM = "UPDATE_FORM";
export const ADD_FORM = "ADD_FORM";
export const DELETE_FORM = "DELETE_FORM";
export const LOAD_TASK_AJAX_SUCCESS = "LOAD_TASK_AJAX_SUCCESS";
export const UPDATE_CAT_MODEL_TYPE = "UPDATE_CAT_MODEL_TYPE";
export const LOAD_ITEM_POOL_SELECT_LIST_AJAX_SUCCESS = "LOAD_ITEM_POOL_SELECT_LIST_AJAX_SUCCESS";
export const DELETE_TASK_CAT_CONFIG_AJAX_SUCCESS = "DELETE_TASK_CAT_CONFIG_AJAX_SUCCESS";
export const SAVE_TASK_CAT_CONFIG_AJAX_SUCCESS = "SAVE_TASK_CAT_CONFIG_AJAX_SUCCESS";
export const EDIT_STOP_CRITERIA = "EDIT_STOP_CRITERIA";
export const CANCEL_EDIT_STOP_CRITERIA = "CANCEL_EDIT_STOP_CRITERIA";
export const UPDATE_STOP_CRITERIA = "UPDATE_STOP_CRITERIA";
export const ADD_STOP_CRITERIA = "ADD_STOP_CRITERIA";
export const DELETE_STOP_CRITERIA = "DELETE_STOP_CRITERIA";
export const ASSOCIATE_ITEM_TO_CAT_CONFIG = "ASSOCIATE_ITEM_TO_CAT_CONFIG";
export const UNASSOCIATE_ITEM_TO_CAT_CONFIG = "UNASSOCIATE_ITEM_TO_CAT_CONFIG";
export const UPDATE_CAT_CONFIG_ITEM_ORDER = "UPDATE_CAT_CONFIG_ITEM_ORDER";
export const UPDATE_CAT_CONFIG_SUBMITTED = "UPDATE_CAT_CONFIG_SUBMITTED";
export const REMOVE_CAT_CONFIG_MASTERY_STOP_CRITERIA = "REMOVE_CAT_CONFIG_MASTERY_STOP_CRITERIA";