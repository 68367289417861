import React from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { setRedirectUrl, loginSuccess, refreshLogin } from '../Login/LoginAction';
import * as routes from "../../components/routes/routesConstants";

import localStorage from "../../components/localStorage/access";
import {createRedirectUrl} from "../../components/routes/createRedirectUrl";
import AuthenticatedLayout from "./AuthenticatedLayoutPage";

export class EnsureLoggedIn extends React.Component {
    componentDidMount() {
        this.verifyUserIsLoggedIn();
    }

    componentDidUpdate() {
        this.verifyUserIsLoggedIn();
    }

    verifyUserIsLoggedIn() {
        const { dispatch, isLoggedIn, location, history } = this.props;
        if (!localStorage.isLoggedIn()) {
            if(localStorage.isRefreshable()) {
                dispatch(loginSuccess(false, false, ""));
                dispatch(refreshLogin());
            }
            else {
                const redirectUrl = createRedirectUrl(location.search, location.hash);
                dispatch(setRedirectUrl(redirectUrl));
                history.push(routes.LOGIN.path);
            }
        }
        else if(!isLoggedIn) {
            const auth = localStorage.getAuthentication();
            dispatch(loginSuccess(true, auth.isAdmin, auth.role));
        }
    }

    render() {
        const {layout: Layout, component: Component, exact, path} = this.props;

        if (this.props.isLoggedIn && localStorage.isLoggedIn()) {
            return (
                <Layout path={path} exact={exact} component={Component}/>
            )
        }
        else {
            return null;
        }
    }
}

EnsureLoggedIn.defaultProps = {
    layout: AuthenticatedLayout
};

EnsureLoggedIn.propTypes = {
    layout: PropTypes.func,
    component: PropTypes.func.isRequired,
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    dispatch: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        isLoggedIn: state.login.isLoggedIn,
    };
}
export default withRouter(connect(mapStateToProps)(EnsureLoggedIn));