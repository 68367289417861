import React from "react";
import PropTypes from "prop-types";

import ButtonIcon from "../../components/buttons/buttonIcon";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import TextField from "../../components/inputs/textField";
import SearchableSelectField from "../../components/inputs/searchableSelectField";

const StopCriteriaEdit = ({ config }) => {
    return (
        <tr>
            <td>
                <SearchableSelectField config={{
                    name: "StopCriteriaType",
                    label: "Stop Criteria",
                    onChange: (event) => config.onStopCriteriaChange(event, config.setField),
                    defaultOption: "Select a stop criterion",
                    value: config.fields.StopCriteriaType.toString(),
                    error: (config.submitted && config.errors.StopCriteriaType) ? "*" : "",
                    showLabel: false,
                    options: config.stopCriteriaOptions,
                    includeDefaultOption: true
                }}/>
                <input type={"hidden"} value={config.fields.StopCriteriaType} name={"StopCriteriaType"}/>
            </td>
            <td>
                <TextField config={{
                    name: "StopCriteriaValue",
                    label: "Stop criterion value",
                    showLabel: false,
                    error: (config.submitted && config.errors.StopCriteriaValue) ? "*" : "",
                    value: config.fields.StopCriteriaValue,
                    maxLength: 50
                }}/>
            </td>
            <td className={"text-center whitespace-wrap"}>
                <ButtonIcon config={{
                    name: "btnSave",
                    label: "Save",
                    iconType: buttonConstants.ICON_SAVE,
                    disabled: config.isLoading,
                    isSubmit: true
                }} />
                <ButtonIcon config={{
                    name: "btnCancel",
                    label: "Cancel",
                    onClick: config.onEditStopCriteriaCancel,
                    iconType: buttonConstants.ICON_CANCEL,
                    disabled: config.isLoading
                }} />
                <input type={"hidden"} name={"Id"} value={config.fields.Id} />
            </td>
        </tr>
    );
};

StopCriteriaEdit.propTypes = {
    config: PropTypes.shape({
        criterion: PropTypes.object.isRequired,
        fields: PropTypes.object.isRequired,
        submitted: PropTypes.bool.isRequired,
        errors: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        onStopCriteriaChange: PropTypes.func.isRequired,
        onEditStopCriteriaCancel: PropTypes.func.isRequired,
        stopCriteriaOptions: PropTypes.array.isRequired,
        setField: PropTypes.func.isRequired
    })
};

export default StopCriteriaEdit;