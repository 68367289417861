import * as actionTypes from "./ItemPoolConfigurationConstants";
import * as config from "../../constants/config";

export function itemPoolDefaultState() {
    return {
        itemPoolSearchCriteria: {
            NameContains: "",
            CatModelType: 0,
            Page: 1,
            RecordsPerPage: config.defaultRecordsPerPage,
            OrderByColumn: "Name",
            OrderDescending: false,
            itemPoolSearchPerformed: false,
            Fields: ""
        },
        itemPoolTotalRecords: 0,
        itemPoolSearchResults: [],
        itemPoolSelectList: [],
        itemPoolInfo: {
            Id: 0,
            Name: "",
            CatModelType: 0,
            CatModelTypeDescription: "",
            ItemParameters: []
        },
        importResults: {
            Message: ""
        }
    };
}

export const itemPoolInitialState = itemPoolDefaultState();

export function itemPoolConfigurationReducer(state = itemPoolInitialState, action) {
    switch (action.type) {
        case actionTypes.LOAD_ITEM_POOL_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    itemPoolSearchResults: [...action.data.Data],
                    itemPoolTotalRecords: action.data.TotalRecords
                });
        case actionTypes.SAVE_ITEM_POOL_SEARCH_CRITERIA:
            return Object.assign({}, state, { itemPoolSearchCriteria: action.criteria });
        case actionTypes.EDIT_ITEM_POOL_AJAX_SUCCESS:
        case actionTypes.IMPORT_ITEM_PARAMETER_AJAX_SUCCESS:
            return Object.assign({}, state, { itemPoolInfo: Object.assign({}, state.itemPoolInfo, action.data) });
        case actionTypes.ADD_ITEM_POOL:
        case actionTypes.SAVE_ITEM_POOL_AJAX_SUCCESS:
            return Object.assign({}, state, { itemPoolInfo: itemPoolDefaultState().itemPoolInfo, importResults: action.importResults });
        case actionTypes.IMPORT_ITEM_PARAMETER_ERRORS_AJAX_SUCCESS:
        case actionTypes.CLEAR_OUT_IMPORT_MESSAGE:
            return Object.assign({}, state, {importResults: action.importResults});
        default:
            return state;
    }
}