let slabData = null;

function getSlabData() {
    if(slabData === null) {
        slabData = { UniqueScores: 100,  UniqueAudioFiles: 67 };
    }

    return slabData;
}

export {getSlabData};