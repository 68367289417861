import React from "react";
import PropTypes from "prop-types";
import EnrollmentInfo from "./EnrollmentInfo";

const EnrollmentMgmtSearchRow = ({ student, assessmentPeriods }) => {
    return (
        <tr>
            <td className={"text-center"}>
                {student.StudentTrackingId}
            </td>
            <td>
                {student.LastName}, {student.FirstName}
            </td>
            {
                assessmentPeriods.map((ap, index) => {
                    return (
                        <EnrollmentInfo enrollments={student.Enrollments} assessmentPeriod={ap.name} key={index}/>
                    );
                })
            }
        </tr>
    );
};

EnrollmentMgmtSearchRow.propTypes = {
    student: PropTypes.object.isRequired,
    assessmentPeriods: PropTypes.array.isRequired
};

export default EnrollmentMgmtSearchRow;