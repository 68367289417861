import React from "react";
import PropTypes from "prop-types";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import Button from "../../components/buttons/button";
import {generateStopCriteriaOptions, MasteryStopCriteriaOption} from "../Tasks/taskCatConfigConstants";
import SearchableSelectField from "../../components/inputs/searchableSelectField";
import TextField from "../../components/inputs/textField";
import ButtonIcon from "../../components/buttons/buttonIcon";

const StopCriteriaList = ({config}) => {

    const numberOfColumns = "3";
    let stopCriteriaOptions = getStopCriteriaOptions(config.stopCriteria[`McatStopCriteria_${config.groupIndex}`]);

    const getCriterionName = (value) => {
        const criterion = getStopCriteriaOptions().find(s => s.value === parseInt(value));
        if (!criterion)
            return "";

        return criterion.name
    }

    function getStopCriteriaOptions(stopCriteria, criterionId) {

        let stopCriteriaOptions = []

        if (criterionId === 0) {
            stopCriteriaOptions = generateStopCriteriaOptions(stopCriteria);
        } else {
            stopCriteriaOptions = generateStopCriteriaOptions(stopCriteria, criterionId);
        }

        stopCriteriaOptions = stopCriteriaOptions.filter(opt => opt.value !== MasteryStopCriteriaOption.value);

        return stopCriteriaOptions;
    }

    return (
        <div>
            <div className={"grid-x"}>
                <label htmlFor={`McatStopCriteria_${config.groupIndex}`} className={"cell auto"}>
                    Stop Criteria
                </label>
                <div className={"cell text-right medium-4 large-4"}>
                    <Button config={{
                        disabled: config.isAddStopCriteriaDisabled,
                        name: "btnAddStopCriteria",
                        label: "Add Stop",
                        onClick: config.onAddStopCriteria,
                        btnClass: "secondary",
                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                    }}/>
                </div>
            </div>
            <table className="table-grid hover stack">
                <thead>
                </thead>
                <tbody>
                {config.stopCriteria[`McatStopCriteria_${config.groupIndex}`].map(
                    (criterion, index) => {
                        if (config.isEditing && index === config.editingStopCriteriaRowIndex) {
                            return (<tr key={index}>
                                    <td>
                                        <SearchableSelectField config={{
                                            name: `StopCriteriaType_${config.groupIndex}`,
                                            label: "Stop Criteria",
                                            onChange: (selectedValue) => config.onStopCriteriaChange(selectedValue, config.setField, config.groupIndex),
                                            defaultOption: "Select a stop criterion",
                                            value: config.stopCriteria[`StopCriteriaType_${config.groupIndex}`].toString(),
                                            showLabel: false,
                                            options: stopCriteriaOptions,
                                            includeDefaultOption: true,
                                        }}/>
                                        <input type={"hidden"}
                                               value={config.stopCriteria[`StopCriteriaType_${config.groupIndex}`]}
                                               name={`StopCriteriaType_${config.groupIndex}`}/>
                                    </td>
                                    <td>
                                        <TextField config={{
                                            name: `StopCriteriaValue_${config.groupIndex}`,
                                            label: "Stop criterion value",
                                            showLabel: false,
                                            value: config.stopCriteria[`StopCriteriaValue_${config.groupIndex}`],
                                            maxLength: 50
                                        }}/>
                                    </td>
                                    <td className={"text-center whitespace-wrap"}>
                                        <ButtonIcon config={{
                                            name: "btnSave",
                                            label: "Save",
                                            iconType: buttonConstants.ICON_SAVE,
                                            disabled: config.isLoading,
                                            onClick: (event) => config.onEditStopCriteriaSave(event, config.setField, config.fields, config.groupIndex)
                                        }}/>
                                        <ButtonIcon config={{
                                            name: "btnCancel",
                                            label: "Cancel",
                                            onClick: (event) => config.onEditStopCriteriaCancel(event, config.setField, config.fields, config.groupIndex),
                                            iconType: buttonConstants.ICON_CANCEL,
                                            disabled: config.isLoading
                                        }}/>
                                        <input type={"hidden"} name={`McatStopCriterionId_${config.groupIndex}`}
                                               value={config.fields[`McatStopCriterionId_${config.groupIndex}`]}/>
                                    </td>
                                </tr>
                            );
                        } else {
                            return (
                                <tr key={index}>
                                    <td>
                                        {getCriterionName(criterion.StopCriteriaType)}
                                    </td>
                                    <td>
                                        {criterion.StopCriteriaValue}
                                    </td>
                                    <td className={"text-center whitespace-nowrap"}>
                                        <ButtonIcon config={{
                                            disabled: config.fields[`isEditing_${config.groupIndex}`],
                                            name: "btnEdit",
                                            label: "Edit",
                                            onClick: (event) => config.onEditStopCriteria(event, config.setField, criterion.McatStopCriterionId, config.fields, config.groupIndex, index),
                                            iconType: buttonConstants.ICON_EDIT
                                        }}/>
                                        <ButtonIcon config={{
                                            disabled: config.fields[`isEditing_${config.groupIndex}`],
                                            name: "btnDelete",
                                            label: "Delete",
                                            iconType: buttonConstants.ICON_DELETE,
                                            onClick: (event) => config.onDeleteStopCriteria(event, config.setField, criterion.McatStopCriterionId, config.fields, config.groupIndex)
                                        }}/>
                                    </td>
                                </tr>
                            );
                        }
                    }
                )}
                {config.stopCriteria[`McatStopCriteria_${config.groupIndex}`].length === 0 &&
                <tr>
                    <td colSpan={numberOfColumns}>
                        Click &quot;Add Stop&quot; to add new stop criteria.
                        {config.submitted &&
                        <div className="text-danger">At least one stop criterion is required!</div>}
                    </td>
                </tr>
                }
                </tbody>
            </table>
        </div>
    );
};

StopCriteriaList.propTypes = {
    config: PropTypes.shape({
    stopCriteria: PropTypes.object,
    isAddStopCriteriaDisabled: PropTypes.bool,
    setField: PropTypes.func.isRequired,
    fields: PropTypes.object,
    groupIndex: PropTypes.number,
    submitted: PropTypes.bool,
    editingStopCriteriaRowIndex: PropTypes.number,
    isLoading: PropTypes.bool,
    isEditing: PropTypes.bool,
    onAddStopCriteria: PropTypes.func.isRequired,
    onDeleteStopCriteria: PropTypes.func.isRequired,
    onEditStopCriteriaCancel: PropTypes.func.isRequired,
    onEditStopCriteriaSave: PropTypes.func.isRequired,
    onStopCriteriaChange: PropTypes.func.isRequired,
    onEditStopCriteria: PropTypes.func.isRequired,
    errors: PropTypes.object,
 })


};

export default StopCriteriaList;