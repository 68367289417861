import { beginAjaxCall, ajaxCallError } from "../AjaxStatus/AjaxStatusActions";
import * as actionTypes from "./ForgotPasswordConstants";
import forgotPasswordApi from "./ForgotPasswordApi";

export function requestPasswordReset(forgotPasswordViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return forgotPasswordApi.requestPasswordReset(forgotPasswordViewModel)
            .then(() => {
                dispatch(requestPasswordResetSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function requestPasswordResetSuccess() {
    return { type: actionTypes.REQUEST_PASSWORD_AJAX_SUCCESS };
}
