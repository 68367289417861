import { beginAjaxCall, ajaxCallError } from "../AjaxStatus/AjaxStatusActions";
import * as actionTypes from "./siteDetailConstants";
import siteDetailApi from "./siteDetailApi";
import storage from "../../components/localStorage/access";

export function siteDetailSuccess(siteDetail) {
    return { type: actionTypes.GET_SITE_DETAIL_AJAX_SUCCESS, siteDetail };
}

export function getSiteDetails() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return siteDetailApi.getSiteDetails()
            .then((siteDetail) => {
                storage.persistSiteDetails(siteDetail);
                dispatch(siteDetailSuccess(siteDetail));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}