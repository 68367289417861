import React from "react";
import PropTypes from "prop-types";
import EnrollmentMgmtSearchRow from "./EnrollmentMgmtSearchRow";
import ResultsPager from "../../components/table/ResultsPager";
import {generateApOptions } from "./EnrollmentMgmtConstants";
import SearchResultsHeader from "./SearchResultsExpandedHeader";

const EnrollmentMgmtSearchResults = ({ config }) => {
    const assessmentPeriods = generateApOptions().filter(f => f.value === config.selectedAssessmentPeriod || config.selectedAssessmentPeriod === "");
    const numberOfColumns = (assessmentPeriods.length + 2).toString();

    return (
        <div>
            <table className="table-grid hover stack">
                <SearchResultsHeader assessmentPeriods={assessmentPeriods} order={config.order} selectedYear={config.selectedYear}/>
                <tbody>
                {config.enrollmentSearchResults.map(
                    (student, index) => {
                        return (<EnrollmentMgmtSearchRow
                            key={index}
                            student={student}
                            assessmentPeriods={assessmentPeriods}
                        />);
                    }
                )}
                {config.enrollmentTotalRecords === 0 &&
                <tr>
                    <td colSpan={numberOfColumns}>
                        No enrollments match the search criteria.
                    </td>
                </tr>
                }
                </tbody>
                {config.enrollmentTotalRecords > 0 &&
                <tfoot>
                <tr>
                    <td colSpan={numberOfColumns}>
                        <ResultsPager config={{
                            first: config.first,
                            previous: config.previous,
                            next: config.next,
                            last: config.last,
                            totalRecords: config.enrollmentTotalRecords,
                            recordsPerPage: config.enrollmentRecordsPerPage,
                            currentPage: config.currentPage
                        }}  />
                    </td>
                </tr>
                </tfoot>
                }
            </table>
        </div>
    );
};

EnrollmentMgmtSearchResults.propTypes = {
    config: PropTypes.shape({
        enrollmentSearchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
        order: PropTypes.func,
        first: PropTypes.func.isRequired,
        last: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired,
        enrollmentTotalRecords: PropTypes.number.isRequired,
        currentPage: PropTypes.number,
        enrollmentRecordsPerPage: PropTypes.number,
        selectedAssessmentPeriod: PropTypes.string.isRequired,
        selectedYear: PropTypes.string.isRequired
    })
};

export default EnrollmentMgmtSearchResults;