import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as itemBankActions from "./ItemBankActions";
import * as itemActions from "../Items/ItemAction";
import * as routes from "../../components/routes/routesConstants";
import {getParams} from "../../components/layout/getParams";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import ItemBankInfoForm from "./ItemBankInfoForm";
import {Notify} from "../../components/notification/notify";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import * as config from "../../constants/config";
import $ from 'jquery';
import * as sortOptionOnDragEnd from "../../components/inputs/sortOptionOnDragEnd";
import * as loginActions from "../Login/LoginAction";

let searchBoxEnterKey = false;

export class ItemBankInfoPage extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            itemBankId: 0
        };

        this.onItemBankSubmit = this.onItemBankSubmit.bind(this);
        this.onReturnItemBankList = this.onReturnItemBankList.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
    }

    componentDidMount(){
        this.props.actions.updatePageTitle_BreadCrumb(routes.ITEM_BANK_INFO);

        this.props.actions.loadItemSelectList();

        const paramObj = getParams(this.props.location.pathname, routes.ITEM_BANK_INFO);
        if(paramObj !== null)
            this.setState(paramObj);

        if(paramObj.itemBankId !== "0")
            this.props.actions.editItemBank(parseInt(paramObj.itemBankId));
        else
            this.props.actions.addItemBank();

        $(document).ready(() => {
            PushFocusToElement("Name");
            searchBoxEnterKey = this.preventSearchBoxEnterKeyFromSubmittingForm();
        });
    }

    preventSearchBoxEnterKeyFromSubmittingForm() {
        $(window).keydown(function(event){
            searchBoxEnterKey = (event.keyCode === 13 && event.target.className === "select-search-box__search");
        });
    }

    onDragEnd(result) {
        let itemBank = Object.assign({}, this.props.itemBankInfo);

        const newItems = sortOptionOnDragEnd.Handler(result, itemBank.ItemIds);

        itemBank.ItemIds = newItems;

        this.props.actions.updateItemBank(itemBank);
    }

    onDeleteItem(event, index, setField) {
        event.preventDefault();
        if(confirm("Are you sure you want to remove this item?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page.")) {
            if(this.props.itemBankInfo.ItemIds.length === 1)
                setField({ItemIds: ""});

            this.props.actions.unassociateItem(index);
        }
    }

    onItemChange(item, setField) {
        setField({ItemIds: "Yes"});
        this.props.actions.associateItem(parseInt(item.value));

        let container = $(".sort-container > div.cell > div");
        let height = container.get(0).scrollHeight;
        container.animate({scrollTop: height});

        searchBoxEnterKey = false;
    }

    onItemBankSubmit({fields, isValid}) {
        if (isValid && !searchBoxEnterKey) {
            this.setState({isLoading: true});

            let detachedFields = Object.assign({}, {Name: fields.Name});
            if(detachedFields.Id === "")
                detachedFields.Id = 0;

            const itemBank = Object.assign({}, this.props.itemBankInfo, detachedFields);

            this.props.actions.saveItemBank(itemBank)
                .then(() => this.props.history.push(routes.ITEM_BANK_LIST.path))
                .catch(() => this.setState({isLoading: false}));
        }
        else if(searchBoxEnterKey) {
            PushFocusToElement("select-search-box__search");
        }
        else {
            PushFocusToElement("Name");
        }
    }

    onReturnItemBankList(event) {
        event.preventDefault();
        Notify.Clear();
        this.props.history.push(routes.ITEM_BANK_LIST.path);
    }

    isItemBankUpdateable() {
        if(this.state.itemBankId === "0")
            return true;

        if(this.props.itemBankInfo === undefined ||
            this.props.itemBankInfo.Links === undefined)
            return false;

        const itemBankLinkList = this.props.itemBankInfo.Links.filter(f => f.Method === config.DELETE_API_METHOD);
        return (itemBankLinkList.length > 0);
    }

    createPageDirections() {
        let pageDir = "";
        if(this.state.itemBankId === "0")
            pageDir = "Create a new item bank.";
        else {
            pageDir = !this.isItemBankUpdateable() ? <strong className={"text-danger"}>NOTE - this item bank is associated with scores.  Only make changes that will not affect the overall outcome of previously saved scores.</strong> : "Edit an item bank.";
        }
        return <p>{pageDir}</p>;
    }

    render() {
        const {itemBankInfo, itemSelectList} = this.props;
        return (
            <div>
                <div className={"clearfix"}>
                    <Button config={{
                        name: "btnReturnItemBankList",
                        label: "Return to Item Bank List",
                        onClick: this.onReturnItemBankList,
                        btnClass: "light float-right",
                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                    }}/>
                    {this.createPageDirections()}
                </div>
                {
                    itemBankInfo.Id !== undefined &&
                    itemSelectList !== undefined &&
                    itemBankInfo.Id.toString() === this.state.itemBankId &&
                    itemSelectList.length > 0 &&
                    <div>
                        <ItemBankInfoForm config={{
                            onItemBankSubmit: this.onItemBankSubmit,
                            isLoading: this.state.isLoading,
                            itemBankInfo: itemBankInfo,
                            isUpdateable: true,
                            onReturnItemBankList: this.onReturnItemBankList,
                            onDragEnd: this.onDragEnd,
                            itemSelectList: itemSelectList,
                            onDeleteItem: this.onDeleteItem,
                            onItemChange: this.onItemChange
                        }}/>
                    </div>
                }
            </div>
        );
    }
}

ItemBankInfoPage.propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    itemBankInfo: PropTypes.object,
    itemSelectList: PropTypes.array
};

function mapStateToProps(state) {
    return {
        itemBankInfo: state.itemBank.itemBankInfo,
        itemSelectList: state.item.itemSelectList
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        itemBankActions,
        itemActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (ItemBankInfoPage));
