import { combineReducers } from 'redux';
import {loginReducer as login} from "./Login/LoginReducer";
import ajaxStatus from "./AjaxStatus/AjaxStatusReducer";
import {headerReducer as header} from "./AuthenticatedLayout/HeaderReducer.js";
import {districtReducer as district} from "./District/DistrictReducer";
import {registerReducer as register} from "./Register/RegisterReducer";
import {studentMgmtReducer as studentMgmt} from "./StudentMgmt/StudentMgmtReducer";
import {itemReducer as item} from "./Items/ItemReducer";
import {itemBankReducer as itemBank} from "./ItemBanks/ItemBankReducer";
import {taskTypeReducer as taskType} from "./TaskType/TaskTypeReducer";
import {taskReducer as task} from "./Tasks/TaskReducer";
import {assessmentReducer as assessment} from "./Assessments/AssessmentReducer";
import {userMgmtReducer as userMgmt} from "./UserMgmt/UserMgmtReducer";
import {enrollmentMgmtReducer as enrollmentMgmt} from "./EnrollmentMgmt/EnrollmentMgmtReducer";
import {reportingReducer as reporting} from "./Reporting/ReportingReducer";
import {slabReducer as slab} from "./Slab/SlabReducer";
import {itemPoolConfigurationReducer as itemPool} from "./ItemPoolConfiguration/ItemPoolConfigurationReducer";
import {siteDetailReducer as siteDetail} from "./SiteDetails/siteDetailReducer";

import { connectRouter } from 'connected-react-router';

const rootReducer = (history) =>  combineReducers({
    login,
    ajaxStatus,
    header,
    district,
    register,
    studentMgmt,
    item,
    itemBank,
    taskType,
    task,
    assessment,
    userMgmt,
    enrollmentMgmt,
    reporting,
    slab,
    itemPool,
    siteDetail,
    router: connectRouter(history)
});

export default rootReducer;