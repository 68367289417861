import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as itemActions from "./ItemAction";
import * as routes from "../../components/routes/routesConstants";
import {getParams} from "../../components/layout/getParams";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import ItemInfoForm from "./ItemInfoForm";
import {Notify} from "../../components/notification/notify";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import * as config from "../../constants/config";
import OptionsList from "./OptionsList";
import ButtonBar from "../../components/buttons/buttonBar";
import {optionInitialState} from "./ItemReducer";
import * as loginActions from "../Login/LoginAction";

export class ItemInfoPage extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            itemId: 0,
            resetSubmittedCount: 0
        };

        this.onItemSubmit = this.onItemSubmit.bind(this);
        this.onReturnItemList = this.onReturnItemList.bind(this);
        this.onItemFileUploadChange = this.onItemFileUploadChange.bind(this);
        this.onRemoveItemFileClick = this.onRemoveItemFileClick.bind(this);
        this.onCancelItemFileUpload = this.onCancelItemFileUpload.bind(this);
        this.onOptionFileUploadChange = this.onOptionFileUploadChange.bind(this);
        this.onRemoveOptionFileClick = this.onRemoveOptionFileClick.bind(this);
        this.onCancelOptionFileUpload = this.onCancelOptionFileUpload.bind(this);
        this.onEditOption = this.onEditOption.bind(this);
        this.onEditOptionSubmit = this.onEditOptionSubmit.bind(this);
        this.onEditOptionCancel = this.onEditOptionCancel.bind(this);
        this.onDeleteOption = this.onDeleteOption.bind(this);
        this.onAddOption = this.onAddOption.bind(this);
    }

    componentDidMount(){
        this.props.actions.updatePageTitle_BreadCrumb(routes.ITEM_INFO);

        const paramObj = getParams(this.props.location.pathname, routes.ITEM_INFO);
        if(paramObj !== null)
            this.setState(paramObj);

        if(paramObj.itemId !== "0")
            this.props.actions.editItem(parseInt(paramObj.itemId));
        else
            this.props.actions.addItem();
     }

     componentDidUpdate(prevProps)
     {
         let itemInfo = this.props.itemInfo;
         if(itemInfo.Id !== undefined && prevProps.itemInfo.Id !== undefined && itemInfo.Id !== prevProps.itemInfo.Id)
            PushFocusToElement("Name");
     }

     onItemSubmit({fields, isValid}) {
         if (isValid) {
             this.setState({isLoading: true});

             let detachedFields = Object.assign({}, fields);
             if(detachedFields.Id === "")
                 detachedFields.Id = 0;

             const item = Object.assign({}, this.props.itemInfo, detachedFields);

             this.props.actions.saveItem(item)
                 .then(() => this.props.history.push(routes.ITEM_LIST.path))
                 .catch(() => this.setState({isLoading: false}));
         }
         else {
             PushFocusToElement("Name");
         }
     }

    onItemFileUploadChange(event) {
        event.preventDefault();
        const files = event.target.files;
        const fileMediaType = event.target.id;
        let item = Object.assign({}, this.props.itemInfo);
        item.FileData.append(fileMediaType, files[0]);
        this.props.actions.updateItem(item);
    }

    onRemoveItemFileClick(event, mediaType) {
        event.preventDefault();
        let item = Object.assign({}, this.props.itemInfo);
        item[mediaType] = "";
        this.props.actions.updateItem(item);
    }

    onCancelItemFileUpload(event, fileMediaType) {
        event.preventDefault();
        let item = Object.assign({}, this.props.itemInfo);
        item.FileData.delete(fileMediaType);
        this.props.actions.updateItem(item);
    }

    // ******************************** OPTIONS *************************************//

    onOptionFileUploadChange(event, option) {
        event.preventDefault();
        const files = event.target.files;
        const fileMediaType = event.target.id;
        let detachedOption = Object.assign({}, option);
        detachedOption.FileData.append(fileMediaType, files[0]);
        this.props.actions.updateOption(detachedOption, true, this.props.editingRowIndex);
}

    onRemoveOptionFileClick(event, mediaType, option) {
        event.preventDefault();
        let detachedOption = Object.assign({}, option);
        detachedOption[mediaType] = "";
        this.props.actions.updateOption(detachedOption, true, this.props.editingRowIndex);
    }

    onCancelOptionFileUpload(event, fileMediaType, option) {
        event.preventDefault();
        let detachedOption = Object.assign({}, option);
        detachedOption.FileData.delete(fileMediaType);
        this.props.actions.updateOption(detachedOption, true, this.props.editingRowIndex);
    }

    onEditOption(event, option, setField, index) {
        event.preventDefault();

        setField({Id: option.Id, Name: option.Name, ValueText: option.ValueText, IsCorrect: option.IsCorrect});

        this.props.actions.editOption(index);
    }

    onEditOptionSubmit({fields, isValid}) {
        if (isValid) {
            let detachedFields = Object.assign({}, fields);
            if(detachedFields.Id === "")
                detachedFields.Id = 0;

            const option = Object.assign({}, this.props.itemInfo.Options[this.props.editingRowIndex], detachedFields);

            this.props.actions.updateOption(option, false, 0);
        }
        else
            PushFocusToElement("Name", "table-grid");
    }

    onEditOptionCancel(event) {
        event.preventDefault();
        this.props.actions.cancelEditOption();
        this.setState({resetSubmittedCount: this.state.resetSubmittedCount + 1});
    }

    onDeleteOption(event, index) {
        event.preventDefault();
        if(this.props.addRowIndex >= 0 || confirm("Are you sure you want to delete this option?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page."))
            this.props.actions.deleteOption(index);
    }

    onAddOption(event, setField) {
        event.preventDefault();
        this.props.actions.addOption();

        this.setState({resetSubmittedCount: this.state.resetSubmittedCount + 1});

        const optionInitial = optionInitialState();
        setField({Id: optionInitial.Id, Name: optionInitial.Name, ValueText: optionInitial.ValueText, IsCorrect: optionInitial.IsCorrect});
    }

    onReturnItemList(event) {
        event.preventDefault();
        Notify.Clear();
        this.props.actions.cancelItem();
        this.props.history.push(routes.ITEM_LIST.path);
    }

    isItemUpdateable() {
        if(this.state.itemId === "0")
            return true;

        if(this.props.itemInfo === undefined ||
            this.props.itemInfo.Links === undefined)
            return false;

        const itemLinkList = this.props.itemInfo.Links.filter(f => f.Method === config.DELETE_API_METHOD);
        return (itemLinkList.length > 0);
    }

    shouldDisableSave() {
        return this.props.isEditing;
    }

    createPageDirections() {
        let pageDir = "";
        if(this.state.itemId === "0")
            pageDir = "Create a new item.";
        else {
            pageDir = !this.isItemUpdateable() ? <strong className={"text-danger"}>NOTE - this item is associated with scores.  Only make changes that will not affect the overall outcome of previously saved scores.</strong> : "Edit an item.";
        }
        return <p>{pageDir}</p>;
    }

    shouldRenderItemInfoForm(itemInfo) {
        const dataStateIsGood = itemInfo.Id !== undefined && itemInfo.Id.toString() === this.state.itemId;

        return dataStateIsGood;
    }

    render() {
        const {itemInfo} = this.props;
        return (
            <div>
                <div className={"clearfix"}>
                    <Button config={{
                        name: "btnReturnItemList",
                        label: "Return to Item List",
                        onClick: this.onReturnItemList,
                        btnClass: "light float-right",
                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                    }}/>
                    {this.createPageDirections()}
                </div>
                {
                    this.shouldRenderItemInfoForm(itemInfo) &&
                    <div>
                        <ItemInfoForm config={{
                            onItemSubmit: this.onItemSubmit,
                            isLoading: this.state.isLoading,
                            onFileUploadChange: this.onItemFileUploadChange,
                            itemInfo: itemInfo,
                            onRemoveFileClick: this.onRemoveItemFileClick,
                            onCancelUpload: this.onCancelItemFileUpload,
                            shouldDisableSave: this.shouldDisableSave()
                        }}/>
                        <hr />
                        <OptionsList key={this.state.resetSubmittedCount} config={{
                            options: itemInfo.Options,
                            isEditing: this.props.isEditing,
                            editingRowIndex: this.props.editingRowIndex,
                            addRowIndex: this.props.addRowIndex,
                            onEditOption: this.onEditOption,
                            onEditOptionSubmit: this.onEditOptionSubmit,
                            onEditOptionCancel: this.onEditOptionCancel,

                            onFileUploadChange: this.onOptionFileUploadChange,
                            onCancelUpload: this.onCancelOptionFileUpload,
                            onRemoveFileClick: this.onRemoveOptionFileClick,
                            onDeleteOption: this.onDeleteOption,
                            onAddOption: this.onAddOption
                        }}/>
                        <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_RIGHT} wrapperClass={"margin-top-2"}>
                            <Button config={{
                                name: "btnCancel",
                                label: "Cancel",
                                onClick: this.onReturnItemList,
                                btnClass: "secondary",
                                btnType: buttonConstants.BUTTON_TYPE_BUTTON
                            }} />
                            <label htmlFor={"btnSave"} className={"button" + (this.shouldDisableSave() ? " is-disabled" : "")}>
                                <span>Save</span>
                            </label>
                        </ButtonBar>
                    </div>
                }
            </div>
        );
    }
}

ItemInfoPage.propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    itemInfo: PropTypes.object,
    isEditing: PropTypes.bool,
    editingRowIndex: PropTypes.number,
    addRowIndex: PropTypes.number
};

function mapStateToProps(state) {
    return {
        itemInfo: state.item.itemInfo,
        isEditing: state.item.isEditing,
        editingRowIndex: state.item.editingRowIndex,
        addRowIndex: state.item.addRowIndex
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        itemActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (ItemInfoPage));
