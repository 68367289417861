import * as config from "../../constants/config";
import {getAccounts} from "../../mockData/accountData";
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";
import {mockRejectApiCall, mockResolveApiCall, sendRequest} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerForgotPasswordApi {
    static requestPasswordReset(forgotPasswordViewModel) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}forgot-password`,
                RequestTypes.PUT,
                JSON.stringify(forgotPasswordViewModel.Email),
                ApiSuccessMessages.FORGOT_PASSWORD_SUCCESS.replace(ApiSuccessMessages.FORGOT_PASSWORD_EMAIL, forgotPasswordViewModel.Email)
            )
        );
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockForgotPasswordApi {
    static requestPasswordReset(forgotPasswordViewModel) {
        let userAcct = getAccounts().find(account => account.Email === forgotPasswordViewModel.Email);

        if (!userAcct) {
            return mockRejectApiCall("There was an error processing this request - Email not found!.");
        } else {
            return mockResolveApiCall(ApiSuccessMessages.FORGOT_PASSWORD_SUCCESS.replace(ApiSuccessMessages.FORGOT_PASSWORD_EMAIL, forgotPasswordViewModel.Email));
        }
    }
}

const ForgotPasswordApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerForgotPasswordApi : MockForgotPasswordApi;
export default ForgotPasswordApi;