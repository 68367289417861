import * as types from './AjaxStatusConstants';
import * as logoff from "../AuthenticatedLayout/HeaderConstants";

export const ajaxStatusInitialState = {
    ajaxCallsInProgress: 0
};


function actionTypeEndsInSuccess(type) {
    return type.substring(type.length - 13) === '_AJAX_SUCCESS';
}

export default function ajaxStatusReducer(state = ajaxStatusInitialState, action) {
    if (action.type === types.BEGIN_AJAX_CALL) {
        return Object.assign({}, state, {ajaxCallsInProgress: state.ajaxCallsInProgress + 1});
    }
    else if (action.type === types.AJAX_CALL_ERROR ||
        actionTypeEndsInSuccess(action.type)) {
        return Object.assign({}, state, {ajaxCallsInProgress: state.ajaxCallsInProgress > 0 ? state.ajaxCallsInProgress - 1 : 0});
    }
    else if(action.type === logoff.LOGOFF_SUCCESS) {
        return Object.assign({}, state, {ajaxCallsInProgress: 0});
    }

    return state;
}