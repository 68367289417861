import { beginAjaxCall, ajaxCallError } from "../AjaxStatus/AjaxStatusActions";
import * as actionTypes from "./DistrictConstants";
import districtApi from "./DistrictApi";

export function loadDistrictsSuccess(allDistricts) {
    return { type: actionTypes.GET_DISTRICTS_AJAX_SUCCESS, allDistricts };
}

export function loadDistricts() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return districtApi.getDistricts()
            .then((allDistricts) => {
                dispatch(loadDistrictsSuccess(allDistricts));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function loadDistrict() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return districtApi.getDistrict()
            .then((allDistricts) => {
                dispatch(loadDistrictsSuccess(allDistricts));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}