import $ from 'jquery';
import * as config from "../../constants/config";
import LocalStorage from "../../components/localStorage/access";
import {getEnrollmentData, getDistrictsWithEnrollments, getSchools, getTeachers} from "../../mockData/studentData";
import {
    mockRejectApiCall,
    mockResolveApiCall,
    sendRequest,
    downloadBlob
} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerEnrollmentMgmtApi {
    static getEnrollments(enrollmentSearchCriteria) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}enrollments?${$.param(enrollmentSearchCriteria)}`,
                RequestTypes.GET,
            ));
    }

    static getEnrollmentFilterData(enrollmentSearchCriteria) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}enrollments/filters?${$.param(enrollmentSearchCriteria)}`,
                RequestTypes.GET,
            ));
    }

    static exportEnrollmentData(enrollmentSearchCriteria) {
        return downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}enrollments/export?${$.param(enrollmentSearchCriteria)}`,
                RequestTypes.GET,
                null,
                ApiSuccessMessages.EXPORT_SUCCESS
            ));
    }

    static importEnrollments(fileData) {
        let request = AjaxRequestOptions(
            `${config.apiUrl}enrollments/import`,
            RequestTypes.POST,
            fileData);

        request.processData = false;
        request.enctype = `multipart/form-data`;
        request.contentType = false;

        return sendRequest(request);
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockEnrollmentMgmtApi {
    static getEnrollments(enrollmentSearchCriteria) {
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            let students = getEnrollmentData();

            if (enrollmentSearchCriteria.Year !== "")
                students = students.filter(student => student.Enrollments.filter(enrollment => enrollment.Year.toString() === enrollmentSearchCriteria.Year).length > 0);

            if (enrollmentSearchCriteria.AssessmentPeriod !== "")
                students = students.filter(student => student.Enrollments.filter(enrollment => enrollment.AssessmentPeriod === enrollmentSearchCriteria.AssessmentPeriod).length > 0);

            if (enrollmentSearchCriteria.DistrictId > 0)
                students = students.filter(student => student.Enrollments.filter(enrollment => enrollment.DistrictId == enrollmentSearchCriteria.DistrictId).length > 0);

            if (enrollmentSearchCriteria.SchoolName !== "")
                students = students.filter(student => student.Enrollments.filter(enrollment => enrollment.SchoolName === enrollmentSearchCriteria.SchoolName).length > 0);

            if (enrollmentSearchCriteria.TeacherName !== "")
                students = students.filter(student => student.Enrollments.filter(enrollment => enrollment.TeacherName === enrollmentSearchCriteria.TeacherName).length > 0);

            let sortProp = enrollmentSearchCriteria.OrderByColumn;
            let sortDir = (enrollmentSearchCriteria.OrderDescending) ? -1 : 1;

            students.sort(function (a, b) {
                return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);
            });

            const skip = (enrollmentSearchCriteria.Page - 1) * enrollmentSearchCriteria.RecordsPerPage;
            const take = enrollmentSearchCriteria.RecordsPerPage + skip;

            return mockResolveApiCall(Object.assign({}, {
                Data: students.slice(skip, take),
                TotalRecords: students.length
            }));
        }
    }

    static getEnrollmentFilterData(enrollmentSearchCriteria) {
        const data = {
            Districts: getDistrictsWithEnrollments(enrollmentSearchCriteria),
            Schools: getSchools(enrollmentSearchCriteria),
            Teachers: getTeachers(enrollmentSearchCriteria)
        };
        return mockResolveApiCall(data);
    }

    static exportEnrollmentData() {
        // no mock available as no way to create a mock Excel file
    }

    static importEnrollments() {
        // no mock available as no way to create a mock Excel file
        return mockResolveApiCall("EnrollmentImported", "1 enrollment imported");
    }
}

const EnrollmentMgmtApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerEnrollmentMgmtApi : MockEnrollmentMgmtApi;
export default EnrollmentMgmtApi;