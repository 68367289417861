import React from 'react';
import ReachEveryReader from "../../images/ReachEveryReader.png";
import CZFLogo from "../../images/CZI.png";
import PropTypes from "prop-types";
import * as config from "../../constants/config";

const Footer = ({supportEmail}) => {
    if(supportEmail == "")
        supportEmail = config.supportEmail
    return (
        <footer className={"cell shrink footer text-center"}>
            <div className={"grid-x align-middle"}>
                <div className={"cell medium-1 large-1 large-offset-4"}>
                    <img src={ReachEveryReader} alt={"Reach Every Reader"} width={"79"} height={"73"} />
                </div>
                <div className={"cell medium-2 large-2"}>
                    <p>Email: <a href={`mailto:${supportEmail}`}>{supportEmail}</a><br/>

                        Phone: 1-800-357-1072</p>
                </div>
                <div className={"cell medium-1 large-1"}>
                    <img src={CZFLogo} alt={"Chan Zuckerberg Initiative"} width={"117"} height={"49"} />
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    supportEmail: PropTypes.string,
};

Footer.defaultProps = {
    supportEmail: ""
};

export default Footer;