import {beginAjaxCall, ajaxCallError} from "../AjaxStatus/AjaxStatusActions";
import reportingApi from "./ReportingApi";
import * as actionTypes from "./ReportingConstants";

export function loadReportingInfoSuccess(data) {
    return {type: actionTypes.LOAD_REPORTING_AJAX_SUCCESS, data};
}

export function loadReportingInfo(reportingFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return reportingApi.getReportingInfo(reportingFilterCriteria)
            .then(data => {
                dispatch(loadReportingInfoSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function saveReportingSearchCriteria(criteria) {
    return {type: actionTypes.SAVE_REPORTING_SEARCH_CRITERIA, criteria};
}

export function exportReportDataSuccess() {
    return {type: actionTypes.EXPORT_REPORT_AJAX_SUCCESS};
}

export function exportReportData(reportingFilterCriteria) {
    return dispatch => {

        dispatch(beginAjaxCall());

        return reportingApi.exportReportData(reportingFilterCriteria)
            .then(() => {
                dispatch(exportReportDataSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}