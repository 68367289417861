import React from "react";
import PropTypes from "prop-types";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import ButtonMock from "../../components/buttons/buttonMock";

const EnrollmentInfo = ({ enrollments, assessmentPeriod }) => {
    const apEnrollments = enrollments.filter(f => f.AssessmentPeriod === assessmentPeriod);
    if(apEnrollments.length === 0)
        return <td className={"text-center"}>N/A</td>;

    return (
        <td className={"text-center enrollment-info"}>
            {
                apEnrollments.map((enrollment, index) => {
                    let icon = buttonConstants["ICON_" + (enrollment.Status).replace(/ /g, "_").toUpperCase()]; // eslint-disable-line import/namespace
                    return (
                        <div className={"grid-x"} key={index}>
                            <div className={"cell secondary"}>
                                {enrollment.AssessmentName}
                            </div>
                            <div className={"cell"}>
                                <ButtonMock name={"statusSymbol"} label={enrollment.Status} btnClass={"icons icon-" + icon} />
                            </div>
                            <div className={"cell"}>
                                <small>{enrollment.DistrictName} &gt; {enrollment.SchoolName} &gt; {enrollment.TeacherName} ({enrollment.Grade})</small>
                            </div>
                        </div>
                    );
                })
            }
        </td>
    );
};

EnrollmentInfo.propTypes = {
    enrollments: PropTypes.array.isRequired,
    assessmentPeriod: PropTypes.string.isRequired
};

export default EnrollmentInfo;
