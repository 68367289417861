import React from "react";
import PropTypes from "prop-types";

const SelectField = ({ config }) => {

    config = Object.assign({}, selectFieldDefaults(), config);

    if (!config.showLabel) {
        config.labelClass += " show-for-sr";
    }

    let selectListOptions = [];
    if(config.options && config.options.length > 0) {
        selectListOptions = config.options;
    }

    if(config.value === null){
        config.value = "";
    }
    return (
        <div className={"grid-x " + config.wrapperClass}>
            <label htmlFor={config.name} className={"cell " + config.labelClass}>{ (config.isRequired) && <span className="text-danger">* </span>}{config.label}</label>
            <div className={"cell " + config.inputClass}>
                <select name={config.name}
                        id={config.name}
                        value={config.value}
                        onChange={config.onChange}>
                    {config.includeDefaultOption && <option value="">{config.defaultOption}</option>}
                    {selectListOptions.map((listItem, index) =>
                    {
                        return <option key={index} value={listItem.value}>{listItem.name}</option>;
                    })
                    }
                </select>
            </div>
            {config.error && <div className="text-danger">{config.error}</div>}
        </div>
    );
};

SelectField.propTypes = {
    config: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        defaultOption: PropTypes.string,
        value: PropTypes.string,
        error: PropTypes.string,
        showLabel: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.object),
        includeDefaultOption: PropTypes.bool,

        wrapperClass: PropTypes.string,
        labelClass: PropTypes.string,
        inputClass: PropTypes.string,
        isRequired: PropTypes.bool,
        onChange: PropTypes.func
    })
};

function selectFieldDefaults() {
    return {
        includeDefaultOption: true,
        defaultOption: "Select an Option",
        wrapperClass: "",
        labelClass: "small-12",
        inputClass: "small-12",
        isRequired: false,
        onChange: (f => f)
    };
}

export default SelectField;