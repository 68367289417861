import * as config from "../../constants/config";
import {getAccounts, saveAccounts} from "../../mockData/accountData";
import {getDistrictData} from "../../mockData/districtData";
import {mockRejectApiCall, mockResolveApiCall, sendRequest} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerRegisterApi {
    static createAccount(accountViewModel) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}users`,
                RequestTypes.POST,
                JSON.stringify(accountViewModel),
            )
        );
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockRegisterApi {
    static getNewId() {
        return getAccounts().length + 1000;
    }

    static createAccount(accountViewModel) {
        let emailCheck = getAccounts().filter(u => u.Email === accountViewModel.Email);
        if (emailCheck.length > 0) {
            return mockRejectApiCall("There was an error processing this request - this email is already associated with another account.");
        } else if (accountViewModel.recaptchaResponse === "") {
            return mockRejectApiCall("There was an error processing this request - please verify that you are human by clicking on the reCaptcha checkbox.");
        } else {

            let districtInfo = getDistrictData().filter(f => f.Id === parseInt(accountViewModel.DistrictId));
            const newProfile = {
                Id: this.getNewId(),
                Email: accountViewModel.Email,
                FirstName: accountViewModel.FirstName,
                LastName: accountViewModel.LastName,
                Password: accountViewModel.Password,
                isadmin: false,
                Role: "Proctor",
                RoleId: 1,
                DistrictId: parseInt(accountViewModel.DistrictId),
                DistrictName: districtInfo[0].DistrictName
            };
            saveAccounts([...getAccounts(), newProfile]);

            return mockResolveApiCall(newProfile);
        }
    }
}

const RegisterApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerRegisterApi : MockRegisterApi;
export default RegisterApi;