import store from "store";
import storeExpirePlugin from "../../../tools/storeExpire";
import * as storageTypes from "./storageTypes";

store.addPlugin(storeExpirePlugin);

export default class Access {
    static isLoggedIn() {
        const accessToken = store.get(storageTypes.ACCESS_TOKEN);
        return accessToken && accessToken.length > 0;
    }

    static getRole() {
        return store.get(storageTypes.ROLE);
    }

    static isRefreshable() {
        const refreshToken = store.get(storageTypes.REFRESH_TOKEN);
        return refreshToken && refreshToken.length > 0;
    }

    static persistLogin(access_token, at_expires_in, refresh_token, rt_expires_in, isAdmin, role) {
        store.set(storageTypes.ACCESS_TOKEN, access_token, at_expires_in);
        store.set(storageTypes.ACCESS_TOKEN_EXPIRES, at_expires_in, at_expires_in);
        store.set(storageTypes.REFRESH_TOKEN, refresh_token, rt_expires_in);
        store.set(storageTypes.REFRESH_TOKEN_EXPIRES, rt_expires_in, rt_expires_in);
        store.set(storageTypes.IS_ADMIN, isAdmin, at_expires_in);
        store.set(storageTypes.ROLE, role, at_expires_in);
    }

    static persistSiteDetails(siteDetails) {
        store.set(storageTypes.SITE_DETAILS, siteDetails);
    }

    static getSiteDetails() {
        return store.get(storageTypes.SITE_DETAILS);
    }

    static removeAccessToken() {
        store.remove(storageTypes.ACCESS_TOKEN);
    }

    static logout() {
        store.remove(storageTypes.ACCESS_TOKEN_EXPIRES);
        store.remove(storageTypes.ACCESS_TOKEN);
        store.remove(storageTypes.REFRESH_TOKEN);
        store.remove(storageTypes.REFRESH_TOKEN_EXPIRES);
        store.remove(storageTypes.IS_ADMIN);
        store.remove(storageTypes.ROLE);
    }

    static getAccessToken() {
        return store.get(storageTypes.ACCESS_TOKEN);
    }

    static getRefreshToken() {
        return store.get(storageTypes.REFRESH_TOKEN);
    }

    static getAuthTokenValue() {
        return "Bearer " + this.getAccessToken();
    }

    static getAuthentication() {
        return {isAdmin: store.get(storageTypes.IS_ADMIN), role: store.get(storageTypes.ROLE)};
    }
}