import React from "react";
import PropTypes from "prop-types";

const McatConfigAssociatedTasks = ({associatedTasks}) => {
    return (
        <div>
            <table className="table-grid hover stack" id={"associated-tasks"}>
                <thead>
                <tr>
                    <th className="text-left ">Order</th>
                    <th className="text-left ">Task
                        Name
                    </th>
                    <th className="text-left ">MCAT
                        Group Name
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    associatedTasks.map(
                        (task, index) => {
                            return (
                                <tr key={task.id * 10 + index}>
                                    <td>
                                        {task.order}
                                    </td>
                                    <td>
                                        {task.name}
                                    </td>
                                    <td>
                                        {task.mcatGroupName}
                                    </td>
                                </tr>
                            );
                        }
                    )}
                </tbody>
            </table>
        </div>
    );
};

McatConfigAssociatedTasks.propTypes = {
     associatedTasks: PropTypes.array.isRequired
};

export default McatConfigAssociatedTasks;