import { beginAjaxCall, ajaxCallError } from "../AjaxStatus/AjaxStatusActions";
import * as actionTypes from "./RegisterConstants";
import registerApi from "./RegisterApi";

export function createAccountSuccess(accountViewModel) {
    return { type: actionTypes.CREATE_ACCOUNT_AJAX_SUCCESS, accountViewModel };
}

export function createAccount(accountViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return registerApi.createAccount(accountViewModel)
            .then(() => {
                dispatch(createAccountSuccess(accountViewModel));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}
