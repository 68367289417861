import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as taskActions from "./TaskAction";
import * as itemBankActions from "../ItemBanks/ItemBankActions";
import * as routes from "../../components/routes/routesConstants";
import {getParams} from "../../components/layout/getParams";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import TaskInfoForm from "./TaskInfoForm";
import {Notify} from "../../components/notification/notify";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import * as config from "../../constants/config";
import FormsList from "./FormsList";
import {formInitialState} from "./TaskReducer";
import $ from "jquery";
import {convertGeneralDataToOptions} from "../../components/generalData/convertGeneralDataToOptions";
import * as generalData from "../../components/generalData/generalDataConstants";
import * as loginActions from "../Login/LoginAction";

let searchBoxEnterKey = false;

export class TaskInfoPage extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            taskId: 0,
            resetSubmittedCount: 0,
            calidationSubmitButtonClick: "btnSave"
        };

        this.onTaskSubmit = this.onTaskSubmit.bind(this);
        this.onReturnTaskList = this.onReturnTaskList.bind(this);
        this.onEditForm = this.onEditForm.bind(this);
        this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
        this.onEditFormCancel = this.onEditFormCancel.bind(this);
        this.onDeleteForm = this.onDeleteForm.bind(this);
        this.onAddForm = this.onAddForm.bind(this);
        this.onItemBankChange = this.onItemBankChange.bind(this);
        this.onCalidationCatConfigClick = this.onCalidationCatConfigClick.bind(this);
    }

    componentDidMount() {
        this.props.actions.updatePageTitle_BreadCrumb(routes.TASK_INFO);

        this.props.actions.loadItemBankSelectList();

        const paramObj = getParams(this.props.location.pathname, routes.TASK_INFO);
        if (paramObj !== null)
            this.setState(paramObj);

        if (paramObj.taskId !== "0")
            this.props.actions.editTask(parseInt(paramObj.taskId));
        else
            this.props.actions.addTask();

        $(document).ready(() => {
            PushFocusToElement("Name");
            searchBoxEnterKey = this.preventSearchBoxEnterKeyFromSubmittingForm();
        });
    }

    preventSearchBoxEnterKeyFromSubmittingForm() {
        $(window).keydown(function (event) {
            searchBoxEnterKey = (event.keyCode === 13 && event.target.className === "select-search-box__search");
        });
    }

    onTaskSubmit(event) {
        if (event.isValid) {
            let detachedFields = Object.assign({}, event.fields);
            if (detachedFields.Id === "")
                detachedFields.Id = 0;

            detachedFields.TaskTypeId = parseInt(detachedFields.TaskTypeId);

            const task = Object.assign({}, this.props.taskInfo, this.props.taskInfo.CatConfig, detachedFields);

            if (task.Forms.length === 0) {
                Notify.Error("At least one form needs to be associated with this task.");
                return;
            }

            this.setState({isLoading: true});
            this.props.actions.saveTask(task)
                .then((taskId) => {
                        const calidationSubmitRoute = this.state.calidationSubmitButtonClick === "btnSave" ? routes.TASK_LIST.path
                            : routes.TASK_CAT_CONFIG_INFO.path.replace(routes.TASK_CAT_CONFIG_INFO.params.taskId, taskId);
                        this.props.history.push(calidationSubmitRoute)
                    }
                )
                .catch(() => this.setState({isLoading: false}));
        } else {
            PushFocusToElement("Name");
        }
    }

    onCalidationCatConfigClick() {
        this.setState({calidationSubmitButtonClick: "btnCatConfig"});
    }

    // ******************************** FORMS *************************************//

    onEditForm(event, form, setField, index) {
        event.preventDefault();

        setField({Id: form.Id, FormName: form.Name, ItemBankId: form.ItemBankId});

        this.props.actions.editForm(index);
    }

    isFormNameUnqiue(newFormName) {
        let nameUnique = true;
        for (let i = 0; i < this.props.taskInfo.Forms.length && nameUnique; i++) {
            if (i !== this.props.editingRowIndex) {
                nameUnique = (this.props.taskInfo.Forms[i].Name !== newFormName);
            }
        }
        return nameUnique;
    }

    onEditFormSubmit({fields, isValid}) {
        if (isValid && !searchBoxEnterKey) {
            let detachedFields = Object.assign({}, fields);
            if (detachedFields.Id === "")
                detachedFields.Id = 0;

            if (!this.isFormNameUnqiue(detachedFields.FormName)) {
                Notify.Error("The form name is already being used.");
                return;
            }

            const form = Object.assign({}, this.props.taskInfo.Forms[this.props.editingRowIndex], {
                Id: detachedFields.Id,
                Name: detachedFields.FormName,
                ItemBankId: detachedFields.ItemBankId
            });

            this.props.actions.updateForm(form, false, 0);
        } else if (searchBoxEnterKey) {
            PushFocusToElement("select-search-box__search");
        } else
            PushFocusToElement("FormName");
    }

    onEditFormCancel(event) {
        event.preventDefault();
        this.props.actions.cancelEditForm();
        this.setState({resetSubmittedCount: this.state.resetSubmittedCount + 1});
    }

    onDeleteForm(event, index) {
        event.preventDefault();
        if (this.props.addRowIndex >= 0 || confirm("Are you sure you want to delete this form?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page."))
            this.props.actions.deleteForm(index);
    }

    onAddForm(event, setField) {
        event.preventDefault();
        this.props.actions.addForm();

        this.setState({resetSubmittedCount: this.state.resetSubmittedCount + 1});

        const formInitial = formInitialState();
        setField({Id: formInitial.Id, FormName: formInitial.Name, ItemBankId: formInitial.ItemBankId});
    }

    onItemBankChange(itemBank, setField) {
        setField({ItemBankId: parseInt(itemBank.value)});
        searchBoxEnterKey = false;
    }

    onReturnTaskList(event) {
        event.preventDefault();
        Notify.Clear();
        this.props.history.push(routes.TASK_LIST.path);
    }

    isTaskUpdateable() {
        if (this.state.taskId === "0")
            return true;

        if (this.props.taskInfo === undefined ||
            this.props.taskInfo.Links === undefined)
            return false;

        const taskLinkList = this.props.taskInfo.Links.filter(f => f.Method === config.DELETE_API_METHOD);
        return (taskLinkList.length > 0);
    }

    shouldDisableSave() {
        return this.props.isEditing;
    }

    createPageDirections() {
        let pageDir = "";
        if (this.state.taskId === "0")
            pageDir = "Create a new task.";
        else {
            pageDir = !this.isTaskUpdateable() ?
                <strong className={"text-danger"}>NOTE - this task is associated with scores. Only make changes that
                    will not affect the overall outcome of previously saved scores.</strong> : "Edit a task.";
        }
        return <p>{pageDir}</p>;
    }

    render() {
        const {taskInfo, taskTypes, itemBanks} = this.props;
        return (
            <div>
                <div className={"clearfix"}>
                    <Button config={{
                        name: "btnReturnTaskList",
                        label: "Return to Task List",
                        onClick: this.onReturnTaskList,
                        btnClass: "light float-right",
                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                    }}/>
                    {this.createPageDirections()}
                </div>
                <div className={"grid-x"}>
                    <div className={"cell center-element medium-6 large-6"}>
                        {
                            taskInfo.Id !== undefined &&
                            taskInfo.Id.toString() === this.state.taskId &&
                            taskTypes !== undefined &&
                            itemBanks !== undefined &&
                            <div>
                                <TaskInfoForm config={{
                                    onTaskSubmit: this.onTaskSubmit,
                                    isLoading: this.state.isLoading,
                                    taskInfo: taskInfo,
                                    shouldDisableSave: this.shouldDisableSave(),
                                    taskTypes: taskTypes
                                }}/>
                                <hr/>
                                <FormsList key={this.state.resetSubmittedCount} config={{
                                    forms: taskInfo.Forms,
                                    isEditing: this.props.isEditing,
                                    editingRowIndex: this.props.editingRowIndex,
                                    addRowIndex: this.props.addRowIndex,
                                    onEditForm: this.onEditForm,
                                    onEditFormSubmit: this.onEditFormSubmit,
                                    onEditFormCancel: this.onEditFormCancel,

                                    onDeleteForm: this.onDeleteForm,
                                    onAddForm: this.onAddForm,
                                    itemBanks: itemBanks,
                                    onItemBankChange: this.onItemBankChange
                                }}/>

                                <div className={"grid-x margin-top-2 _buttonContainer"}>
                                    <div className={"cell center-element medium-6 large-6"}>
                                        <label htmlFor={"btnCatConfig"}
                                               className={"button secondary" + (this.shouldDisableSave() ? " is-disabled" : "")}
                                               onClick={this.onCalidationCatConfigClick}>
                                            <span>CAT Config</span>
                                        </label>
                                    </div>

                                    <div className={"cell text-right medium-6 large-6"}>
                                        <Button config={{
                                            name: "btnCancel",
                                            label: "Cancel",
                                            onClick: this.onReturnTaskList,
                                            btnClass: "secondary button-margin",
                                            btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                        }}/>
                                        <label htmlFor={"btnSave"}
                                               className={"button" + (this.shouldDisableSave() ? " is-disabled" : "")}>
                                            <span>Save</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

TaskInfoPage.propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    taskInfo: PropTypes.object,
    isEditing: PropTypes.bool,
    editingRowIndex: PropTypes.number,
    addRowIndex: PropTypes.number,
    taskTypes: PropTypes.array,
    itemBanks: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        taskInfo: state.task.taskInfo,
        isEditing: state.task.isEditing,
        editingRowIndex: state.task.editingRowIndex,
        addRowIndex: state.task.addRowIndex,
        taskTypes: convertGeneralDataToOptions(state.taskType.taskTypes, generalData.TASK_TYPE_DATA.name),
        itemBanks: state.itemBank.itemBankSelectList,
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        taskActions,
        itemBankActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskInfoPage));
