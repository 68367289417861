import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as studentMgmtActions from "./StudentMgmtAction";
import {studentMgmtInitialState} from "./StudentMgmtReducer";
import * as routes from "../../components/routes/routesConstants";
import StudentMgmtSearchForm from "./StudentMgmtSearchForm";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import StudentMgmtSearchResults from "./StudentMgmtSearchResults";
import {Search} from "../../components/table/Search";
import $ from 'jquery';
import * as loginActions from "../Login/LoginAction";

export class StudentMgmtPage extends Search {
    constructor(props, context) {
        super(props, context, studentMgmtInitialState.studentSearchCriteria);

        this.state = {
            isLoading: false,
            resetSubmittedCount: 0
        };

        this.onEditStudent = this.onEditStudent.bind(this);
        this.onEditStudentSubmit = this.onEditStudentSubmit.bind(this);
        this.onEditStudentCancel = this.onEditStudentCancel.bind(this);
        this.onExportStudents = this.onExportStudents.bind(this);
        this.onImportStudents = this.onImportStudents.bind(this);
    }

    componentDidMount(){
        PushFocusToElement("NameContains");
        this.props.actions.updatePageTitle_BreadCrumb(routes.STUDENT_MGMT);
        if(this.props.studentSearchCriteria !== undefined &&
            this.props.studentSearchCriteria.studentSearchPerformed) {
            this.props.actions.loadStudents(this.props.studentSearchCriteria);
        }
        this.setLoadSearchResults(this.loadStudents);
        this.setProps(this.props.studentSearchCriteria, this.props.studentTotalRecords);

        this.props.actions.clearOutImportMessage();
    }

    componentDidUpdate() {
        this.setProps(this.props.studentSearchCriteria, this.props.studentTotalRecords);
    }

    loadStudents(studentSearchCriteria, clearOutImportResults = true) {
        if(clearOutImportResults)
            this.props.actions.clearOutImportMessage();

        studentSearchCriteria.studentSearchPerformed = true;
        this.props.actions.saveStudentSearchCriteria(studentSearchCriteria);


        this.props.actions.loadStudents(studentSearchCriteria);

        this.cancelOutStudentEdit();
    }

    onEditStudent(event, student, setField) {
        event.preventDefault();

        setField(student);

        this.props.actions.editStudent(student.Id);
        this.props.actions.clearOutImportMessage();
    }

    onEditStudentSubmit({fields, isValid}) {
        if (isValid) {
            this.setState({isLoading: true});

            this.props.actions.updateStudent(fields)
                .then(() => this.setState({isLoading: false, resetSubmittedCount: this.state.resetSubmittedCount + 1}))
                .catch(() => this.setState({isLoading: false}));
        }
    }

    onEditStudentCancel(event) {
        event.preventDefault();
        this.cancelOutStudentEdit();
    }

    cancelOutStudentEdit() {
        this.props.actions.cancelEditStudent();
        this.setState({resetSubmittedCount: this.state.resetSubmittedCount + 1});
    }

    onExportStudents(event) {
        event.preventDefault();
        this.props.actions.clearOutImportMessage();
        this.props.actions.exportStudentData(this.props.studentSearchCriteria);
    }

    onImportStudents(event) {
        event.preventDefault();
        this.props.actions.clearOutImportMessage();
        const files = event.target.files;
        const fileMediaType = event.target.id;
        var fileData = new FormData();
        fileData.append(fileMediaType, files[0]);
        this.props.actions.importStudents(fileData)
            .then(() => this.reloadAfterImport())
            .catch(() => this.setState({isLoading: false}));
    }

    reloadAfterImport() {
        $("#btnImport").val("");
        this.setState({isLoading: false});
        let searchCriteria = Object.assign({}, this.props.studentSearchCriteria);
        this.loadStudents(searchCriteria, false);
    }

    render() {
        let {studentTotalRecords, studentSearchCriteria, studentSearchResults, isEditing, editingStudentId, importResults} = this.props;

        return (
            <div>
                <p>To add students, click &quot;Import&quot; and select your Excel file that contains columns with headers First Name, Last Name, and Student Tracking Id.</p>
                    <StudentMgmtSearchForm config={{
                    onSearchSubmit: this.onSearchSubmit,
                    clearCriteria: this.clearCriteria,
                    isLoading: this.state.isLoading,
                    studentSearchCriteria: studentSearchCriteria,
                    onExportStudents: this.onExportStudents,
                    onImportStudents: this.onImportStudents,
                    importResults: importResults,
                    isEditing: isEditing
                }} />
                {studentSearchCriteria !== undefined &&
                 studentSearchCriteria.studentSearchPerformed &&
                    <div id={"searchResults"}>
                        <hr/>
                        <StudentMgmtSearchResults key={this.state.resetSubmittedCount} config={{
                            studentSearchResults: studentSearchResults,
                            order: this.order,
                            first: this.first,
                            last: this.last,
                            next: this.next,
                            previous: this.previous,
                            studentTotalRecords: studentTotalRecords,
                            currentPage: studentSearchCriteria.Page,
                            studentRecordsPerPage: studentSearchCriteria.RecordsPerPage,
                            isEditing: isEditing,
                            editingStudentId: editingStudentId,
                            onEditStudent: this.onEditStudent,
                            onEditStudentSubmit: this.onEditStudentSubmit,
                            onEditStudentCancel: this.onEditStudentCancel,
                            isLoading: this.state.isLoading
                        }}/>
                    </div>
                }
            </div>
        );
    }
}

StudentMgmtPage.propTypes = {
    studentSearchResults: PropTypes.arrayOf(PropTypes.object),
    studentSearchCriteria: PropTypes.object,
    studentTotalRecords: PropTypes.number,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isEditing: PropTypes.bool,
    editingStudentId: PropTypes.number,
    importResults: PropTypes.object
};

function mapStateToProps(state) {
    return {
        studentSearchResults: state.studentMgmt.studentSearchResults,
        studentSearchCriteria: state.studentMgmt.studentSearchCriteria,
        studentTotalRecords: state.studentMgmt.studentTotalRecords,
        isEditing: state.studentMgmt.isEditing,
        editingStudentId: state.studentMgmt.editingStudentId,
        importResults: state.studentMgmt.importResults
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        studentMgmtActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (StudentMgmtPage));
