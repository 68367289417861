import React from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {Route, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";

/* eslint-disable import/no-named-as-default */
import Header from "./Header";
import Footer from "../../components/layout/Footer";
import * as districtActions from "../District/DistrictAction";
import * as taskTypeActions from "../TaskType/TaskTypeAction";
import * as siteDetailActions from "../SiteDetails/siteDetailAction";
import {loadGeneralData} from "../../components/generalData/loadGeneralData";
import Loader from "../../components/layout/Loader";
import BreadCrumbs from "../../components/layout/BreadCrumbs";
import * as routes from "../../components/routes/routesConstants";
import SubMenu from "../../components/layout/SubMenu";

export class AuthenticatedLayoutPage extends React.Component {
    componentDidMount() {
        loadGeneralData(this.props);
        this.props.actions.loadDistrict();
        this.props.actions.getSiteDetails();
    }

    render() {
        const {component: Component, exact, path, history, routeInfo, isLoading, siteDetail} = this.props;

        let contentContainerClass = "";
        if(routeInfo !== null && routeInfo.display !== routes.DASHBOARD.display)
            contentContainerClass = " content-container";

        let pageColor = "";
        if(routeInfo !== null &&
            routeInfo.section !== undefined &&
            routeInfo.section.display !== "")
            pageColor = "page-" + routeInfo.section.color;

        return (
            <Route {...exact} {...path} render={matchProps => (
                <div className={"grid-y authenticated " + pageColor}>
                    <Loader isLoading={isLoading} />
                    <Header/>
                    <main className={"cell body"}>
                        <div className={"grid-x grid-container" + contentContainerClass}>
                            {
                                routeInfo !== null &&
                                routeInfo.section !== undefined &&
                                routeInfo.section.display !== "" &&
                                <div className={"cell content-section"}>
                                    <BreadCrumbs sectionInfo={routeInfo} history={history}/>
                                </div>
                            }
                            {
                                routeInfo !== null &&
                                routeInfo.section !== undefined &&
                                routeInfo.section.subMenu !== undefined &&
                                routeInfo.section.subMenu !== "" &&
                                <div className={"cell content-section"}>
                                    <SubMenu sectionInfo={routeInfo} history={history}/>
                                </div>
                            }
                            <div className={"cell"}>
                                {
                                    routeInfo !== null &&
                                        routeInfo.display !== "" &&
                                    <h2>{routeInfo.display}</h2>
                                }
                                <Component {...matchProps} />
                            </div>
                        </div>
                    </main>
                    <Footer supportEmail={siteDetail.SupportEmail}/>
                </div>
            )}/>
        )
    }
}

AuthenticatedLayoutPage.propTypes = {
    component: PropTypes.func.isRequired,
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    routeInfo: PropTypes.object,
    actions: PropTypes.object.isRequired,
    districts: PropTypes.array,
    taskTypes: PropTypes.array,
    siteDetail: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired
};

AuthenticatedLayoutPage.defaultProps = {
    isLoading: false
};


function mapStateToProps(state) {
    return {
        routeInfo: state.header.RouteInfo,
        districts: state.district.districts,
        taskTypes: state.taskType.taskTypes,
        siteDetail: state.siteDetail,
        isLoading: state.ajaxStatus.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        districtActions,
        taskTypeActions,
        siteDetailActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticatedLayoutPage));