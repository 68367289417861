import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {withRouter, Link} from "react-router-dom";
import * as routes from "../../components/routes/routesConstants";
import * as headerActions from "./HeaderAction";

export class Header extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.props.actions.getUserIdForHeader();
    }

    render() {
        let userIdentifier = "";
        if(this.props.headerInfo !== undefined)
            userIdentifier = this.props.headerInfo.Email;

        return (
            <header className={"cell header shrink"}>
                <div>
                    <div className={"grid-x grid-container align-middle text-center"}>
                        <div className={"cell medium-8 header-icon"}><h1 className={"show-for-sr"}>New Generation Screener</h1></div>
                        <div className={"cell medium-2"}>{userIdentifier}</div>
                        <div className={"cell medium-2"}><Link to={routes.LOGIN.path}>Logout</Link></div>
                    </div>
                </div>
            </header>
        )
    }
}

Header.propTypes = {
    headerInfo: PropTypes.object,
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        headerInfo: state.header,
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (Header));
