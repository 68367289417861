import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as enrollmentMgmtActions from "./EnrollmentMgmtAction";
import {enrollmentMgmtInitialState} from "./EnrollmentMgmtReducer";
import * as routes from "../../components/routes/routesConstants";
import EnrollmentMgmtSearchForm from "./EnrollmentMgmtSearchForm";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import EnrollmentMgmtSearchResults from "./EnrollmentMgmtSearchResults";
import {Search} from "../../components/table/Search";
import $ from 'jquery';
import {Notify} from "../../components/notification/notify";

export class EnrollmentMgmtPage extends Search {
    constructor(props, context) {
        super(props, context, enrollmentMgmtInitialState.enrollmentSearchCriteria);

        this.state = {
            isLoading: false
        };

        this.onExportEnrollments = this.onExportEnrollments.bind(this);
        this.onImportEnrollments = this.onImportEnrollments.bind(this);
        this.onFiltersChange = this.onFiltersChange.bind(this);
        this.setupDirections = this.setupDirections.bind(this);
    }

    componentDidMount(){
        $(".callout-content").hide();
        PushFocusToElement("AssessmentPeriod");
        this.props.actions.updatePageTitle_BreadCrumb(routes.ENROLL_MGMT);
        if(this.props.enrollmentSearchCriteria !== undefined) {
            this.props.actions.loadEnrollmentFilterData(this.props.enrollmentSearchCriteria);

            if (this.props.enrollmentSearchCriteria !== undefined &&
                this.props.enrollmentSearchCriteria.enrollmentSearchPerformed) {
                this.props.actions.loadEnrollments(this.props.enrollmentSearchCriteria);
            }
        }
        this.setLoadSearchResults(this.loadEnrollments);
        this.setProps(this.props.enrollmentSearchCriteria, this.props.enrollmentTotalRecords);

        this.props.actions.clearOutImportMessage();
    }

    componentDidUpdate() {
        this.setProps(this.props.enrollmentSearchCriteria, this.props.enrollmentTotalRecords);
    }

    convertDistrictToInt(enrollmentSearchCriteria) {
        let crit = Object.assign({}, enrollmentSearchCriteria);
        crit.DistrictId = (crit.DistrictId === "") ? 0 : parseInt(crit.DistrictId);

        return crit;
    }

    loadEnrollments(enrollmentSearchCriteria, clearOutImportResults = true) {
        if(clearOutImportResults)
            this.props.actions.clearOutImportMessage();

        enrollmentSearchCriteria.enrollmentSearchPerformed = true;
        const updatedEnrollmentSearchCriteria = this.convertDistrictToInt(enrollmentSearchCriteria);
        this.props.actions.saveEnrollmentSearchCriteria(updatedEnrollmentSearchCriteria);

        this.props.actions.loadEnrollments(enrollmentSearchCriteria);
    }

    clearCriteria(event, setField) {
        event.preventDefault();
        Notify.Clear();
        const initState = Object.assign({}, enrollmentMgmtInitialState.enrollmentSearchCriteria);

        this.props.actions.loadEnrollmentFilterData(initState);
        setField(initState);

        this.loadEnrollments(initState);
    }

    onFiltersChange(event, setField) {
        event.preventDefault();

        let searchCriteria = Object.assign({}, this.props.enrollmentSearchCriteria);
        const fieldUpdating = event.target.name;
        searchCriteria[fieldUpdating] = event.target.value;

        const updatedSearchCriteria = this.convertDistrictToInt(searchCriteria);

        this.getFilterData(fieldUpdating, updatedSearchCriteria, setField);
    }

    getFilterData(fieldUpdating, updatedSearchCriteria, setField) {
        
        this.props.actions.loadEnrollmentFilterData(updatedSearchCriteria);

        let districtId = updatedSearchCriteria.DistrictId;
        let schoolName = updatedSearchCriteria.SchoolName;

        switch(fieldUpdating) {
            case "DistrictId":
                schoolName = "";
                break;
            case "AssessmentPeriod":
            case "Year":
                districtId = 0;
                schoolName = "";
        }

        const filters = {
            DistrictId: districtId,
            SchoolName: schoolName,
            TeacherName: "",
            Year: updatedSearchCriteria.Year,
            AssessmentPeriod: updatedSearchCriteria.AssessmentPeriod
        };

        setTimeout(() => {
            setField(filters);
        }, 0);

        updatedSearchCriteria.enrollmentSearchPerformed = false;
        updatedSearchCriteria.DistrictId = districtId;
        updatedSearchCriteria.SchoolName = schoolName;
        updatedSearchCriteria.TeacherName = "";
        this.props.actions.saveEnrollmentSearchCriteria(updatedSearchCriteria);
    }

    onExportEnrollments(event) {
        event.preventDefault();
        this.props.actions.clearOutImportMessage();
        this.props.actions.exportEnrollmentData(this.props.enrollmentSearchCriteria);
    }

    onImportEnrollments(event) {
        event.preventDefault();
        this.props.actions.clearOutImportMessage();
        const files = event.target.files;
        const fileMediaType = event.target.id;
        var fileData = new FormData();
        fileData.append(fileMediaType, files[0]);
        this.props.actions.importEnrollments(fileData)
            .then(() => {
                $("#btnImport").val("");
                this.setState({isLoading: false});
                let searchCriteria = Object.assign({}, this.props.enrollmentSearchCriteria);
                this.loadEnrollments(searchCriteria, false);
            })
            .catch(() => this.setState({isLoading: false}));
    }

    setupDirections(event) {
        event.preventDefault();

        let calloutContent = $(".callout-content");
        let calloutLink = $(".callout-link");
        if(calloutContent.is(":visible"))
            calloutLink.text("Show Import Directions");
        else
            calloutLink.text("Hide Import Directions");

        calloutContent.toggle();
    }

    render() {
        let {enrollmentTotalRecords, enrollmentSearchCriteria, enrollmentSearchResults, importResults, districts, schools, teachers} = this.props;

        return (
            <div>
                <div className={"callout light"}>
                    <a href={"#"} className={"callout-link"} onClick={this.setupDirections}>Show Import Directions</a>
                    <div className={"callout-content margin-top-1"}>
                        <p>To enroll students, click Import and select your Excel file that contains columns with headers Student Tracking Id, Year, Grade, AP, Assessment Name,
                            District, School, Teacher, &quot;Remove Enrollment?&quot;.  Alternatively click the Export button after applying your filters and doing a search to see a sample import file.</p>
                        <p>The records imported have the following limitations:</p>
                        <ul>
                            <li>Year - four digit format (yyyy) between 2018 and two years greater than the current year</li>
                            <li>Grade - KG, 1, 2, etc...</li>
                            <li>AP - AP1, AP2, or AP3</li>
                            <li>District - valid district name</li>
                            <li>School and teacher name just need to be less than 50 characters but can be any value needed</li>
                            <li>To update or remove an enrollment, no scores can be associated.  Use Y or Yes in the Remove Enrollment column to have that enrollment deleted.</li>
                        </ul>
                    </div>
                </div>
                <EnrollmentMgmtSearchForm config={{
                    onSearchSubmit: this.onSearchSubmit,
                    clearCriteria: this.clearCriteria,
                    isLoading: this.state.isLoading,
                    enrollmentSearchCriteria: enrollmentSearchCriteria,
                    onExportEnrollments: this.onExportEnrollments,
                    onImportEnrollments: this.onImportEnrollments,
                    importResults: importResults,
                    districts: districts,
                    schools: schools,
                    teachers: teachers,
                    onFiltersChange: this.onFiltersChange
                }} />
                {enrollmentSearchCriteria !== undefined &&
                enrollmentSearchCriteria.enrollmentSearchPerformed &&
                <div id={"searchResults"}>
                    <hr/>
                    <EnrollmentMgmtSearchResults config={{
                        enrollmentSearchResults: enrollmentSearchResults,
                        order: this.order,
                        first: this.first,
                        last: this.last,
                        next: this.next,
                        previous: this.previous,
                        enrollmentTotalRecords: enrollmentTotalRecords,
                        currentPage: enrollmentSearchCriteria.Page,
                        enrollmentRecordsPerPage: enrollmentSearchCriteria.RecordsPerPage,
                        isLoading: this.state.isLoading,
                        selectedAssessmentPeriod: enrollmentSearchCriteria.AssessmentPeriod,
                        selectedYear: enrollmentSearchCriteria.Year
                    }}/>
                </div>
                }
            </div>
        );
    }
}

EnrollmentMgmtPage.propTypes = {
    enrollmentSearchResults: PropTypes.arrayOf(PropTypes.object),
    enrollmentSearchCriteria: PropTypes.object,
    enrollmentTotalRecords: PropTypes.number,
    districts: PropTypes.array,
    schools: PropTypes.array,
    teachers: PropTypes.array,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    importResults: PropTypes.object
};

function mapStateToProps(state) {
    return {
        enrollmentSearchResults: state.enrollmentMgmt.enrollmentSearchResults,
        enrollmentSearchCriteria: state.enrollmentMgmt.enrollmentSearchCriteria,
        enrollmentTotalRecords: state.enrollmentMgmt.enrollmentTotalRecords,
        districts: state.enrollmentMgmt.districts,
        schools: state.enrollmentMgmt.schools,
        teachers: state.enrollmentMgmt.teachers,
        importResults: state.enrollmentMgmt.importResults
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        enrollmentMgmtActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (EnrollmentMgmtPage));
