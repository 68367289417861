export function IsTaskTypeKerningGrid(taskTypeId, taskTypes){
    let isKerningGrid = false;

    if(taskTypeId === undefined || taskTypes === undefined)
        return isKerningGrid;

    const taskType = taskTypes.filter(f => f.value.toString() === taskTypeId.toString());
    if(taskType.length > 0)
        isKerningGrid = taskType[0].name === "Kerning Grid";

    return isKerningGrid;
}