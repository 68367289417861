import { beginAjaxCall, ajaxCallError } from "../AjaxStatus/AjaxStatusActions";
import * as actionTypes from "./ResetPasswordConstants";
import resetPasswordApi from "./ResetPasswordApi";

export function resetPassword(resetPasswordViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return resetPasswordApi.resetPassword(resetPasswordViewModel)
            .then(() => {
                dispatch(resetPasswordSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function resetPasswordSuccess() {
    return { type: actionTypes.RESET_PASSWORD_AJAX_SUCCESS };
}
