let students = null;

function getStudents() {
    if(students === null) {
        students = [
            {
                Id: 1,
                StudentTrackingId: "1",
                FirstName: "John",
                LastName: "Smith",
                IsActive: true,
                Enrollments: [
                    {
                        Id: 1,
                        Year: 2018,
                        AssessmentName: "KG-AP1-1",
                        AssessmentPeriod: "AP1",
                        DistrictId: 37,
                        DistrictName: "Leon",
                        SchoolName: "Godby HS",
                        TeacherName: "Mr. Griffin",
                        Grade: "KG",
                        Status: "Complete"
                    },
                    {
                        Id: 2,
                        Year: 2018,
                        AssessmentName: "KG-AP1-2",
                        AssessmentPeriod: "AP1",
                        DistrictId: 37,
                        DistrictName: "Leon",
                        SchoolName: "Godby HS",
                        TeacherName: "Mr. Griffin",
                        Grade: "KG",
                        Status: "Complete"
                    },
                    {
                        Id: 3,
                        Year: 2018,
                        AssessmentName: "KG-AP3-1",
                        AssessmentPeriod: "AP3",
                        DistrictId: 37,
                        DistrictName: "Leon",
                        SchoolName: "Godby HS",
                        TeacherName: "Mr. Cody",
                        Grade: "KG",
                        Status: "Ready to Begin"
                    }
                ]
            },
            {
                Id: 2,
                StudentTrackingId: "2",
                FirstName: "Jane",
                LastName: "Doe",
                IsActive: true,
                Enrollments: [
                    {
                        Id: 4,
                        Year: 2018,
                        AssessmentName: "KG-AP2-1",
                        AssessmentPeriod: "AP2",
                        DistrictId: 1,
                        DistrictName: "Alachua",
                        SchoolName: "Bob Elem School",
                        TeacherName: "Ms. Smith",
                        Grade: "KG",
                        Status: "In Progress"
                    },
                    {
                        Id: 5,
                        Year: 2019,
                        AssessmentName: "First-AP3-1",
                        AssessmentPeriod: "AP3",
                        DistrictId: 1,
                        DistrictName: "Alachua",
                        SchoolName: "Bob Elem School",
                        TeacherName: "Ms. Doe",
                        Grade: "First",
                        Status: "Ready to Begin"
                    }
                ]
            },
            {
                Id: 3,
                StudentTrackingId: "33432",
                FirstName: "Chuck",
                LastName: "Brown",
                IsActive: true,
                Enrollments: [{
                    Id: 6,
                    Year: 2018,
                    AssessmentName: "KG-AP1-1",
                    AssessmentPeriod: "AP1",
                    DistrictId: 21,
                    DistrictName: "Gilchrist",
                    SchoolName: "Gilchrist Elem School",
                    TeacherName: "Mr. Fletcher",
                    Grade: "KG",
                    Status: "In Progress"
                }]
            },
            {
                Id: 4,
                StudentTrackingId: "421",
                FirstName: "Ansel",
                LastName: "Adams",
                IsActive: true,
                Enrollments: []
            },
            {
                Id: 5,
                StudentTrackingId: "0003",
                FirstName: "Jennifer",
                LastName: "Jones",
                IsActive: true,
                Enrollments: []
            },
            {
                Id: 6,
                StudentTrackingId: "5427",
                FirstName: "Zebra",
                LastName: "Strips",
                IsActive: true,
                Enrollments: []
            },
            {
                Id: 7,
                StudentTrackingId: "7",
                FirstName: "Deshawn",
                LastName: "Johnson",
                IsActive: true,
                Enrollments: []
            },
            {
                Id: 8,
                StudentTrackingId: "8",
                FirstName: "Heather",
                LastName: "Diamond",
                IsActive: true,
                Enrollments: []
            },
            {
                Id: 9,
                StudentTrackingId: "9",
                FirstName: "Stephen",
                LastName: "Griffin",
                IsActive: true,
                Enrollments: []
            },
            {
                Id: 10,
                StudentTrackingId: "010",
                FirstName: "Eva",
                LastName: "Diamond-Jones",
                IsActive: true,
                Enrollments: []
            },
            {
                Id: 11,
                StudentTrackingId: "86534",
                FirstName: "John",
                LastName: "Reynolds",
                IsActive: true,
                Enrollments: []
            },
            {
                Id: 12,
                StudentTrackingId: "12",
                FirstName: "Tom",
                LastName: "Cruise",
                IsActive: true,
                Enrollments: []
            },
            {
                Id: 13,
                StudentTrackingId: "8293",
                FirstName: "Sophia",
                LastName: "Reynolds",
                IsActive: false,
                Enrollments: []
            },
            {
                Id: 14,
                StudentTrackingId: "014",
                FirstName: "Albert",
                LastName: "Einstein",
                IsActive: false,
                Enrollments: []
            },
            {
                Id: 15,
                StudentTrackingId: "00099",
                FirstName: "Frank",
                LastName: "Graham",
                IsActive: false,
                Enrollments: []
            }
        ];
    }

    return students;
}

export {getStudents};

function saveStudents(newStudentList) {
    students = newStudentList;
}

export {saveStudents};

function getEnrollmentData() {
    return getStudents().filter(f => f.Enrollments.length > 0);
}

export {getEnrollmentData};


function getDistrictsWithEnrollments(enrollmentSearchCriteria) {
    let studentsWithEnrollments = getEnrollmentData();
    let districts = [];
    for(let i = 0; i < studentsWithEnrollments.length; i++) {
        for(let j = 0; j < studentsWithEnrollments[i].Enrollments.length; j++) {
            if(studentsWithEnrollments[i].Enrollments[j].Year.toString() === enrollmentSearchCriteria.Year
                && (studentsWithEnrollments[i].Enrollments[j].AssessmentPeriod.toString() === enrollmentSearchCriteria.AssessmentPeriod || enrollmentSearchCriteria.AssessmentPeriod === "")
                && districts.filter(f => f.DistrictName === studentsWithEnrollments[i].Enrollments[j].DistrictName).length === 0) {
                districts.push({
                    Id: studentsWithEnrollments[i].Enrollments[j].DistrictId,
                    DistrictName: studentsWithEnrollments[i].Enrollments[j].DistrictName
                });
            }
        }
    }

    let sortProp ="name";
    let sortDir = 1;

    districts.sort(function (a, b) {
        return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);
    });

    return districts;
}

export {getDistrictsWithEnrollments};

function getSchools(enrollmentSearchCriteria) {
    let studentsWithEnrollments = getEnrollmentData();
    let schools = [];
    for(let i = 0; i < studentsWithEnrollments.length; i++) {
        for(let j = 0; j < studentsWithEnrollments[i].Enrollments.length; j++) {
            if(studentsWithEnrollments[i].Enrollments[j].Year.toString() === enrollmentSearchCriteria.Year
                && (studentsWithEnrollments[i].Enrollments[j].AssessmentPeriod.toString() === enrollmentSearchCriteria.AssessmentPeriod || enrollmentSearchCriteria.AssessmentPeriod === "")
                && studentsWithEnrollments[i].Enrollments[j].DistrictId === enrollmentSearchCriteria.DistrictId
                && schools.filter(f => f.SchoolName === studentsWithEnrollments[i].Enrollments[j].SchoolName).length === 0) {
                schools.push({
                    SchoolName: studentsWithEnrollments[i].Enrollments[j].SchoolName
                });
            }
        }
    }

    let sortProp ="name";
    let sortDir = 1;

    schools.sort(function (a, b) {
        return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);
    });

    return schools;
}

export {getSchools};

function getTeachers(enrollmentSearchCriteria) {
    let studentsWithEnrollments = getEnrollmentData();
    let teachers = [];
    for(let i = 0; i < studentsWithEnrollments.length; i++) {
        for(let j = 0; j < studentsWithEnrollments[i].Enrollments.length; j++) {
            if(studentsWithEnrollments[i].Enrollments[j].Year.toString() === enrollmentSearchCriteria.Year
                && (studentsWithEnrollments[i].Enrollments[j].AssessmentPeriod.toString() === enrollmentSearchCriteria.AssessmentPeriod || enrollmentSearchCriteria.AssessmentPeriod === "")
                && studentsWithEnrollments[i].Enrollments[j].DistrictId === enrollmentSearchCriteria.DistrictId
                && studentsWithEnrollments[i].Enrollments[j].SchoolName === enrollmentSearchCriteria.SchoolName
                && teachers.filter(f => f.TeacherName === studentsWithEnrollments[i].Enrollments[j].TeacherName).length === 0) {
                teachers.push({
                    TeacherName: studentsWithEnrollments[i].Enrollments[j].TeacherName
                });
            }
        }
    }

    let sortProp ="name";
    let sortDir = 1;

    teachers.sort(function (a, b) {
        return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);
    });

    return teachers;
}

export {getTeachers};