import React from 'react';
import ReactPlayer from "react-player";
import PropTypes from "prop-types";
import ButtonIcon from "../buttons/buttonIcon";
import * as buttonConstants from "../buttons/buttonConstants";

class MediaPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            url: null
        }
    }

    playPause = () => {
        this.setState({ playing: !this.state.playing, url: this.props.config.url })
    };

    onPlay = () => {
        this.setState({ playing: true })
    };

    onPause = () => {
        this.setState({ playing: false })
    };

    onEnded = () => {
        this.setState({ playing: false })
    };

    render() {
        return (
            <div className={"mediaPlayer"}>
                {
                    !this.state.playing &&
                    <ButtonIcon config={{
                        name: "btnPlay",
                        label: "Play",
                        onClick: this.playPause,
                        iconType: buttonConstants.ICON_PLAY
                    }}/>
                }
                {
                    this.state.playing &&
                    <ButtonIcon config={{
                        name: "btnPause",
                        label: "Pause",
                        onClick: this.playPause,
                        iconType: buttonConstants.ICON_PAUSE
                    }}/>
                }
                <ReactPlayer
                    className={"react-player"}
                    url={this.state.url}
                    controls={false}
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                    onEnded={this.onEnded}
                    playing={this.state.playing}
                    width={"100%"}
                    height={"100%"}
                />
            </div>
        )
    }
}

MediaPlayer.propTypes = {
    config: PropTypes.shape({
        url: PropTypes.string.isRequired
    })
};

export default MediaPlayer;