import React from "react";
import PropTypes from "prop-types";

const FileUploadButton = ({ name, label, onChange, disabled }) => {
    let labelClass = "button";
    if(disabled)
        labelClass += " is-disabled";
    return (
        <div>
                <input type="file"
                       name={name}
                       id={name}
                       onChange={onChange}
                       disabled={disabled}
                />
                <label htmlFor={name} className={labelClass}>
                    <span>{label}</span>
                </label>
        </div>
    );
};

FileUploadButton.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default FileUploadButton;