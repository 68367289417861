import React from "react";
import PropTypes from "prop-types";
import ButtonIcon from "../../components/buttons/buttonIcon";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import ButtonBar from "../../components/buttons/buttonBar";
import * as config from "../../constants/config";

const AssessmentListSearchRow = ({ assessment, onEditAssessment, onDeleteAssessment, onMcatGroupEdit }) => {
    const assessmentLinkList = assessment.Links.filter(f => f.Method === config.DELETE_API_METHOD);
    let assessmentLinkInfo = {};
    if(assessmentLinkList.length > 0)
        assessmentLinkInfo = assessmentLinkList[0];

    return (
        <tr>
            <td className={"text-center"}>{assessment.AssessmentType}</td>
            <td>{assessment.Name}</td>
            <td className={"text-center"}>{assessment.DateCreated}</td>
            <td className={"text-center"}>{assessment.DateModified}</td>
            <td className={"text-center"}>{assessment.NumberOfTasks}</td>
            <td className={"text-center"}>{assessment.McatTaskGroupCount}</td>
            <td className={"text-center"}>
                <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_CENTER}>
                    <ButtonIcon config={{
                        name: "btnMcat",
                        label: "MCAT",
                        disabled: (assessment.McatTaskGroupCount === 0),
                        onClick: (event) => onMcatGroupEdit(event, assessment.Id),
                        iconType: buttonConstants.ICON_CAT
                    }} />
                    <ButtonIcon config={{
                        name: "btnEdit",
                        label: "Edit",
                        onClick: (event) => onEditAssessment(event, assessment.Id),
                        iconType: buttonConstants.ICON_EDIT
                    }} />
                    <ButtonIcon config={{
                        name: "btnDelete",
                        label: (assessmentLinkList.length === 0) ? "Delete not enabled due to being associated with a score" : "Delete",
                        disabled: (assessmentLinkList.length === 0),
                        onClick: (event) => onDeleteAssessment(event, assessmentLinkInfo),
                        iconType: buttonConstants.ICON_DELETE
                    }} />
                </ButtonBar>
            </td>
        </tr>
    );
};

AssessmentListSearchRow.propTypes = {
    assessment: PropTypes.object.isRequired,
    onEditAssessment: PropTypes.func.isRequired,
    onDeleteAssessment: PropTypes.func.isRequired,
    onMcatGroupEdit: PropTypes.func.isRequired
};

export default AssessmentListSearchRow;