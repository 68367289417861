import {beginAjaxCall, ajaxCallError} from "../AjaxStatus/AjaxStatusActions";
import itemApi from "./ItemApi";
import * as actionTypes from "./ItemConstants";
import {itemDefaultState} from "./ItemReducer";

export function loadItemsSuccess(data) {
    return {type: actionTypes.LOAD_ITEMS_AJAX_SUCCESS, data};
}

export function loadItems(itemFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return itemApi.getItems(itemFilterCriteria)
            .then(data => {
                dispatch(loadItemsSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function loadItemSelectListSuccess(data) {
    return {type: actionTypes.LOAD_ITEM_SELECT_LIST_AJAX_SUCCESS, data};
}

export function loadItemSelectList() {
    return dispatch => {
        dispatch(beginAjaxCall());

        let itemFilterCriteria = itemDefaultState().itemSearchCriteria;
        itemFilterCriteria.Fields = "Id,Name";

        return itemApi.getItems(itemFilterCriteria)
            .then(data => {
                dispatch(loadItemSelectListSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function saveItemSearchCriteria(criteria) {
    return {type: actionTypes.SAVE_ITEM_SEARCH_CRITERIA, criteria};
}

export function addItem() {
    return {type: actionTypes.ADD_ITEM};
}

export function cancelItem() {
    return {type: actionTypes.CANCEL_ITEM};
}

export function editItemSuccess(item) {
    return {type: actionTypes.EDIT_ITEM_AJAX_SUCCESS, data: item};
}

export function editItem(itemId) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return itemApi.getItem(itemId)
            .then((item) => {
                dispatch(editItemSuccess(item));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function updateItem(item) {
    return {type: actionTypes.UPDATE_ITEM, data: item};
}

export function saveItemSuccess() {
    return {type: actionTypes.SAVE_ITEM_AJAX_SUCCESS};
}

export function saveItem(item) {
    return dispatch => {
        dispatch(beginAjaxCall());

        if (item.Id === 0) {
            return itemApi.addItem(item)
                .then(() => {
                    dispatch(saveItemSuccess());
                })
                .catch(error => {
                        dispatch(ajaxCallError(error));
                        throw (error);
                    }
                );
        } else {
            return itemApi.updateItem(item)
                .then(() => {
                    dispatch(saveItemSuccess());
                })
                .catch(error => {
                        dispatch(ajaxCallError(error));
                        throw (error);
                    }
                );
        }
    };
}

export function deleteItemSuccess() {
    return {type: actionTypes.DELETE_ITEM_AJAX_SUCCESS};
}

export function deleteItem(deleteLinkInfo) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return itemApi.deleteItem(deleteLinkInfo)
            .then(() => {
                dispatch(deleteItemSuccess());
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            );
    };
}

export function editOption(index) {
    return {type: actionTypes.EDIT_OPTION, data: index};
}

export function cancelEditOption() {
    return {type: actionTypes.CANCEL_EDIT_OPTION};
}

export function updateOption(option, isEditing, editingRowIndex) {
    return {
        type: actionTypes.UPDATE_OPTION,
        data: {option: option, isEditing: isEditing, editingRowIndex: editingRowIndex}
    };
}

export function addOption() {
    return {type: actionTypes.ADD_OPTION};
}

export function deleteOption(index) {
    return {type: actionTypes.DELETE_OPTION, data: index};
}