import $ from 'jquery';
import * as config from "../../constants/config";
import LocalStorage from "../../components/localStorage/access";
import {
    getItemBanksData,
    saveItemBanksData,
    getItemsArrayForItemBank
} from "../../mockData/assessmentData";
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";
import convertObjWithSpecificFields from "../../mockData/convertObjWithSpecificFields";
import {mockRejectApiCall, mockResolveApiCall, sendRequest} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerItemBankApi {
    static getItemBanks(itemBankSearchCriteria) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}itemBanks?${$.param(itemBankSearchCriteria)}`,
                RequestTypes.GET,
            ));
    }

    static getItemBank(itemBankId) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}itemBanks/${itemBankId}`,
                RequestTypes.GET,
            ));
    }

    static deleteItemBank(linkInfo) {
        return sendRequest(
            AjaxRequestOptions(
                linkInfo.Href,
                RequestTypes.DELETE,
                ApiSuccessMessages.ITEM_BANK_DELETED
            ));
    }

    static addItemBank(itemBank) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}itemBanks`,
                RequestTypes.POST,
                JSON.stringify(itemBank),
                ApiSuccessMessages.ITEM_BANK_ADDED
            )
        );
    }

    static updateItemBank(itemBank) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}itemBanks`,
                RequestTypes.PUT,
                JSON.stringify(itemBank),
                ApiSuccessMessages.ITEM_BANK_UPDATED
            )
        );
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockItemBankApi {
    static getNewId() {
        const itemBanks = getItemBanksData();
        let itemBankId = 0;
        itemBanks.map(itemBank => {
            if (itemBank.Id > itemBankId)
                itemBankId = itemBank.Id;
        });

        return (itemBankId + 1);
    }

    static getItemBanks(itemBankSearchCriteria) {
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            let itemBanks = getItemBanksData();

            if (itemBankSearchCriteria.NameContains !== "")
                itemBanks = itemBanks.filter(itemBank => itemBank.Name.includes(itemBankSearchCriteria.NameContains));

            if (itemBankSearchCriteria.ItemNameContains !== "")
                itemBanks = itemBanks.filter(itemBank => itemBank.Items.filter(item => item.Name.includes(itemBankSearchCriteria.ItemNameContains)).length > 0);

            let sortProp = itemBankSearchCriteria.OrderByColumn;
            let sortDir = (itemBankSearchCriteria.OrderDescending) ? -1 : 1;

            itemBanks.sort(function (a, b) {
                return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);
            });

            const skip = (itemBankSearchCriteria.Page - 1) * itemBankSearchCriteria.RecordsPerPage;
            const take = itemBankSearchCriteria.RecordsPerPage + skip;

            let itemBanksReturned = (itemBankSearchCriteria.Fields !== "")
                ? convertObjWithSpecificFields(itemBankSearchCriteria.Fields, itemBanks)
                : itemBanks.slice(skip, take);

            return mockResolveApiCall(Object.assign({}, {
                Data: itemBanksReturned,
                TotalRecords: itemBanks.length
            }));
        }
    }

    static getItemBank(itemBankId) {
        let itemBanks = getItemBanksData();
        const itemBankRecord = itemBanks.filter(i => i.Id === itemBankId);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (itemBankRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the item bank record.");
        else {
            return mockResolveApiCall(itemBankRecord[0]);
        }
    }

    static deleteItemBank(linkInfo) {
        let itemBanks = getItemBanksData();
        const itemBankRecord = itemBanks.filter(i => i.Id === parseInt(linkInfo.Href));
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (itemBankRecord.length === 0)
            new ApiUtility({
                status: 400,
                responseText: "There was an error while attempting to retrieve the item bank record."
            }, reject).errorResponseHandler();
        else {
            const otherItemBanks = itemBanks.filter(i => i.Id !== parseInt(linkInfo.Href));
            saveItemBanksData([...otherItemBanks]);
            return mockResolveApiCall(ApiSuccessMessages.ITEM_BANK_DELETED);
        }
    }

    static addItemBank(itemBank) {
        let itemBanks = getItemBanksData();
        const otherItems = itemBanks.filter(i => i.Id !== itemBank.Id);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (otherItems.filter(f => f.Name === itemBank.Name).length > 0)
            return mockRejectApiCall("The item bank name entered is already associated with another item bank.");
        else {
            const newId = this.getNewId();
            const addItemBank = {
                Id: newId,
                Name: itemBank.Name,
                ItemIds: itemBank.ItemIds,
                Items: getItemsArrayForItemBank(itemBank.ItemIds),
                NumberOfItems: itemBank.ItemIds.length,
                DateCreated: "02/14/2018",
                DateModified: "02/14/2018",
                Links: [{
                    Href: newId.toString(),
                    Rel: "delete_item_bank",
                    Method: "DELETE"
                }]
            };
            saveItemBanksData([...otherItems, addItemBank]);
            return mockResolveApiCall(ApiSuccessMessages.ITEM_BANK_ADDED);
        }
    }

    static updateItemBank(itemBank) {
        let itemBanks = getItemBanksData();
        const otherItemBanks = itemBanks.filter(i => i.Id !== itemBank.Id);
        const itemRecord = itemBanks.filter(i => i.Id === itemBank.Id);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (itemRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the item bank record.");
        else if (otherItemBanks.filter(f => f.Name === itemBank.Name).length > 0)
            return mockRejectApiCall("The item bank name entered is already associated with another item bank.");
        else {
            const editItemBank = {
                Id: itemBank.Id,
                Name: itemBank.Name,
                ItemIds: itemBank.ItemIds,
                Items: getItemsArrayForItemBank(itemBank.ItemIds),
                NumberOfItems: itemBank.ItemIds.length,
                DateCreated: itemBank.DateCreated,
                DateModified: itemBank.DateModified,
                Links: itemRecord[0].Links
            };
            saveItemBanksData([...otherItemBanks, editItemBank]);
            return mockResolveApiCall(ApiSuccessMessages.ITEM_BANK_UPDATED);
        }
    }
}

const ItemBankApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerItemBankApi : MockItemBankApi;
export default ItemBankApi;