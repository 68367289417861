export const STUDENT_MGMT_SECTION = {
    display: "Student Management",
    color: "blue"
};
export const ASSESSMENT_MGMT_SECTION = {
    display: "Assessment Management",
    color: "green",
    subMenu : "ASSESSMENT_MGMT_SECTION_SUBMENU"
};
export const ENROLL_MGMT_SECTION = {
    display: "Enrollment Management",
    color: "orange"
};
export const USER_MGMT_SECTION = {
    display: "User Management",
    color: "red"
};
export const REPORTING_SECTION = {
    display: "Reporting",
    color: "purple"
};

export const SLAB_SECTION = {
    display: "Soapbox Labs",
    color: "yellow"
};

export const ITEM_POOL_CONFIGURATION_SECTION = {
    display: "CAT Data Management",
    color: "green",
};

export const LOGIN = {
    path: "/",
    display: "Log in",
    browserTabTitle: ""
};
export const PAGE_NOT_FOUND = {
    path: "/",
    display: "Page Not Found!",
};
export const DASHBOARD = {
    path: "/Dashboard",
    display: "",
    browserTabTitle: "Dashboard"
};
export const FORGOT_PASSWORD = {
    path: "/forgot-password",
    display: "Forgot your password?",
};

export const RESET_PASSWORD_WITHOUT_GUID = {
    path: "/reset-password",
    display: "Reset your password?",
};

export const RESET_PASSWORD = {
    path: "/reset-password/:resetPasswordGuid",
    params: {
        resetPasswordGuid: ":resetPasswordGuid"
    },
    display: "Reset your password?",
};

export const REGISTER = {
    path: "/register",
    display: "Register",
};
export const REGISTRATION_COMPLETE = {
    path: "/registrationComplete",
    display: "Registration Complete",
};
export const STUDENT_MGMT = {
    path: "/student-mgmt/search",
    display: "Student List",
    section: STUDENT_MGMT_SECTION
};
export const ASSESSMENT_MGMT = {
    path: "/assessment-mgmt/search",
    display: "Assessment List",
    section: ASSESSMENT_MGMT_SECTION,
    subMenuDisplay: "Assessments"
};
export const ASSESSMENT_INFO = {
    path: "/assessment-mgmt/info/:assessmentId",
    params: {
        assessmentId: ":assessmentId"
    },
    display: "Assessment Information",
    section: ASSESSMENT_MGMT_SECTION
};
export const ASSESSMENT_MCAT_CONFIG_INFO = {
    path: "/assessment-mgmt/mcatConfig/:assessmentId",
    params: {
        assessmentId: ":assessmentId"
    },
    display: "MCAT Group Configuration",
    section: ASSESSMENT_MGMT_SECTION
}
export const ENROLL_MGMT = {
    path: "/enroll-mgmt/search",
    display: "Enrollment List",
    section: ENROLL_MGMT_SECTION
};
export const REPORTING = {
    path: "/reporting/search",
    display: "Reporting",
    section: REPORTING_SECTION
};
export const USER_MGMT = {
    path: "/user-mgmt/search",
    display: "User List",
    section: USER_MGMT_SECTION
};
export const SLAB = {
    path: "/slab/search",
    display: "Soapbox Labs",
    section: SLAB_SECTION
};
export const ITEM_POOL_MGMT = {
    path: "/item-pool-configuration/search",
    display: "Item Pool Configuration List",
    section: ITEM_POOL_CONFIGURATION_SECTION,
    subMenuDisplay: "Item Pool Configurations"
};
export const ITEM_POOL_INFO = {
    path: "/item-pool-configuration/info/:itemPoolId",
    params: {
       itemPoolId: ":itemPoolId"
    },
    display: "Item Pool Configuration Information",
    section: ITEM_POOL_CONFIGURATION_SECTION
};

export const TASK_LIST = {
    path: "/tasks/search",
    display: "Task List",
    section: ASSESSMENT_MGMT_SECTION,
    subMenuDisplay: "Tasks"
};

export const TASK_INFO = {
    path: "/tasks/info/:taskId",
    params: {
        taskId: ":taskId"
    },
    display: "Task Information",
    section: ASSESSMENT_MGMT_SECTION
};

export const TASK_CAT_CONFIG_INFO = {
    path: "/tasks/catConfigInfo/:taskId",
    params: {
        taskId: ":taskId"
    },
    display: "CAT Configuration",
    section: ASSESSMENT_MGMT_SECTION
};

export const ITEM_BANK_LIST = {
    path: "/itemBanks/search",
    display: "Item Bank List",
    section: ASSESSMENT_MGMT_SECTION,
    subMenuDisplay: "Item Banks"
};
export const ITEM_BANK_INFO = {
    path: "/itemBanks/info/:itemBankId",
    params: {
        itemBankId: ":itemBankId"
    },
    display: "Item Bank Information",
    section: ASSESSMENT_MGMT_SECTION
};
export const ITEM_LIST = {
    path: "/items/search",
    display: "Item List",
    section: ASSESSMENT_MGMT_SECTION,
    subMenuDisplay: "Items"
};

export const ITEM_INFO = {
    path: "/items/info/:itemId",
    params: {
        itemId: ":itemId"
    },
    display: "Item Information",
    section: ASSESSMENT_MGMT_SECTION
};

export const ASSESSMENT_MGMT_SECTION_SUBMENU = [ASSESSMENT_MGMT, TASK_LIST, ITEM_BANK_LIST, ITEM_LIST];