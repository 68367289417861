import queryString from "querystring";

function createRedirectUrl(searchStr, hash) {
    let url = "";

    if(searchStr && searchStr.charAt(0) === "?")
        searchStr = searchStr.substr(1);

    let parsed = queryString.parse(searchStr);

    if(parsed.redirectUrl) {
        url = parsed.redirectUrl;
        if(hash)
            url += hash;
    }

    return url;
}
export {createRedirectUrl};