import {beginAjaxCall, ajaxCallError} from "../AjaxStatus/AjaxStatusActions";
import enrollmentMgmtApi from "./EnrollmentMgmtApi";
import * as actionTypes from "./EnrollmentMgmtConstants";

export function loadEnrollmentsSuccess(data) {
    return {type: actionTypes.LOAD_ENROLLMENTS_AJAX_SUCCESS, data};
}

export function loadEnrollments(enrollmentFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return enrollmentMgmtApi.getEnrollments(enrollmentFilterCriteria)
            .then(data => {
                dispatch(loadEnrollmentsSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function getEnrollmentFilterDataSuccess(data) {
    return {type: actionTypes.LOAD_ENROLLMENT_FILTER_DATA_AJAX_SUCCESS, data};
}

export function loadEnrollmentFilterData(enrollmentFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return enrollmentMgmtApi.getEnrollmentFilterData(enrollmentFilterCriteria)
            .then(data => {
                dispatch(getEnrollmentFilterDataSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function saveEnrollmentSearchCriteria(criteria) {
    return {type: actionTypes.SAVE_ENROLLMENT_SEARCH_CRITERIA, criteria};
}

export function exportEnrollmentData(enrollmentFilterCriteria) {
    return () => {
        enrollmentMgmtApi.exportEnrollmentData(enrollmentFilterCriteria);
    };
}

export function importEnrollmentsSuccess(importResults) {
    return {type: actionTypes.IMPORT_ENROLLMENTS_AJAX_SUCCESS, importResults};
}

export function importEnrollments(fileData) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return enrollmentMgmtApi.importEnrollments(fileData)
            .then((importResults) => {
                dispatch(importEnrollmentsSuccess(importResults));
            })
            .catch(error => {
                    if (error.Message)
                        dispatch(importEnrollmentsSuccess({Message: error.Message}));
                    else {
                        dispatch(ajaxCallError(error));
                        throw(error);
                    }
                }
            );
    };
}

export function clearOutImportMessage() {
    var importResults = {Message: ""};
    return {type: actionTypes.CLEAR_OUT_ENROLLMENT_IMPORT_MESSAGE, importResults};
}
