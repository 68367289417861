import {
    isArrayNullOrEmpty,
    filterArrayBySecondArray,
    isNullOrUndefined
} from "../../components/common/commonUtilities";
import {CIRTAdaptingModelValue} from "../../constants/catModelConstants";

export function generateItemSelectionOptions(catModelType) {
    const cirtAdaptingValue = 7;
    const allItemSelections =  [
        { value: 1, name: "Point Fisher Information", instructions: "The item with the highest Fisher Information for the computed ability estimate is selected and administered." },
        { value: 2, name: "a-Stratification", instructions: "The a-stratified multistage algorithm divides the item pool into K strata based on sorted values of a, where a fixed number of items is administered per stratum starting with the lowest discriminating strata." },
        { value: 3, name: "a-Stratification with b-Blocking", instructions: "A refinement of the a-stratified algorithm where items are divided into blocks based on the b parameter." },
        { value: 4, name: "a-Stratification with content blocking", instructions: "A variation of the a-stratified algorithm where items are divided into blocks based on the content codes within the item pool." },
        { value: 5, name: "Closest b-parameter Proximity", instructions: "The item with the closest difficulty (b) parameter to the computed ability estimate is selected and administered next." },
        { value: 6, name: "Progressive Method", instructions: "An extension of PFI method that adds a random component whose contribution is more prominent at the beginning of administration and diminishes over administration." },
        { value: 7, name: "CIRT Adapting", instructions: "Custom selection algorithm for CIRT adapting model." }
    ];

    return catModelType === CIRTAdaptingModelValue
        ? allItemSelections.filter(f => f.value === cirtAdaptingValue)
        : allItemSelections.filter(f => f.value !== cirtAdaptingValue);
}

export function getItemSelectionAlgorithm(itemSelection) {
    let itemSelectionOptions = generateItemSelectionOptions(true);
    itemSelection = isNaN(itemSelection) || itemSelection === "" ? 0 : itemSelection;
    return  itemSelectionOptions.find(itemAlgorithm=>itemAlgorithm.value === parseInt(itemSelection));
}

export const ItemPoolConfig = {
    accuracy: "1", speed: "2"
};

export function generateStopCriteriaOptions(stopCriteria, selectedOptionId) {
    let stopCriteriaOptions = [
        { value: 4, name: "Delta theta Minimum Threshold"},
        { value: 7, name: "Fixed Length" },
        { value: 8, name: "Master Stop Criteria" },
        { value: 2, name: "Maximum Number of Items Administered"},
        { value: 3, name: "Minimum Item Information"},
        { value: 6, name: "Minimum Number of Items Administered" },
        { value: 1, name: "Standard Error Threshold"},
        { value: 5, name: "|theta-beta| >= Threshold"},
        { value: 9, name: "Consecutive Incorrect"},
        { value: 10, name: "Consecutive Correct"},
    ];

    if (isArrayNullOrEmpty(stopCriteria))
        return stopCriteriaOptions;

    let filteredOptions = filterArrayBySecondArray(stopCriteriaOptions, "value", stopCriteria, "StopCriteriaType", false);
    if(isNullOrUndefined(selectedOptionId))
        return filteredOptions;

    const selectedOption = stopCriteriaOptions.find(s=>s.value === selectedOptionId);
    if (!isNullOrUndefined(selectedOption))
        return [...filteredOptions, selectedOption];

    return filteredOptions;
}

export const MasteryStopCriteriaOption = { value: 8, name: "Master Stop Criteria" };
