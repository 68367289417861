import * as actionTypes from "./LoginConstants";
import * as logoff from "../AuthenticatedLayout/HeaderConstants";

export const loginInitialState = {
    isLoggedIn: false,
    redirectUrl: "",
    isAdmin: false,
    role: ""
};

export function  loginReducer(state = loginInitialState, action) {
    switch(action.type){
        case actionTypes.LOGIN_AJAX_SUCCESS:
            return Object.assign({}, state, { isLoggedIn: action.loginData.isLoggedIn, isAdmin: action.loginData.isAdmin, role: action.loginData.role });
        case actionTypes.LOGIN_SET_RETURNURL:
            return Object.assign({}, state, {redirectUrl: action.redirectUrl});
        case logoff.LOGOFF_SUCCESS:
            return Object.assign({}, state, loginInitialState);
        default:
            return state;
    }
}