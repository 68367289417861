export const LOAD_ENROLLMENTS_AJAX_SUCCESS = "LOAD_ENROLLMENTS_AJAX_SUCCESS";
export const LOAD_ENROLLMENT_FILTER_DATA_AJAX_SUCCESS = "LOAD_ENROLLMENT_FILTER_DATA_AJAX_SUCCESS";
export const SAVE_ENROLLMENT_SEARCH_CRITERIA = "SAVE_ENROLLMENT_SEARCH_CRITERIA";
export const IMPORT_ENROLLMENTS_AJAX_SUCCESS = "IMPORT_ENROLLMENTS_AJAX_SUCCESS";
export const CLEAR_OUT_ENROLLMENT_IMPORT_MESSAGE = "CLEAR_OUT_ENROLLMENT_IMPORT_MESSAGE";
export const ENROLLMENT_START_YEAR = 2018;

export function generateYearOptions() {
    const startYear = ENROLLMENT_START_YEAR;
    const endYear = (new Date()).getFullYear() + 2;
    let years = [];
    for(let i = startYear; i <= endYear; i++) {
        years.push({ value: i.toString(), name: i.toString()});
    }

    return years;
}

export function generateApOptions() {
    return [
        { value: "1", name: "AP1" },
        { value: "2", name: "AP2" },
        { value: "3", name: "AP3" }
    ];
}