import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as itemPoolActions from "./ItemPoolConfigurationActions";
import {itemPoolInitialState} from "./ItemPoolConfigurationReducer";
import * as routes from "../../components/routes/routesConstants";
import ItemPoolSearchForm from "./ItemPoolSearchForm";
import ItemPoolSearchResults from "./ItemPoolListSearchResults";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import {Search} from "../../components/table/Search";
import * as loginActions from "../Login/LoginAction";
import localStorage from "../../components/localStorage/access";

export class ItemPoolConfigurationPage extends Search {
    constructor(props, context) {
        super(props, context, itemPoolInitialState.itemPoolSearchCriteria);

        this.state = {
            isLoading: false
        };

        this.onAddItemPool = this.onAddItemPool.bind(this);
        this.onEditItemPool = this.onEditItemPool.bind(this);
        this.onDeleteItemPool = this.onDeleteItemPool.bind(this);
    }

    componentDidMount(){
        PushFocusToElement("NameContains");
        this.props.actions.updatePageTitle_BreadCrumb(routes.ITEM_POOL_MGMT);
        if(this.props.itemPoolSearchCriteria !== undefined &&
            this.props.itemPoolSearchCriteria.itemPoolSearchPerformed) {
            this.props.actions.loadItemPools(this.props.itemPoolSearchCriteria);
        }

        this.setLoadSearchResults(this.loadItemPools);
        this.setProps(this.props.itemPoolSearchCriteria, this.props.itemPoolTotalRecords);
    }

    componentDidUpdate() {
        this.setProps(this.props.itemPoolSearchCriteria, this.props.itemPoolTotalRecords);
    }

    loadItemPools(itemPoolSearchCriteria) {
        itemPoolSearchCriteria.itemPoolSearchPerformed = true;
        this.props.actions.saveItemPoolSearchCriteria(itemPoolSearchCriteria);

        this.props.actions.loadItemPools(itemPoolSearchCriteria);
    }

    onAddItemPool(event) {
        event.preventDefault();

        this.props.history.push(routes.ITEM_POOL_INFO.path.replace(routes.ITEM_POOL_INFO.params.itemPoolId, 0));
    }

    onEditItemPool(event, itemPoolId) {
        event.preventDefault();

        this.props.history.push(routes.ITEM_POOL_INFO.path.replace(routes.ITEM_POOL_INFO.params.itemPoolId, itemPoolId));
    }

    onDeleteItemPool(event, deleteLinkInfo) {
        event.preventDefault();

        if(confirm("Are you sure you want to delete this item pool?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page."))
        {
            this.props.actions.deleteItemPool(deleteLinkInfo)
                .then(() => this.reloadAfterDelete())
        }
    }

    reloadAfterDelete() {
        let criteria = Object.assign({}, this.props.itemPoolSearchCriteria);
        criteria.Page = 1;

        this.loadItemPools(criteria);
    }

    render() {
        let {itemPoolTotalRecords, itemPoolSearchCriteria, itemPoolSearchResults } = this.props;

        return (
            <div>
                <ItemPoolSearchForm config={{
                    onSearchSubmit: this.onSearchSubmit,
                    clearCriteria: this.clearCriteria,
                    isLoading: this.state.isLoading,
                    itemPoolSearchCriteria: itemPoolSearchCriteria,
                    onAddItemPool: this.onAddItemPool
                }} />
                {itemPoolSearchCriteria !== undefined &&
                itemPoolSearchCriteria.itemPoolSearchPerformed &&
                <div id={"searchResults"}>
                    <hr/>
                    <ItemPoolSearchResults config={{
                        itemPoolSearchResults: itemPoolSearchResults,
                        order: this.order,
                        first: this.first,
                        last: this.last,
                        next: this.next,
                        previous: this.previous,
                        itemPoolTotalRecords: itemPoolTotalRecords,
                        currentPage: itemPoolSearchCriteria.Page,
                        itemPoolRecordsPerPage: itemPoolSearchCriteria.RecordsPerPage,
                        onEditItemPool: this.onEditItemPool,
                        onDeleteItemPool: this.onDeleteItemPool
                    }}/>
                </div>
                }
            </div>
        );
    }
}

ItemPoolConfigurationPage.propTypes = {
    itemPoolSearchResults: PropTypes.arrayOf(PropTypes.object),
    itemPoolSearchCriteria: PropTypes.object,
    itemPoolTotalRecords: PropTypes.number,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        itemPoolSearchResults: state.itemPool.itemPoolSearchResults,
        itemPoolSearchCriteria: state.itemPool.itemPoolSearchCriteria,
        itemPoolTotalRecords: state.itemPool.itemPoolTotalRecords
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        itemPoolActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (ItemPoolConfigurationPage));
