import React from "react";
import PropTypes from "prop-types";
import {FormValidation} from "calidation";
import FormEditForm from "./FormEditForm";
import {createErrorList, hasErrors} from "../../components/form/createErrorList";
import FormRow from "./FormRow";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import Button from "../../components/buttons/button";

const FormsList = ({ config }) => {
    const numberOfColumns = "3";

    let formConfig = {
        FormName: {
            isRequired: 'Form text is required!'
        },
        ItemBankId: {
             isRequired: 'Item bank is required!'
         },
        Id: {}
    };

    return (
        <FormValidation onSubmit={config.onEditFormSubmit} config={formConfig} id={"formsForm"}>
            {({ fields, errors, submitted, setField }) => (
                <div>
                    <div className={"grid-x"}>
                        <div className={"cell auto"}>
                            <h2>Forms</h2>
                        </div>
                        <div className={"cell text-right medium-4 large-4"}>
                            <Button config={{
                                disabled: config.isEditing,
                                name: "btnAddForm",
                                label: "Add Form",
                                onClick: (event) => config.onAddForm(event, setField),
                                btnClass: "secondary",
                                btnType: buttonConstants.BUTTON_TYPE_BUTTON
                            }}/>
                        </div>
                    </div>
                    {submitted && hasErrors(errors) &&
                    <div className="text-danger">
                        <p>Please correct the issues as specified.</p>
                        {createErrorList(errors)}
                    </div>
                    }
                    <table className="table-grid hover stack">
                        <thead>
                        <tr>
                            <th className="text-left">Name</th>
                            <th className="text-left">Item Bank</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {config.forms.map(
                            (form, index) => {
                                if(config.isEditing && index === config.editingRowIndex) {
                                    return (<FormEditForm key={index} config={{
                                        form: form,
                                        fields: fields,
                                        submitted: submitted,
                                        errors: errors,
                                        onEditFormCancel: (config.addRowIndex >= 0) ? (event) => config.onDeleteForm(event, index) : config.onEditFormCancel,
                                        itemBanks: config.itemBanks,
                                        setField: setField,
                                        onItemBankChange: config.onItemBankChange
                                    }}/>);
                                }
                                else {
                                    return (<FormRow
                                        key={index}
                                        form={form}
                                        itemBanks={config.itemBanks}
                                        isEditing={config.isEditing}
                                        onEditForm={(event) => config.onEditForm(event, form, setField, index)}
                                        onDeleteForm={(event) => config.onDeleteForm(event, index)}/>);
                                }
                            }
                        )}
                        {config.forms.length === 0 &&
                        <tr>
                            <td colSpan={numberOfColumns}>
                                Click &quot;Add Form&quot; to add a new form.
                            </td>
                        </tr>
                        }
                        </tbody>
                    </table>
                </div>
            )}
        </FormValidation>
    );
};

FormsList.propTypes = {
    config: PropTypes.shape({
        forms: PropTypes.arrayOf(PropTypes.object),
        isEditing: PropTypes.bool.isRequired,
        editingRowIndex: PropTypes.number,
        addRowIndex: PropTypes.number,
        onEditForm: PropTypes.func.isRequired,
        onEditFormSubmit: PropTypes.func.isRequired,
        onEditFormCancel: PropTypes.func.isRequired,
        onDeleteForm: PropTypes.func.isRequired,
        onAddForm: PropTypes.func.isRequired,
        itemBanks: PropTypes.array.isRequired,
        onItemBankChange: PropTypes.func.isRequired
    })
};

export default FormsList;