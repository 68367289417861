import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as assessmentActions from "./AssessmentActions";
import * as mCatConfigActions from "./McatConfigActions";
import * as taskActions from "../Tasks/TaskAction";
import * as loginActions from "../Login/LoginAction";
import * as routes from "../../components/routes/routesConstants";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import {getParams} from "../../components/layout/getParams";
import {Notify} from "../../components/notification/notify";
import McatConfigAssociatedTasks from "./McatConfigAssociatedTasks";
import * as config from "../../constants/config";
import McatConfigGroupForm from "./McatConfigGroupForm";
import {removeDuplicateItemsFromArray} from "../../components/common/commonUtilities";

export function McatConfigInfoPage({actions, history, location, assessmentInfo, taskSelectList, itemPoolSelectList}) {

    const [assessmentId, setAssessmentId] = useState(0);
    const [itemPools, setItemPools] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [mCatTaskGroups, setMcatTaskGroups] = useState([]);
    const [associatedTasks, setAssociatedTasks] = useState([]);
    const [isAddTaskBtnDisabled, setDisableAddTaskBtn] = useState(false);
    const [editingTaskRowIndex, setTaskRowIndex] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [isAddStopCriteriaBtnDisabled, setDisableAddStopCriteriaBtn] = useState(false);
    const [editingStopCriteriaRowIndex, setStopCriteriaRowIndex] = useState(0);
    const [temporaryId, setTemporaryId] = useState(-1);

    const nextTemporaryId = () => {
        setTemporaryId(temporaryId - 1);
        return temporaryId;
    }
    const stringConstants = {
        notApplicable: "N/A",
        groupPropertySeparator: "_"
    };

    useEffect(() => {
        actions.updatePageTitle_BreadCrumb(routes.ASSESSMENT_MCAT_CONFIG_INFO);
        const paramObj = getParams(location.pathname, routes.ASSESSMENT_MCAT_CONFIG_INFO);

        if (paramObj !== null)
            setAssessmentId(parseInt(paramObj.assessmentId));

        if (paramObj !== null && assessmentId !== 0) {
            actions.editAssessment(assessmentId);
            actions.loadTaskSelectList();
        }
    }, [assessmentId]);

    useEffect(() => {
        associatedTasksFactory();
    }, [taskSelectList]);

    useEffect(() => {
        if (!assessmentInfo || (assessmentInfo.McatTaskGroups && assessmentInfo.McatTaskGroups.length === 0))
            return;
        setMcatTaskGroups(assessmentInfo.McatTaskGroups);
    }, [assessmentInfo]);

    useEffect(() => {
        setItemPools(itemPoolSelectList);
    }, [itemPoolSelectList]);

    useEffect(() => {
        if (!associatedTasks)
            return;
        const taskIds = associatedTasks.map((task) => {
            return task.id;
        });

        if (taskIds.length > 0)
            actions.loadItemPoolSelectList(taskIds);
    }, [associatedTasks]);

    function mcatTaskGroupInitialState() {
        return {
            McatTaskGroupId: nextTemporaryId(),
            AssessmentId: 0,
            GroupName: "",
            McatModelType: 0,
            ItemPool: [],
            Tasks: [],
            McatStopCriteria: [],
        };
    }

    function onReturnAssessmentInfoPage(event) {
        event.preventDefault();
        Notify.Clear();
        history.push(routes.ASSESSMENT_INFO.path.replace(routes.ASSESSMENT_INFO.params.assessmentId, assessmentId.toString()));
    }

    function onReturnAssessmentListPage() {
        history.push(routes.ASSESSMENT_MGMT.path)
    }

    function shouldDisablePageActions() {
        return isEditing;
    }

    function associateMcatGroupToTask(mCatGroups, taskId) {
        let mCatGroup = mCatGroups.find(s => s.Tasks.find(i => i === taskId));
        return !mCatGroup ? stringConstants.notApplicable : mCatGroup.GroupName;
    }

    function associatedTasksFactory() {
        let associatedTasks = [];
        if (!assessmentInfo || !assessmentInfo.TaskIds)
            return;

        for (let j = 0; j < assessmentInfo.TaskIds.length; j++) {
            let task = taskSelectList.find(f => f.value === assessmentInfo.TaskIds[j]);
            if (task) {
                if (!associatedTasks.find(f => f.id === task.value))
                    associatedTasks.push({
                        order: j + 1,
                        id: task.value,
                        name: task.name,
                        mcatGroupName: associateMcatGroupToTask(assessmentInfo.McatTaskGroups, task.value)
                    });
            }
        }

        setAssociatedTasks(associatedTasks);
    }

    function isUpdateable() {
        if (assessmentId === 0)
            return true;

        if (assessmentInfo === undefined ||
            assessmentInfo.Links === undefined)
            return false;

        const assessmentInfoLinkList = assessmentInfo.Links.filter(f => f.Method === config.DELETE_API_METHOD);
        return (assessmentInfoLinkList.length > 0);
    }

    function createPageDirections() {
        let pageDir = "";
        if (!assessmentInfo.McatTaskGroups || assessmentInfo.McatTaskGroups.length === 0)
            pageDir = "Create new MCAT Group Configuration";
        else {
            pageDir = !isUpdateable() ?
                <strong className={"text-danger"}>NOTE - this assessment is associated with scores. Only make changes
                    that
                    will not affect the overall outcome of previously saved
                    scores.</strong> : "Edit MCAT Group Configuration";
        }
        return <p>{pageDir}</p>;
    }

    function mCatTaskGroupArrayBuilder(fields, assessmentId) {
        const allProperties = Object.keys(fields).map((propValue) => {
            let temp = propValue.split(stringConstants.groupPropertySeparator);
            return temp[0];
        });
        const properties = removeDuplicateItemsFromArray(allProperties);
        const numberOfMcatGroups = allProperties.length / properties.length;

        let mCatTaskGroups = [];
        for (let i = 0; i < numberOfMcatGroups; i++) {
            let mCatTaskGroup = {};
            properties.map((propertyName) => {
                if (propertyName === "Tasks") {
                    mCatTaskGroup[propertyName] = fields[`${propertyName}_${i}`].map((task) => {
                        return task.value;
                    })
                }
                if (propertyName === "McatStopCriteria") {
                    let temp = fields[`${propertyName}_${i}`].map((mcatStopCriteria) => {
                        if (mcatStopCriteria.McatStopCriterionId < 0) {
                            return Object.assign({}, mcatStopCriteria, {McatStopCriterionId: 0});
                        }
                        return mcatStopCriteria;
                    })
                    mCatTaskGroup[propertyName] = temp;
                }
                if (propertyName !== "Tasks" && propertyName !== "McatStopCriteria")
                    mCatTaskGroup[propertyName] = fields[`${propertyName}_${i}`];
            });
            if (mCatTaskGroup.McatTaskGroupId === "")
                mCatTaskGroup.McatTaskGroupId = 0;

            mCatTaskGroup.McatTaskGroupId = parseInt(mCatTaskGroup.McatTaskGroupId);
            mCatTaskGroup.AssessmentId = assessmentId;
            mCatTaskGroups.push(mCatTaskGroup);
        }

        return mCatTaskGroups;
    }

    function onMcatTaskGroupSubmit(event) {
        if (event.isValid) {
            setIsLoading(true);

            let detachedFields = Object.assign({}, event.fields);
            let mCatTaskGroupsArray = mCatTaskGroupArrayBuilder(detachedFields, assessmentId);
            actions.saveMcatTaskGroups(mCatTaskGroupsArray)
                .then(() => history.push(routes.ASSESSMENT_MGMT.path))
                .catch(() => setIsLoading(false));
        }
    }

    function onSaveClick() {
        if (!mCatTaskGroups || (mCatTaskGroups && mCatTaskGroups.length === 0)) {
            actions.deleteMcatTaskGroups(assessmentId)
                .then(() => history.push(routes.ASSESSMENT_MGMT.path))
                .catch(() => setIsLoading(false));
        }
    }

    function onAddGroup() {
        setMcatTaskGroups([...mCatTaskGroups, mcatTaskGroupInitialState()]);
    }

    if (!assessmentInfo)
        return null;

    return (
        <div>
            <div className={"clearfix"}>
                <Button config={{
                    name: "btnReturnAssessmentInformation",
                    label: "Return to Assessment Information",
                    onClick: onReturnAssessmentInfoPage,
                    btnClass: "light float-right",
                    btnType: buttonConstants.BUTTON_TYPE_BUTTON
                }}/>
                {createPageDirections()}
            </div>
            <div className={"grid-x grid-padding-x align-bottom align-center"}>
                <div className={"cell medium-6 large-6 "}>
                    <label htmlFor={assessmentInfo.Name} className={"cell small-12"}>Assessment Name:</label>
                    <p>{assessmentInfo.Name}</p>
                    <McatConfigAssociatedTasks associatedTasks={associatedTasks}/>
                </div>
            </div>
            <p></p>
            <div className={"grid-x grid-padding-x align-bottom align-center"}>
                <div className={"cell medium-6 large-6"}>
                    <div className={"clearfix"}>
                        <Button config={{
                            name: "btnAddGroup",
                            label: "Add Group",
                            onClick: onAddGroup,
                            btnClass: "float-right",
                            btnType: buttonConstants.BUTTON_TYPE_BUTTON
                        }}/>
                        <h4>MCAT Task Groups</h4>
                    </div>
                </div>
            </div>
            {
                mCatTaskGroups &&
                mCatTaskGroups.length > 0 &&
                <McatConfigGroupForm config={{
                    mCatTaskGroups: mCatTaskGroups,
                    associatedTasks: associatedTasks,
                    onMcatTaskGroupSubmit: (event) => onMcatTaskGroupSubmit(event),
                    itemPoolSelectList: itemPools,
                    isLoading: isLoading,
                    shouldDisablePageActions: false,
                    isAddTaskBtnDisabled: isAddTaskBtnDisabled,
                    editingTaskRowIndex: editingTaskRowIndex,
                    setAssociatedTasks: setAssociatedTasks,
                    setDisableAddTaskBtn: setDisableAddTaskBtn,
                    setTaskRowIndex: setTaskRowIndex,
                    notApplicable: stringConstants.notApplicable,
                    setStopCriteriaRowIndex: setStopCriteriaRowIndex,
                    setDisableAddStopCriteriaBtn: setDisableAddStopCriteriaBtn,
                    setIsEditing: setIsEditing,
                    isAddStopCriteriaBtnDisabled: isAddStopCriteriaBtnDisabled,
                    editingStopCriteriaRowIndex: editingStopCriteriaRowIndex,
                    groupPropertySeparator: stringConstants.groupPropertySeparator,
                    nextTemporaryId: nextTemporaryId,
                    setMcatTaskGroups: setMcatTaskGroups,
                    mCatTaskGroupArrayBuilder: mCatTaskGroupArrayBuilder
                }}

                />
            }
            <div className={"grid-x grid-padding-x align-bottom align-center"}>
                <div className={"cell center-element medium-6 large-6"}>
                    <div className={"cell text-right medium-6 large-6"}>
                        <Button config={{
                            name: "btnCancel",
                            label: "Cancel",
                            onClick: () => onReturnAssessmentListPage(),
                            btnClass: "secondary button-margin",
                            disabled: shouldDisablePageActions(),
                            btnType: buttonConstants.BUTTON_TYPE_BUTTON
                        }}/>
                        <label htmlFor={"btnSave"}
                               className={"button " + (shouldDisablePageActions() ? " is-disabled" : "")}
                               onClick={() => onSaveClick()}
                        >
                            <span>Save</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

McatConfigInfoPage.propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    assessmentInfo: PropTypes.object,
    taskSelectList: PropTypes.array,
    itemPoolSelectList: PropTypes.array
};

const mapStateToProps = state => ({
    assessmentInfo: state.assessment.assessmentInfo,
    taskSelectList: state.task.taskSelectList,
    itemPoolSelectList: state.assessment.itemPoolSelectList,
});


function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        assessmentActions,
        taskActions,
        mCatConfigActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(McatConfigInfoPage));
