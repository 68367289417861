import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";
import {generateIsActiveOptions, generateRoleOptions} from "./UserMgmtConstants";

import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import TextField from "../../components/inputs/textField";
import SelectField from "../../components/inputs/selectField";

const UserListSearchForm = ({config}) => {
    let formConfig = {
        NameEmailContains: {},
        RoleId: {},
        DistrictId: {},
        IsActive: {}
    };

    let includeDefaultOption = config.districts.length > 1;
    let districtDefaultOption =  includeDefaultOption ? "Any District" : config.districts[0].name;

    return (
        <FormValidation onSubmit={config.onSearchSubmit} config={formConfig} initialValues={config.userSearchCriteria} >
            {({ fields, setField }) => (
                <div className={"input-bottom-alignment"}>
                    <div className={"grid-x grid-padding-x"}>
                        <div className={"cell medium-6 large-6"}>
                            <TextField config={{
                                name: "NameEmailContains",
                                label: "Name or Email:",
                                maxLength: 100,
                                showLabel: true,
                                value: fields.NameEmailContains
                            }} />
                        </div>
                        <div className={"cell medium-3 large-3"}>
                            <SelectField config={{
                                name: "RoleId",
                                label: "Role",
                                showLabel: true,
                                options: generateRoleOptions(),
                                value: fields.RoleId.toString(),
                                defaultOption: "Any Role"
                            }} />
                        </div>
                    </div>
                    <div className={"grid-x grid-padding-x align-bottom"}>
                        <div className={"cell medium-3 large-3"}>
                        <SelectField config={{
                            name: "DistrictId",
                            label: "District",
                            showLabel: true,
                            options: config.districts,
                            value: fields.DistrictId.toString(),
                            includeDefaultOption: includeDefaultOption,
                            defaultOption: districtDefaultOption,
                            inputClass: "margin-bottom-0"
                        }} />
                    </div>
                        <div className={"cell medium-3 large-3"}>
                            <SelectField config={{
                                name: "IsActive",
                                label: "Status",
                                showLabel: true,
                                options: generateIsActiveOptions(),
                                value: (fields.IsActive !== null) ? fields.IsActive.toString() : null,
                                defaultOption: "Any Status",
                                inputClass: "margin-bottom-0"
                            }} />
                        </div>
                        <div className={"cell medium-5 large-5"}>
                            <div className={"button-group"}>
                                <Button config={{
                                    name: "btnClearUserSearch",
                                    label: "Clear",
                                    onClick: (event) => config.clearCriteria(event, setField),
                                    btnClass: "light",
                                    btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                }} />
                                <Button config={{
                                    name: "btnSearchUsers",
                                    label: "Search",
                                    disabled: config.isLoading
                                }} />
                            </div>
                        </div>
                        <div className={"cell medium-1 large-1 text-right"}>
                            <Button config={{
                                name: "btnExport",
                                label: "Export",
                                onClick: config.onExportUsers,
                                btnClass: "light",
                                disabled: !config.userSearchCriteria.userSearchPerformed,
                                btnType: buttonConstants.BUTTON_TYPE_BUTTON
                            }}/>
                        </div>
                    </div>
                </div>
            )}
        </FormValidation>
    );
};

UserListSearchForm.propTypes = {
    config: PropTypes.shape({
        onSearchSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        clearCriteria: PropTypes.func.isRequired,
        userSearchCriteria: PropTypes.object,
        districts: PropTypes.array.isRequired,
        onExportUsers: PropTypes.func.isRequired
    })
};

export default UserListSearchForm;