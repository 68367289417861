import React from 'react';
import PropTypes from "prop-types";

const TaskInfoForCatConfig = ({config}) => {

    return (
        <>
            <div className={"grid-x"}>
                <div className={"cell medium-8 large-8 "}>
                    <label htmlFor={config.taskInfo.Name} className={"cell small-12"}>Task Name:</label>
                    <p>{config.taskInfo.Name}</p>
                </div>
            </div>
        </>
    );
};

TaskInfoForCatConfig.propTypes = {
    config: PropTypes.shape({
        taskInfo: PropTypes.object.isRequired
    })
};

export default TaskInfoForCatConfig;