import * as actionTypes from "./SlabConstants";

export function slabDefaultState() {
    return {
        slabSearchCriteria: {
            StartDate: "",
            EndDate: "",
            Reprocess: false,
            Export: "",
            slabSearchPerformed: false
        },
        processedAudioFiles: 0,
        remainingAudioFiles: 0
    };
}

export const slabInitialState = slabDefaultState();

export function slabReducer(state = slabInitialState, action) {
    switch(action.type){
        case actionTypes.LOAD_SLAB_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    processedAudioFiles: action.data.ProcessedAudioFiles,
                    remainingAudioFiles: action.data.RemainingAudioFiles
                });
        case actionTypes.SAVE_SLAB_SEARCH_CRITERIA:
            return Object.assign({}, state, { slabSearchCriteria: action.criteria});
         default:
            return state;
    }
}