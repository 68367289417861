import * as config from "../../constants/config";
import {getTaskTypesData} from "../../mockData/assessmentData";
import {mockResolveApiCall, sendRequest} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerTaskTypeApi {
    static getTaskTypes() {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}taskTypes`,
                RequestTypes.GET,
            ));
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockTaskTypeApi {
    static getTaskTypes() {
        return mockResolveApiCall(getTaskTypesData());
    }
}

const TaskTypeApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerTaskTypeApi : MockTaskTypeApi;
export default TaskTypeApi;