export const LOAD_ITEM_BANKS_AJAX_SUCCESS = "LOAD_ITEM_BANKS_AJAX_SUCCESS";
export const LOAD_ITEM_BANK_SELECT_LIST_AJAX_SUCCESS = "LOAD_ITEM_BANK_SELECT_LIST_AJAX_SUCCESS";
export const SAVE_ITEM_BANK_SEARCH_CRITERIA = "SAVE_ITEM_BANK_SEARCH_CRITERIA";
export const DELETE_ITEM_BANK_AJAX_SUCCESS = "DELETE_ITEM_BANK_AJAX_SUCCESS";
export const EDIT_ITEM_BANK_AJAX_SUCCESS = "EDIT_ITEM_BANK_AJAX_SUCCESS";
export const SAVE_ITEM_BANK_AJAX_SUCCESS = "SAVE_ITEM_BANK_AJAX_SUCCESS";
export const ADD_ITEM_BANK = "ADD_ITEM_BANK";
export const UPDATE_ITEM_BANK = "UPDATE_ITEM_BANK";
export const ASSOCIATE_ITEM_TO_ITEM_BANK = "ASSOCIATE_ITEM_TO_ITEM_BANK";
export const UNASSOCIATE_ITEM_TO_ITEM_BANK = "UNASSOCIATE_ITEM_TO_ITEM_BANK";
