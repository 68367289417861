import React from "react";
import PropTypes from "prop-types";
import {toDangerouslySetHtml} from "../layout/toDangerouslySetHtml";

const ImportErrorNotification = ({importResults }) => {

    if(importResults == null ||
        importResults.Message === "")
        return null;

    return (
        <div className={"margin-top-1 import-errors"}>
            <p><strong>Please fix the following issues and try re-importing again.</strong></p>
            <div dangerouslySetInnerHTML={toDangerouslySetHtml(importResults.Message)} />
        </div>
    );
};

ImportErrorNotification.propTypes = {
    importResults: PropTypes.object
};

export default ImportErrorNotification;