import React from "react";
import PropTypes from "prop-types";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";

const ItemInfoMedia = ({ config }) => {

    return (
        <div className={"grid-x " + config.wrapperClass}>
            <div className={"cell fileUploadContainer"}>
                {(config.label !== "") &&
                    <span><strong>{config.label}</strong></span>
                }
                <div className={"grid-x mediaContainer align-middle"}>
                    <div className={"cell"}>
                        {config.media}
                    </div>
                </div>
                <Button config={{
                    name: "btnRemove",
                    label: "Remove",
                    btnClass: "light",
                    onClick: config.onRemoveClick,
                    btnType: buttonConstants.BUTTON_TYPE_BUTTON
                }}/>
            </div>
        </div>
    );
};

ItemInfoMedia.propTypes = {
    config: PropTypes.shape({
        media: PropTypes.object.isRequired,
        onRemoveClick: PropTypes.func.isRequired,
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        wrapperClass: PropTypes.string
    })
};

export default ItemInfoMedia;