import React from 'react';
import PropTypes from "prop-types";
import moment from "moment";
import { FormValidation } from "calidation";
import Button from "../../components/buttons/button";
import {createErrorList, hasErrors} from "../../components/form/createErrorList";
import DateTimePickerField from "../../components/flatpickr-node/dateTimePickerField";
import CheckBox from "../../components/inputs/checkBoxField";

const SlabSearchForm = ({config}) => {
    let startDate = null;
    if(config.slabSearchCriteria.StartDate)
        startDate = moment(config.slabSearchCriteria.StartDate).format("YYYY-MM-DD");

    let endDate = null;
    if(config.slabSearchCriteria.EndDate)
        endDate = moment(config.slabSearchCriteria.EndDate).format("YYYY-MM-DD");

    let slabSearchConfig = {
        Reprocess: { }
    };

    return (
        <FormValidation onSubmit={config.onSearchSubmit} config={slabSearchConfig} id={"slabSearchForm"}>
        {({ fields }) => (
                <div>
                    {config.hasSubmitted && hasErrors(config.errors) &&
                    <div className="text-danger">
                        <p>Please correct the issues as specified.</p>
                        {createErrorList(config.errors)}
                    </div>
                    }
                    <div className={"input-bottom-alignment"}>
                        <div className={"grid-x grid-padding-x align-bottom"}>
                            <div className={"cell medium-3 large-3"}>
                                <DateTimePickerField config={{
                                    name: "StartDate",
                                    label: "Start Date:",
                                    error: (config.hasSubmitted && config.errors.StartDate) ? "*" : "",
                                    enableTime: false,
                                    dateFormat: "m-j-Y",
                                    showLabel: true,
                                    value: startDate,
                                    inputClass: "margin-bottom-0",
                                    onChange: config.onDateChange
                                }}
                                />
                            </div>
                            <div className={"cell medium-3 large-3"}>
                                <DateTimePickerField config={{
                                    name: "EndDate",
                                    label: "End Date:",
                                    error: (config.hasSubmitted && config.errors.EndDate) ? "*" : "",
                                    enableTime: false,
                                    dateFormat: "m-j-Y",
                                    showLabel: true,
                                    value: endDate,
                                    inputClass: "margin-bottom-0",
                                    onChange: config.onDateChange
                                }}
                                />
                            </div>
                            <div className={"cell medium-3 large-3"}>
                                <CheckBox config={{
                                    name: "Reprocess",
                                    label: "Reprocess audio files",
                                    checked: fields.Reprocess,
                                    showLabel: true
                                }} />
                            </div>

                            <div className={"cell medium-3 large-3"}>
                                <div className={"button-group"}>
                                    <Button config={{
                                        name: "btnSearchSlabInfo",
                                        label: "Update",
                                        disabled: config.isLoading
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )}
        </FormValidation>
    );
};

SlabSearchForm.propTypes = {
    config: PropTypes.shape({
        onSearchSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        hasSubmitted: PropTypes.bool.isRequired,
        errors: PropTypes.object,
        slabSearchCriteria: PropTypes.object,
        onDateChange: PropTypes.func.isRequired
    })
};

export default SlabSearchForm;