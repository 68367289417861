import $ from 'jquery';
import * as config from "../../constants/config";
import LocalStorage from "../../components/localStorage/access";
import { getSlabData } from "../../mockData/slabData";
import {
    downloadBlob,
    mockRejectApiCall,
    mockResolveApiCall,
    sendRequest
} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerSlabApi {
    static getSlabInfo(slabSearchCriteria) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}slab?${$.param(slabSearchCriteria)}`,
                RequestTypes.GET,
            ));
    }

    static processAudioFiles(slabSearchCriteria){
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}slab?${$.param(slabSearchCriteria)}`,
                RequestTypes.POST,
            )
        );
    }

    static exportSlabData(slabSearchCriteria) {
        return downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}slab/export?${$.param(slabSearchCriteria)}`,
                RequestTypes.GET,
                null,
                ApiSuccessMessages.EXPORT_SUCCESS
            ));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockSlabApi {
    static getSlabInfo() {
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            let slabData = getSlabData();

            return mockResolveApiCall(Object.assign({}, slabData));
        }
    }

    static exportSlabData() {
        // no mock available as no way to create a mock Excel file
    }
}

const SlabApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerSlabApi : MockSlabApi;
export default SlabApi;