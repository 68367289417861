import React from "react";
import PropTypes from "prop-types";
import ButtonIcon from "../../components/buttons/buttonIcon";
import * as buttonConstants from "../../components/buttons/buttonConstants";

const FormRow = ({ form, itemBanks, isEditing, onEditForm, onDeleteForm }) => {
    let itemBankName = "";
    let itemBankInfo = itemBanks.filter(f => f.value === form.ItemBankId);
    if(itemBankInfo.length > 0)
        itemBankName = itemBankInfo[0].name;

    return (
        <tr>
            <td>{form.Name}</td>
            <td>{itemBankName}</td>
            <td className={"text-center"}>
                <ButtonIcon config={{
                    disabled: isEditing,
                    name: "btnEdit",
                    label: "Edit",
                    onClick: onEditForm,
                    iconType: buttonConstants.ICON_EDIT
                }} />
                <ButtonIcon config={{
                    disabled: isEditing,
                    name: "btnDelete",
                    label: "Delete",
                    iconType: buttonConstants.ICON_DELETE,
                    onClick: onDeleteForm
                }} />
            </td>
        </tr>
    );
};

FormRow.propTypes = {
    form: PropTypes.object.isRequired,
    itemBanks: PropTypes.array.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onEditForm: PropTypes.func.isRequired,
    onDeleteForm: PropTypes.func.isRequired
};

export default FormRow;