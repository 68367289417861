import * as actionTypes from "./EnrollmentMgmtConstants";
import * as config from "../../constants/config";
import {convertDataToOptions} from "../../components/generalData/convertGeneralDataToOptions";

export const enrollmentMgmtInitialState = {
    enrollmentSearchCriteria: {
        Year: actionTypes.ENROLLMENT_START_YEAR.toString(),
        AssessmentPeriod: "",
        DistrictId: 0,
        SchoolName: "",
        TeacherName: "",
        Page: 1,
        RecordsPerPage: config.defaultRecordsPerPage,
        OrderByColumn: "LastName",
        OrderDescending: false,
        enrollmentSearchPerformed: false
    },
    enrollmentTotalRecords: 0,
    enrollmentSearchResults: [],
    districts: [],
    schools: [],
    teachers: [],
    importResults: {
        Message: ""
    }
};

export function enrollmentMgmtReducer(state = enrollmentMgmtInitialState, action) {
    switch(action.type){
        case actionTypes.LOAD_ENROLLMENTS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    enrollmentSearchResults: [...action.data.Data],
                    enrollmentTotalRecords: action.data.TotalRecords
                });
        case actionTypes.LOAD_ENROLLMENT_FILTER_DATA_AJAX_SUCCESS:
            return Object.assign({}, state, {
                districts: convertDataToOptions([...action.data.Districts], "Id", "%s;DistrictName"),
                schools: convertDataToOptions([...action.data.Schools], "SchoolName", "%s;SchoolName"),
                teachers: convertDataToOptions([...action.data.Teachers], "TeacherName", "%s;TeacherName")
            });
        case actionTypes.SAVE_ENROLLMENT_SEARCH_CRITERIA:
            return Object.assign({}, state, { enrollmentSearchCriteria: action.criteria});
        case actionTypes.IMPORT_ENROLLMENTS_AJAX_SUCCESS:
        case actionTypes.CLEAR_OUT_ENROLLMENT_IMPORT_MESSAGE:
            return Object.assign({}, state, {importResults: action.importResults});
        default:
            return state;
    }
}