import React from "react";

export function createErrorList(errors)
{
    return (
        <ul>
        {
            Object.values(errors).map((error, index) => {
                if (error !== null && error !== "") {
                    return <li key={index}>{error}</li>;
                }
            })
        }
        </ul>
    );
}

export function hasErrors(errors)
{
    return (Object.values(errors).filter(f => f !== null).length > 0);
}