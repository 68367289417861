import React from "react";
import PropTypes from "prop-types";
import ItemPoolListSearchRow from "./ItemPoolListSearchRow";
import ResultsPager from "../../components/table/ResultsPager";

const ItemPoolListSearchResults = ({ config }) => {
    const numberOfColumns = "3";

    return (
        <div>
            <p>Item pools that have disabled delete icons cannot be deleted because they are associated with a score.</p>
            <table className="table-grid hover stack">
                <thead>
                <tr>
                    <th onClick={config.order} order-by-value="Name" className="text-left is-clickable">ItemPool Name</th>
                    <th onClick={config.order} order-by-value="CatModelType" className="is-clickable">CAT Model</th>

                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {config.itemPoolSearchResults.map(
                    (itemPool, index) => {
                        return (<ItemPoolListSearchRow
                            key={index}
                            itemPool={itemPool}
                            onEditItemPool={config.onEditItemPool}
                            onDeleteItemPool={config.onDeleteItemPool} />);
                    }
                )}
                {config.itemPoolTotalRecords === 0 &&
                <tr>
                    <td colSpan={numberOfColumns}>
                        No item pools match the search criteria.
                    </td>
                </tr>
                }
                </tbody>
                {config.itemPoolTotalRecords > 0 &&
                <tfoot>
                <tr>
                    <td colSpan={numberOfColumns}>
                        <ResultsPager config={{
                            first: config.first,
                            previous: config.previous,
                            next: config.next,
                            last: config.last,
                            totalRecords: config.itemPoolTotalRecords,
                            recordsPerPage: config.itemPoolRecordsPerPage,
                            currentPage: config.currentPage
                        }}  />
                    </td>
                </tr>
                </tfoot>
                }
            </table>
        </div>
    );
};

ItemPoolListSearchResults.propTypes = {
    config: PropTypes.shape({
        itemPoolSearchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
        order: PropTypes.func,
        first: PropTypes.func.isRequired,
        last: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired,
        itemPoolTotalRecords: PropTypes.number.isRequired,
        currentPage: PropTypes.number,
        itemPoolRecordsPerPage: PropTypes.number,
        onEditItemPool: PropTypes.func.isRequired,
        onDeleteItemPool: PropTypes.func.isRequired
    })
};

export default ItemPoolListSearchResults;