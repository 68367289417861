import {beginAjaxCall, ajaxCallError} from "../AjaxStatus/AjaxStatusActions";
import itemBankApi from "./ItemBankApi";
import * as actionTypes from "./ItemBankConstants";
import {itemBankDefaultState} from "./ItemBankReducer";

export function loadItemBanksSuccess(data) {
    return {type: actionTypes.LOAD_ITEM_BANKS_AJAX_SUCCESS, data};
}

export function loadItemBanks(itemBankFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return itemBankApi.getItemBanks(itemBankFilterCriteria)
            .then(data => {
                dispatch(loadItemBanksSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function loadItemBankSelectListSuccess(data) {
    return {type: actionTypes.LOAD_ITEM_BANK_SELECT_LIST_AJAX_SUCCESS, data};
}

export function loadItemBankSelectList() {
    return dispatch => {
        dispatch(beginAjaxCall());
        let itemBankFilterCriteria = itemBankDefaultState().itemBankSearchCriteria;
        itemBankFilterCriteria.Fields = "Id,Name";

        return itemBankApi.getItemBanks(itemBankFilterCriteria)
            .then(data => {
                dispatch(loadItemBankSelectListSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function saveItemBankSearchCriteria(criteria) {
    return {type: actionTypes.SAVE_ITEM_BANK_SEARCH_CRITERIA, criteria};
}

export function deleteItemBankSuccess() {
    return {type: actionTypes.DELETE_ITEM_BANK_AJAX_SUCCESS};
}

export function deleteItemBank(deleteLinkInfo) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return itemBankApi.deleteItemBank(deleteLinkInfo)
            .then(() => {
                dispatch(deleteItemBankSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function addItemBank() {
    return {type: actionTypes.ADD_ITEM_BANK};
}

export function editItemBankSuccess(itemBank) {
    return {type: actionTypes.EDIT_ITEM_BANK_AJAX_SUCCESS, data: itemBank};
}

export function editItemBank(itemBankId) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return itemBankApi.getItemBank(itemBankId)
            .then((itemBank) => {
                dispatch(editItemBankSuccess(itemBank));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function updateItemBank(itemBank) {
    return {type: actionTypes.UPDATE_ITEM_BANK, data: itemBank};
}

export function saveItemBankSuccess() {
    return {type: actionTypes.SAVE_ITEM_BANK_AJAX_SUCCESS};
}

export function saveItemBank(itemBank) {
    return dispatch => {
        dispatch(beginAjaxCall());

        if (itemBank.Id === 0) {
            return itemBankApi.addItemBank(itemBank)
                .then(() => {
                    dispatch(saveItemBankSuccess());
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                });
        } else {
            return itemBankApi.updateItemBank(itemBank)
                .then(() => {
                    dispatch(saveItemBankSuccess());
                })
                .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                });
        }
    };
}

export function associateItem(itemId) {
    return {type: actionTypes.ASSOCIATE_ITEM_TO_ITEM_BANK, data: itemId};
}

export function unassociateItem(index) {
    return {type: actionTypes.UNASSOCIATE_ITEM_TO_ITEM_BANK, data: index};
}
