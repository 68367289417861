import {generalDataList} from "./generalDataConstants";

function loadGeneralData(props)
{
    generalDataList.map( generalDataOption => {
        if(props[generalDataOption.propName].length === 0)
            props.actions["load" + generalDataOption.name]();
    });
}

export {loadGeneralData};