import * as config from "../../constants/config";
import {getAccounts} from "../../mockData/accountData";
import {mockRejectApiCall, mockResolveApiCall, sendRequest} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerLoginApi {
    static attemptLogin(loginViewModel) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}Login`,
                RequestTypes.POST,
                JSON.stringify(loginViewModel)
            )
        );
    }

    static refreshToken(refreshToken) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}RefreshToken`,
                RequestTypes.POST,
                {refreshToken: refreshToken}
            )
        );
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockLoginApi {
    static attemptLogin(loginViewModel) {
        let userAcct = getAccounts().filter(account =>
            account.Email === loginViewModel.Email &&
            account.Password === loginViewModel.Password &&
            account.IsActive);

        if (userAcct.length === 0)
            return mockRejectApiCall("There was an error while attempting to login.");
        else if (userAcct[0].Role === "Proctor")
            return mockRejectApiCall("This login is only for administrators. If you are an administrator experiencing difficulty logging in, contact support for assistance.  Proctors will be contacted by an administrator for next steps.");
        else {
            let convertToMilliseconds = config.mockAtExpiresInSeconds * 1000;
            const at_expiration = new Date().getTime() + convertToMilliseconds;

            convertToMilliseconds = config.mockRtExpiresInSeconds * 1000;
            const rt_expiration = new Date().getTime() + convertToMilliseconds;
            return mockResolveApiCall(Object.assign({}, {
                AccessToken: userAcct[0].Id.toString(),
                ExpiresIn: at_expiration,
                IsAdmin: userAcct[0].isadmin,
                Role: userAcct[0].Role,
                Identity: Object.assign({}, {
                    RefreshToken: userAcct[0].Id.toString(),
                    RefreshTokenExpiresIn: rt_expiration
                })
            }));
        }
    }

    static refreshToken(refreshToken) {
        let userAcct = getAccounts().filter(account => account.Id === parseInt(refreshToken));

        if (userAcct.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            let convertToMilliseconds = config.mockAtExpiresInSeconds * 1000;
            const at_expiration = new Date().getTime() + convertToMilliseconds;

            convertToMilliseconds = config.mockRtExpiresInSeconds * 1000;
            const rt_expiration = new Date().getTime() + convertToMilliseconds;

            return mockResolveApiCall(Object.assign({}, {
                AccessToken: userAcct[0].Id.toString(),
                ExpiresIn: at_expiration,
                IsAdmin: userAcct[0].isadmin,
                Role: userAcct[0].Role,
                Identity: Object.assign({}, {
                    RefreshToken: userAcct[0].Id.toString(),
                    RefreshTokenExpiresIn: rt_expiration
                })
            }));
        }
    }
}

const LoginApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerLoginApi : MockLoginApi;
export default LoginApi;