import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";

import Button from "../../components/buttons/button";
import TextField from "../../components/inputs/textField";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import * as routes from "../../components/routes/routesConstants";
import ButtonLink from "../../components/buttons/buttonLink";

const LoginForm = ({onLoginSubmit, isValid, isLoading, onRegisterClick, onForgotPasswordClick}) => {
    let formConfig = {
        Email: {
            isRequired: 'Email is required!'
        },
        Password: {
            isRequired: 'Password is required!'
        }
    };
  return (
      <FormValidation onSubmit={onLoginSubmit} config={formConfig}>
          {({ fields, errors, submitted }) => (
            <div>
                {submitted && !isValid && <p className="text-danger">Please correct the issues as specified and try logging in again.</p>}
                <TextField config={{
                    name: "Email",
                    label: "Email",
                    maxLength: 300,
                    showLabel: true,
                    value: fields.Email,
                    error: (submitted && errors.Email) ? errors.Email : "",
                    tabIndex: 1
                }} />
                <div className={"forgot-password"}>
                    <ButtonLink config={{
                        name: "btnForgotPassword",
                        label: routes.FORGOT_PASSWORD.display,
                        onClick: onForgotPasswordClick,
                        tabIndex: 4
                    }} />
                </div>
                <TextField config={{
                    name: "Password",
                    label: "Password",
                    maxLength: 100,
                    inputType: "password",
                    showLabel: true,
                    value: fields.Password,
                    error: (submitted && errors.Password) ? errors.Password : "",
                    tabIndex: 2
                }} />
                <div className={"button-group expanded margin-top-2 "}>
                    <Button config={{
                        name:"btnRegister",
                        label: "Register",
                        onClick: onRegisterClick,
                        btnClass: "secondary",
                        btnType: buttonConstants.BUTTON_TYPE_BUTTON,
                        tabIndex: 5
                    }} />
                    <Button config={{
                        name: "btnSignIn",
                        label: "Sign In",
                        disabled: isLoading,
                        tabIndex: 3
                    }} />
                </div>
            </div>
          )}
      </FormValidation>
  );
};


LoginForm.propTypes = {
    onLoginSubmit: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onRegisterClick: PropTypes.func.isRequired,
    onForgotPasswordClick: PropTypes.func.isRequired
};

export default LoginForm;