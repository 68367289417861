let districts = null;

function getDistrictData() {
    if(districts === null) {
        districts = [
            { Id: 0,  DistrictName: "Admin (No district)",  DistrictNumber: "" },
            { Id: 1,  DistrictName: "Alachua",              DistrictNumber: "01" },
            { Id: 2,  DistrictName: "Baker",                DistrictNumber: "02" },
            { Id: 3,  DistrictName: "Bay",                  DistrictNumber: "03" },
            { Id: 4,  DistrictName: "Bradford",             DistrictNumber: "04" },
            { Id: 5,  DistrictName: "Brevard",              DistrictNumber: "05" },
            { Id: 6,  DistrictName: "Broward",              DistrictNumber: "06" },
            { Id: 7,  DistrictName: "Calhoun",              DistrictNumber: "07" },
            { Id: 8,  DistrictName: "Charlotte",            DistrictNumber: "08" },
            { Id: 9,  DistrictName: "Citrus",               DistrictNumber: "09" },
            { Id: 10, DistrictName: "Clay",                 DistrictNumber: "10" },
            { Id: 11, DistrictName: "Collier",              DistrictNumber: "11" },
            { Id: 12, DistrictName: "Columbia",             DistrictNumber: "12" },
            { Id: 13, DistrictName: "Dade",                 DistrictNumber: "13" },
            { Id: 14, DistrictName: "DeSoto",               DistrictNumber: "14" },
            { Id: 15, DistrictName: "Dixie",                DistrictNumber: "15" },
            { Id: 16, DistrictName: "Duval",                DistrictNumber: "16" },
            { Id: 17, DistrictName: "Escambia",             DistrictNumber: "17" },
            { Id: 18, DistrictName: "Flagler",              DistrictNumber: "18" },
            { Id: 19, DistrictName: "Franklin",             DistrictNumber: "19" },
            { Id: 20, DistrictName: "Gadsden",              DistrictNumber: "20" },
            { Id: 21, DistrictName: "Gilchrist",            DistrictNumber: "21" },
            { Id: 22, DistrictName: "Glades",               DistrictNumber: "22" },
            { Id: 23, DistrictName: "Gulf",                 DistrictNumber: "23" },
            { Id: 24, DistrictName: "Hamilton",             DistrictNumber: "24" },
            { Id: 25, DistrictName: "Hardee",               DistrictNumber: "25" },
            { Id: 26, DistrictName: "Hendry",               DistrictNumber: "26" },
            { Id: 27, DistrictName: "Hernando",             DistrictNumber: "27" },
            { Id: 28, DistrictName: "Highlands",            DistrictNumber: "28" },
            { Id: 29, DistrictName: "Hillsborough",         DistrictNumber: "29" },
            { Id: 30, DistrictName: "Holmes",               DistrictNumber: "30" },
            { Id: 31, DistrictName: "Indian River",         DistrictNumber: "31" },
            { Id: 32, DistrictName: "Jackson",              DistrictNumber: "32" },
            { Id: 33, DistrictName: "Jefferson",            DistrictNumber: "33" },
            { Id: 34, DistrictName: "Lafayette",            DistrictNumber: "34" },
            { Id: 35, DistrictName: "Lake",                 DistrictNumber: "35" },
            { Id: 36, DistrictName: "Lee",                  DistrictNumber: "36" },
            { Id: 37, DistrictName: "Leon",                 DistrictNumber: "37" },
            { Id: 38, DistrictName: "Levy",                 DistrictNumber: "38" },
            { Id: 39, DistrictName: "Liberty",              DistrictNumber: "39" },
            { Id: 40, DistrictName: "Madison",              DistrictNumber: "40" },
            { Id: 41, DistrictName: "Manatee",              DistrictNumber: "41" },
            { Id: 42, DistrictName: "Marion",               DistrictNumber: "42" },
            { Id: 43, DistrictName: "Martin",               DistrictNumber: "43" },
            { Id: 44, DistrictName: "Monroe",               DistrictNumber: "44" },
            { Id: 45, DistrictName: "Nassau",               DistrictNumber: "45" },
            { Id: 46, DistrictName: "Okaloosa",             DistrictNumber: "46" },
            { Id: 47, DistrictName: "Okeechobee",           DistrictNumber: "47" },
            { Id: 48, DistrictName: "Orange",               DistrictNumber: "48" },
            { Id: 49, DistrictName: "Osceola",              DistrictNumber: "49" },
            { Id: 50, DistrictName: "Palm Beach",           DistrictNumber: "50" },
            { Id: 51, DistrictName: "Pasco",                DistrictNumber: "51" },
            { Id: 52, DistrictName: "Pinellas",             DistrictNumber: "52" },
            { Id: 53, DistrictName: "Polk",                 DistrictNumber: "53" },
            { Id: 54, DistrictName: "Putnam",               DistrictNumber: "54" },
            { Id: 55, DistrictName: "St. Johns",            DistrictNumber: "55" },
            { Id: 56, DistrictName: "St. Lucie",            DistrictNumber: "56" },
            { Id: 57, DistrictName: "Santa Rosa",           DistrictNumber: "57" },
            { Id: 58, DistrictName: "Sarasota",             DistrictNumber: "58" },
            { Id: 59, DistrictName: "Seminole",             DistrictNumber: "59" },
            { Id: 60, DistrictName: "Sumter",               DistrictNumber: "60" },
            { Id: 61, DistrictName: "Suwannee",             DistrictNumber: "61" },
            { Id: 62, DistrictName: "Taylor",               DistrictNumber: "62" },
            { Id: 63, DistrictName: "Union",                DistrictNumber: "63" },
            { Id: 64, DistrictName: "Volusia",              DistrictNumber: "64" },
            { Id: 65, DistrictName: "Wakulla",              DistrictNumber: "65" },
            { Id: 66, DistrictName: "Walton",               DistrictNumber: "66" },
            { Id: 67, DistrictName: "Washington",           DistrictNumber: "67" },
            { Id: 68, DistrictName: "FSDB",                 DistrictNumber: "71" },
            { Id: 69, DistrictName: "P.K. Young (UF)",      DistrictNumber: "72" },
            { Id: 70, DistrictName: "FSU School",           DistrictNumber: "73" },
            { Id: 71, DistrictName: "Henderson School",     DistrictNumber: "74" },
            { Id: 72, DistrictName: "FAMU School",          DistrictNumber: "75" }
        ];
    }

    return districts;
}

export {getDistrictData};