import React from "react";
import PropTypes from "prop-types";
import ButtonBar from "../buttons/buttonBar";
import * as buttonConstants from "../buttons/buttonConstants";
import Button from "../buttons/button";
import * as routes from "../routes/routesConstants";
import {Notify} from "../notification/notify";

const SubMenu = ({ sectionInfo, history }) => {
    const subMenuConstantGroup = sectionInfo.section.subMenu;
    return (
        <div className={"submenu"}>
            <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_CENTER} isExpanded={true}>
                {routes[subMenuConstantGroup].map((menu, index) => { // eslint-disable-line import/namespace
                    return(<Button key={index} config={{
                        name: "btn" + menu.subMenuDisplay,
                        label: menu.subMenuDisplay,
                        onClick: () => {Notify.Clear(); history.push(menu.path)},
                        btnClass: (menu.subMenuDisplay === sectionInfo.subMenuDisplay) ? "secondary": "",
                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                    }}/>);
                })}
            </ButtonBar>
        </div>
    );
};

SubMenu.propTypes = {
    sectionInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default SubMenu;