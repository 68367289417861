import {beginAjaxCall, ajaxCallError} from "../AjaxStatus/AjaxStatusActions";
import itemPoolApi from "./ItemPoolConfigurationApi";
import * as actionTypes from "./ItemPoolConfigurationConstants";

export function loadItemPoolsSuccess(data) {
    return {type: actionTypes.LOAD_ITEM_POOL_AJAX_SUCCESS, data};
}

export function loadItemPools(itemPoolFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return itemPoolApi.getItemPools(itemPoolFilterCriteria)
            .then(data => {
                dispatch(loadItemPoolsSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function saveItemPoolSearchCriteria(criteria) {
    return {type: actionTypes.SAVE_ITEM_POOL_SEARCH_CRITERIA, criteria};
}

export function deleteItemPoolSuccess() {
    return {type: actionTypes.DELETE_ITEM_POOL_AJAX_SUCCESS};
}

export function deleteItemPool(deleteLinkInfo) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return itemPoolApi.deleteItemPool(deleteLinkInfo)
            .then(() => {
                dispatch(deleteItemPoolSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function addItemPool() {
    return {type: actionTypes.ADD_ITEM_POOL};
}

export function editItemPoolSuccess(itemPool) {
    return {type: actionTypes.EDIT_ITEM_POOL_AJAX_SUCCESS, data: itemPool};
}

export function editItemPool(itemPoolId) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return itemPoolApi.getItemPool(itemPoolId)
            .then((itemPool) => {
                dispatch(editItemPoolSuccess(itemPool));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function importItemPoolSuccess(importResults) {
    return {type: actionTypes.IMPORT_ITEM_PARAMETER_AJAX_SUCCESS, data: importResults};
}

export function importItemPoolErrors(importResults) {
    return {type: actionTypes.IMPORT_ITEM_PARAMETER_ERRORS_AJAX_SUCCESS, importResults};
}

export function importItemPool(fileData) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return itemPoolApi.importItemPool(fileData)
            .then((importResults) => {
                dispatch(importItemPoolSuccess(importResults));
            })
            .catch(error => {
                    if (error.Message)
                        dispatch(importItemPoolErrors({Message: error.Message}));
                    else {
                        dispatch(ajaxCallError(error));
                        throw (error);
                    }
                }
            );
    };
}

export function saveItemPoolSuccess() {
    return {type: actionTypes.SAVE_ITEM_POOL_AJAX_SUCCESS};
}

export function saveItemPool(itemPool) {
    return dispatch => {
        dispatch(beginAjaxCall());

        let funcToCall = (itemPool.Id === 0) ? itemPoolApi.addItemPool : itemPoolApi.updateItemPool;
        return funcToCall(itemPool)
            .then(() => {
                dispatch(saveItemPoolSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}


export function exportItemParameterData(itemPoolId) {
    return () => {
        itemPoolApi.exportItemParameterData(itemPoolId);
    };
}

export function clearOutImportMessage() {
    let importResults = {Message: ""};
    return {type: actionTypes.CLEAR_OUT_IMPORT_MESSAGE, importResults};
}