import React from "react";
import PropTypes from "prop-types";
import ButtonBar from "../buttons/buttonBar";
import ButtonIcon from "../buttons/buttonIcon";
import ButtonMock from "../buttons/buttonMock";
import * as buttonConstants from "../buttons/buttonConstants";

const ResultsPager = ({config}) => {
    config = Object.assign({}, resultsPagerDefaults(), config);

    let firstVisibleRecord = (config.currentPage - 1) * config.recordsPerPage + 1;

    let recordsOnCurrentPage = config.currentPage * config.recordsPerPage;
    let lastVisibleRecord = config.totalRecords > recordsOnCurrentPage ?
        ((config.currentPage - 1) * config.recordsPerPage) + config.recordsPerPage :
        config.totalRecords;

    let canNavigateBackward = config.currentPage === 1;
    let canNavigateForward = lastVisibleRecord >= config.totalRecords;

    return (
        <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_CENTER}>
            <ButtonIcon config={{
                onClick: config.first,
                iconType: buttonConstants.ICON_FIRST_PAGE,
                name: "btnFirstPage",
                label: "First Page",
                disabled: canNavigateBackward || config.isEditing
            }}  />
            <ButtonIcon config={{
                onClick: config.previous,
                iconType: buttonConstants.ICON_PREVIOUS_PAGE,
                name: "btnPreviousPage",
                label: "Previous Page",
                disabled: canNavigateBackward || config.isEditing
            }}  />

            <ButtonMock name="record-information" label={firstVisibleRecord + " - " + lastVisibleRecord + " of " + config.totalRecords} />

            <ButtonIcon config={{
                onClick: config.next,
                iconType: buttonConstants.ICON_NEXT_PAGE,
                name: "btnNextPage",
                label: "Next Page",
                disabled: canNavigateForward || config.isEditing
            }}  />
            <ButtonIcon config={{
                onClick: config.last,
                iconType: buttonConstants.ICON_LAST_PAGE,
                name: "btnLastPage",
                label: "Last Page",
                disabled: canNavigateForward || config.isEditing
            }}  />

        </ButtonBar>
    );
};

ResultsPager.propTypes = {
    config: PropTypes.shape({
        first: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        last: PropTypes.func.isRequired,
        totalRecords: PropTypes.number,
        recordsPerPage: PropTypes.number,
        currentPage: PropTypes.number,
        isEditing: PropTypes.bool
    })
};

function resultsPagerDefaults() {
    return {
        isEditing: false
    };
}

export default ResultsPager;