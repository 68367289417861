import * as actionTypes from "./RegisterConstants";

export const registerInitialState = {
    Id: 0,
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
    isadmin: false,
    DistrictId: 0,
    DistrictName: "",
    RoleId: 1,
    Role: "Proctor"
};

export function registerReducer(state = registerInitialState, action) {
    switch(action.type){
        case actionTypes.CREATE_ACCOUNT_AJAX_SUCCESS:
            return Object.assign({}, state, {
                FirstName: action.accountViewModel.FirstName,
                LastName: action.accountViewModel.LastName,
                Email: action.accountViewModel.Email,
                Password: action.accountViewModel.Password,
                DistrictId: action.accountViewModel.DistrictId,
                DistrictName: action.accountViewModel.DistrictName
            });
        default:
            return state;
    }
}