import Toastr from "toastr";

// more options - http://codeseven.github.io/toastr/demo.html
export class Notify {
    static Error(message, callback = null){
        Toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-middle-center",
            "hideDuration": "500",
            "timeOut": 0,
            "extendedTimeOut": 0,
        };

        Toastr.error(message, '', { onHidden: callback });
    }

    static ServerError(message, callback){
        Toastr.options = {
            "closeButton": true,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-middle-center",
            "hideDuration": "1000"
        };

        Toastr.error(message, '', { onHidden: callback });
    }

    static Success(message, callback = null) {
        Toastr.options = {
            "closeButton": true,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-middle-center",
            "hideDuration": "1000"
        };
        if(callback !== null)
            Toastr.success(message, '', {onHidden: callback});
        else
            Toastr.success(message);
    }

    static Info(message) {
        Toastr.options = {
            "closeButton": true,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-middle-center",
            "hideDuration": "1000"
        };
        Toastr.info(message);
    }

    static Warning(message) {
        Toastr.options = {
            "closeButton": true,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-middle-center",
            "hideDuration": "1000"
        };
        Toastr.warning(message);
    }

    static Clear() {
        Toastr.clear();
    }
}
