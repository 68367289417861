import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter } from "react-router-dom";
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as forgotPasswordActions from "./ForgotPasswordAction";
import * as routes from "../../components/routes/routesConstants";
import {Notify} from "../../components/notification/notify";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import ForgotPasswordForm from "./ForgotPasswordForm";

export class ForgotPasswordPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isValid: false,
            isLoading: false
        };

        this.onForgottenPasswordSubmit = this.onForgottenPasswordSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount(){
        PushFocusToElement("Email");
        this.props.actions.updatePageTitle_BreadCrumb(routes.FORGOT_PASSWORD);
    }

    onForgottenPasswordSubmit({fields, isValid}) {
        if (isValid) {
            this.setState({isLoading: true, isValid: true});
            this.props.actions.requestPasswordReset(fields)
                .then(() => this.setState({isLoading: false}))
                .catch(() => this.setState({isLoading: false}))
        }
        else {
            this.setState({isValid: false});
            PushFocusToElement("Email");
        }
    }

    onCancel() {
        Notify.Clear();
        this.props.history.push(routes.LOGIN.path);
    }

    render() {
        return (
            <div>
                <ForgotPasswordForm config={{
                    onForgotPasswordSubmit: this.onForgottenPasswordSubmit,
                    isValid: this.state.isValid,
                    onCancel: this.onCancel,
                    isLoading: this.state.isLoading
                }}/>
            </div>
        );
    }
}

ForgotPasswordPage.propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        forgotPasswordActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(null, mapDispatchToProps) (ForgotPasswordPage));
