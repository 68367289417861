import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as itemPoolConfigurationActions from "./ItemPoolConfigurationActions";
import * as routes from "../../components/routes/routesConstants";
import {getParams} from "../../components/layout/getParams";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import { getCatModelTypeDescription } from "../../constants/catModelConstants";
import ItemPoolConfigurationInfoForm from "./ItemPoolConfigurationInfoForm";
import {Notify} from "../../components/notification/notify";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import * as config from "../../constants/config";
import $ from 'jquery';
import * as loginActions from "../Login/LoginAction";

export class ItemPoolConfigurationInfoPage extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            itemPoolId: 0
        };

        this.onItemPoolSubmit = this.onItemPoolSubmit.bind(this);
        this.onReturnItemPoolList = this.onReturnItemPoolList.bind(this);
        this.onExportItemParameters = this.onExportItemParameters.bind(this);
        this.onImportItemParameters = this.onImportItemParameters.bind(this);
    }

    componentDidMount()
    {
        this.props.actions.updatePageTitle_BreadCrumb(routes.ITEM_POOL_INFO);

        const paramObj = getParams(this.props.location.pathname, routes.ITEM_POOL_INFO);
        if(paramObj !== null)
            this.setState(paramObj);

        let itemPoolId = parseInt(paramObj.itemPoolId);

        if(itemPoolId !== 0)
            this.props.actions.editItemPool(itemPoolId);
        else
            this.props.actions.addItemPool();

        $(document).ready(() => {
            PushFocusToElement("Name");
        });
    }

    onExportItemParameters(event) {
        event.preventDefault();
        this.props.actions.clearOutImportMessage();
        this.props.actions.exportItemParameterData(this.state.itemPoolId);
    }

    onImportItemParameters(event) {
        event.preventDefault();
        this.props.actions.clearOutImportMessage();
        const files = event.target.files;
        let fileData = new FormData();
        fileData.append("importItemParameters", files[0]);

        this.props.actions.importItemPool(fileData);
        $("#importItemParameters").val("");
    }

    isImportError(submitted, itemPoolInfo) {
        return submitted && itemPoolInfo.ItemParameters.length === 0;
    }

    onItemPoolSubmit({fields, isValid}) {
        if (this.isImportError(true, this.props.itemPoolInfo))
            return;

        if (isValid) {
            this.setState({isLoading: true});

            fields.CatModelTypeDescription =  getCatModelTypeDescription(fields.CatModelType);

            let detachedFields = Object.assign({}, {Name: fields.Name, CatModelType: fields.CatModelType, CatModelTypeDescription: fields.CatModelTypeDescription});
            if(detachedFields.Id === "")
                detachedFields.Id = 0;

            const itemPool = Object.assign({}, this.props.itemPoolInfo, detachedFields);

            this.props.actions.saveItemPool(itemPool)
                .then(() => this.props.history.push(routes.ITEM_POOL_MGMT.path))
                .catch(() => {
                    this.setState({isLoading: false});
                });
        }
        else {
            PushFocusToElement("Name");
        }
    }

    onReturnItemPoolList(event) {
        event.preventDefault();
        Notify.Clear();
        this.props.history.push(routes.ITEM_POOL_MGMT.path);
    }

    isItemPoolUpdateable() {
        if(this.state.itemPoolId === "0")
            return true;

        if(this.props.itemPoolInfo === undefined ||
            this.props.itemPoolInfo.Links === undefined)
            return false;

        const itemPoolLinkList = this.props.itemPoolInfo.Links.filter(f => f.Method === config.DELETE_API_METHOD);
        return (itemPoolLinkList.length > 0);
    }

    createPageDirections() {
        let pageDir = "";
        if(this.state.itemPoolId === "0")
            pageDir = "Create a new itemPool.";
        else {
            pageDir = !this.isItemPoolUpdateable() ? <strong className={"text-danger"}>NOTE - this item pool is associated with scores.  Only make changes that will not affect the overall outcome of previously saved scores.</strong> : "Edit an item pool.";
        }
        return <p>{pageDir}</p>;
    }

    render() {
        const {itemPoolInfo, importResults} = this.props;
        return (
            <div>
                <div className={"clearfix"}>
                    <Button config={{
                        name: "btnReturnItemPoolList",
                        label: "Return to Config List",
                        onClick: this.onReturnItemPoolList,
                        btnClass: "light float-right",
                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                    }}/>
                    {this.createPageDirections()}
                </div>
                {
                    itemPoolInfo.Id !== undefined &&
                    itemPoolInfo.Id.toString() === this.state.itemPoolId &&
                    <div>
                        <ItemPoolConfigurationInfoForm config={{
                            onItemPoolSubmit: this.onItemPoolSubmit,
                            isLoading: this.state.isLoading,
                            itemPoolInfo: itemPoolInfo,
                            onReturnItemPoolList: this.onReturnItemPoolList,
                            onImportItemParameters: this.onImportItemParameters,
                            onExportItemParameters: this.onExportItemParameters,
                            isImportError: this.isImportError,
                            importResults: importResults
                        }}/>
                    </div>
                }
            </div>
        );
    }
}

ItemPoolConfigurationInfoPage.propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    itemPoolInfo: PropTypes.object,
    itemPoolSearchCriteria: PropTypes.object,
    importResults: PropTypes.object
};

function mapStateToProps(state) {
    return {
        itemPoolInfo: state.itemPool.itemPoolInfo,
        importResults: state.itemPool.importResults
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        itemPoolConfigurationActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (ItemPoolConfigurationInfoPage));
