export function isInArray(array, string) {
	return !!array.filter(value => value === string).length;
}

export function isInSelectList(list, string) {
	return !!list.filter(item => item.value === string || item.text === string).length;
}

export function isObjectNullOrEmpty(obj) {
	return !(obj && Object.getOwnPropertyNames(obj).length !== 0);
}

export function isArrayNullOrEmpty(arr) {
	return !(isArray(arr) && arr.length !== 0);
}

export function isObjectArray(arr) {
	if (!isArrayNullOrEmpty(arr) && typeof arr[0] === 'object')
		return true;

	return false;
}

export function isArray(value) {
	return (typeof value === 'object' && value.constructor === Array);
}

export function isString(value) {
	return (typeof value === 'string' && value.constructor === String);
}

export function toString(value){
	return isNullOrUndefined(value) ? "" : value.toString();
}

export function isBoolean(value) {
	return (typeof value === 'boolean');
}

export function isNumber(value) {
	return !isNullOrUndefined(value) && !isNaN(value);
}

export function isNullOrUndefined(value) {
	return value === null || value === undefined;
}

export function convertToArray(unknownType) {
	if (isString(unknownType)) {
		unknownType = unknownType.split(',');
	}
	else if (!Array.isArray(unknownType)) {
		unknownType = [unknownType];
	}
	return unknownType;
}

export function deepCopyObject(object) {
	if (!object) return {};
	return JSON.parse(JSON.stringify(object));
}

export function deepCopyArray(array) {
	if (isArrayNullOrEmpty(array)) return [];
	return deepCopyObject(array);
}

export function arraysEqual(a, b) {
	/*
		Array-aware equality checker:
		Returns whether arguments a and b are === to each other;
		however if they are equal-lengthed arrays, returns whether their
		elements are pairwise === to each other recursively under this
		definition.
	*/
	if (a instanceof Array && b instanceof Array) {
		if (a.length !== b.length)  // assert same length
			return false;
		for (let i = 0; i < a.length; i++)  // assert each element equal
			if (!arraysEqual(a[i], b[i]))
				return false;
		return true;
	}
	else {
		return a === b;  // if not both arrays, should be the same
	}
}

export function dynamicSort(property, SortDescending = false, baseProperty = "") {
	let sortOrder = SortDescending ? -1 : 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function (a, b) {
		let result = 0;
		if (a[property] === b[property] && baseProperty !== "")
			result = (a[baseProperty] < b[baseProperty]) ? -1 : (a[baseProperty] > b[baseProperty]) ? 1 : 0;
		else
			result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
		return result * sortOrder;
	};
}

export function filterObjectArrayByField(array, filter, field) {
	let result = [...array];
	if (filter && !isArrayNullOrEmpty(array)) {
		const searchStrings = filter.split(",").map(d => d.trim());
		result =
			array
				.filter(ins => searchStrings
					.some(function (search) {
						return (ins[field].toLowerCase().indexOf(search.toLowerCase()) >= 0);
					}));
	}

	return result;
}

export function convertFlatArrayToObjectArray(flatArray, keyValue)
{
	if (isNullOrUndefined(keyValue))
		keyValue = "value";

	return flatArray.map(element => {let obj = {}; obj[keyValue] = element; return obj;});
}

function filterObjectArrayBySecondObjectArray(primaryArray, arrayField, subArray, subArrayField, isContainedInBoth)
{
	if (isContainedInBoth)
		return primaryArray.filter(search =>
			!!subArray.find(s => s[subArrayField].toString() === search[arrayField].toString())
		);
	else
		return primaryArray.filter(search =>
			!subArray.find(s => s[subArrayField].toString() === search[arrayField].toString())
		);
}

function filterArrayByObjectArray(primaryArray, subArray, subArrayField, isContainedInBoth)
{
	if (isContainedInBoth)
		return primaryArray.filter(search =>
			!!subArray.find(s => s[subArrayField].toString() === search.toString())
		);
	else
		return primaryArray.filter(search =>
			!subArray.find(s => s[subArrayField].toString() === search.toString())
		);
}

function filterArrayByArray(primaryArray, subArray, isContainedInBoth)
{
	if (isContainedInBoth)
		return primaryArray.filter(search => subArray.includes(search))
	else
		return primaryArray.filter(search => !subArray.includes(search))
}

export function filterArrayBySecondArray(primaryArray, arrayField, subArray, subArrayField, isContainedInBoth = false)
{
	if (isArrayNullOrEmpty(primaryArray) || isArrayNullOrEmpty(subArray))
		return primaryArray;

	let isPrimaryArrayObjectArray = isObjectArray(primaryArray);
	let isSubArrayObjectArray = isObjectArray(subArray);

	if(isPrimaryArrayObjectArray) {
		if (!isSubArrayObjectArray)
			subArray = convertFlatArrayToObjectArray(subArray, subArrayField);

		return filterObjectArrayBySecondObjectArray(primaryArray, arrayField, subArray, subArrayField, isContainedInBoth)
	}

	if(isSubArrayObjectArray)
	{
		return filterArrayByObjectArray(primaryArray, subArray, subArrayField, isContainedInBoth)
	}

	return filterArrayByArray(primaryArray, subArray, isContainedInBoth);

}

function uniqueValues(value, index, self)
{
	return self.indexOf(value) === index;
}

export function removeDuplicateItemsFromArray(array)
{
	return array.filter(uniqueValues);
}

export function generateSelectListFromArray(array) {
	let selectItems = [];

	if (isArrayNullOrEmpty(array)) return selectItems;

	array.map((item) => {
		selectItems.push(createListItem(item, convertCamelCaseToSpaceDelimited(item)));
	});

	return selectItems;
}

export function generateObjectFromArray(array) {
	let object = {};

	if (isArrayNullOrEmpty(array)) return object;

	array.map((item) => {
		object = Object.assign(object, {[item]: item});
	});

	return object;
}

export function createRedirectUrl(pathname, searchStr) {
	return (searchStr && searchStr !== "")
		? pathname + searchStr
		: pathname;
}


export function createListFromObject(object) {
	let result = [];
	for (let property in object) {
		if (objectHasProperty(object, property)) {
			result.push(createListItem(object[property], convertCamelCaseToSpaceDelimited(property), property));
		}
	}
	return result;
}

export function createListItem(value, text, id) {
	return {
		text: text ? text : value,
		value: value,
		id: id ? id : value
	};
}


export const generateArchivableSelectListFromArray = (items = [], value) => {
	let list = [];

	if (isString(value) && value.length > 1 && !items.includes(value))
		list.push(createListItem(value, `${value} (archived)`));

	if (isArray(items))
		items.map((item) => {
			list.push(createListItem(item));
		});
	return list;
};

export function convertCamelCaseToSpaceDelimited(string = "") {
	const regex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
	return string.replace(regex, '$1$4 $2$3$5');
}

export function convertToNumber(value = "not a number") {
	const conversion = Number(value);
	const result = isNaN(conversion) ? undefined : conversion;
	return result;
}

export function optionIndexInArray(array, option = "", wholeWord = true) {
	const optionValue = option.toLowerCase();

	for (let i = 0; i < array.length; i++) {
		const arrayValue = array[i].toLowerCase();
		if ((wholeWord && arrayValue === optionValue) || (!wholeWord && arrayValue.startsWith(optionValue)))
			return i;
	}

	return -1;
}

export function getPropName(prop, value) {
	let listPropertyNames = Object.keys(prop);
	return listPropertyNames.find(f => prop[f] === value);
}

export function getDistinctIds(array, id) {
	return [...new Set(array.map(item => {
		return item[id];
	}))];
}

export function getPropertyIfDefined(obj, prop) {
	if (isObjectNullOrEmpty(obj) || !obj[prop])
		return "";

	return obj[prop];
}

export function objectHasProperty(object, property) {
	return !isObjectNullOrEmpty(object) && !!Object.prototype.hasOwnProperty.call(object, property);
}

function uuidv4() {
	return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
}