import React from "react";
import PropTypes from "prop-types";

import TextField from "../../components/inputs/textField";
import ButtonIcon from "../../components/buttons/buttonIcon";
import CheckBox from "../../components/inputs/checkBoxField";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import SelectField from "../../components/inputs/selectField";
import {generateRoleOptions} from "./UserMgmtConstants";

const UserListEditForm = ({ config }) => {
    return (
            <tr>
                <td className={"text-center"}>
                    <CheckBox config={{
                        name: "IsActive",
                        label: "Is Active",
                        checked: (config.fields.IsActive),
                        showLabel: false,
                        error: (config.submitted && config.errors.IsActive) ? "*" : ""
                    }} />
                </td>
                <td>
                    <TextField config={{
                        name: "Email",
                        label: "Email",
                        maxLength: 300,
                        showLabel: false,
                        value: config.fields.Email,
                        error: (config.submitted && config.errors.Email) ? "*" : ""
                    }} />
                </td>
                <td>
                    <TextField config={{
                        name: "FirstName",
                        label: "First Name",
                        maxLength: 50,
                        showLabel: false,
                        value: config.fields.FirstName,
                        error: (config.submitted && config.errors.FirstName) ? "*" : ""
                    }} />
                </td>
                <td>
                    <TextField config={{
                        name: "LastName",
                        label: "Last Name",
                        maxLength: 50,
                        showLabel: false,
                        value: config.fields.LastName,
                        error: (config.submitted && config.errors.LastName) ? "*" : ""
                    }} />
                </td>
                <td>
                    <SelectField config={{
                        name: "RoleId",
                        label: "Role",
                        showLabel: false,
                        options: generateRoleOptions(),
                        defaultOption: "Select a Role",
                        value: config.fields.RoleId.toString(),
                        error: (config.submitted && config.errors.RoleId) ? "*" : ""
                    }} />
                </td>
                <td>
                    <SelectField config={{
                        name: "DistrictId",
                        label: "District",
                        showLabel: false,
                        options: config.districts,
                        defaultOption: "Select a District",
                        value: config.fields.DistrictId.toString(),
                        error: (config.submitted && config.errors.DistrictId) ? "*" : ""
                    }} />
                </td>
                <td className={"text-center"}>
                    <ButtonIcon config={{
                        name: "btnSave",
                        label: "Save",
                        iconType: buttonConstants.ICON_SAVE,
                        disabled: config.isLoading,
                        isSubmit: true
                    }} />
                    <ButtonIcon config={{
                        name: "btnCancel",
                        label: "Cancel",
                        onClick: config.onCancel,
                        iconType: buttonConstants.ICON_CANCEL,
                        disabled: config.isLoading
                    }} />
                    <input type={"hidden"} name={"Id"} value={config.fields.Id} />
                </td>
            </tr>
    );
};

UserListEditForm.propTypes = {
    config: PropTypes.shape({
        user: PropTypes.object.isRequired,
        onCancel: PropTypes.func.isRequired,
        fields: PropTypes.object.isRequired,
        submitted: PropTypes.bool.isRequired,
        errors: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        districts: PropTypes.array.isRequired
    })
};

export default UserListEditForm;