import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter } from "react-router-dom";
import {Notify} from "../../components/notification/notify";

import PushFocusToElement from '../../components/form/pushFocusToElement';
import {createRedirectUrl} from "../../components/routes/createRedirectUrl";
import LoginForm from "./LoginForm";

import * as loginActions from "./LoginAction";
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as routes from "../../components/routes/routesConstants";
import {ALLOWED_ROLES} from "./LoginConstants";

export class LoginPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isValid: false,
            isLoading: false
        };

        this.onLoginSubmit = this.onLoginSubmit.bind(this);
        this.onRegisterClick = this.onRegisterClick.bind(this);
        this.onForgotPasswordClick = this.onForgotPasswordClick.bind(this);
    }

    componentDidMount() {
        Notify.Clear();
        this.props.actions.logOff();

        const redirectUrl = createRedirectUrl(this.props.location.search, this.props.location.hash);
        if(redirectUrl != "")
            this.props.actions.setRedirectUrl(redirectUrl);

        PushFocusToElement("Email");
        this.props.actions.updatePageTitle_BreadCrumb(routes.LOGIN);
    }

    onLoginSubmit({fields, isValid}) {
        if (isValid) {
            this.setState({isLoading: true, isValid: true});
            this.props.actions.attemptLogin(fields)
                .then(() => this.redirect())
                .catch(() => this.setState({isLoading: false}))
        }
        else {
            this.setState({isValid: false});
            PushFocusToElement("Email");
        }
    }

    redirect() {
        this.setState({isLoading: false});
        const { history } = this.props;
        const {isLoggedIn, redirectUrl, role} = this.props.loginInfo;
        if(isLoggedIn) {
            if(ALLOWED_ROLES.includes(role) ) {
                if (redirectUrl !== "")
                    window.location.href = redirectUrl;
                else {
                    Notify.Clear();
                    history.push(routes.DASHBOARD.path);
                }
            }
        }
        else {
            Notify.Clear();
            history.push(routes.LOGIN.path);
        }
    }

    onRegisterClick(event) {
        event.preventDefault();
        Notify.Clear();
        this.props.history.push(routes.REGISTER.path);
    }

    onForgotPasswordClick(event) {
        event.preventDefault();

        Notify.Clear();
        this.props.history.push(routes.FORGOT_PASSWORD.path);
    }

    render() {
        return (
            <div>
                <LoginForm
                    onLoginSubmit={this.onLoginSubmit}
                    isValid={this.state.isValid}
                    isLoading={this.state.isLoading}
                    onRegisterClick={this.onRegisterClick}
                    onForgotPasswordClick={this.onForgotPasswordClick} />
            </div>
        );
    }
}

LoginPage.propTypes = {
    actions: PropTypes.object.isRequired,
    loginInfo: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        loginInfo: state.login
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        loginActions,
        headerActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (LoginPage));
