import * as config from "../../constants/config";
import {getAccounts} from "../../mockData/accountData";
import LocalStorage from "../../components/localStorage/access";
import {
    sendRequest,
    mockRejectApiCall,
    mockResolveApiCall
} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerHeaderApi {
    static getUserIdForHeader() {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}users/current`,
                RequestTypes.GET,
            ));
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockHeaderApi {
    static getUserIdForHeader() {
        const userAcct = getAccounts().filter(account => account.Id === parseInt(LocalStorage.getAccessToken()));
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (userAcct.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else
            return mockResolveApiCall({Email: userAcct[0].Email});
    }
}

const
    HeaderApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerHeaderApi : MockHeaderApi;

export default HeaderApi;