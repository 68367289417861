import React from "react";
import PropTypes from "prop-types";
import ButtonIcon from "../../components/buttons/buttonIcon";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import MediaPlayer from "../../components/audio/MediaPlayer";

const OptionRow = ({ option, isEditing, onEditOption, onDeleteOption }) => {

    let valueImage = <span>N/A</span>;
    if(option.FileData.get("ValueImage") !== null)
        valueImage = <em>{option.FileData.get("ValueImage").name} will be uploaded.</em>;
    else if(option.ValueImage !== "")
        valueImage = <img src={option.ValueImage} alt={"Value Image"} width={"50"} height={"50"}/>;

    let valueAudio = <span>N/A</span>;
    if(option.FileData.get("ValueAudio") !== null)
        valueAudio = <em>{option.FileData.get("ValueAudio").name} will be uploaded.</em>;
    else if(option.ValueAudio !== "")
        valueAudio = <MediaPlayer config={{url: option.ValueAudio}}/>;

    return (
        <tr>
            <td className={"text-center"}>
                {option.Name}
            </td>
            <td>
                {option.ValueText}
            </td>
            <td className={"text-center"}>
                {valueImage}
            </td>
            <td className={"text-center"}>
                {valueAudio}
            </td>
            <td className={"text-center"}>
                {(option.IsCorrect) ? "YES" : "NO"}
            </td>
            <td className={"text-center"}>
                <ButtonIcon config={{
                    disabled: isEditing,
                    name: "btnEdit",
                    label: "Edit",
                    onClick: onEditOption,
                    iconType: buttonConstants.ICON_EDIT
                }} />
                <ButtonIcon config={{
                    disabled: isEditing,
                    name: "btnDelete",
                    label: "Delete",
                    iconType: buttonConstants.ICON_DELETE,
                    onClick: onDeleteOption
                }} />
            </td>
        </tr>
    );
};

OptionRow.propTypes = {
    option: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onEditOption: PropTypes.func.isRequired,
    onDeleteOption: PropTypes.func.isRequired
};

export default OptionRow;