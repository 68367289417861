import React from "react";
import PropTypes from "prop-types";

const SearchResultsHeader = ({ order, selectedYear, assessmentPeriods }) => {
    if(assessmentPeriods.length === 1) {
        return (
            <thead>
                <tr>
                    <th onClick={order} order-by-value="StudentTrackingId" className="is-clickable">Student Tracking Id</th>
                    <th onClick={order} order-by-value="LastName" className="text-left is-clickable">Student Name</th>
                    <th>{selectedYear} - {assessmentPeriods[0].name}</th>
                </tr>
            </thead>
        );
    }
    else {
        return (
            <thead>
                <tr>
                    <th rowSpan={"2"} onClick={order} order-by-value="StudentTrackingId" className="is-clickable">Student
                        Tracking Id
                    </th>
                    <th rowSpan={"2"} onClick={order} order-by-value="LastName" className="text-left is-clickable">Student
                        Name
                    </th>
                    <th colSpan={"3"}>{selectedYear}</th>
                </tr>
                <tr>
                    {
                        assessmentPeriods.map((ap, index) => {
                            return (
                                <th key={index}>{ap.name}</th>
                            );
                        })
                    }
                </tr>
            </thead>
        );
    }
};

SearchResultsHeader.propTypes = {
    order: PropTypes.func.isRequired,
    selectedYear: PropTypes.string.isRequired,
    assessmentPeriods: PropTypes.array.isRequired
};

export default SearchResultsHeader;