import React from "react";
import PropTypes from "prop-types";
import TaskListSearchRow from "./TaskListSearchRow";
import ResultsPager from "../../components/table/ResultsPager";

const TaskListSearchResults = ({ config }) => {
    const numberOfColumns = "5";
    return (
        <div>
            <p>Tasks that have disabled delete icons cannot be deleted because they are associated with a score.</p>
            <table className="table-grid hover stack">
                <thead>
                <tr>
                    <th onClick={config.order} order-by-value="Name" className="text-left is-clickable">Task Name</th>
                    <th onClick={config.order} order-by-value="TaskType" className="text-left is-clickable">Type</th>
                    <th onClick={config.order} order-by-value="NumberOfForms" className="is-clickable">Number of Forms</th>
                    <th onClick={config.order} order-by-value="CatModelType" className="is-clickable">CAT Model</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {config.taskSearchResults.map(
                    (task, index) => {
                        return (<TaskListSearchRow
                            key={index}
                            task={task}
                            onCatConfigEdit={config.onCatConfigEdit}
                            onEditTask={config.onEditTask}
                            onDeleteTask={config.onDeleteTask} />);
                    }
                )}
                {config.taskTotalRecords === 0 &&
                <tr>
                    <td colSpan={numberOfColumns}>
                        No tasks match the search criteria.
                    </td>
                </tr>
                }
                </tbody>
                {config.taskTotalRecords > 0 &&
                <tfoot>
                <tr>
                    <td colSpan={numberOfColumns}>
                        <ResultsPager config={{
                            first: config.first,
                            previous: config.previous,
                            next: config.next,
                            last: config.last,
                            totalRecords: config.taskTotalRecords,
                            recordsPerPage: config.taskRecordsPerPage,
                            currentPage: config.currentPage
                        }}  />
                    </td>
                </tr>
                </tfoot>
                }
            </table>
        </div>
    );
};

TaskListSearchResults.propTypes = {
    config: PropTypes.shape({
        taskSearchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
        order: PropTypes.func,
        first: PropTypes.func.isRequired,
        last: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired,
        taskTotalRecords: PropTypes.number.isRequired,
        currentPage: PropTypes.number,
        taskRecordsPerPage: PropTypes.number,
        onCatConfigEdit: PropTypes.func.isRequired,
        onEditTask: PropTypes.func.isRequired,
        onDeleteTask: PropTypes.func.isRequired
    })
};

export default TaskListSearchResults;