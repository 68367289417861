import {beginAjaxCall, ajaxCallError} from "../AjaxStatus/AjaxStatusActions";
import taskApi from "./TaskApi";
import * as actionTypes from "./TaskConstants";
import {taskDefaultState} from "./TaskReducer";

export function loadTasksSuccess(data) {
    return {type: actionTypes.LOAD_TASKS_AJAX_SUCCESS, data};
}

export function loadTasks(taskFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return taskApi.getTasks(taskFilterCriteria)
            .then(data => {
                dispatch(loadTasksSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function loadTaskSelectListSuccess(data) {
    return {type: actionTypes.LOAD_TASK_SELECT_LIST_AJAX_SUCCESS, data};
}

export function loadTaskSelectList() {
    return dispatch => {
        dispatch(beginAjaxCall());
        let taskFilterCriteria = taskDefaultState().taskSearchCriteria;
        taskFilterCriteria.Fields = "Id,Name";

        return taskApi.getTasks(taskFilterCriteria)
            .then(data => {
                dispatch(loadTaskSelectListSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function saveTaskSearchCriteria(criteria) {
    return {type: actionTypes.SAVE_TASK_SEARCH_CRITERIA, criteria};
}

export function addTask() {
    return {type: actionTypes.ADD_TASK};
}

export function editTaskSuccess(task) {
    return {type: actionTypes.EDIT_TASK_AJAX_SUCCESS, data: task};
}

export function editTask(taskId) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return taskApi.getTask(taskId)
            .then((task) => {
                dispatch(editTaskSuccess(task));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function saveTaskSuccess() {
    return {type: actionTypes.SAVE_TASK_AJAX_SUCCESS};
}

export function saveTask(task) {
    return dispatch => {
        dispatch(beginAjaxCall());

        if (task.Id === 0) {
            return taskApi.addTask(task)
                .then((task) => {
                    dispatch(saveTaskSuccess());
                    return task.Id;
                })
                .catch(error => {
                        dispatch(ajaxCallError(error));
                        throw (error);
                    }
                );
        } else {
            return taskApi.updateTask(task)
                .then(() => {
                    dispatch(saveTaskSuccess());
                    return task.Id;
                })
                .catch(error => {
                        dispatch(ajaxCallError(error));
                        throw (error);
                    }
                );
        }
    };
}

export function deleteTaskSuccess() {
    return {type: actionTypes.DELETE_TASK_AJAX_SUCCESS};
}

export function deleteTask(deleteLinkInfo) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return taskApi.deleteTask(deleteLinkInfo)
            .then(() => {
                dispatch(deleteTaskSuccess());
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            );
    };
}

export function editForm(index) {
    return {type: actionTypes.EDIT_FORM, data: index};
}

export function cancelEditForm() {
    return {type: actionTypes.CANCEL_EDIT_FORM};
}

export function updateForm(form, isEditing, editingRowIndex) {
    return {type: actionTypes.UPDATE_FORM, data: {form: form, isEditing: isEditing, editingRowIndex: editingRowIndex}};
}

export function addForm() {
    return {type: actionTypes.ADD_FORM};
}

export function deleteForm(index) {
    return {type: actionTypes.DELETE_FORM, data: index};
}