import { DefaultError } from "./DefaultError";
import { NoNotification } from "./NoNotification";
import { DefaultNotification }  from "./DefaultNotification";
import { MultiErrorMessageNotification }  from "./MultiErrorMessageNotification";
import { NotificationTypes } from "./NotificationTypes";
import { ToastrAdapter } from "./ToastrAdapter";
import {catchError} from "../common/actionUtility";

export class ToastrNotificationFactory {
    constructor() {
        this._toastrAdapter = new ToastrAdapter();
    }

    clear() {
        return new this._toastrAdapter.clear();
    }

    createNone() {
        return new NoNotification(); 
    }

    createSuccess(message) {
        return new DefaultNotification(this._toastrAdapter, NotificationTypes.SUCCESS, message);
    }

    createWarning(message) {
        return new DefaultNotification(this._toastrAdapter, NotificationTypes.WARNING, message);
    }

    createError(message) {
        return new DefaultNotification(this._toastrAdapter, NotificationTypes.ERROR, message);
    }

    createErrorsFromXhrObject(xhrObject) {
	    if(xhrObject.status === 403)
            return new DefaultNotification(this._toastrAdapter, NotificationTypes.ERROR, "Please contact support to verify your access to this section.");

        if (xhrObject.status === 404)
            return new DefaultNotification(this._toastrAdapter, NotificationTypes.ERROR, "Your session has expired.  Please login again.");

        if (xhrObject.responseText === "")
            return new NoNotification();
    
        let errorResponseObject;

        try {
            errorResponseObject = JSON.parse(xhrObject.responseText);
        }
        catch(e){
            catchError('The server did not return a valid error object.');
	        return new DefaultError(this._toastrAdapter);
        }

        if (!errorResponseObject || !errorResponseObject.Errors)
            return new DefaultError(this._toastrAdapter);

        let notification;
        let totalErrors = errorResponseObject.TotalErrors;

        if (totalErrors === 1)
            notification = new DefaultNotification(this._toastrAdapter, NotificationTypes.ERROR, errorResponseObject.Errors[0].Message);
        else if (totalErrors > 1)
            notification = new MultiErrorMessageNotification(this._toastrAdapter, errorResponseObject.Errors);
        else 
            notification = new NoNotification();

        return notification;
    }

    createInfo(message) {
        let notification = new DefaultNotification(this._toastrAdapter, NotificationTypes.INFO, message);
        return notification;
    }
}