import * as actionTypes from "./ReportingConstants";

export function reportingDefaultState() {
    return {
        reportingSearchCriteria: {
            StartDate: "",
            EndDate: "",
            Export: "",
            reportingSearchPerformed: false
        },
        uniqueScores: 0,
        uniqueAudioFiles: 0
    };
}

export const reportingInitialState = reportingDefaultState();

export function reportingReducer(state = reportingInitialState, action) {
    switch(action.type){
        case actionTypes.LOAD_REPORTING_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    uniqueScores: action.data.UniqueScores,
                    uniqueAudioFiles: action.data.UniqueAudioFiles
                });
        case actionTypes.SAVE_REPORTING_SEARCH_CRITERIA:
            return Object.assign({}, state, { reportingSearchCriteria: action.criteria});
        default:
            return state;
    }
}