import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter, Link } from "react-router-dom";
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import localStorage from "../../components/localStorage/access";
import {DashboardRoutes, DASHBOARD_MAX_TILES_PER_ROW} from "./DashboardConstants";
import * as routes from "../../components/routes/routesConstants";

export class DashboardPage extends React.Component {
    constructor(props, context) {
        super(props, context);

    }

    componentDidMount(){
        this.props.actions.updatePageTitle_BreadCrumb(routes.DASHBOARD);
    }

    render() {
            return (
                <div id={"dashboard"} className={"grid-x"}>
                    {DashboardRoutes[localStorage.getRole()].map(function (route, index) {
                        const numberOfColumns = parseInt(12 / DASHBOARD_MAX_TILES_PER_ROW).toString();
                        let classType = (index % DASHBOARD_MAX_TILES_PER_ROW !== (DASHBOARD_MAX_TILES_PER_ROW - 1)) ? numberOfColumns : "auto";
                        return (
                            <div className={"cell tile-container medium-" + classType + " large-" + classType}
                                 key={index}>
                                <div className={"tile"}>
                                    <Link to={route.path}
                                          className={"button large tile-" + route.section.color}>{route.section.display}</Link>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }
}

DashboardPage.propTypes = {
    actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(null, mapDispatchToProps) (DashboardPage));
