import React from "react";
import PropTypes from "prop-types";
import ItemListSearchRow from "./ItemListSearchRow";
import ResultsPager from "../../components/table/ResultsPager";

const ItemListSearchResults = ({ config }) => {
    const numberOfColumns = "5";

    return (
        <div>
            <p>Items that have disabled delete icons cannot be deleted because they are associated with a score.</p>
            <table className="table-grid hover stack">
                <thead>
                <tr>
                    <th onClick={config.order} order-by-value="Name" className="text-left is-clickable">Item Name</th>
                    <th onClick={config.order} order-by-value="PromptText" className="text-left is-clickable">Prompt Text</th>
                    <th>Image</th>
                    <th>Audio</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {config.itemSearchResults.map(
                    (item, index) => {
                        return (<ItemListSearchRow
                            key={index}
                            item={item}
                            onEditItem={config.onEditItem}
                            onDeleteItem={config.onDeleteItem} />);
                    }
                )}
                {config.itemTotalRecords === 0 &&
                <tr>
                    <td colSpan={numberOfColumns}>
                        No items match the search criteria.
                    </td>
                </tr>
                }
                </tbody>
                {config.itemTotalRecords > 0 &&
                <tfoot>
                <tr>
                    <td colSpan={numberOfColumns}>
                        <ResultsPager config={{
                            first: config.first,
                            previous: config.previous,
                            next: config.next,
                            last: config.last,
                            totalRecords: config.itemTotalRecords,
                            recordsPerPage: config.itemRecordsPerPage,
                            currentPage: config.currentPage
                        }}  />
                    </td>
                </tr>
                </tfoot>
                }
            </table>
        </div>
    );
};

ItemListSearchResults.propTypes = {
    config: PropTypes.shape({
        itemSearchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
        order: PropTypes.func,
        first: PropTypes.func.isRequired,
        last: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired,
        itemTotalRecords: PropTypes.number.isRequired,
        currentPage: PropTypes.number,
        itemRecordsPerPage: PropTypes.number,
        onEditItem: PropTypes.func.isRequired,
        onDeleteItem: PropTypes.func.isRequired
    })
};

export default ItemListSearchResults;