import React from "react";
import PropTypes from "prop-types";
import * as buttonConstants from "./buttonConstants";

const ButtonLink = ({ config }) => {
    config = Object.assign({}, buttonLinkDefaults(), config);

    return (
        <button id={config.name} type={buttonConstants.BUTTON_TYPE_BUTTON} onClick={config.onClick} className={"button-link"} disabled={config.disabled} tabIndex={config.tabIndex}><span />{config.label}</button>
    );
};

ButtonLink.propTypes = {
    config: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        tabIndex: PropTypes.number
    })
};

function buttonLinkDefaults() {
    return {
        disabled: false
    };
}

export default ButtonLink;