import React from "react";
import PropTypes from "prop-types";
import ItemParameterRow from "./ItemParameterRow";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import Button from "../../components/buttons/button";
import ButtonBar from "../../components/buttons/buttonBar";
import FileUploadButton from "../../components/inputs/fileUploadButton";

const ItemParameterList = ({ config }) => {
    const numberOfColumns = "3";

    return (
        <div>
            <div className={"grid-x"}>
                <div className={"cell auto"}>
                    <h2>Item Parameters</h2>
                </div>
                <div className={"cell text-right medium-6 large-6"}>
                    <ButtonBar buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_RIGHT}>
                        <Button config={{
                            name: "btnExport",
                            label: "Export Excel",
                            onClick: config.onExportItemParameters,
                            btnClass: "light",
                            btnType: buttonConstants.BUTTON_TYPE_BUTTON,
                            disabled: (config.itemPoolInfo.Id === 0)
                        }}/>
                        <FileUploadButton name={"importItemParameters"} label={"Import Excel"} onChange={config.onImportItemParameters}/>
                    </ButtonBar>
                </div>
            </div>
            <div className={"grid-x"}>
                <div className={"cell text-right auto"}>
                    <p>The Import Excel file needs to contain columns with headers Item Name, A, and B.</p>
                </div>
            </div>

            <table className="table-grid hover stack">
                <thead>
                <tr>
                    <th className={"text-left"}>Item Name</th>
                    <th>A</th>
                    <th>B</th>
                </tr>
                </thead>
                <tbody>
                {config.itemPoolInfo.ItemParameters.map(
                    (itemParameter, index) => {
                            return (<ItemParameterRow
                                key={index}
                                itemParameter={itemParameter}/>);
                    }
                )}
                {config.itemPoolInfo.ItemParameters.length === 0 &&
                <tr>
                    <td colSpan={numberOfColumns}>
                        Click &quot;Import Excel&quot; to add item parameters.
                    </td>
                </tr>
                }
                </tbody>
            </table>
            {config.itemPoolInfo.ItemParameters.length !== 0 &&
                <div className={"grid-x"}>
                    <div className={"cell text-right auto"}>
                        Last imported from &quot;{config.itemPoolInfo.LastImportFileName}&quot; on {config.itemPoolInfo.LastImportDate}
                    </div>
                </div>
            }
        </div>
    );
};

ItemParameterList.propTypes = {
    config: PropTypes.shape({
        itemPoolInfo: PropTypes.object,
        onExportItemParameters: PropTypes.func,
        onImportItemParameters: PropTypes.func
    })
};

export default ItemParameterList;