import * as config from "../../constants/config";
import { mockResolveApiCall, sendRequest} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";
import {isNullOrUndefined} from "../../components/common/commonUtilities";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerSiteDetailsApi {
    static getSiteDetails() {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}siteSettings`,
                RequestTypes.GET,
            ));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockSiteDetailsApi {
    static getSiteDetails() {
        return mockResolveApiCall({SiteName: "MOCK", SupportEmail: "support+MCK@fcim.org"});
    }
}

const siteDetailApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerSiteDetailsApi : MockSiteDetailsApi;
export default siteDetailApi;