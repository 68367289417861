import * as actionTypes from "./AssessmentConstants";
import * as config from "../../constants/config";
import {convertDataToOptions} from "../../components/generalData/convertGeneralDataToOptions";

export function assessmentDefaultState() {
    return {
        assessmentSearchCriteria: {
            NameContains: "",
            TaskNameContains: "",
            Page: 1,
            RecordsPerPage: config.defaultRecordsPerPage,
            OrderByColumn: "Name",
            OrderDescending: false,
            assessmentSearchPerformed: false,
            Fields: ""
        },
        assessmentTotalRecords: 0,
        assessmentSearchResults: [],
        assessmentSelectList: [],
        isEditing: false,
        assessmentInfo: {
            Id: 0,
            Name: "",
            AssessmentType: actionTypes.generateAssessmentTypeOptions()[0].value,
            TaskIds: [],
            McatTaskGroupCount: 0,
            McatTaskGroups:[],
            DatePublished:"",
            IsPublished: false
        }
    };
}

export const assessmentInitialState = assessmentDefaultState();

export function assessmentReducer(state = assessmentInitialState, action) {
    switch(action.type){
        case actionTypes.LOAD_ASSESSMENTS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    assessmentSearchResults: [...action.data.Data],
                    assessmentTotalRecords: action.data.TotalRecords
                });
        case actionTypes.SAVE_ASSESSMENT_SEARCH_CRITERIA:
            return Object.assign({}, state, { assessmentSearchCriteria: action.criteria});
        case actionTypes.EDIT_ASSESSMENT_AJAX_SUCCESS:
        case actionTypes.UPDATE_ASSESSMENT:
            return Object.assign({}, state, { assessmentInfo: Object.assign({}, state.assessmentInfo, action.data)});
        case actionTypes.ADD_ASSESSMENT:
        case actionTypes.SAVE_ASSESSMENT_AJAX_SUCCESS:
            return Object.assign({}, state, { assessmentInfo:  assessmentDefaultState().assessmentInfo});
        case actionTypes.PUBLISH_ASSESSMENT_AJAX_SUCCESS:
            return Object.assign({}, state, { assessmentInfo: Object.assign({}, state.assessmentInfo, action.data)});
        case actionTypes.ASSOCIATE_TASK_TO_ASSESSMENT:
            return Object.assign(
                {},
                state,
                {
                    assessmentInfo: Object.assign(
                        {},
                        state.assessmentInfo,
                        {TaskIds: [...state.assessmentInfo.TaskIds, action.data]})
                });
        case actionTypes.UNASSOCIATE_TASK_TO_ASSESSMENT: {
            let allTasks = [...state.assessmentInfo.TaskIds];
            allTasks.splice(action.data, 1);
            return Object.assign(
                {},
                state,
                {assessmentInfo: Object.assign({}, state.assessmentInfo, {TaskIds: allTasks})}
            );
        }
        case actionTypes.LOAD_ITEM_POOL_SELECT_LIST_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    itemPoolSelectList: convertDataToOptions([...action.data.Data], "Id", "%s;Name")
                });
        case actionTypes.SAVE_MCAT_AJAX_SUCCESS:
            return Object.assign({}, state, { assessmentInfo:  assessmentDefaultState().assessmentInfo});
        default:
            return state;
    }
}