import React from "react";
import {Notify} from "../notification/notify";

export class Search extends React.Component {
    constructor(props, context, itemInitialState) {
        super(props, context);

        this.initialState = itemInitialState;

        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.clearCriteria = this.clearCriteria.bind(this);
        this.order = this.order.bind(this);
        this.first = this.first.bind(this);
        this.last = this.last.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    setLoadSearchResults(loadSearchResults) {
        this.loadSearchResults = loadSearchResults;
    }

    setProps(searchCriteria, totalRecords) {
        this.searchCriteria = searchCriteria;
        this.totalRecords = totalRecords;
    }

    onSearchSubmit({fields}) {
        Notify.Clear();
        let criteria = Object.assign({}, this.searchCriteria, fields);
        criteria.Page = 1;
        this.loadSearchResults(criteria);
    }

    clearCriteria(event, setField) {
        event.preventDefault();
        Notify.Clear();
        const initState = Object.assign({}, this.initialState);
        this.loadSearchResults(initState);

        setField(initState);
    }

    order(event) {
        event.preventDefault();

        const target = event.target;
        if(target.attributes["order-by-value"]) {
            let criteria = Object.assign({}, this.searchCriteria);
            const columnOrderBy = target.attributes["order-by-value"].nodeValue;
            if (criteria.OrderByColumn === columnOrderBy) {
                criteria.OrderDescending = !criteria.OrderDescending;
            }
            else {
                criteria.OrderDescending = false;
                criteria.OrderByColumn = columnOrderBy;
            }

            this.loadSearchResults(criteria);
        }
    }

    first(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.searchCriteria);
        criteria.Page = 1;

        this.loadSearchResults(criteria);
    }

    last(event) {
        event.preventDefault();

        const totalRecords = this.totalRecords;

        const lastPage = Math.ceil(totalRecords / this.searchCriteria.RecordsPerPage);

        let criteria = Object.assign({}, this.searchCriteria);
        criteria.Page = lastPage;

        this.loadSearchResults(criteria);
    }

    next(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.searchCriteria);
        criteria.Page = criteria.Page + 1;

        this.loadSearchResults(criteria);
    }

    previous(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.searchCriteria);
        criteria.Page--;

        this.loadSearchResults(criteria);
    }
}