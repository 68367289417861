import React from "react";
import PropTypes from "prop-types";
import AssessmentListSearchRow from "./AssessmentListSearchRow";
import ResultsPager from "../../components/table/ResultsPager";

const AssessmentListSearchResults = ({ config }) => {
    const numberOfColumns = "7";

    return (
        <div>
            <p>Assessments that have disabled delete icons cannot be deleted because they are associated with a score.</p>
            <table className="table-grid hover stack">
                <thead>
                <tr>
                    <th onClick={config.order} order-by-value="AssessmentType" className="is-clickable">Type</th>
                    <th onClick={config.order} order-by-value="Name" className="text-left is-clickable">Assessment Name</th>
                    <th onClick={config.order} order-by-value="DateCreated" className="is-clickable">Date Created</th>
                    <th onClick={config.order} order-by-value="DateModified" className="is-clickable">Date Modified</th>
                    <th onClick={config.order} order-by-value="NumberOfTasks" className="is-clickable">Number of Tasks</th>
                    <th onClick={config.order} order-by-value="McatTaskGroupCount" className="is-clickable">MCAT Groups</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {config.assessmentSearchResults.map(
                    (assessment, index) => {
                        return (<AssessmentListSearchRow
                            key={index}
                            assessment={assessment}
                            onEditAssessment={config.onEditAssessment}
                            onMcatGroupEdit={config.onMcatGroupEdit}
                            onDeleteAssessment={config.onDeleteAssessment} />);
                    }
                )}
                {config.assessmentTotalRecords === 0 &&
                <tr>
                    <td colSpan={numberOfColumns}>
                        No assessments match the search criteria.
                    </td>
                </tr>
                }
                </tbody>
                {config.assessmentTotalRecords > 0 &&
                <tfoot>
                <tr>
                    <td colSpan={numberOfColumns}>
                        <ResultsPager config={{
                            first: config.first,
                            previous: config.previous,
                            next: config.next,
                            last: config.last,
                            totalRecords: config.assessmentTotalRecords,
                            recordsPerPage: config.assessmentRecordsPerPage,
                            currentPage: config.currentPage
                        }}  />
                    </td>
                </tr>
                </tfoot>
                }
            </table>
        </div>
    );
};

AssessmentListSearchResults.propTypes = {
    config: PropTypes.shape({
        assessmentSearchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
        order: PropTypes.func,
        first: PropTypes.func.isRequired,
        last: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired,
        assessmentTotalRecords: PropTypes.number.isRequired,
        currentPage: PropTypes.number,
        assessmentRecordsPerPage: PropTypes.number,
        onEditAssessment: PropTypes.func.isRequired,
        onDeleteAssessment: PropTypes.func.isRequired,
        onMcatGroupEdit: PropTypes.func.isRequired
    })
};

export default AssessmentListSearchResults;