import React from "react";
import PropTypes from "prop-types";
import DateTimePicker from "react-flatpickr";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin.js";
import "flatpickr/dist/themes/light.css";

const DateTimePickerField = ({ config }) => {

    config = Object.assign({}, dateTimePickerFieldDefaults(), config);

    if (!config.showLabel) config.labelClass += " is-visuallyhidden";
    config.wrapperClass += config.disabled ? " is-disabled" : "";

    if (config.error && config.error.length > 0) {
        config.wrapperClass += " has-error";
    }

    const options = {
        altFormat: config.dateFormat,
        allowInput: true,
        altInput: true,
        enableTime: config.enableTime,
        onChange: config.onChange,
        class: config.name,
        plugins: [labelPlugin()],
        onReady: (a, b, fp) => {
            fp.altInput.setAttribute("aria-label", config.ariaLabel ? config.ariaLabel : config.label);
        },
    };

    return (
        <div className={"grid-x " + config.wrapperClass}>
            <label className={"cell " + config.labelClass} htmlFor={name}>{ (config.isRequired) && <span className="text-danger">* </span>}{config.label}</label>
            <div className={"cell " + config.inputClass}>
                <DateTimePicker
                    value={config.value}
                    options={options}
                />
            </div>

            {config.error && <div className="text-danger">{config.error}</div>}
        </div>

    );
};

DateTimePickerField.propTypes = {
    config: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string,
        showLabel: PropTypes.bool,
        error: PropTypes.string,
        disabled: PropTypes.bool,
        ariaLabel: PropTypes.string,

        wrapperClass: PropTypes.string,
        labelClass: PropTypes.string,
        inputClass: PropTypes.string,
        isRequired: PropTypes.bool,
        dateFormat: PropTypes.string,
        enableTime: PropTypes.bool
    })
};

function dateTimePickerFieldDefaults() {
    return {
        wrapperClass: "",
        disabled: false,
        labelClass: "small-12",
        inputClass: "small-12",
        isRequired: false,
        dateFormat: "m-j-Y h:iK",
        enableTime: true,
        ariaLabel: "",
    };
}

export default DateTimePickerField;