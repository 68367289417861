import {beginAjaxCall, ajaxCallError} from "../AjaxStatus/AjaxStatusActions";
import slabApi from "./SlabApi";
import * as actionTypes from "./SlabConstants";

export function loadSlabInfoSuccess(data) {
    return {type: actionTypes.LOAD_SLAB_AJAX_SUCCESS, data};
}

export function slabAjaxSuccess() {
    return {type: actionTypes.SLAB_AJAX_SUCCESS};
}

export function loadSlabInfo(slabFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return slabApi.getSlabInfo(slabFilterCriteria)
            .then(data => {
                dispatch(loadSlabInfoSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function saveSlabSearchCriteria(criteria) {
    return {type: actionTypes.SAVE_SLAB_SEARCH_CRITERIA, criteria};
}

export function processAudioFiles(slabFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return slabApi.processAudioFiles(slabFilterCriteria)
            .then(() => {
                slabApi.getSlabInfo(slabFilterCriteria)
                    .then(data => {
                        dispatch(loadSlabInfoSuccess(data));
                    })
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            );

    };
}

export function exportSlabData(slabFilterCriteria) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return slabApi.exportSlabData(slabFilterCriteria)
            .then(() => {
                dispatch(slabAjaxSuccess());
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            );
    };
}