export function generateCatModelOptions(includeNoneValue = false) {

    const catModeList =  [
        { value: 1, name: "UCAT" },
        { value: 2, name: "CIRT Scoring" },
        { value: 3, name: "CIRT Adapting" },
        { value: 4, name: "Mastery" },
        { value: 5, name: "MCAT Bifactor" },
        { value: 6, name: "MCAT ESEM" }
    ];

    if (includeNoneValue)
        catModeList.unshift({ value: 0, name: "None"});

    return catModeList;
}

export function getCatModelTypeDescription(catModelType) {
    let catModelOptions = generateCatModelOptions(true);
    catModelType = isNaN(catModelType) || catModelType === "" ? 0 : catModelType;
    return catModelOptions.find(catModel=>catModel.value === parseInt(catModelType)).name;
}

export const ItemPoolConfigDisplaySpeed = [2, 3];
export const CIRTAdaptingModelValue = 3;
export const MasteryModel = 4;

export function generateMcatModelOptions(includeNoneValue=false) {
    const mCatModelList = [
        { value: 1, name: "MCAT Bifactor" },
        { value: 2, name: "MCAT ESEM" }
    ];

    if (includeNoneValue)
        mCatModelList.unshift({ value: 0, name: "None"});

    return mCatModelList;
}