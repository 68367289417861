import {generalDataList} from "./generalDataConstants";

export function convertGeneralDataToOptions(rawGeneralDataOptions, generalDataName) {

    const generalDataOption = generalDataList.filter(f => f.name.toLowerCase() === generalDataName.toLowerCase());
    if(generalDataOption.length === 0)
        return [];


    return convertDataToOptions(rawGeneralDataOptions, generalDataOption[0].value, generalDataOption[0].text);
}

export function convertDataToOptions(rawDataOptions, valueParam, textParam) {
    let textArr = (Array.isArray(textParam)) ? textParam : [textParam]

    return rawDataOptions.map(item => {
        return {
            value: item[valueParam],
            name: adjustText(item, textArr)
        }
    });
}

function adjustText(item, outputOptions){
    let newText = "";
    for(let i = 0; i < outputOptions.length; i++) {
        var allOptions = outputOptions[i].split(';');

        let allPropertiesHaveValue = checkToSeeIfAllPropertiesHaveValues(item, allOptions);

        if(allPropertiesHaveValue) {
            let argIterator = 1;
            return allOptions[0].replace(/%s/g, function() {
                return item[allOptions[argIterator++]];
            });
        }
    }

    return newText;
}

function checkToSeeIfAllPropertiesHaveValues(item, allOptions) {
    let allPropertiesHaveValue = true;
    for(let j = 1; j < allOptions.length && allPropertiesHaveValue; j++) {
        if(!item[allOptions[j]] || item[allOptions] === "" )
            allPropertiesHaveValue = false;
    }

    return allPropertiesHaveValue;
}