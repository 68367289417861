export const LOAD_ITEMS_AJAX_SUCCESS = "LOAD_ITEMS_AJAX_SUCCESS";
export const LOAD_ITEM_SELECT_LIST_AJAX_SUCCESS = "LOAD_ITEM_SELECT_LIST_AJAX_SUCCESS";
export const SAVE_ITEM_AJAX_SUCCESS = "SAVE_ITEM_AJAX_SUCCESS";
export const SAVE_ITEM_SEARCH_CRITERIA = "SAVE_ITEM_SEARCH_CRITERIA";
export const EDIT_ITEM_AJAX_SUCCESS = "EDIT_ITEM_AJAX_SUCCESS";
export const ADD_ITEM = "ADD_ITEM";
export const CANCEL_ITEM = "CANCEL_ITEM";
export const DELETE_ITEM_AJAX_SUCCESS = "DELETE_ITEM_AJAX_SUCCESS";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const EDIT_OPTION = "EDIT_OPTION";
export const CANCEL_EDIT_OPTION = "CANCEL_EDIT_OPTION";
export const UPDATE_OPTION = "UPDATE_OPTION";
export const ADD_OPTION = "ADD_OPTION";
export const DELETE_OPTION = "DELETE_OPTION";
