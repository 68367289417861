import React from "react";
import PropTypes from "prop-types";
import ButtonBar from "../../components/buttons/buttonBar";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import Button from "../../components/buttons/button";

const SlabSearchResults = ({ processedAudioFiles, remainingAudioFiles, reprocessFiles, onExportScores, onProcessAudioFiles, isAjaxLoading }) => {
    const anyScoresForExport = (processedAudioFiles > 0);
    const anyAudioFilesToProcess = (remainingAudioFiles > 0);
    return (
        <div>
            <table className="table-grid shrink stack align-center margin-top-2 margin-bottom-3">
                <tbody>
                <tr>
                    <td className={"text-right"}>Number of Processed Audio Files</td>
                    <td className={"text-center"}>{processedAudioFiles}</td>
                </tr>
                <tr>
                    <td className={"text-right"}>Number of Audio Files to be Processed</td>
                    <td className={"text-center"}>{remainingAudioFiles}</td>
                </tr>
                </tbody>
            </table>

            <ButtonBar name={"exportBtnContainers"} buttonBarPosition={buttonConstants.BUTTON_BAR_POSITION_CENTER}>
                <Button config={{
                    name: "btnExportScores",
                    label: "Export Scores",
                    onClick: onExportScores,
                    btnClass: "secondary",
                    btnType: buttonConstants.BUTTON_TYPE_BUTTON,
                    disabled: !anyScoresForExport || isAjaxLoading
                }}/>
                <Button config={{
                    name: "btnProcessAudioFiles",
                    label: "Process Audio Files",
                    onClick: onProcessAudioFiles,
                    btnClass: "secondary",
                    btnType: buttonConstants.BUTTON_TYPE_BUTTON,
                    disabled: (!reprocessFiles && !anyAudioFilesToProcess) || isAjaxLoading
                }}/>
            </ButtonBar>
        </div>
    );
};

SlabSearchResults.propTypes = {
    processedAudioFiles: PropTypes.number,
    remainingAudioFiles: PropTypes.number,
    reprocessFiles: PropTypes.bool,
    onExportScores: PropTypes.func.isRequired,
    onProcessAudioFiles: PropTypes.func.isRequired,
    isAjaxLoading: PropTypes.bool
};

export default SlabSearchResults;