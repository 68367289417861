import * as actionTypes from "./StudentMgmtConstants";
import * as config from "../../constants/config";

export const studentMgmtInitialState = {
    studentSearchCriteria: {
        NameContains: "",
        StudentTrackingIdContains: "",
        IsActive: null,
        Page: 1,
        RecordsPerPage: config.defaultRecordsPerPage,
        OrderByColumn: "LastName",
        OrderDescending: false,
        studentSearchPerformed: false
    },
    studentTotalRecords: 0,
    studentSearchResults: [],
    isEditing: false,
    editingStudentId: 0,
    importResults: {
        Message: ""
    }
};

export function studentMgmtReducer(state = studentMgmtInitialState, action) {
    switch(action.type){
        case actionTypes.LOAD_STUDENTS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    studentSearchResults: [...action.data.Data],
                    studentTotalRecords: action.data.TotalRecords
                });
        case actionTypes.SAVE_STUDENT_SEARCH_CRITERIA:
            return Object.assign({}, state, { studentSearchCriteria: action.criteria});
        case actionTypes.EDIT_STUDENT:
            return Object.assign({}, state, { isEditing: true, editingStudentId: action.data });
        case actionTypes.CANCEL_EDIT_STUDENT:
            return Object.assign({}, state, { isEditing: false, editingStudentId: 0 });
        case actionTypes.SAVE_STUDENT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    isEditing: false,
                    editingStudentId: 0,
                    studentSearchResults: state.studentSearchResults.map(student => {
                        let detachedStudent = Object.assign({}, student);

                        if(detachedStudent.Id !== action.data.Id) return detachedStudent;

                        return action.data;
                    }),
                });
        case actionTypes.IMPORT_STUDENTS_AJAX_SUCCESS:
        case actionTypes.CLEAR_OUT_IMPORT_MESSAGE:
            return Object.assign({}, state, {importResults: action.importResults});
        default:
            return state;
    }
}