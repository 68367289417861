import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter } from "react-router-dom";
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as resetPasswordActions from "./ResetPasswordAction";
import * as routes from "../../components/routes/routesConstants";
import {Notify} from "../../components/notification/notify";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import ResetPasswordForm from "./ResetPasswordForm";
import {getParams} from "../../components/layout/getParams";

export class ResetPasswordPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            resetPasswordGuid: ""
        };

        this.onResetPasswordSubmit = this.onResetPasswordSubmit.bind(this);
        this.onReturn = this.onReturn.bind(this);
    }

    componentDidMount(){
        PushFocusToElement("Password");
        this.props.actions.updatePageTitle_BreadCrumb(routes.RESET_PASSWORD);

        const paramObj = getParams(this.props.location.pathname, routes.RESET_PASSWORD);
        if(paramObj !== null)
            this.setState(paramObj);
        else
            Notify.Error("There is an error with the link you used to reset your password.  Please send yourself another reset password link.", () => { this.props.history.push(routes.FORGOT_PASSWORD.path); })
    }

    onResetPasswordSubmit({fields, isValid}) {
        if (isValid) {
            this.setState({isLoading: true});
            let detachedFields = Object.assign({}, fields);
            detachedFields.ResetPasswordGuid = this.state.resetPasswordGuid;

            this.props.actions.resetPassword(detachedFields)
                .then(() => {
                    this.setState({isLoading: false});
                    this.props.history.push(routes.LOGIN.path);
                })
                .catch(() => this.setState({isLoading: false}))
        }
        else {
            PushFocusToElement("Password");
        }
    }

    onReturn() {
        Notify.Clear();
        this.props.history.push(routes.LOGIN.path);
    }

    render() {
        return (
            <div>
                <ResetPasswordForm config={{
                    onResetPasswordSubmit: this.onResetPasswordSubmit,
                    onReturn: this.onReturn,
                    isLoading: this.state.isLoading
                }}/>
            </div>
        );
    }
}

ResetPasswordPage.propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        resetPasswordActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(null, mapDispatchToProps) (ResetPasswordPage));
