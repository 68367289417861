export const LOAD_STUDENTS_AJAX_SUCCESS = "LOAD_STUDENTS_AJAX_SUCCESS";
export const SAVE_STUDENT_AJAX_SUCCESS = "SAVE_STUDENT_AJAX_SUCCESS";
export const SAVE_STUDENT_SEARCH_CRITERIA = "SAVE_STUDENT_SEARCH_CRITERIA";
export const EDIT_STUDENT = "EDIT_STUDENT";
export const CANCEL_EDIT_STUDENT = "CANCEL_EDIT_STUDENT";
export const IMPORT_STUDENTS_AJAX_SUCCESS = "IMPORT_STUDENTS_AJAX_SUCCESS";
export const CLEAR_OUT_IMPORT_MESSAGE = "CLEAR_OUT_IMPORT_MESSAGE";

export function generateIsActiveOptions() {
    return [
        { value: true, name: "Active" },
        { value: false, name: "Inactive" }
    ];
}