import React from "react";
import PropTypes from "prop-types";
import {FormValidation} from "calidation";
import {createErrorList, hasErrors} from "../../components/form/createErrorList";
import SearchableSelectField from "../../components/inputs/searchableSelectField";
import SortOptions from "../../components/inputs/sortOptions";
import {filterArrayBySecondArray} from "../../components/common/commonUtilities";

const InitialItemsForm = ({config}) => {

    let initialItemsConfig = {
        ItemIds: {
        },
        Id: {}
    };

    let unsortedItems = filterArrayBySecondArray(config.itemSelectList, "value", config.itemIds, "value", true);
    let associatedItems = [];

    for (let i = 0; i < config.itemIds.length; i++) {
        let item = unsortedItems.find(s => s.value === config.itemIds[i]);
        if (item)
            associatedItems.push(item);
    }

    return (
        <FormValidation config={initialItemsConfig} id={"initialItemsForm"}>
            {({errors, submitted, setField}) => (
                <div>
                    <div className={"grid-x"}>
                        <div className={"cell auto"}>
                            <h3>Initial Items to Administer</h3>
                        </div>
                    </div>
                    {submitted && hasErrors(errors) &&
                    <div className="text-danger">
                        <p>Please correct the issues as specified.</p>
                        {createErrorList(errors)}
                    </div>
                    }
                    <div className={"grid-x"}>
                        <div className={"cell medium-10 large-10 align-center"}>
                            <SearchableSelectField config={{
                                name: "itemSelected",
                                label: "Item",
                                defaultOption: "Select an item",
                                showLabel: false,
                                options: config.itemSelectList.filter(f => !associatedItems.map(s => s.value).includes(f.value)),
                                includeDefaultOption: true,
                                onChange: (event) => config.onItemChange(event, setField)
                            }}/>
                            {
                                associatedItems.length > 0 &&
                                <SortOptions config={{
                                    onDragEnd: config.onDragEnd,
                                    options: associatedItems,
                                    showDelete: true,
                                    onDeleteOption: config.onDeleteItem,
                                    setField: setField,
                                    wrapperClass: "item-sort-container",
                                    width: null
                                }}/>
                            }
                            <p></p>
                        </div>
                    </div>
                </div>
            )}
        </FormValidation>
    );
};

InitialItemsForm.propTypes = {
    config: PropTypes.shape({
        isEditing: PropTypes.bool.isRequired,
        itemSelectList: PropTypes.arrayOf(PropTypes.object),
        itemIds: PropTypes.array,
        onItemChange: PropTypes.func.isRequired,
        onDragEnd: PropTypes.func.isRequired,
        onDeleteItem: PropTypes.func.isRequired,
    })
};

export default InitialItemsForm;