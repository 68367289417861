import React from 'react';
import * as config from "../../constants/config";
import PropTypes from "prop-types";
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as routes from "../../components/routes/routesConstants";

export class RegistrationComplete extends React.Component {
    componentDidMount(){
        this.props.actions.updatePageTitle_BreadCrumb(routes.REGISTRATION_COMPLETE);
    }

    render() {
        return (
            <div>
                <p>Thank you for registering!</p>
                <p>If you are new site administrator, please contact <a
                    href={"mailto:" + this.props.siteDetail.SupportEmail}>support</a> so that your account can be given access to the
                    portal.</p>
                <p>For new proctors, you will be contacted by an administrator for next steps. Until then, enjoy your
                    day!</p>
            </div>
        );
    }
}


RegistrationComplete.propTypes = {
    actions: PropTypes.object.isRequired,
    siteDetail: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        siteDetail: state.siteDetail
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (RegistrationComplete);