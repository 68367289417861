import React from "react";
import PropTypes from "prop-types";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import SearchableSelectField from "../../components/inputs/searchableSelectField";
import ButtonIcon from "../../components/buttons/buttonIcon";

const AssociatedTasksList = ({config}) => {
    const numColumns = 2;

    return (
        <div className={"grid-x grid-padding-x align-bottom align-center"}>
            <div className={"cell"}>
                <div className={"grid-x"}>
                    <label htmlFor={`Tasks_${config.groupIndex}`} className={"cell auto"}>
                        Associated Tasks
                    </label>
                    <div className={"cell text-right medium-4 large-4"}>
                        <Button config={{
                            disabled: config.isAddTaskDisabled,
                            name: "btnAddTask",
                            label: "Add Task",
                            onClick: config.onAddTask,
                            btnClass: "secondary",
                            btnType: buttonConstants.BUTTON_TYPE_BUTTON
                        }}/>
                    </div>
                </div>
                <table className="table-grid hover stack">
                    <thead>
                    </thead>
                    <tbody>
                    {config.tasks.map(
                        (task, index) => {
                            if (config.isEditing && index === config.editingTaskRowIndex) {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <SearchableSelectField config={{
                                                name: "taskSelected",
                                                label: "Task",
                                                defaultOption: "Select a task",
                                                showLabel: false,
                                                options: config.availableTaskSelectList,
                                                includeDefaultOption: true,
                                                onChange: (selectedTask) => config.onTaskSave(selectedTask, config.setField, config.fields, config.groupIndex)
                                            }}/>
                                        </td>
                                        <td className={"text-center whitespace-nowrap"}>
                                            <ButtonIcon config={{
                                                name: "btnCancel",
                                                label: "Cancel",
                                                iconType: buttonConstants.ICON_CANCEL,
                                                onClick: (event) => config.onCancelTask(event, config.setField, config.fields, config.groupIndex)
                                            }}/>
                                        </td>
                                    </tr>
                                );
                            } else {
                                return (<tr key={index}>
                                        <td>
                                            {task.name}
                                        </td>
                                        <td className={"text-center whitespace-nowrap"}>
                                            <ButtonIcon config={{
                                                name: "btnDelete",
                                                label: "Delete",
                                                iconType: buttonConstants.ICON_DELETE,
                                                onClick: (event) => config.onDeleteTask(event, config.setField, task.value, config.fields, config.groupIndex)
                                            }}/>
                                        </td>
                                    </tr>
                                );
                            }
                        }
                    )}
                    {config.tasks.length === 0 &&
                    <tr>
                        <td colSpan={numColumns}>
                            Click &quot;Add Task&quot; to add Tasks.
                            {config.submitted &&
                            <div className="text-danger">At least one task is
                                required!</div>}
                        </td>
                    </tr>
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

AssociatedTasksList.propTypes = {
    config: PropTypes.shape({
        tasks: PropTypes.array,
        availableTaskSelectList: PropTypes.array,
        isAddTaskDisabled: PropTypes.bool,
        isLoading: PropTypes.bool,
        isEditing: PropTypes.bool,
        onAddTask: PropTypes.func.isRequired,
        onDeleteTask: PropTypes.func.isRequired,
        onTaskSave: PropTypes.func.isRequired,
        onCancelTask: PropTypes.func.isRequired,
        setField: PropTypes.func.isRequired,
        fields: PropTypes.object,
        groupIndex:  PropTypes.number,
        submitted:  PropTypes.bool,
        editingTaskRowIndex:  PropTypes.number,
        hasErrors:  PropTypes.bool,
        errorList:  PropTypes.object,
    })
}

export default AssociatedTasksList;