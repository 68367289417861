import React from "react";
import PropTypes from "prop-types";
import ItemBankListSearchRow from "./ItemBankListSearchRow";
import ResultsPager from "../../components/table/ResultsPager";

const ItemBankListSearchResults = ({ config }) => {
    const numberOfColumns = "5";

    return (
        <div>
            <p>Item banks that have disabled delete icons cannot be deleted because they are associated with a score.</p>
            <table className="table-grid hover stack">
                <thead>
                <tr>
                    <th onClick={config.order} order-by-value="Name" className="text-left is-clickable">Item Bank Name</th>
                    <th onClick={config.order} order-by-value="DateCreated" className="is-clickable">Date Created</th>
                    <th onClick={config.order} order-by-value="DateModified" className="is-clickable">Date Modified</th>
                    <th onClick={config.order} order-by-value="NumberOfItems" className="is-clickable">Number of Items</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {config.itemBankSearchResults.map(
                    (itemBank, index) => {
                        return (<ItemBankListSearchRow
                            key={index}
                            itemBank={itemBank}
                            onEditItemBank={config.onEditItemBank}
                            onDeleteItemBank={config.onDeleteItemBank} />);
                    }
                )}
                {config.itemBankTotalRecords === 0 &&
                <tr>
                    <td colSpan={numberOfColumns}>
                        No item banks match the search criteria.
                    </td>
                </tr>
                }
                </tbody>
                {config.itemBankTotalRecords > 0 &&
                <tfoot>
                <tr>
                    <td colSpan={numberOfColumns}>
                        <ResultsPager config={{
                            first: config.first,
                            previous: config.previous,
                            next: config.next,
                            last: config.last,
                            totalRecords: config.itemBankTotalRecords,
                            recordsPerPage: config.itemBankRecordsPerPage,
                            currentPage: config.currentPage
                        }}  />
                    </td>
                </tr>
                </tfoot>
                }
            </table>
        </div>
    );
};

ItemBankListSearchResults.propTypes = {
    config: PropTypes.shape({
        itemBankSearchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
        order: PropTypes.func,
        first: PropTypes.func.isRequired,
        last: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired,
        itemBankTotalRecords: PropTypes.number.isRequired,
        currentPage: PropTypes.number,
        itemBankRecordsPerPage: PropTypes.number,
        onEditItemBank: PropTypes.func.isRequired,
        onDeleteItemBank: PropTypes.func.isRequired
    })
};

export default ItemBankListSearchResults;