import $ from 'jquery';
import * as config from "../../constants/config";
import LocalStorage from "../../components/localStorage/access";
import {getAccounts, saveAccounts} from "../../mockData/accountData";
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";
import {getDistrictData} from "../../mockData/districtData";
import {
    downloadBlob,
    mockRejectApiCall,
    mockResolveApiCall,
    sendRequest
} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerUserMgmtApi {
    static getUsers(userSearchCriteria) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}users?${$.param(userSearchCriteria)}`,
                RequestTypes.GET,
            ));
    }

    static updateUser(user) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}users`,
                RequestTypes.PUT,
                JSON.stringify(user),
                ApiSuccessMessages.UPDATE_USER
            )
        );
    }

    static exportUserData(userSearchCriteria) {
        return downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}users/export?${$.param(userSearchCriteria)}`,
                RequestTypes.GET,
                null,
                ApiSuccessMessages.EXPORT_SUCCESS
            ));
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockUserMgmtApi {
    static getUsers(userSearchCriteria) {
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            let users = getAccounts();

            if (userSearchCriteria.NameEmailContains !== "")
                users = users.filter(user => (user.FirstName + " " + user.LastName).includes(userSearchCriteria.NameEmailContains) ||
                    (user.Email).includes(userSearchCriteria.NameEmailContains));

            if (userSearchCriteria.RoleId > -1)
                users = users.filter(user => user.RoleId == userSearchCriteria.RoleId);

            if (userSearchCriteria.DistrictId > -1)
                users = users.filter(user => user.DistrictId == userSearchCriteria.DistrictId);

            if (userSearchCriteria.IsActive !== null && userSearchCriteria.IsActive !== "")
                users = users.filter(user => (user.IsActive === (userSearchCriteria.IsActive.toLowerCase() === "true")));

            let sortProp = userSearchCriteria.OrderByColumn;
            let sortDir = (userSearchCriteria.OrderDescending) ? -1 : 1;

            users.sort(function (a, b) {
                return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);
            });

            const skip = (userSearchCriteria.Page - 1) * userSearchCriteria.RecordsPerPage;
            const take = userSearchCriteria.RecordsPerPage + skip;

            return mockResolveApiCall(Object.assign({}, {
                Data: users.slice(skip, take),
                TotalRecords: users.length
            }));
        }
    }

    static updateUser(user) {
        let users = getAccounts();
        let districts = getDistrictData();
        const otherUsers = users.filter(s => s.Id !== user.Id);
        const userRecord = users.filter(s => s.Id === user.Id);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (userRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the user record.");
        else if (otherUsers.filter(f => f.Email === user.Email).length > 0)
            return mockRejectApiCall("The email entered is already associated with another user.");
        else {
            const editUser = {
                Id: user.Id,
                Email: user.Email,
                FirstName: user.FirstName,
                LastName: user.LastName,
                IsActive: (user.IsActive.toString().toLowerCase() === "true"),
                isadmin: (user.RoleId === 0),
                Role: (user.RoleId === 0) ? "Admin" : "Proctor",
                RoleId: user.RoleId,
                DistrictId: user.DistrictId,
                DistrictName: districts.filter(f => f.Id === user.DistrictId)[0].DistrictName,
                Password: user.Password
            };
            saveAccounts([...otherUsers, editUser]);
            return mockResolveApiCall(ApiSuccessMessages.UPDATE_USER);
        }

    }

    static exportUserData() {
        // no mock available as no way to create a mock Excel file
    }
}

const UserMgmtApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerUserMgmtApi : MockUserMgmtApi;
export default UserMgmtApi;