import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as userMgmtActions from "./UserMgmtAction";
import * as forgotPasswordActions from "../ForgotPassword/ForgotPasswordAction";
import {userMgmtInitialState} from "./UserMgmtReducer";
import * as routes from "../../components/routes/routesConstants";
import UserListSearchForm from "./UserListSearchForm";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import UserListSearchResults from "./UserListSearchResults";
import {Search} from "../../components/table/Search";
import * as generalData from "../../components/generalData/generalDataConstants";
import {convertGeneralDataToOptions} from "../../components/generalData/convertGeneralDataToOptions";
import {Notify} from "../../components/notification/notify";
import * as loginActions from "../Login/LoginAction";

export class UserListPage extends Search {
    constructor(props, context) {
        super(props, context, userMgmtInitialState.userSearchCriteria);

        this.state = {
            isLoading: false,
            resetSubmittedCount: 0
        };

        this.onEditUser = this.onEditUser.bind(this);
        this.onEditUserSubmit = this.onEditUserSubmit.bind(this);
        this.onEditUserCancel = this.onEditUserCancel.bind(this);
        this.onResetPassword = this.onResetPassword.bind(this);
        this.onExportUsers = this.onExportUsers.bind(this);
    }

    componentDidMount(){
        PushFocusToElement("NameContains");
        this.props.actions.updatePageTitle_BreadCrumb(routes.USER_MGMT);
        if(this.props.userSearchCriteria !== undefined &&
            this.props.userSearchCriteria.userSearchPerformed) {
            this.props.actions.loadUsers(this.props.userSearchCriteria);
        }
        this.setLoadSearchResults(this.loadUsers);
        this.setProps(this.props.userSearchCriteria, this.props.userTotalRecords);
    }

    componentDidUpdate() {
        this.setProps(this.props.userSearchCriteria, this.props.userTotalRecords);
    }

    loadUsers(userSearchCriteria) {
        userSearchCriteria.userSearchPerformed = true;
        userSearchCriteria.RoleId = (userSearchCriteria.RoleId === "") ? -1 : parseInt(userSearchCriteria.RoleId);
        userSearchCriteria.DistrictId = (userSearchCriteria.DistrictId === "") ? -1 : parseInt(userSearchCriteria.DistrictId);

        this.props.actions.saveUserSearchCriteria(userSearchCriteria);


        this.props.actions.loadUsers(userSearchCriteria);

        this.cancelOutUserEdit();
    }

    onEditUser(event, user, setField) {
        event.preventDefault();

        setField(user);

        this.props.actions.editUser(user.Id);
    }

    onEditUserSubmit({fields, isValid}) {
        if (isValid) {
            let detachedFields = Object.assign({}, fields);

            detachedFields.RoleId = parseInt(detachedFields.RoleId);
            detachedFields.DistrictId = parseInt(detachedFields.DistrictId);

            if(detachedFields.RoleId === 1 && detachedFields.DistrictId === 0) {
                Notify.Error("A non-admin district needs to be selected for the Proctor role.")
                return;
            }

            this.setState({isLoading: true});

            this.props.actions.updateUser(detachedFields)
                .then(() => this.setState({isLoading: false, resetSubmittedCount: this.state.resetSubmittedCount + 1}))
                .catch(() => this.setState({isLoading: false}));
        }
    }

    onEditUserCancel(event) {
        event.preventDefault();
        this.cancelOutUserEdit();
    }

    cancelOutUserEdit() {
        this.props.actions.cancelEditUser();
        this.setState({resetSubmittedCount: this.state.resetSubmittedCount + 1});
    }

    onResetPassword(event, user) {
        event.preventDefault();

        if(confirm("Are you sure you want to send an email to " + user.Email + " so that they will be able to reset their password?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page.")) {
            this.setState({isLoading: true, isValid: true});
            this.props.actions.requestPasswordReset({Email: user.Email})
                .then(() => this.setState({isLoading: false}))
                .catch(() => this.setState({isLoading: false}))
        }
    }

    onExportUsers(event) {
        event.preventDefault();

        this.props.actions.exportUserData(this.props.userSearchCriteria);
    }

    render() {
        let {userTotalRecords, userSearchCriteria, userSearchResults, isEditing, editingUserId, districts} = this.props;

        return (
            <div>
                {
                    districts !== undefined &&
                    districts.length > 0 &&
                    <UserListSearchForm config={{
                        onSearchSubmit: this.onSearchSubmit,
                        clearCriteria: this.clearCriteria,
                        isLoading: this.state.isLoading,
                        userSearchCriteria: userSearchCriteria,
                        districts: districts,
                        onExportUsers: this.onExportUsers
                    }}/>
                }
                {userSearchCriteria !== undefined &&
                userSearchCriteria.userSearchPerformed &&
                districts !== undefined &&
                districts.length > 0 &&
                <div id={"searchResults"}>
                    <hr/>
                    <UserListSearchResults key={this.state.resetSubmittedCount} config={{
                        userSearchResults: userSearchResults,
                        order: this.order,
                        first: this.first,
                        last: this.last,
                        next: this.next,
                        previous: this.previous,
                        userTotalRecords: userTotalRecords,
                        currentPage: userSearchCriteria.Page,
                        userRecordsPerPage: userSearchCriteria.RecordsPerPage,
                        isEditing: isEditing,
                        editingUserId: editingUserId,
                        onEditUser: this.onEditUser,
                        onEditUserSubmit: this.onEditUserSubmit,
                        onEditUserCancel: this.onEditUserCancel,
                        isLoading: this.state.isLoading,
                        districts: districts,
                        onResetPassword: this.onResetPassword
                    }}/>
                </div>
                }
            </div>
        );
    }
}

UserListPage.propTypes = {
    userSearchResults: PropTypes.arrayOf(PropTypes.object),
    userSearchCriteria: PropTypes.object,
    userTotalRecords: PropTypes.number,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isEditing: PropTypes.bool,
    editingUserId: PropTypes.number,
    districts: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    return {
        userSearchResults: state.userMgmt.userSearchResults,
        userSearchCriteria: state.userMgmt.userSearchCriteria,
        userTotalRecords: state.userMgmt.userTotalRecords,
        isEditing: state.userMgmt.isEditing,
        editingUserId: state.userMgmt.editingUserId,
        districts: convertGeneralDataToOptions(state.district.districts, generalData.DISTRICT_DATA.name)
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        userMgmtActions,
        forgotPasswordActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (UserListPage));
