import $ from 'jquery';
import * as config from "../../constants/config";
import LocalStorage from "../../components/localStorage/access";
import {getItemsData, saveItemsData} from "../../mockData/assessmentData";
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";
import {objectToFormData} from "object-to-formdata";
import convertObjWithSpecificFields from "../../mockData/convertObjWithSpecificFields";
import {mockRejectApiCall, mockResolveApiCall, sendRequest} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerItemApi {
    static convertObjectToFormData(item) {
        let propsToRemove = [];
        item.FileData.forEach((element, name) => {
            if (name !== "PromptImage" && name !== "PromptAudio") {
                propsToRemove.push(name);
            }
        });

        for (let i = 0; i < propsToRemove.length; i++) {
            item.FileData.delete(propsToRemove[i]);
        }

        for (let i = 0; i < item.Options.length; i++) {
            if (item.Options[i].FileData.get("ValueImage") !== null) {
                item.FileData.append("Option" + i + "-ValueImage", item.Options[i].FileData.get("ValueImage"));
            }

            if (item.Options[i].FileData.get("ValueAudio") !== null) {
                item.FileData.append("Option" + i + "-ValueAudio", item.Options[i].FileData.get("ValueAudio"));
            }
        }

        let formData = objectToFormData(item, {indices: true}, item.FileData);
        return formData;
    }

    static getItems(itemSearchCriteria) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}items?${$.param(itemSearchCriteria)}`,
                RequestTypes.GET,
            ));
    }

    static getItem(itemId) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}items/${itemId}`,
                RequestTypes.GET,
            ));
    }

    static deleteItem(linkInfo) {
        return sendRequest(
            AjaxRequestOptions(
                linkInfo.Href,
                RequestTypes.DELETE,
                ApiSuccessMessages.ITEM_DELETED
            ));
    }

    static addItem(item) {
        let request = AjaxRequestOptions(
            `${config.apiUrl}items`,
            RequestTypes.POST,
            this.convertObjectToFormData(item),
            ApiSuccessMessages.ITEM_ADDED);

        request.processData = false;
        request.enctype = `multipart/form-data`;
        request.contentType = false;

        return sendRequest(request);
    }

    static updateItem(item) {
        let request = AjaxRequestOptions(
            `${config.apiUrl}items`,
            RequestTypes.PUT,
            this.convertObjectToFormData(item),
            ApiSuccessMessages.ITEM_UPDATED);

        request.processData = false;
        request.enctype = `multipart/form-data`;
        request.contentType = false;

        return sendRequest(request);
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockItemApi {
    static getNewId() {
        const items = getItemsData();
        let itemId = 0;
        items.map(item => {
            if (item.Id > itemId)
                itemId = item.Id;
        });

        return (itemId + 1);
    }

    static getNewOptionId() {
        const items = getItemsData();
        let optionId = 0;
        items.map(item => {
            item.Options.map(option => {
                if (option.Id > optionId)
                    optionId = option.Id;
            })
        });

        return (optionId + 1);
    }

    static getSampleMedia(mediaType) {
        return (mediaType.endsWith("Image") ? config.mockFilePath + "items/1/opt1.png" : config.mockFilePath + "items/1/opt1.wav");
    }

    static getItems(itemSearchCriteria) {
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            let items = getItemsData();

            if (itemSearchCriteria.NameContains !== "")
                items = items.filter(item => item.Name.includes(itemSearchCriteria.NameContains));

            let sortProp = itemSearchCriteria.OrderByColumn;
            let sortDir = (itemSearchCriteria.OrderDescending) ? -1 : 1;

            items.sort(function (a, b) {
                return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);
            });

            let recordsPerPage = items.length;
            if (itemSearchCriteria.RecordsPerPage !== null)
                recordsPerPage = itemSearchCriteria.RecordsPerPage;

            const skip = (itemSearchCriteria.Page - 1) * recordsPerPage;
            const take = recordsPerPage + skip;

            let itemsReturned = (itemSearchCriteria.Fields !== "")
                ? convertObjWithSpecificFields(itemSearchCriteria.Fields, items)
                : items.slice(skip, take);

            return mockResolveApiCall(Object.assign({}, {
                Data: itemsReturned,
                TotalRecords: items.length
            }));
        }
    }

    static getItem(itemId) {
        let items = getItemsData();
        const itemRecord = items.filter(i => i.Id === itemId);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (itemRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the item record.");
        else {
            return mockResolveApiCall(itemRecord[0]);
        }
    }

    static deleteItem(linkInfo) {
        let items = getItemsData();
        const itemRecord = items.filter(i => i.Id === parseInt(linkInfo.Href));
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (itemRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the item record.");
        else {
            const otherItems = items.filter(i => i.Id !== parseInt(linkInfo.Href));
            saveItemsData([...otherItems]);
            return mockResolveApiCall(ApiSuccessMessages.ITEM_DELETED);
        }
    }

    static addItem(item) {
        let items = getItemsData();
        const otherItems = items.filter(i => i.Id !== item.Id);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (otherItems.filter(f => f.Name === item.Name).length > 0)
            return mockRejectApiCall("The item name entered is already associated with another item.");
        else {
            const newId = this.getNewId();
            let newOptionIdStart = this.getNewOptionId();
            const addItem = {
                Id: newId,
                Name: item.Name,
                PromptText: item.PromptText,
                PromptImage: (item.FileData.get("PromptImage") !== null) ? this.getSampleMedia("PromptImage") : item.PromptImage,
                PromptAudio: (item.FileData.get("PromptAudio") !== null) ? this.getSampleMedia("PromptAudio") : item.PromptAudio,
                Options: item.Options.map(option => {
                    return {
                        Id: newOptionIdStart++,
                        Name: option.Name,
                        ValueText: option.ValueText,
                        ValueImage: (option.FileData.get("ValueImage") !== null) ? this.getSampleMedia("ValueImage") : option.ValueImage,
                        ValueAudio: (option.FileData.get("ValueAudio") !== null) ? this.getSampleMedia("ValueAudio") : option.ValueAudio,
                        IsCorrect: option.IsCorrect
                    }
                }),
                Links: [{
                    Href: newId.toString(),
                    Rel: "delete_item",
                    Method: "DELETE"
                }]
            };
            saveItemsData([...otherItems, addItem]);
            return mockResolveApiCall(ApiSuccessMessages.ITEM_ADDED);
        }
    }

    static updateItem(item) {
        let items = getItemsData();
        const otherItems = items.filter(i => i.Id !== item.Id);
        const itemRecord = items.filter(i => i.Id === item.Id);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (itemRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the item record.");
        else if (otherItems.filter(f => f.Name === item.Name).length > 0)
            return mockRejectApiCall("The item name entered is already associated with another item.");
        else {
            let newOptionIdStart = this.getNewOptionId();
            const editItem = {
                Id: item.Id,
                Name: item.Name,
                PromptText: item.PromptText,
                PromptImage: (item.FileData.get("PromptImage") !== null) ? this.getSampleMedia("PromptImage") : item.PromptImage,
                PromptAudio: (item.FileData.get("PromptAudio") !== null) ? this.getSampleMedia("PromptAudio") : item.PromptAudio,
                Options: item.Options.map(option => {
                    return {
                        Id: (option.Id === 0) ? newOptionIdStart++ : option.Id,
                        Name: option.Name,
                        ValueText: option.ValueText,
                        ValueImage: (option.FileData.get("ValueImage") !== null) ? this.getSampleMedia("ValueImage") : option.ValueImage,
                        ValueAudio: (option.FileData.get("ValueAudio") !== null) ? this.getSampleMedia("ValueAudio") : option.ValueAudio,
                        IsCorrect: option.IsCorrect
                    }
                }),
                Links: itemRecord[0].Links
            };
            saveItemsData([...otherItems, editItem]);
            return mockResolveApiCall(ApiSuccessMessages.ITEM_UPDATED);
        }
    }
}

const ItemApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerItemApi : MockItemApi;
export default ItemApi;