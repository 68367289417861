import React from "react";
import PropTypes from "prop-types";
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as taskActions from "./TaskAction";
import * as taskCatConfigActions from "./TaskCatConfigAction";
import * as loginActions from "../Login/LoginAction";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as routes from "../../components/routes/routesConstants";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import * as config from "../../constants/config";
import {getParams} from "../../components/layout/getParams";
import {Notify} from "../../components/notification/notify";
import TaskInfoForCatConfig from "./TaskInfoForCatConfig";
import TaskCatConfigInfoForm from "./TaskCatConfigInfoForm";
import {
    ItemPoolConfig,
    generateStopCriteriaOptions,
    MasteryStopCriteriaOption
} from "./taskCatConfigConstants";
import $ from "jquery";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import {
    convertToNumber,
    toString
} from "../../components/common/commonUtilities";
import {ItemPoolConfigDisplaySpeed, MasteryModel} from "../../constants/catModelConstants";
import StopCriteriaList from "./StopCriteriaList";
import {stopCriteriaInitialState} from "./TaskReducer";
import * as itemActions from "../Items/ItemAction";
import * as sortOptionOnDragEnd from "../../components/inputs/sortOptionOnDragEnd";
import InitialItemsForm from "./InitialItemsForm";

let searchBoxEnterKey = false;

export class TaskCatConfigInfoPage extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            taskId: 0,
            hasFormErrors: false,
            resetSubmittedCount: 0
        };


        this.onReturnTaskInfoPage = this.onReturnTaskInfoPage.bind(this);
        this.onCatModelChange = this.onCatModelChange.bind(this);

        this.onEditStopCriteria = this.onEditStopCriteria.bind(this);
        this.onEditStopCriteriaSubmit = this.onEditStopCriteriaSubmit.bind(this);
        this.onEditStopCriteriaCancel = this.onEditStopCriteriaCancel.bind(this);
        this.onDeleteStopCriteria = this.onDeleteStopCriteria.bind(this);
        this.onAddStopCriteria = this.onAddStopCriteria.bind(this);
        this.onStopCriteriaChange = this.onStopCriteriaChange.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.getStopCriteriaOptions = this.getStopCriteriaOptions.bind(this);
    }

    componentDidMount() {
        this.props.actions.updatePageTitle_BreadCrumb(routes.TASK_CAT_CONFIG_INFO);

        const paramObj = getParams(this.props.location.pathname, routes.TASK_CAT_CONFIG_INFO);
        let taskId = parseInt(paramObj.taskId);
        this.setState({taskId});

        if (paramObj !== null && taskId !== 0)
            this.props.actions.editTask(taskId);

        this.props.actions.loadItemPoolSelectList(taskId);

        this.props.actions.loadItemSelectList();

        $(document).ready(() => {
            PushFocusToElement("CatModelType");
        });
    }

    onReturnTaskInfoPage(event) {
        event.preventDefault();
        Notify.Clear();
        this.props.history.push(routes.TASK_INFO.path.replace(routes.TASK_INFO.params.taskId, this.state.taskId));
    }

    isCatConfigFormVisible() {
        return this.props.taskInfo.CatConfig && this.props.taskInfo.CatConfig.CatModelType > 0;
    }

    onCatModelChange(event, setField) {
        const catModeType = parseInt(event.target.value);
        this.props.actions.updateTaskCatModelType(catModeType);
        setField({CatModelType: catModeType, ItemSelectionAlgorithm: 0});
        if (catModeType !== MasteryStopCriteriaOption.value)
            this.props.actions.removeMasteryStopCriteria(MasteryStopCriteriaOption.value);
    }

    isTaskUpdateable() {
        if (this.state.taskId === 0)
            return true;

        if (this.props.taskInfo === undefined ||
            this.props.taskInfo.Links === undefined)
            return false;

        const taskLinkList = this.props.taskInfo.Links.filter(f => f.Method === config.DELETE_API_METHOD);
        return (taskLinkList.length > 0);
    }

    shouldDisablePageActions() {
        return this.props.isEditing;
    }

    createPageDirections() {
        let pageDir = "";
        if (this.props.taskInfo.CatModelType === 0)
            pageDir = "Create new CAT Configuration.";
        else {
            pageDir = !this.isTaskUpdateable() ?
                <strong className={"text-danger"}>NOTE - this task is associated with scores. Only make changes that
                    will not affect the overall outcome of previously saved
                    scores.</strong> : "Edit CAT Configuration.";
        }
        return <p>{pageDir}</p>;
    }

    onTaskCatConfigSubmit(event) {
        this.props.actions.updateSubmitted(true);
        if (!event.isValid || this.props.taskInfo.CatConfig.StopCriteria.length === 0)
            this.setState({hasFormErrors: true});

        if (event.isValid && this.props.taskInfo.CatConfig.StopCriteria.length > 0) {
            let detachedFields = Object.assign({}, event.fields);
            if (detachedFields.Id === "")
                detachedFields.Id = 0;

            detachedFields.Id = parseInt(detachedFields.Id);

            let itemPoolConfigs = {};
            itemPoolConfigs[ItemPoolConfig.accuracy] = convertToNumber(detachedFields.ItemPoolAccuracy);
            if (ItemPoolConfigDisplaySpeed.find(s => s === this.props.taskInfo.CatConfig.CatModelType))
                itemPoolConfigs[ItemPoolConfig.speed] = convertToNumber(detachedFields.ItemPoolSpeed);

            const taskCatConfig = Object.assign({}, this.props.taskInfo.CatConfig, detachedFields, {
                ItemPoolConfigs: itemPoolConfigs,
                TaskId: this.state.taskId
            });

            this.setState({isLoading: true});

            this.props.actions.saveTaskCatConfig(taskCatConfig)
                .then(() => this.props.history.push(routes.TASK_LIST.path))
                .catch(() => this.setState({isLoading: false}));
        } else {
            PushFocusToElement("CatModelType");
        }
    }

    onReturnTaskListPage() {
        this.props.history.push(routes.TASK_LIST.path);
    }

    onSaveOrDisplayClick() {
        this.props.actions.removeTaskCatConfig(this.state.taskId)
            .then(() => this.props.history.push(routes.TASK_LIST.path))
            .catch(() => this.setState({isLoading: false}));
    }

    convertToCatInfoForm() {
        const catConfig = this.props.taskInfo.CatConfig;
        return Object.assign({}, catConfig, {
            ItemPoolAccuracy: toString(catConfig.ItemPoolConfigs[ItemPoolConfig.accuracy]),
            ItemPoolSpeed: toString(catConfig.ItemPoolConfigs[ItemPoolConfig.speed])
        })
    }

    onEditStopCriteria(event, criterion, setField, index) {
        event.preventDefault();

        setField({...criterion});

        this.props.actions.editStopCriteria(index);
    }

    isStopCriterionDuplicated(stopCriterionType) {
        let duplicated = false;
        for (let i = 0; i < this.props.taskInfo.CatConfig.StopCriteria.length && !duplicated; i++) {
            if (i !== this.props.editingStopCriteriaRowIndex) {
                duplicated = (this.props.taskInfo.CatConfig.StopCriteria[i].StopCriteriaType === stopCriterionType.toString());
            }
        }
        return duplicated;
    }

    onEditStopCriteriaSubmit({fields, isValid}) {
        if (isValid && !searchBoxEnterKey) {
            let detachedFields = Object.assign({}, fields);
            if (detachedFields.Id === "")
                detachedFields.Id = 0;

            if (this.isStopCriterionDuplicated(detachedFields.StopCriteriaType)) {
                Notify.Error("The stop criteria is already being used.");
                return;
            }

            const stopCriterion = Object.assign({}, this.props.taskInfo.CatConfig.StopCriteria[this.props.editingStopCriteriaRowIndex], {
                Id: detachedFields.Id,
                StopCriteriaType: detachedFields.StopCriteriaType,
                StopCriteriaValue: detachedFields.StopCriteriaValue
            });

            this.props.actions.updateStopCriteria(stopCriterion, false, 0);
        } else if (searchBoxEnterKey) {
            PushFocusToElement("select-search-box__search");
        } else
            PushFocusToElement("StopCriteriaType");
    }

    onEditStopCriteriaCancel(event) {
        event.preventDefault();
        this.props.actions.cancelEditStopCriteria();
        this.setState({resetSubmittedCount: this.state.resetSubmittedCount + 1});
    }

    onStopCriteriaChange(criterion, setField) {
        setField({StopCriteriaType: parseInt(criterion.value)});
        searchBoxEnterKey = false;
    }

    onDeleteStopCriteria(event, index) {
        event.preventDefault();
        if (this.props.addStopCriteriaRowIndex >= 0 || confirm("Are you sure you want to delete this stop criteria?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page."))
            this.props.actions.deleteStopCriteria(index);
    }

    onAddStopCriteria(event, setField) {
        event.preventDefault();
        this.props.actions.addStopCriteria();

        this.setState({resetSubmittedCount: this.state.resetSubmittedCount + 1});
        setField({...stopCriteriaInitialState()});
    }

    getStopCriteriaOptions(stopCriteria, criterionId) {
        if (this.props.taskInfo.CatConfig.CatModelType === MasteryModel) {
            stopCriteria = stopCriteria.filter(sc => sc.StopCriteriaType !== MasteryStopCriteriaOption.value);
        }

        let stopCriteriaOptions = []

        if (criterionId === 0) {
            stopCriteriaOptions = generateStopCriteriaOptions(stopCriteria);
        } else {
            stopCriteriaOptions = generateStopCriteriaOptions(stopCriteria, criterionId);
        }

        if (this.props.taskInfo.CatConfig.CatModelType !== MasteryModel)
            stopCriteriaOptions = stopCriteriaOptions.filter(opt => opt.value !== MasteryStopCriteriaOption.value);

        return stopCriteriaOptions;
    }

    onItemChange(item, setField) {
        setField({itemIds: "Yes"});
        this.props.actions.associateItem(parseInt(item.value));

        let container = $(".item-sort-container > div.cell > div");
        let height = container.get(0).scrollHeight;
        container.animate({scrollTop: height});

        searchBoxEnterKey = false;
    }

    onDragEnd(result) {
        let catConfig = Object.assign({}, this.props.taskInfo.CatConfig);

        const newItems = sortOptionOnDragEnd.Handler(result, catConfig.ItemIds);

        catConfig.ItemIds = newItems;

        this.props.actions.updateCatConfigItemOrder(catConfig);
    }

    onDeleteItem(event, index, setField) {
        event.preventDefault();
        if (confirm("Are you sure you want to remove this item?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page.")) {
            if (this.props.taskInfo.CatConfig.ItemIds.length === 1)
                setField({itemIds: ""});

            this.props.actions.unassociateItem(index);
        }
    }

    render() {
        if (!this.props.taskInfo || !this.props.itemPoolSelectList || !this.props.itemSelectList || !this.props.taskInfo.CatConfig)
            return null;

        return (
            <div>
                <div className={"clearfix"}>
                    <Button config={{
                        name: "btnReturnTaskInformation",
                        label: "Return to Task Information",
                        onClick: this.onReturnTaskInfoPage,
                        btnClass: "light float-right",
                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                    }}/>
                    {this.createPageDirections()}
                </div>

                <div className={"grid-x grid-padding-x align-bottom align-center"}>
                    <div className={"cell medium-6 large-6 "}>
                        <TaskInfoForCatConfig config={{
                            taskInfo: this.props.taskInfo
                        }}/>

                        <TaskCatConfigInfoForm config={{
                            onCatModelChange: this.onCatModelChange,
                            isLoading: this.state.isLoading,
                            onTaskCatConfigSubmit: (event) => this.onTaskCatConfigSubmit(event),
                            catConfig: this.convertToCatInfoForm(),
                            shouldDisablePageActions: this.shouldDisablePageActions(),
                            itemPoolSelectList: this.props.itemPoolSelectList,
                            hasFormErrors: this.props.taskInfo.CatConfig.StopCriteria.length === 0,
                            isCatConfigFormVisible: this.isCatConfigFormVisible()
                        }}/>

                        {this.isCatConfigFormVisible() &&
                        <>
                            <StopCriteriaList key={this.state.resetSubmittedCount} config={{
                                addStopDisabled: generateStopCriteriaOptions(this.props.taskInfo.CatConfig.StopCriteria).length === 0,
                                stopCriteria: this.props.taskInfo.CatConfig.StopCriteria,
                                isEditing: this.props.isEditing,
                                editingStopCriteriaRowIndex: this.props.editingStopCriteriaRowIndex,
                                addStopCriteriaRowIndex: this.props.addStopCriteriaRowIndex,
                                onEditStopCriteria: this.onEditStopCriteria,
                                onEditStopCriteriaSubmit: this.onEditStopCriteriaSubmit,
                                onEditStopCriteriaCancel: this.onEditStopCriteriaCancel,

                                onDeleteStopCriteria: this.onDeleteStopCriteria,
                                onAddStopCriteria: this.onAddStopCriteria,
                                stopCriteriaOptions: generateStopCriteriaOptions(),
                                onStopCriteriaChange: this.onStopCriteriaChange,
                                getStopCriteriaOptions: this.getStopCriteriaOptions,
                                isSubmitted: this.props.isSubmitted
                            }}/>

                            <InitialItemsForm key={this.state.resetSubmittedCount + 1} config={{
                                isEditing: this.props.isEditing,
                                itemSelectList: this.props.itemSelectList,
                                itemIds: this.props.taskInfo.CatConfig.ItemIds,
                                onItemChange: this.onItemChange,
                                onDragEnd: this.onDragEnd,
                                onDeleteItem: this.onDeleteItem,
                            }}/>
                        </>
                        }
                        <div className={"grid-x"}>
                            <div className={"cell center-element medium-6 large-6"}>
                                <div className={"cell text-right medium-6 large-6"}>
                                    <Button config={{
                                        name: "btnCancel",
                                        label: "Cancel",
                                        onClick: () => this.onReturnTaskListPage(),
                                        btnClass: "secondary button-margin",
                                        disabled: this.shouldDisablePageActions(),
                                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                    }}/>
                                    {this.isCatConfigFormVisible() &&
                                    <label htmlFor={"btnSave"}
                                           className={"button" + (this.shouldDisablePageActions() ? " is-disabled" : "")}>
                                        <span>Save</span>
                                    </label>
                                    }
                                    {!this.isCatConfigFormVisible() &&
                                    <label htmlFor={"btnSaveOrDisplay"}
                                           className={"button" + (this.shouldDisablePageActions() ? " is-disabled" : "")}
                                           onClick={() => this.onSaveOrDisplayClick()}>
                                        <span>Save</span>
                                    </label>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TaskCatConfigInfoPage.propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    taskInfo: PropTypes.object,
    isEditing: PropTypes.bool,
    itemPoolSelectList: PropTypes.array,
    editingStopCriteriaRowIndex: PropTypes.number,
    addStopCriteriaRowIndex: PropTypes.number,
    itemSelectList: PropTypes.array,
    isSubmitted: PropTypes.bool
};

function mapStateToProps(state) {

    let itemSelectList = [];
    if(state.item.itemSelectList && state.task.taskInfo && state.task.taskInfo.AssociatedItemIds) {
        itemSelectList = state.item.itemSelectList.filter(f => state.task.taskInfo.AssociatedItemIds.includes(f.value));
    }

    return {
        taskInfo: state.task.taskInfo,
        isEditing: state.task.isEditing,
        itemPoolSelectList: state.task.itemPoolSelectList,
        editingStopCriteriaRowIndex: state.task.editingStopCriteriaRowIndex,
        addStopCriteriaRowIndex: state.task.addStopCriteriaRowIndex,
        itemSelectList: itemSelectList,
        isSubmitted: state.task.isSubmitted
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        taskActions,
        taskCatConfigActions,
        itemActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskCatConfigInfoPage));