export class NotificationAdapter {
    constructor() {
    }

	error() {
		throw new Error("You have to implement the method error.");
	}
	success() {
		throw new Error("You have to implement the method success.");
	}
	info() {
		throw new Error("You have to implement the method info.");
	}
	warning() {
		throw new Error("You have to implement the method warning.");
	}
}