import React from "react";
import PropTypes from "prop-types";
import {FormValidation} from "calidation";
import StudentMgmtSearchRow from "./StudentMgmtSearchRow";
import StudentMgmtEditForm from "./StudentMgmtEditForm";
import ResultsPager from "../../components/table/ResultsPager";
import {createErrorList, hasErrors} from "../../components/form/createErrorList";

const StudentMgmtSearchResults = ({ config }) => {
    const numberOfColumns = "5";

    let formConfig = {
        StudentTrackingId: {
            isRequired: 'Student Tracking Id is required!'
        },
        LastName: {
            isRequired: 'Last name is required!'
        },
        FirstName: {
            isRequired: 'First name is required!'
        },
        IsActive: {},
        Id: {}
    };
    return (
        <FormValidation onSubmit={config.onEditStudentSubmit} config={formConfig}>
            {({ fields, errors, submitted, setField }) => (
            <div>
                {submitted && hasErrors(errors) &&
                    <div className="text-danger">
                        <p>Please correct the issues as specified.</p>
                        {createErrorList(errors)}
                    </div>
                }
                <table className="table-grid hover stack">
                <thead>
                    <tr>
                        <th onClick={config.order} order-by-value="IsActive" className="is-clickable">Is Active</th>
                        <th onClick={config.order} order-by-value="StudentTrackingId" className="is-clickable">Student Tracking Id</th>
                        <th onClick={config.order} order-by-value="LastName" className="text-left is-clickable">Last Name</th>
                        <th onClick={config.order} order-by-value="FirstName" className="text-left is-clickable">First Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {config.studentSearchResults.map(
                    (student, index) => {
                        if(config.isEditing && student.Id === config.editingStudentId) {
                            return (<StudentMgmtEditForm key={index} config={{
                                student: student,
                                onCancel: config.onEditStudentCancel,
                                fields: fields,
                                submitted: submitted,
                                errors: errors,
                                isLoading: config.isLoading
                            }} />);
                        }
                        else {
                            return (<StudentMgmtSearchRow
                                key={index}
                                student={student}
                                isEditing={config.isEditing}
                                onEditStudent={config.onEditStudent}
                                setField={setField} />);
                        }
                    }
                )}
                {config.studentTotalRecords === 0 &&
                    <tr>
                        <td colSpan={numberOfColumns}>
                            No students match the search criteria.
                        </td>
                    </tr>
                }
                </tbody>
                {config.studentTotalRecords > 0 &&
                    <tfoot>
                    <tr>
                        <td colSpan={numberOfColumns}>
                            <ResultsPager config={{
                                first: config.first,
                                previous: config.previous,
                                next: config.next,
                                last: config.last,
                                totalRecords: config.studentTotalRecords,
                                recordsPerPage: config.studentRecordsPerPage,
                                currentPage: config.currentPage,
                                isEditing: config.isEditing
                            }}  />
                        </td>
                    </tr>
                    </tfoot>
                }
                </table>
                </div>
            )}
        </FormValidation>
    );
};

StudentMgmtSearchResults.propTypes = {
    config: PropTypes.shape({
        studentSearchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
        order: PropTypes.func,
        first: PropTypes.func.isRequired,
        last: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired,
        studentTotalRecords: PropTypes.number.isRequired,
        currentPage: PropTypes.number,
        studentRecordsPerPage: PropTypes.number,
        isEditing: PropTypes.bool.isRequired,
        editingStudentId: PropTypes.number,
        onEditStudent: PropTypes.func.isRequired,
        onEditStudentSubmit: PropTypes.func.isRequired,
        onEditStudentCancel: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    })
};

export default StudentMgmtSearchResults;