import * as actionTypes from "./DistrictConstants";

export const districtInitialState = {
    districts: []
};

export function districtReducer(state = districtInitialState, action) {
    switch(action.type){
        case actionTypes.GET_DISTRICTS_AJAX_SUCCESS:
            return Object.assign({}, state, {districts: action.allDistricts});
        default:
            return state;
    }
}