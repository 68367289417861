import React from 'react';
import {Link} from 'react-router-dom';
import * as routes from "../components/routes/routesConstants";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as headerActions from "./AuthenticatedLayout/HeaderAction";
import PropTypes from "prop-types";
import {getSiteDetail} from "../components/layout/SetPageTitle";

export class NotFoundPage extends React.Component {
    componentDidMount() {
        this.props.actions.updatePageTitle_BreadCrumb(routes.PAGE_NOT_FOUND);
    }

    render() {
        return (
            <div>
                <p>Please let <a href={"mailto:" + getSiteDetail().SupportEmail}>tech support</a> know if there is a bad link.</p>
                <p>
                    Go back to the <Link to={routes.LOGIN.path}>login page</Link> or return to the <a href={"javascript:window.history.back()"}>previous page</a>.
                </p>
            </div>
        );
    }
}

NotFoundPage.propTypes = {
    actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps) (NotFoundPage);
