import React from 'react';
import PropTypes from "prop-types";
import { FormValidation, ValidatorsProvider } from "calidation";
import TextField from "../../components/inputs/textField";
import {hasErrors} from "../../components/form/createErrorList";
import Button from "../../components/buttons/button";
import SelectField from "../../components/inputs/selectField";
import {IsTaskTypeKerningGrid} from "../TaskType/TaskTypeUtility";

const TaskInfoForm = ({config}) => {
    const extraValidators = {
        isInteger: config => value => (value === null || value === undefined || !(/^[-]?\d+$/.test(value))) ? config.message : null
    };
    let formConfig = {
        Name: {
            isRequired: 'Task name is required!'
        },
        TaskTypeId: {
            isGreaterThan : {
                message: "Task type is required!",
                value: 0
            },
            isRequired : {
                message: "Task type is required!",
                value: ""
            },
        },
        TimeLimit: {
            isRequired: 'Time limit is required!',
            isInteger: 'Time limit must be an integer!'
        },
        ItemsPerPage: {
            isRequired: 'Items per page is required!',
            isInteger: 'Items per page must be an integer!'
        },
        GridRowTotal: {
            isInteger: {
                message: '# of rows is required and must be an integer!',
                validateIf: ({ fields }) => IsTaskTypeKerningGrid(fields.TaskTypeId, config.taskTypes)
            },
            isGreaterThan: {
                message: '# of rows is required and must be greater than zero',
                value: 0,
                validateIf: ({ fields }) => IsTaskTypeKerningGrid(fields.TaskTypeId, config.taskTypes)
            }
        },
        GridColumnTotal: {
            isInteger: {
                message: '# of columns is required and must be an integer!',
                validateIf: ({ fields }) => IsTaskTypeKerningGrid(fields.TaskTypeId, config.taskTypes)
            },
            isGreaterThan: {
                message: '# of columns is required and must be greater than zero',
                value: 0,
                validateIf: ({ fields }) => IsTaskTypeKerningGrid(fields.TaskTypeId, config.taskTypes)
            }
        },
        GridSpacing: {
            isNumber: {
                message: 'Spacing is required and must be a decimal!',
                validateIf: ({ fields }) => IsTaskTypeKerningGrid(fields.TaskTypeId, config.taskTypes)
            },
            isGreaterThan: {
                message: 'Spacing is required and must be greater than zero',
                value: 0,
                validateIf: ({ fields }) => IsTaskTypeKerningGrid(fields.TaskTypeId, config.taskTypes)
            }
        },
        Id: {}
    };

    return (
        <ValidatorsProvider validators={extraValidators}>
            <FormValidation onSubmit={config.onTaskSubmit} config={formConfig} initialValues={config.taskInfo} id={"task-form"} >
                {({ fields, errors, submitted }) => (
                    <div>
                        <input type={"hidden"} name={"Id"} value={fields.Id} />
                        <Button config={{
                            name: "btnSave",
                            label: "Save",
                            btnClass: "hide",
                            disabled: (config.isLoading || config.shouldDisableSave)
                        }}/>
                        <Button config={{
                            name: "btnCatConfig",
                            label: "CAT Config",
                            btnClass: "hide",
                            disabled: (config.isLoading || config.shouldDisableSave)
                        }}/>
                        {submitted && hasErrors(errors) && <p className="text-danger">Please correct the issues as specified.</p>}
                        <div className={"grid-x"}>
                            <div className={"cell"}>
                                <TextField config={{
                                    name: "Name",
                                    label: "Task Name",
                                    maxLength: 50,
                                    showLabel: true,
                                    value: fields.Name,
                                    error: (submitted && errors.Name) ? errors.Name : ""
                                }} />
                            </div>
                        </div>
                        <div className={"grid-x"}>
                            <div className={"cell shrink"}>
                                <SelectField config={{
                                    name: "TaskTypeId",
                                    label: "Task Type",
                                    defaultOption: "Select a type",
                                    value: fields.TaskTypeId.toString(),
                                    error: (submitted && errors.TaskTypeId) ? errors.TaskTypeId : "",
                                    showLabel: true,
                                    options: config.taskTypes,
                                    includeDefaultOption: true
                                }}/>
                            </div>
                        </div>
                        <div className={"grid-x"}>
                            <div className={"cell medium-4 large-4"}>
                                <TextField config={{
                                    name: "TimeLimit",
                                    label: "Time Limit (seconds)",
                                    maxLength: 10,
                                    showLabel: true,
                                    value: fields.TimeLimit.toString(),
                                    error: (submitted && errors.TimeLimit) ? errors.TimeLimit : ""
                                }} />
                            </div>
                        </div>
                        <div className={"grid-x"}>
                            <div className={"cell medium-4 large-4"}>
                                <TextField config={{
                                    name: "ItemsPerPage",
                                    label: "Items Per Page",
                                    maxLength: 10,
                                    showLabel: true,
                                    value: fields.ItemsPerPage.toString(),
                                    error: (submitted && errors.ItemsPerPage) ? errors.ItemsPerPage : ""
                                }} />
                            </div>
                        </div>
                        { IsTaskTypeKerningGrid(fields.TaskTypeId, config.taskTypes) &&
                        <div className={"grid-x"}>
                            <div className={"cell medium-4 large-4"}>
                                <TextField config={{
                                    name: "GridRowTotal",
                                    label: "# of Rows",
                                    maxLength: 10,
                                    showLabel: true,
                                    value: fields.GridRowTotal.toString(),
                                    error: (submitted && errors.GridRowTotal) ? errors.GridRowTotal : ""
                                }}/>
                            </div>
                        </div>
                        }
                        {IsTaskTypeKerningGrid(fields.TaskTypeId, config.taskTypes) &&
                        <div className={"grid-x"}>
                            <div className={"cell medium-4 large-4"}>
                                <TextField config={{
                                    name: "GridColumnTotal",
                                    label: "# of Columns",
                                    maxLength: 10,
                                    showLabel: true,
                                    value: fields.GridColumnTotal.toString(),
                                    error: (submitted && errors.GridColumnTotal) ? errors.GridColumnTotal : ""
                                }}/>
                            </div>
                        </div>
                        }
                        {IsTaskTypeKerningGrid(fields.TaskTypeId, config.taskTypes) &&
                        <div className={"grid-x"}>
                            <div className={"cell medium-4 large-4"}>
                                <TextField config={{
                                    name: "GridSpacing",
                                    label: "Spacing",
                                    maxLength: 10,
                                    showLabel: true,
                                    value: fields.GridSpacing.toString(),
                                    error: (submitted && errors.GridSpacing) ? errors.GridSpacing : ""
                                }}/>
                            </div>
                        </div>
                        }
                    </div>
                )}
            </FormValidation>
        </ValidatorsProvider>
    );
};

TaskInfoForm.propTypes = {
    config: PropTypes.shape({
        onTaskSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        taskInfo: PropTypes.object,
        shouldDisableSave: PropTypes.bool.isRequired,
        taskTypes: PropTypes.array.isRequired,
        message: PropTypes.string
    })
};

export default TaskInfoForm;