import * as actionTypes from "./HeaderConstants";

export const headerInitialState = {
    Email: "",
    RouteInfo: {},
};

export function headerReducer(state = headerInitialState, action) {
    switch(action.type){
        case actionTypes.GET_USERID_FOR_HEADER_AJAX_SUCCESS:
            return Object.assign({}, state, {Email: action.userInfo.Email});
        case actionTypes.UPDATE_ROUTE_INFO:
            return Object.assign({}, state, {RouteInfo: action.route});
        case actionTypes.LOGOFF_SUCCESS:
            return Object.assign({}, state, headerInitialState);
        default:
            return state;
    }
}