import * as config from "../../constants/config";
import {getDistrictData} from "../../mockData/districtData";
import {mockResolveApiCall, sendRequest} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerDistrictApi {
    static getDistricts() {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}districts/basic`,
                RequestTypes.GET,
            ));
    }

    static getDistrict() {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}districts`,
                RequestTypes.GET,
            ));
    }

}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockDistrictApi {
    static getDistricts() {
        return mockResolveApiCall(getDistrictData());
    }

    static getDistrict() {
        return mockResolveApiCall([getDistrictData()[1]]);
    }
}

const DistrictApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerDistrictApi : MockDistrictApi;
export default DistrictApi;