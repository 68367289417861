export function Handler(result, list) {
    // dropped outside the list
    if (!result.destination)
        return list;

    return reorder(
        list,
        result.source.index,
        result.destination.index
    );
}

function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}
