import React from "react";
import PropTypes from "prop-types";

const ItemParameterRow = ({ itemParameter }) => {
    return (
        <tr>
            <td className={"text-left"}>
                {itemParameter.Name}
            </td>
            <td className={"text-center"}>
                {itemParameter.ItemParameterA}
            </td>
            <td className={"text-center"}>
                {itemParameter.ItemParameterB}
            </td>
        </tr>
    );
};

ItemParameterRow.propTypes = {
    itemParameter: PropTypes.object.isRequired,
};

export default ItemParameterRow;