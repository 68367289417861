import React from "react";
import PropTypes from "prop-types";
import * as buttonConstants from "./buttonConstants";

const ButtonIcon = ({ config }) => {
    config = Object.assign({}, buttonIconDefaults(), config);
    const isButtonIconTypeIsValid = ((buttonConstants.iconTypes.filter(f => f === config.iconType)).length > 0);

    if(!isButtonIconTypeIsValid)
        return null;

    let btnType = "button";
    if(config.isSubmit)
        btnType = "submit";

    return (
        <button
            id={config.name}
            type={btnType}
            onClick={config.onClick}
            className={"icons icon-" + config.iconType}
            disabled={config.disabled}
            aria-label={config.label}
            title={config.label}>
            <span />
        </button>
    );
};

ButtonIcon.propTypes = {
    config: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        iconType: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        isSubmit: PropTypes.bool
    })
};

function buttonIconDefaults() {
    return {
        disabled: false,
        isSubmit: false
    };
}

export default ButtonIcon;
