import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter } from "react-router-dom";
import {bindActionCreators} from 'redux';
import * as headerActions from "../AuthenticatedLayout/HeaderAction";
import * as assessmentActions from "./AssessmentActions";
import * as taskActions from "../Tasks/TaskAction";
import * as routes from "../../components/routes/routesConstants";
import {getParams} from "../../components/layout/getParams";
import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import AssessmentInfoForm from "./AssessmentInfoForm";
import {Notify} from "../../components/notification/notify";
import PushFocusToElement from "../../components/form/pushFocusToElement";
import * as config from "../../constants/config";
import $ from 'jquery';
import * as sortOptionOnDragEnd from "../../components/inputs/sortOptionOnDragEnd";
import * as loginActions from "../Login/LoginAction";

let searchBoxEnterKey = false;

export class AssessmentInfoPage extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            assessmentId: 0,
            calidationSubmitButtonClick: "btnSave"
        };

        this.onAssessmentSubmit = this.onAssessmentSubmit.bind(this);
        this.onReturnAssessmentList = this.onReturnAssessmentList.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDeleteTask = this.onDeleteTask.bind(this);
        this.onTaskChange = this.onTaskChange.bind(this);
        this.onCalidationMcatConfigClick = this.onCalidationMcatConfigClick.bind(this);
        this.onPublishClick = this.onPublishClick.bind(this);
        this.onDeletePublishedClick = this.onDeletePublishedClick.bind(this);
    }

    componentDidMount(){
        this.props.actions.updatePageTitle_BreadCrumb(routes.ASSESSMENT_INFO);

        this.props.actions.loadTaskSelectList();

        const paramObj = getParams(this.props.location.pathname, routes.ASSESSMENT_INFO);
        if(paramObj !== null)
            this.setState(paramObj);

        if(paramObj.assessmentId !== "0")
            this.props.actions.editAssessment(parseInt(paramObj.assessmentId));
        else
            this.props.actions.addAssessment();

        $(document).ready(() => {
            PushFocusToElement("Name");
            searchBoxEnterKey = this.preventSearchBoxEnterKeyFromSubmittingForm();
        });
    }

    preventSearchBoxEnterKeyFromSubmittingForm() {
        $(window).keydown(function(event){
            searchBoxEnterKey = (event.keyCode === 13 && event.target.className === "select-search-box__search");
        });
    }

    onDragEnd(result, setField) {
        let assessment = Object.assign({}, this.props.assessmentInfo);

        const newTasks = sortOptionOnDragEnd.Handler(result, assessment.TaskIds);

        assessment.TaskIds = newTasks;

        setField({TaskIds: newTasks});

        this.props.actions.updateAssessment(assessment);
    }

    onDeleteTask(event, index, setField) {
        event.preventDefault();
        if(confirm("Are you sure you want to remove this task?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page.")) {
            let allTasks = [...this.props.assessmentInfo.TaskIds];
            allTasks.splice(index, 1);
            setField({TaskIds: allTasks});

            this.props.actions.unassociateTask(index);
        }
    }

    onTaskChange(task, setField) {
        var taskIds = [...this.props.assessmentInfo.TaskIds];
        taskIds.push(parseInt(task.value));
        setField({TaskIds: taskIds});
        this.props.actions.associateTask(parseInt(task.value));

        let container = $(".sort-container > div.cell > div");
        let height = container.get(0).scrollHeight;
        container.animate({scrollTop: height});

        searchBoxEnterKey = false;
    }

    onAssessmentSubmit({fields, isValid}) {
        if (isValid && !searchBoxEnterKey) {
            this.setState({isLoading: true});

            let detachedFields = Object.assign({}, {Name: fields.Name, AssessmentType: fields.AssessmentType});
            if(detachedFields.Id === "")
                detachedFields.Id = 0;

            const assessment = Object.assign({}, this.props.assessmentInfo, detachedFields);

            this.props.actions.saveAssessment(assessment)
                .then((assessment) => {
                    const calidationSubmitRoute = this.state.calidationSubmitButtonClick === "btnSave" ? routes.ASSESSMENT_MGMT.path
                        : routes.ASSESSMENT_MCAT_CONFIG_INFO.path.replace(routes.ASSESSMENT_MCAT_CONFIG_INFO.params.assessmentId, assessment.Id);
                    this.props.history.push(calidationSubmitRoute)
                })
                .catch(() => {
                     this.setState({isLoading: false});
                });
        }
        else if(searchBoxEnterKey) {
            PushFocusToElement("select-search-box__search");
        }
        else {
            PushFocusToElement("Name");
        }
    }

    onReturnAssessmentList(event) {
        event.preventDefault();
        Notify.Clear();
        this.props.history.push(routes.ASSESSMENT_MGMT.path);
    }

    onCalidationMcatConfigClick() {
        this.setState({calidationSubmitButtonClick: "btnMcatConfig"});
    }

    onPublishClick(event) {
        event.preventDefault();
        if(confirm("Are you sure you want to publish this assessment?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page.")) {
            this.props.actions.publishAssessment(this.props.assessmentInfo.Id);
        }
    }

    onDeletePublishedClick(event) {
        event.preventDefault();
        if(confirm("Are you sure you want to un-publish this assessment?\n\nPress \"Ok\" to continue or \"Cancel\" to return to the page.")) {
            this.props.actions.deletePublishedAssessment(this.props.assessmentInfo.Id);
        }
    }

    isAssessmentUpdateable() {
        if(this.state.assessmentId === "0")
            return true;

        if(this.props.assessmentInfo === undefined ||
            this.props.assessmentInfo.Links === undefined)
            return false;

        const assessmentLinkList = this.props.assessmentInfo.Links.filter(f => f.Method === config.DELETE_API_METHOD);
        return (assessmentLinkList.length > 0);
    }

    createPageDirections() {
        let pageDir = "";
        if(this.state.assessmentId === "0")
            pageDir = "Create a new assessment.";
        else {
            pageDir = !this.isAssessmentUpdateable() ? <strong className={"text-danger"}>NOTE - this assessment is associated with scores.  Only make changes that will not affect the overall outcome of previously saved scores.</strong> : "Edit an assessment.";
        }
        return <p>{pageDir}</p>;
    }

    render() {
        const {assessmentInfo, taskSelectList} = this.props;
        return (
            <div>
                <div className={"clearfix"}>
                    <Button config={{
                        name: "btnReturnAssessmentList",
                        label: "Return to Assessment List",
                        onClick: this.onReturnAssessmentList,
                        btnClass: "light float-right",
                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                    }}/>
                    {this.createPageDirections()}
                </div>
                {
                    assessmentInfo.Id !== undefined &&
                    taskSelectList !== undefined &&
                    assessmentInfo.Id.toString() === this.state.assessmentId &&
                    taskSelectList.length > 0 &&
                    <div>
                        <AssessmentInfoForm config={{
                            onAssessmentSubmit: this.onAssessmentSubmit,
                            isLoading: this.state.isLoading,
                            assessmentInfo: assessmentInfo,
                            isUpdateable: true,
                            onReturnAssessmentList: this.onReturnAssessmentList,
                            onDragEnd: this.onDragEnd,
                            taskSelectList: taskSelectList,
                            onDeleteTask: this.onDeleteTask,
                            onTaskChange: this.onTaskChange,
                            onCalidationMcatConfigClick: this.onCalidationMcatConfigClick,
                            onPublishClick: this.onPublishClick,
                            onUpdatePublishedClick: this.onUpdatePublishedClick,
                            onDeletePublishedClick: this.onDeletePublishedClick
                        }}/>
                    </div>
                }
            </div>
        );
    }
}

AssessmentInfoPage.propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    assessmentInfo: PropTypes.object,
    taskSelectList: PropTypes.array
};

function mapStateToProps(state) {
    return {
        assessmentInfo: state.assessment.assessmentInfo,
        taskSelectList: state.task.taskSelectList
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        headerActions,
        assessmentActions,
        taskActions,
        loginActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (AssessmentInfoPage));
