let itemPools = null;

export function getItemPoolData() {
    if(itemPools === null) {
        itemPools = [
            {
                Id: 1,
                Name: "A1",
                CatModelType: 1,
                CatModelTypeDescription: "UCAT",
                LastImportFileName: "Import File 1",
                LastImportDate: "3/25/2020",
                AssociatedWithTaskId: 1,
                ItemParameters: [
                    {
                        Name: "Parameter 1",
                        ItemParameterA: 1.02,
                        ItemParameterB: 1.25
                    },
                    {
                        Name: "Parameter 2",
                        ItemParameterA: 1.32,
                        ItemParameterB: 1.45
                    }
                ],
                Links: [{
                    Href: "1",
                    Rel: "delete_itemPool",
                    Method: "DELETE"
                }]
            },
            {
                Id: 2,
                Name: "A2",
                CatModelType: 4,
                CatModelTypeDescription: "Mastery",
                LastImportFileName: "Import File 2",
                LastImportDate: "3/25/2020",
                AssociatedWithTaskId: 2,
                ItemParameters: [],
                Links: []
            },
            {
                Id: 3,
                Name: "A3",
                CatModelType: 1,
                CatModelTypeDescription: "UCAT",
                LastImportFileName: "Import File 3",
                LastImportDate: "3/25/2020",
                AssociatedWithTaskId: 1,
                ItemParameters: [],
                Links: [{
                    Href: "3",
                    Rel: "delete_itemPool",
                    Method: "DELETE"
                }]
            },
            {
                Id: 6,
                Name: "A6",
                CatModelType: 1,
                CatModelTypeDescription: "UCAT",
                LastImportFileName: "Import File 6",
                LastImportDate: "3/25/2020",
                ItemParameters: [],
                Links: []
            },
            {
                Id: 4,
                Name: "A4",
                CatModelType: 2,
                CatModelTypeDescription: "CIRT Scoring",
                LastImportFileName: "Import File 4",
                LastImportDate: "3/25/2020",
                AssociatedWithTaskId: 2,
                ItemParameters: [],
                Links: [{
                    Href: "4",
                    Rel: "delete_itemPool",
                    Method: "DELETE"
                }]
            },
            {
                Id: 5,
                Name: "A5",
                CatModelType: 4,
                CatModelTypeDescription: "Mastery",
                LastImportFileName: "Import File 5",
                LastImportDate: "3/25/2020",
                AssociatedWithTaskId: 3,
                ItemParameters: [],
                Links: [{
                    Href: "5",
                    Rel: "delete_itemPool",
                    Method: "DELETE"
                }]
            },

        ];
    }

    return itemPools;
}

export function saveItemPoolData(newitemPoolList) {
    itemPools = newitemPoolList;
}