import $ from 'jquery';
import * as config from "../../constants/config";
import LocalStorage from "../../components/localStorage/access";
import {
    getTasksData,
    saveTasksData,
    getItemBanksArrayForTask, getTaskTypesData
} from "../../mockData/assessmentData";
import * as ApiSuccessMessages from "../../components/handlers/apiSuccessMessages";
import convertObjWithSpecificFields from "../../mockData/convertObjWithSpecificFields";
import {mockRejectApiCall, mockResolveApiCall, sendRequest} from "../../components/handlers/requests/apiUtility";
import AjaxRequestOptions from "../../components/handlers/requests/AjaxRequestOptions";
import {RequestTypes} from "../../components/handlers/requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerTaskApi {
    static getTasks(taskSearchCriteria) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}tasks?${$.param(taskSearchCriteria)}`,
                RequestTypes.GET,
            ));
    }

    static getTask(taskId) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}tasks/${taskId}`,
                RequestTypes.GET,
            ));
    }

    static deleteTask(linkInfo) {
        return sendRequest(
            AjaxRequestOptions(
                linkInfo.Href,
                RequestTypes.DELETE,
                ApiSuccessMessages.TASK_DELETED
            ));
    }

    static addTask(task) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}tasks`,
                RequestTypes.POST,
                JSON.stringify(task),
                ApiSuccessMessages.TASK_ADDED
            )
        );
     }

    static updateTask(task) {
        return sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}tasks`,
                RequestTypes.PUT,
                JSON.stringify(task),
                ApiSuccessMessages.TASK_UPDATED
            )
        );
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockTaskApi {
    static getNewId() {
        const tasks = getTasksData();
        let taskId = 0;
        tasks.map(task => {
            if (task.Id > taskId)
                taskId = task.Id;
        });

        return (taskId + 1);
    }

    static getNewFormId() {
        const tasks = getTasksData();
        let formId = 0;
        tasks.map(task => {
            task.Forms.map(form => {
                if (form.Id > formId)
                    formId = form.Id;
            })
        });

        return (formId + 1);
    }

    static getTasks(taskSearchCriteria) {
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else {
            let tasks = getTasksData();

            if (taskSearchCriteria.NameContains !== "")
                tasks = tasks.filter(task => task.Name.includes(taskSearchCriteria.NameContains));

            if (taskSearchCriteria.ItemBankNameContains !== "")
                tasks = tasks.filter(task => task.ItemBanks.filter(itemBanks => itemBanks.Name.includes(taskSearchCriteria.ItemBankNameContains)).length > 0);

            if (taskSearchCriteria.TaskTypeId > 0)
                tasks = tasks.filter(task => task.TaskTypeId === taskSearchCriteria.TaskTypeId);

            if (taskSearchCriteria.CatModelType !== null && taskSearchCriteria.CatModelType >= 0)
                tasks = tasks.filter(task => task.CatModelType === taskSearchCriteria.CatModelType);

            let sortProp = taskSearchCriteria.OrderByColumn;
            let sortDir = (taskSearchCriteria.OrderDescending) ? -1 : 1;

            tasks.sort(function (a, b) {
                return (a[sortProp] > b[sortProp]) ? sortDir : ((b[sortProp] > a[sortProp]) ? -1 * sortDir : 0);
            });

            let recordsPerPage = tasks.length;
            if (taskSearchCriteria.RecordsPerPage !== null)
                recordsPerPage = taskSearchCriteria.RecordsPerPage;

            const skip = (taskSearchCriteria.Page - 1) * recordsPerPage;
            const take = recordsPerPage + skip;

            let tasksReturned = (taskSearchCriteria.Fields !== "")
                ? convertObjWithSpecificFields(taskSearchCriteria.Fields, tasks)
                : tasks.slice(skip, take);

            return mockResolveApiCall(Object.assign({}, {
                Data: tasksReturned,
                TotalRecords: tasks.length
            }));
        }
    }

    static getTask(taskId) {
        let tasks = getTasksData();
        const taskRecord = tasks.filter(i => i.Id === taskId);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (taskRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the task record.");
        else {
            return mockResolveApiCall(taskRecord[0]);
        }
    }

    static deleteTask(linkInfo) {
        let tasks = getTasksData();
        const taskRecord = tasks.filter(i => i.Id === parseInt(linkInfo.Href));
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (taskRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the task record.");
        else {
            const otherTasks = tasks.filter(i => i.Id !== parseInt(linkInfo.Href));
            saveTasksData([...otherTasks]);
            return mockResolveApiCall(ApiSuccessMessages.TASK_DELETED);
        }
    }

    static addTask(task) {
        let tasks = getTasksData();
        const taskTypes = getTaskTypesData();
        const otherTasks = tasks.filter(i => i.Id !== task.Id);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (otherTasks.filter(f => f.Name === task.Name).length > 0)
            return mockRejectApiCall("The task name entered is already associated with another task.");
        else {
            const newId = this.getNewId();
            let newFormIdStart = this.getNewFormId();
            const addTask = {
                Id: newId,
                Name: task.Name,
                TaskTypeId: task.TaskTypeId,
                TimeLimit: task.TimeLimit,
                ItemsPerPage: task.ItemsPerPage,
                GridRowTotal: task.GridRowTotal,
                GridColumnTotal: task.GridColumnTotal,
                GridSpacing: task.GridSpacing,
                TaskType: taskTypes.filter(f => f.Id === task.TaskTypeId)[0].Description,
                ItemBanks: getItemBanksArrayForTask(task.Forms),
                NumberOfForms: task.Forms.length,
                CatModelType: task.CatModelType,
                CatConfig: {...task.CatConfig, ...{TaskId: newId}},
                Forms: task.Forms.map(form => {
                    return {
                        Id: newFormIdStart++,
                        Name: form.Name,
                        ItemBankId: form.ItemBankId
                    }
                }),
                Links: [{
                    Href: newId.toString(),
                    Rel: "delete_task",
                    Method: "DELETE"
                }]
            };
            saveTasksData([...otherTasks, addTask]);
            return mockResolveApiCall(ApiSuccessMessages.TASK_ADDED);
        }
    }

    static updateTask(task) {
        let tasks = getTasksData();
        const taskTypes = getTaskTypesData();
        const otherTasks = tasks.filter(i => i.Id !== task.Id);
        const taskRecord = tasks.filter(i => i.Id === task.Id);
        if (!LocalStorage.isLoggedIn())
            return mockRejectApiCall("There was an error while attempting to retrieve your account.");
        else if (taskRecord.length === 0)
            return mockRejectApiCall("There was an error while attempting to retrieve the task record.");
        else if (otherTasks.filter(f => f.Name === task.Name).length > 0)
            return mockRejectApiCall("The task name entered is already associated with another task.");
        else {
            let newFormIdStart = this.getNewFormId();
            const editTask = {
                Id: task.Id,
                Name: task.Name,
                TaskTypeId: task.TaskTypeId,
                TimeLimit: task.TimeLimit,
                ItemsPerPage: task.ItemsPerPage,
                GridRowTotal: task.GridRowTotal,
                GridColumnTotal: task.GridColumnTotal,
                GridSpacing: task.GridSpacing,
                TaskType: taskTypes.filter(f => f.Id === task.TaskTypeId)[0].Description,
                ItemBanks: getItemBanksArrayForTask(task.Forms),
                NumberOfForms: task.Forms.length,
                CatModelType: task.CatModelType,
                CatConfig: task.CatConfig,
                Forms: task.Forms.map(form => {
                    return {
                        Id: (form.Id === 0) ? newFormIdStart++ : form.Id,
                        Name: form.Name,
                        ItemBankId: form.ItemBankId
                    }
                }),
                Links: taskRecord[0].Links
            };
            saveTasksData([...otherTasks, editTask]);
            return mockResolveApiCall(ApiSuccessMessages.TASK_UPDATED);
        }
    }
}

const TaskApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerTaskApi : MockTaskApi;
export default TaskApi;