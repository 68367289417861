import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";

import TextField from '../../components/inputs/textField';
import Button from '../../components/buttons/button';
import * as buttonConstants from "../../components/buttons/buttonConstants";
import {hasErrors} from "../../components/form/createErrorList";

const ResetPasswordForm = ({ config }) => {
    let formConfig = {
        Password: {
            isRequired: 'Password is required!'
        },
        ConfirmPassword: {
            isRequired: 'Confirm password is required!',
            isEqual: ({fields}) => {
                return {
                    message: 'The two passwords must match',
                    value: fields.Password,
                    validateIf: fields.Password.length > 0
                };
            }
        }
    };

    return (
        <FormValidation onSubmit={config.onResetPasswordSubmit} config={formConfig}>
        {({ fields, errors, submitted }) => (
            <div>
                <p>Update your password.</p>

                {submitted && hasErrors(errors) && <p className="text-danger">Please correct the issues as specified.</p>}

                <TextField config={{
                    name: "Password",
                    label: "Password",
                    maxLength: 64,
                    inputType: "password",
                    showLabel: true,
                    value: fields.Password,
                    error: (submitted && errors.Password) ? errors.Password : ""
                }} />
                <TextField config={{
                    name: "ConfirmPassword",
                    label: "Confirm Password",
                    inputType: "password",
                    maxLength: 64,
                    showLabel: true,
                    value: fields.ConfirmPassword,
                    error: (submitted && errors.ConfirmPassword) ? errors.ConfirmPassword : ""
                }} />


                <div className={"button-group expanded margin-top-2"}>
                    <Button config={{
                        name: "btnReturn",
                        label: "Return to the Login Page",
                        onClick: config.onReturn,
                        btnClass: "secondary",
                        btnType: buttonConstants.BUTTON_TYPE_BUTTON
                    }} />
                    <Button config={{
                        name: "btnSubmit",
                        label: "Update Password",
                        disabled: config.isLoading
                    }} />
                </div>
            </div>
        )}
        </FormValidation>
    );
};

ResetPasswordForm.propTypes = {
    config: PropTypes.shape({
        onResetPasswordSubmit: PropTypes.func.isRequired,
        onReturn: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    })
};

export default ResetPasswordForm;
