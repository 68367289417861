import React from 'react';
import PropTypes from "prop-types";
import { FormValidation } from "calidation";

import Button from "../../components/buttons/button";
import * as buttonConstants from "../../components/buttons/buttonConstants";
import TextField from "../../components/inputs/textField";

const ItemBankListSearchForm = ({config}) => {
    let formConfig = {
        NameContains: {},
        ItemNameContains: {}
    };
    return (
        <FormValidation onSubmit={config.onSearchSubmit} config={formConfig} initialValues={config.itemBankSearchCriteria} >
            {({ fields, setField }) => (
                <div className={"input-bottom-alignment"}>
                    <div className={"grid-x grid-padding-x align-bottom"}>
                        <div className={"cell medium-3 large-3"}>
                            <TextField config={{
                                name: "NameContains",
                                label: "Item Bank Name:",
                                maxLength: 50,
                                showLabel: true,
                                value: fields.NameContains,
                                inputClass: "margin-bottom-0"
                            }} />
                        </div>
                        <div className={"cell medium-3 large-3"}>
                            <TextField config={{
                                name: "ItemNameContains",
                                label: "Associated with Item Name:",
                                maxLength: 50,
                                showLabel: true,
                                value: fields.ItemNameContains,
                                inputClass: "margin-bottom-0"
                            }} />
                        </div>
                        <div className={"cell medium-3 large-3"}>
                            <div className={"button-group"}>
                                <Button config={{
                                    name: "btnClearItemBankSearch",
                                    label: "Clear",
                                    onClick: (event) => config.clearCriteria(event, setField),
                                    btnClass: "light",
                                    btnType: buttonConstants.BUTTON_TYPE_BUTTON
                                }} />
                                <Button config={{
                                    name: "btnSearchItemBanks",
                                    label: "Search",
                                    disabled: config.isLoading
                                }} />
                            </div>
                        </div>
                        <div className={"cell medium-3 large-3 text-right"}>
                            <Button config={{
                                name: "btnCreateItemBank",
                                label: "Create Item Bank",
                                onClick: config.onAddItemBank,
                                btnClass: "light",
                                btnType: buttonConstants.BUTTON_TYPE_BUTTON
                            }}/>
                        </div>
                    </div>
                </div>
            )}
        </FormValidation>
    );
};

ItemBankListSearchForm.propTypes = {
    config: PropTypes.shape({
        onSearchSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        clearCriteria: PropTypes.func.isRequired,
        itemBankSearchCriteria: PropTypes.object,
        onAddItemBank: PropTypes.func.isRequired
    })
};

export default ItemBankListSearchForm;